import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { ModalFooter } from '@chakra-ui/react';
import { BankDetailsPage } from '../pages/BankDetails';

type BankDetailsModalProps = {
  isOpen: boolean;
  invoiceNumber: number;
  onClose: () => void;
};

export const BankDetailsModal = (props: BankDetailsModalProps) => {
  const { isOpen, onClose, invoiceNumber } = props;
  return (
    <Modal isOpen={isOpen} size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Detajet e bankës</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <BankDetailsPage invoiceNumber={invoiceNumber} />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
