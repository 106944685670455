import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { BsSearch } from 'react-icons/bs';
import { CardHeader } from '../../../components/Card';
import { Link } from '../../../components/Link';
import { Loader } from '../../../components/Loader';
import { TablePagination } from '../../../components/Pagination';
import {
  AdminIssue,
  IssueStatus,
  useApproveIssueMutation,
} from '../../../graphql';
import { useAdminIssues } from '../../../hooks/useAdminIssues';
import { getColorStatus, getStatusLabel } from '../../../utils/issue-status';
import { IColumn, SimpleTable } from '../../../utils/table';

export const AdminIssues: React.FC = () => {
  const {
    items,
    loading,
    showEmptyResults,
    totalCount,
    pageInfo,
    getNextPage,
    getPreviousPage,
    refetch,
    search,
    setSearch,
    status,
    setStatus,
  } = useAdminIssues();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentItem, setCurrentItem] = React.useState<AdminIssue>();
  const [approveIssue] = useApproveIssueMutation();
  const [approving, setApproving] = React.useState(false);
  const cancelRef = React.useRef<any>();

  const issueStatuses: IssueStatus[] = useMemo(() => {
    return [
      IssueStatus.InReview,
      IssueStatus.WaitingOffers,
      IssueStatus.ChooseOfferStage,
      IssueStatus.CooperationStage,
      IssueStatus.ClosedSuccessfully,
      IssueStatus.ClosedUnsuccessfully,
      IssueStatus.RejectedByAdmin,
    ];
  }, []);

  const columns: IColumn[] = [
    {
      Header: 'Titulli',
      accessor: 'title',
      tdParams: {
        width: '15%',
      },
    },
    {
      Header: 'Emri',
      accessor: 'issuer.profile.name',
      tdParams: {
        width: '10%',
      },
    },
    {
      Header: 'Mbiemri',
      accessor: 'issuer.profile.lastname',
      tdParams: {
        width: '10%',
      },
    },
    {
      Header: 'Numri i ofertave',
      accessor: 'totalBids',
      tdParams: {
        width: '5%',
        textAlign: 'center',
      },
    },
    {
      Header: 'Statusi',
      accessor: '',
      tdParams: {
        width: '20%',
      },
      Cell: function StatusCell(cell: any, row: any) {
        return (
          <Text fontWeight="bold" color={getColorStatus(row.status)}>
            {getStatusLabel(row.status)}
          </Text>
        );
      },
    },
    {
      Header: 'Data e krijimit',
      accessor: 'createdAt',
      tdParams: {
        width: '20%',
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function DetailsCell(cell: any, row: AdminIssue) {
        return <Link href={`/admin/issue/${row.id}`}>Shiko detajet</Link>;
      },
      tdParams: {
        textAlign: 'right',
        width: '20%',
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function ApproveCell(cell: any, row: AdminIssue) {
        return (
          <Box>
            {row.status === IssueStatus.InReview && (
              <Button
                variant="link"
                colorScheme="blue"
                onClick={() => {
                  setCurrentItem(row);
                  onOpen();
                }}
                size="sm"
              >
                Aprovo
              </Button>
            )}
          </Box>
        );
      },
      tdParams: {
        textAlign: 'right',
      },
    },
  ];

  const approveIssueHandler = async () => {
    try {
      setApproving(true);
      if (currentItem) {
        await approveIssue({
          variables: {
            approveIssueId: currentItem.id,
          },
        });
        toast({
          title: 'Sukses',
          description: 'Çështja u aprovua me sukses',
          status: 'success',
        });
        await refetch();
        onClose();
      }
    } finally {
      setApproving(false);
    }
  };

  return (
    <Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Aprovo Çështjen?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Duke aprovuar këtë çështje do të njoftohet klienti dhe avokatët
            përkatës të specialitetit të duhur.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Anullo
            </Button>
            <Button
              isLoading={approving}
              onClick={approveIssueHandler}
              colorScheme="red"
              ml={3}
            >
              Aprovo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <CardHeader
        title="Lista e çështjeve"
        action={
          <Box className="issue-filters">
            <Stack
              spacing="4"
              direction={{ base: 'column', md: 'row' }}
              justify="space-between"
            >
              <HStack>
                <FormControl minW={{ md: '320px' }} id="search">
                  <InputGroup size="sm">
                    <FormLabel srOnly>Filter by name or email</FormLabel>
                    <InputLeftElement pointerEvents="none" color="gray.400">
                      <BsSearch />
                    </InputLeftElement>
                    <Input
                      onChange={(e) => setSearch(e.target.value)}
                      value={search || ''}
                      placeholder="Kërko"
                    />
                  </InputGroup>
                </FormControl>
              </HStack>
              <Select
                size="sm"
                value={status || undefined}
                onChange={(e) => setStatus(e.target.value)}
                placeholder="Zgjidhni statusin"
              >
                {issueStatuses.map((status) => (
                  <option key={status} value={status}>
                    {getStatusLabel(status)}
                  </option>
                ))}
              </Select>
            </Stack>
          </Box>
        }
      />

      {loading ? (
        <Loader />
      ) : showEmptyResults && items.length === 0 && !loading ? null : (
        <SimpleTable columns={columns} data={items} />
      )}
      {pageInfo && (
        <TablePagination
          total={totalCount}
          pageInfo={pageInfo}
          onNextPage={getNextPage}
          onPreviousPage={getPreviousPage}
        />
      )}
    </Box>
  );
};
