import * as React from 'react';
import { Box } from '@chakra-ui/layout';
import { Account } from '../../graphql';
import { Property } from '../../components/Property';

export const GeneralInfo = ({ account }: { account: Account }) => {
  return (
    <Box w="full" mt={10}>
      <Property label="Email" value={account.email} />
      <Property label="Roli" value={account.role} />
      <Property label="Numri i telefonit" value={account.phoneNumber || '-'} />
      <Property label="Statusi i llogarisë" value={account.accountStatus} />
    </Box>
  );
};
