import {
  Avatar,
  Box,
  Flex,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useMenuButton,
  UseMenuButtonProps,
  useColorModeValue as mode,
  Spinner,
  Tag,
} from '@chakra-ui/react';
import { BsPerson } from 'react-icons/bs';
import { FaSignOutAlt } from 'react-icons/fa';
import { MdHelpOutline, MdOutlineSubscriptions } from 'react-icons/md';
import { useHistory } from 'react-router';
import { AccountRole, Profile, useMeQuery } from '../../graphql';
import { useLogout } from '../../hooks/useLogout';

type UserAvatarProps = {
  profile: Profile;
};

type ProfileDropdownProps = UseMenuButtonProps & UserAvatarProps;

const ProfileMenuButton = (props: ProfileDropdownProps) => {
  const buttonProps = useMenuButton(props);

  return (
    <Flex
      {...buttonProps}
      as="button"
      flexShrink={0}
      rounded="full"
      outline="0"
      _focus={{ shadow: 'outline' }}
    >
      <Box srOnly>Open user menu</Box>
      <Avatar
        size="sm"
        name={props.profile.name}
        src={props.profile.profilePicture?.sm}
      />
    </Flex>
  );
};

interface AccountRoleTagProps {
  role: AccountRole;
}
const AccountRoleTag = ({ role }: AccountRoleTagProps) => {
  if (role === AccountRole.Admin) {
    return <Tag>Administrator</Tag>;
  } else if (role === AccountRole.Client) {
    return <Tag>Klient</Tag>;
  } else if (role === AccountRole.Lawyer) {
    return <Tag>Avokat</Tag>;
  } else {
    return null;
  }
};

export const ProfileDropdown = () => {
  const { data: user, loading } = useMeQuery();
  const logout = useLogout();
  const history = useHistory();

  if (!user || loading) {
    return <Spinner />;
  }

  const handleGoToSupport = () => {
    window.open('https://legit.al/info/pyetje-dhe-pergjigje/', '_blank');
  };

  return (
    <Menu>
      <ProfileMenuButton profile={user.me.profile! as Profile} />
      <MenuList
        rounded="md"
        shadow="lg"
        py="1"
        color={mode('gray.600', 'inherit')}
        fontSize="sm"
      >
        <HStack px="3" py="4">
          <Avatar
            name={user.me.profile?.name || ''}
            src={user.me.profile?.profilePicture?.sm}
          />
          <Box lineHeight="1">
            <Text mt="1" fontSize="md" fontWeight={'bold'}>
              {user.me.profile?.name || ''} {user.me.profile?.lastname || ''}
            </Text>
            <Text mt="1" fontSize="md">
              <AccountRoleTag role={user.me.role} />
            </Text>
          </Box>
        </HStack>
        <MenuItem
          icon={<BsPerson />}
          onClick={() => history.push('/profile')}
          fontWeight="medium"
        >
          Profili
        </MenuItem>
        {user.me.role === AccountRole.Client && (
          <MenuItem
            onClick={() => history.push('/subscription')}
            fontWeight="medium"
            icon={<MdOutlineSubscriptions />}
          >
            Abonimi
          </MenuItem>
        )}
        <MenuItem
          onClick={handleGoToSupport}
          fontWeight="medium"
          icon={<MdHelpOutline />}
        >
          Ndihmë
        </MenuItem>
        <MenuItem
          onClick={logout}
          fontWeight="medium"
          color={mode('red.500', 'red.300')}
          icon={<FaSignOutAlt />}
        >
          Dil
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
