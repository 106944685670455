import React from 'react';
import { Redirect } from 'react-router-dom';
import { AccountRole, AccountStatus } from '../../graphql';
import { useAuthToken } from '../../hooks';
import { useLocation } from 'react-router';

export const Private: React.FC<{ roles?: AccountRole[]; children: any }> = ({
  children,
  roles,
}) => {
  const location = useLocation();
  const { token, user } = useAuthToken();

  if (!user || !token) {
    return <Redirect to="/login" />;
  }

  if (
    location.pathname !== '/activate-account' &&
    (user.accountStatus === AccountStatus.Unconfirmed ||
      user.accountStatus === AccountStatus.PendingAdminVerification)
  ) {
    return <Redirect to="/activate-account" />;
  }

  if (!roles || roles.length === 0) {
    if (!token) {
      return <Redirect to="/login" />;
    } else {
      return children;
    }
  } else {
    if (!token) {
      return <Redirect to="/login" />;
    } else {
      if (roles.includes(user.role)) {
        return children;
      } else {
        return <Redirect to="/home" />;
      }
    }
  }
};
