import {
  Box,
  BoxProps,
  useColorModeValue,
  Flex,
  Heading,
} from '@chakra-ui/react';
import * as React from 'react';

export const Card = (props: BoxProps) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    py="8"
    px={{ base: '4', md: '10' }}
    shadow="base"
    rounded={{ sm: 'lg' }}
    {...props}
  />
);

interface Props {
  title: string | React.ReactNode;
  action?: React.ReactNode;
}

export const CardHeader = (props: Props) => {
  const { title, action } = props;
  return (
    <Flex
      align="center"
      justify="space-between"
      px="6"
      py="4"
      borderBottomWidth="1px"
      marginBottom={"15px"}
    >
      <Heading as="h2" fontSize="xl">
        {title}
      </Heading>

      {action}
    </Flex>
  );
};
