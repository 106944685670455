import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { useColorModeValue as mode } from '@chakra-ui/color-mode';
import { Box, Flex, Text } from '@chakra-ui/react';
import { TableEmpty } from './table-empty';

const accessRowElement = (row: any, value = ''): string => {
  if (value.includes('.')) {
    const tokens = value.split('.');

    let result = row;
    for (const token of tokens) {
      if (result[token]) {
        result = result[token];
      } else {
        return '';
      }
    }

    return result;
  } else {
    return row[value];
  }
};

export interface IColumn {
  Header: string;
  accessor: string;
  Cell?: (data: any, row: any) => JSX.Element;
  tdParams?: { [k: string]: string };
}

interface ISimpleTableProps<T> {
  columns: IColumn[];
  data: T[];
  children?: JSX.Element;
}

export const SimpleTable = <T extends any>(
  params: ISimpleTableProps<T>
): JSX.Element => {
  return (
    <Box>
      <Table my="8" borderWidth="1px" fontSize="sm" width={'100%'}>
        <Thead bg={mode('gray.50', 'gray.800')}>
          <Tr>
            {params.columns.map((column, index) => (
              <Th whiteSpace="nowrap" scope="col" key={index}>
                {column.Header}
              </Th>
            ))}
            <Th />
          </Tr>
        </Thead>
        <Tbody margin="0 auto">
          {params.data.map((row, index) => (
            <Tr key={index}>
              {params.columns.map((column, index) => {
                const cell = accessRowElement(row, column.accessor);
                const element = column.Cell?.(cell, row) ?? cell;
                const tdParams = column.tdParams || {};
                return (
                  <Td key={index} {...tdParams}>
                    <Text noOfLines={3}>{element}</Text>
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex>{params.data.length === 0 && <TableEmpty />}</Flex>
    </Box>
  );
};
