import { Box } from '@chakra-ui/react';
import { InputControl } from '../../../../components/Form';

export const AddressInputControl = ({
  initialAddress,
  name,
}: {
  initialAddress?: Record<string, string>;
  name: string;
}) => {
  return (
    <Box>
      <InputControl name={`${name}.country`} label="Shteti" />
      <InputControl name={`${name}.city`} label="Qyteti" />
      <InputControl name={`${name}.street`} label="Rruga" />
      <InputControl name={`${name}.zip`} label="Kodi postar" />
    </Box>
  );
};
