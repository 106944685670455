import { useCallback, useEffect, useMemo } from 'react';
import {
  InvoicePaginatedRequest,
  useGetAllInvoicesLazyQuery,
} from '../graphql';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { debounce } from 'lodash';

const PER_PAGE = 25;
const INITIAL_PAGE = 1;

function useInvoices() {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [search, setSearchInQuery] = useQueryParam('search', StringParam);
  const [month, setMonthInQuery] = useQueryParam('month', NumberParam);
  const [year, setYearInQuery] = useQueryParam('year', NumberParam);
  const [accountId, setAccountIdInQuery] = useQueryParam(
    'account',
    NumberParam
  );

  const variables = useMemo(
    () =>
      ({
        filter: {
          page: page || INITIAL_PAGE,
          pageLimit: PER_PAGE,
          search: search || null,
          accountId: accountId || null,
          month: month || null,
          year: year || null,
        },
      } as { filter: InvoicePaginatedRequest }),
    [page, search, month, year, accountId]
  );

  const [request, { data, loading, refetch: refetchQuery }] =
    useGetAllInvoicesLazyQuery({
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    if (!page) {
      setPage(INITIAL_PAGE);
    }
    request({ variables }).then(console.log);
  }, []);

  const debouncedRequest = useCallback(debounce(request, 500), []);
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // const isActuallyLoading = useMemo(() => {
  //   return (
  //     loading &&
  //     called &&
  //     !client.readQuery({ query: GetAllInvoicesDocument, variables })
  //   );
  // }, [loading, variables, client]);

  // const showEmptyResults = useMemo(() => !loading && called, [loading, called]);

  const setSearch = async (search: string) => {
    const vars = { ...variables };
    vars.filter.search = search || null;
    vars.filter.page = INITIAL_PAGE;

    await debouncedRequest({ variables: vars });

    if (!search) {
      setSearchInQuery(undefined);
    } else {
      setSearchInQuery(search);
    }
    setPage(INITIAL_PAGE);
  };

  const getPreviousPage = async () => {
    if (data?.getAllInvoices?.pageInfo?.hasPreviousPage) {
      const page = data?.getAllInvoices?.pageInfo?.previousPage || INITIAL_PAGE;
      const vars = { ...variables };
      vars.filter.page = page;
      scrollToTop();
      await refetchQuery({ filter: vars.filter });
      setPage(page);
    }
  };

  const getNextPage = async () => {
    if (data?.getAllInvoices?.pageInfo?.hasNextPage) {
      const page = data?.getAllInvoices?.pageInfo?.nextPage || INITIAL_PAGE;
      const vars = { ...variables };
      vars.filter.page = page;
      scrollToTop();
      await refetchQuery({ filter: vars.filter });
      setPage(page);
    }
  };

  const setMonth = async (month: number) => {
    if (month) {
      setMonthInQuery(month);
    } else {
      setMonthInQuery(undefined);
    }

    const vars = { ...variables };
    vars.filter.page = INITIAL_PAGE;
    vars.filter.month = month;

    await refetchQuery({ filter: vars.filter });
    setPage(INITIAL_PAGE);
  };

  const setYear = async (year: number) => {
    if (year) {
      setYearInQuery(year);
    } else {
      setYearInQuery(undefined);
    }

    const vars = { ...variables };
    vars.filter.page = INITIAL_PAGE;
    vars.filter.year = year;

    await refetchQuery({ filter: vars.filter });
    setPage(INITIAL_PAGE);
  };

  const setAccount = async (accountId: number) => {
    if (accountId) {
      setAccountIdInQuery(accountId);
    } else {
      setAccountIdInQuery(undefined);
    }

    const vars = { ...variables };
    vars.filter.page = INITIAL_PAGE;
    vars.filter.accountId = accountId;

    await refetchQuery({ filter: vars.filter });
    setPage(INITIAL_PAGE);
  };

  const refetch = async () => {
    await refetchQuery();
  };

  return {
    totalCount: data?.getAllInvoices?.totalCount || 0,
    items: data?.getAllInvoices?.nodes || [],
    pageInfo: data?.getAllInvoices?.pageInfo,
    loading,
    showEmptyResults: !loading,
    getNextPage,
    getPreviousPage,
    refetch,
    search,
    setSearch,
    accountId,
    setAccount,
    month,
    setMonth,
    year,
    setYear,
  };
}

export { useInvoices };
