import { Box, HStack, Spacer } from '@chakra-ui/react';
import moment from 'moment';
import { Notification, NotificationStatus } from '../../graphql';
import { Link } from '../Link';

type NotificationProps = {
  notification: Notification;
};

export const NotificationItem = (props: NotificationProps) => {
  const { notification } = props;
  return (
    <Link href={notification.url}>
      <Box borderRadius={'xl'}>
        <Box
          borderLeft={'4px'}
          borderColor={
            notification.status === NotificationStatus.Unread
              ? 'red.300'
              : 'white'
          }
          px="5"
        >
          <HStack>
            <Box
              my="2"
              fontWeight="semibold"
              fontSize={[12, 14, 16]}
              lineHeight="tight"
            >
              {notification.title}
            </Box>
            <Spacer />
            <Box color="gray.600" fontSize="xs">
              {moment(notification.createdAt).format("DD/MM/YYYY")}
            </Box>
          </HStack>

          <Box color="gray.700" fontSize={[11, 13, 15]} letterSpacing="wide">
            {notification.description}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
