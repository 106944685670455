// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    common: {
      loadMore: 'Load More',
    },
    login: {
      login: 'Login',
      noAccount: "Don't have an account?",
      haveAccount: 'Have an account?',
      register: 'Register',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm password',
      forgotPassword: 'Forgot password?',
      resetPassword: 'Reset password',
      resetCode: 'Reset code',
      passwordDoesNotMatch: 'Password does not match',
      name: 'Name',
      lastname: 'Lastname',
      sex: 'Sex',
      birthday: 'Birthday',
      mobileNumber: 'Mobile phone',
      country: 'Country',
      city: 'City',
      healthCenter: 'Health Center',
      licenceNumber: 'Licence Number',
      cv: 'Curriculum Vitae',
    },
    forms: {
      slideOrClick: 'Slide or click to upload',
      invalidFile: 'File is invalid',
      upload: 'Upload',
      delete: 'Delete',
      uploading: 'Uploading',
      size: 'Size',
    },
  },
};
