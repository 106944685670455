import { useColorModeValue as mode } from '@chakra-ui/color-mode';
import { Center } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import {
  AccountRole,
  AccountStatus,
  useUsersByRoleQuery,
} from '../../../graphql';
import { AvatarWithFullName } from './cells/AvatarWithFullName';
import { UserAccountStatus } from './cells/UserAccountStatus';

const columns = [
  {
    Header: 'Name',
    accessor: 'profile',
    Cell: function AvatarCell(data: any, row: any) {
      return (
        <AvatarWithFullName
          name={data.name}
          lastname={data.lastname}
          id={row.id}
          profilePicture={data.profilePicture?.md}
        />
      );
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Status',
    accessor: 'accountStatus',
    Cell: function StatusCell(status: string) {
      return <UserAccountStatus status={status as AccountStatus} />;
    },
  },
];

export const LawyersPage = () => {
  const { data, loading } = useUsersByRoleQuery({
    variables: { type: AccountRole.Lawyer },
  });

  if (loading) {
    return (
      <Center>
        <Spinner></Spinner>
      </Center>
    );
  }

  const access = (row: any, value = ''): string => {
    if (value.includes('.')) {
      const tokens = value.split('.');

      let result = row;
      for (const token of tokens) {
        if (result[token]) {
          result = result[token];
        } else {
          return '';
        }
      }

      return result;
    } else {
      return row[value];
    }
  };

  return (
    <Table my="8" borderWidth="1px" fontSize="sm">
      <Thead bg={mode('gray.50', 'gray.800')}>
        <Tr>
          {columns.map((column, index) => (
            <Th whiteSpace="nowrap" scope="col" key={index}>
              {column.Header}
            </Th>
          ))}
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {data?.users.map((row, index) => (
          <Tr key={index}>
            {columns.map((column, index) => {
              const cell = access(row, column.accessor);
              const element = column.Cell?.(cell, row) ?? cell;
              return (
                <Td whiteSpace="nowrap" key={index}>
                  {element}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
