import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { PricingCard } from '../../../components/Pricing/PricingCard';
import {
  Document,
  GetDocumentByIdQuery,
  Subscription,
  useCapturePaypalOrderPaymentMutation,
  useCreateUserSubscriptionMutation,
  useDownloadDocumentMutation,
  useGetActiveSubscriptionsQuery,
  useGetDocumentByIdQuery,
  useGetDocumentRawHtmlMutation,
  useRequestDocumentPaymentLinkMutation,
} from '../../../graphql';
import { Loader } from '../../../components/Loader';
import { FaCopy, FaDownload, FaFilePdf, FaPaste } from 'react-icons/fa';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { ApolloQueryResult } from '@apollo/client';

type PaymentInfoProps = {
  document: Document;
};

const PaymentInfo = (props: PaymentInfoProps) => {
  return (
    <>
      <Box
        textAlign={{
          // base: 'center',
          lg: 'start',
        }}
      >
        <Heading
          size="2xl"
          lineHeight="normal"
          color={useColorModeValue('red.600', 'red.400')}
          letterSpacing="tight"
        >
          Vetëm një hap larg...
        </Heading>
        <Text
          fontSize={{
            base: 'lg',
            lg: 'xl',
          }}
          mt="4"
          mx={{
            base: 'auto',
            lg: 'unset',
          }}
        >
          Ju mund të shkarkoni dokumentin tuaj pas kryerjes me sukses së
          pagesës.
        </Text>
      </Box>
    </>
  );
};

type PaymentCardProps = {
  document: Document;
  onRefresh: () => void;
};

const PaymentCards = (props: PaymentCardProps) => {
  const toast = useToast();
  const { document } = props;
  const { data: activeSubscriptions, loading } =
    useGetActiveSubscriptionsQuery();
  const [createUserSubscription] = useCreateUserSubscriptionMutation();
  // const [creatingSubscription, setCreatingSubscription] = React.useState(false);
  // const [requestingPaymentLink, setRequestingPaymentLink] =
  //   React.useState(false);
  const [requestPaymentLink] = useRequestDocumentPaymentLinkMutation();
  const [capturePaypalOrderPayment] = useCapturePaypalOrderPaymentMutation();

  const firstSubscription = React.useMemo(() => {
    return activeSubscriptions?.getActiveSubscriptions[0];
  }, [activeSubscriptions]);

  const handlePayForDocumentWithPaypal = async (): Promise<string> => {
    try {
      // setRequestingPaymentLink(true);
      const res = await requestPaymentLink({
        variables: {
          requestDocumentPaymentLinkId: document.id,
        },
      });
      return res.data?.requestDocumentPaymentLink!;
    } catch (error) {
      console.error(error);
    } finally {
      // setRequestingPaymentLink(false);
    }

    return '';
  };

  const handleCreateUserSubscription = async (
    subscription: Subscription
  ): Promise<string> => {
    try {
      // setCreatingSubscription(true);
      const paymentUrl = await createUserSubscription({
        variables: {
          subscriptionId: subscription.id,
        },
      });

      return paymentUrl.data?.createUserSubscription!;
    } catch (err) {
      console.error(err);
    } finally {
      // setCreatingSubscription(false);
    }

    return '';
  };

  if (loading || !firstSubscription) {
    return <Loader />;
  }

  return (
    <SimpleGrid
      mt="5"
      columns={{
        base: 1,
        lg: 2,
      }}
      spacing="6"
    >
      <>
        <PricingCard
          bg="gray.100"
          color="gray.700"
          width={'330px'}
          button={
            <Box>
              <Text fontSize={'sm'} color={'gray.500'} mb="10px">
                Paguaj me PayPal
              </Text>
              <PayPalButtons
                style={{
                  layout: 'horizontal',
                  color: 'white',
                  tagline: false,
                  shape: 'pill',
                }}
                createOrder={async (data, actions) => {
                  const id = await handlePayForDocumentWithPaypal();

                  return id;
                }}
                onApprove={(data, actions) => {
                  return actions!.order!.capture().then(async (details) => {
                    await capturePaypalOrderPayment({
                      variables: {
                        paypalOrderId: data.orderID,
                      },
                    });

                    props.onRefresh();
                  });
                }}
                onError={(err) => [
                  toast({
                    title: `Pati një problem në kryerjen e pagesës.`,
                    status: 'error',
                  }),
                ]}
                onCancel={(err) => [
                  toast({
                    title: `Kryerje e pagesës u anolua nga klienti.`,
                    status: 'info',
                  }),
                ]}
              />
            </Box>
          }
          data={{
            name: document.template.title,
            description: '',
            price: document.price,
            duration: 'none',
            benefits: [
              'Dua të shkarkoj vetëm këtë dokument',
              'Nuk dua akses në dokumenta të tjera',
            ],
          }}
        />

        <PricingCard
          bg="red.600"
          color="white"
          width={'330px'}
          button={
            <Box>
              <Text fontSize={'sm'} color={'gray.50'} mb="10px">
                Paguaj me PayPal
              </Text>
              <PayPalButtons
                style={{
                  layout: 'horizontal',
                  color: 'silver',
                  tagline: false,
                  shape: 'pill',
                }}
                createOrder={async (data, actions) => {
                  const id = await handleCreateUserSubscription(
                    firstSubscription as Subscription
                  );

                  return id;
                }}
                onApprove={(data, actions) => {
                  return actions!.order!.capture().then(async (details) => {
                    await capturePaypalOrderPayment({
                      variables: {
                        paypalOrderId: data.orderID,
                      },
                    });

                    props.onRefresh();
                    // window.location.reload();
                  });
                }}
                onError={(err) => [
                  toast({
                    title: `Pati një problem në kryerjen e pagesës.`,
                    status: 'error',
                  }),
                ]}
                onCancel={(err) => [
                  toast({
                    title: `Kryerje e pagesës u anolua nga klienti.`,
                    status: 'info',
                  }),
                ]}
              />
            </Box>
          }
          data={{
            name: firstSubscription.name,
            description: '',
            price: firstSubscription.price,
            duration: firstSubscription.period.toLocaleLowerCase(),
            benefits: firstSubscription.description.split('\n'),
          }}
        />
      </>
    </SimpleGrid>
  );
};

type PricingProps = {
  document: Document;
  refetch: (variables: {
    getDocumentByIdId: number;
  }) => Promise<ApolloQueryResult<GetDocumentByIdQuery>>;
};

export const Pricing = (props: PricingProps) => (
  <Box as="section" bg={useColorModeValue('white', 'gray.800')}>
    <Box
      maxW={{
        base: 'xl',
        md: '7xl',
      }}
      mx="auto"
    >
      <Center>
        <PaymentCards
          document={props.document}
          onRefresh={() => {
            props.refetch({ getDocumentByIdId: props.document.id });
          }}
        />
      </Center>
      <Box marginTop={'15px'}></Box>
    </Box>
  </Box>
);

interface DocumentAccessParams {
  id: number;
}

export const DocumentAccess = ({ id }: DocumentAccessParams) => {
  const { data, loading, refetch } = useGetDocumentByIdQuery({
    variables: { getDocumentByIdId: id },
  });

  if (loading || !data?.getDocumentById) {
    return <Loader />;
  }

  return (
    <>
      {/* Nese nuk ka blere dokumentin ose nuk ka abonim */}
      {data.getDocumentById.canPay && (
        <PaymentInfo document={data.getDocumentById as Document}></PaymentInfo>
      )}
      {data.getDocumentById.canPay && (
        <Pricing
          document={data.getDocumentById as Document}
          refetch={refetch}
        />
      )}

      {/* Nese ka blere dokumentin*/}
      {!data.getDocumentById.canPay && (
        <DocumentDownload document={data.getDocumentById as Document} />
      )}
    </>
  );
};

type DocumentDownloadProps = {
  document: Document;
};

type useClipboardOptions = {
  timeout: number;
};

const useIFrameClipboard = (
  querySelector: string,
  opts?: useClipboardOptions
) => {
  const [copied, setCopied] = React.useState(false);
  const timeout = opts?.timeout ?? 5000;

  const onCopy = React.useCallback(() => {
    const iframe: HTMLIFrameElement | null =
      document.querySelector(querySelector);
    if (!iframe) {
      throw new Error('No iframe found');
    }

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDoc) {
      throw new Error('No iframe document found');
    }

    let range = new Range();
    range.setStart(iframeDoc.body, 0);
    range.setEnd(iframeDoc.body, iframeDoc.body.childNodes.length);

    iframeDoc.getSelection()?.removeAllRanges();
    iframeDoc.getSelection()?.addRange(range);
    iframeDoc.execCommand('copy');
    setCopied(true);

    // cleanup
    setTimeout(() => {
      setCopied(false);
    }, timeout);
  }, []);

  return {
    onCopy,
    hasCopied: copied,
  };
};

const DocumentDownload = (props: DocumentDownloadProps) => {
  const { document: documentData } = props;

  const [download] = useDownloadDocumentMutation();
  const [getRawHtml] = useGetDocumentRawHtmlMutation();
  const [gettingRawHtml, setGettingRawHtml] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [documentRawHtml, setDocumentRawHtml] = React.useState('');
  const { hasCopied, onCopy } = useIFrameClipboard('#document-content', {
    timeout: 2000,
  });

  const downloadDocument = async () => {
    try {
      setDownloading(true);
      const res = await download({
        variables: {
          downloadDocumentId: documentData.id,
        },
      });
      window.open(res.data?.downloadDocument, '_blank');
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  const handleGetRawHtml = async () => {
    try {
      setGettingRawHtml(true);
      const res = await getRawHtml({
        variables: {
          getRawHtmlId: documentData.id,
        },
      });

      setDocumentRawHtml(res.data?.getDocumentRawHtml || '');
      onOpen();
    } catch (error) {
      console.error(error);
    } finally {
      setGettingRawHtml(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} size="4xl" onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Përmbajtja</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="lg" overflow="auto">
            <iframe
              id="document-content"
              title="Document"
              width="100%"
              height="400px"
              srcDoc={documentRawHtml}
            ></iframe>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onCopy} colorScheme="red" isFullWidth={true}>
              {hasCopied ? 'U kopjua!' : 'Kopjo'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        textAlign={{
          // base: 'center',
          lg: 'start',
        }}
      >
        {/* <SectionTitle>Shkarko dokumentin</SectionTitle> */}
        <Center>
          <SimpleGrid
            mt="5"
            columns={{
              base: 1,
              lg: 2,
            }}
            spacing="6"
          >
            <Box
              bg="gray.100"
              color="gray.700"
              width={'330px'}
              p={'15px'}
              borderRadius={'lg'}
              textAlign={'center'}
            >
              <Box fontSize={"sm"}>
                <Icon as={FaFilePdf} fontSize={'5xl'} color={'red.500'} />

                <Text color={'gray.600'} my="15px">
                  Shkarko dokumentin "{documentData.title}" në formatin &nbsp;
                  <b>PDF</b>. Ideale nëse nuk do t'u duhet të bëni ndryshime në
                  dokumentin përfundimtar.
                </Text>
                <Text></Text>
              </Box>
              <Button
                leftIcon={<FaDownload />}
                colorScheme="red"
                variant="ghost"
                fontSize={'lg'}
                isDisabled={downloading}
                isLoading={downloading}
                onClick={downloadDocument}
              >
                Shkarko
              </Button>
            </Box>

            <Box
              bg="gray.100"
              color="gray.700"
              width={'330px'}
              p={'15px'}
              borderRadius={'lg'}
              textAlign={'center'}
            >
              <Box>
                <Icon as={FaCopy} fontSize={'5xl'} color={'red.500'} />

                <Text color={'gray.600'} my="15px" fontSize={"sm"}>
                  Legit mundohet të bëjë më të mirën në strukturimin dhe
                  formatimin e dokumentiit tuaj. Por, nëse mendoni se ka gabime
                  apo dëshironi të shtoni diçka mund të kopjoni përmbajtjen dhe
                  ta redaktoni përmes mjeteve të jashtme.
                </Text>
                <Text></Text>
              </Box>
              <Button
                leftIcon={<FaPaste />}
                colorScheme="red"
                variant="ghost"
                fontSize={'lg'}
                isDisabled={gettingRawHtml}
                isLoading={gettingRawHtml}
                onClick={handleGetRawHtml}
              >
                Shfaq përmbajtjen
              </Button>
            </Box>
          </SimpleGrid>
        </Center>
      </Box>
    </>
  );
};

export const DocumentAccessPage = () => {
  // @ts-ignore
  const { id } = useParams();

  return <DocumentAccess id={Number(id)} />;
};
