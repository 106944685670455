import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
// import { Private } from "../components/Private";

import LoginPage from './Login';

import DashboardLayout from '../layouts/Dashboard';
import { AccountRole } from '../graphql';
import { RegisterPage } from './Register';
import { Private } from '../components/Private';
import ActivateAccountPage from './ActivateAccount';
import HomePage from './Home';
import NotAuthenticated from '../components/NotAuthenticated';
import RequestPasswordResetPage from './RequestPasswordReset';
import ResetPasswordPage from './ResetPassword';
import { ProfilePage } from './Profile';
import { DocumentsListPage } from './Documents';
import { ContactLawyerPage } from './Client/ContactLawyer';
import { AdminsPage, ClientsPage, LawyersPage } from './Admin/UsersList';
import { UserDetailsPage } from './UserDetails';
import {
  CountriesPage,
  DocumentCategoriesPage,
  PositionsPage,
  SpecialitiesPage,
} from './Admin/Configs';
import { CitiesPage } from './Admin/Configs/cities';
import IssuesPage from './Lawyer/IssuesList';
import IssueBidsPage from './Client/IssueBids';
import { ClientIssuePage } from './Client/IssueDetails';
import { LawyerIssueDetailsPage } from './Lawyer/IssueDetails';
import NotificationsPage from './Notifications';
import { AdminIssues } from './Admin/Issues';
import { AdminIssueDetailsPage } from './Admin/IssueDetails';
import MyIssues from './Lawyer/MyIssues';
import { QueryParamProvider } from 'use-query-params';
import { LawyerInvoicesListPage } from './Admin/Invoices/lawyer';
import { ClientIssueChatPage } from './Client/IssueDetails/chat';
import { LawyerIssueChatPage } from './Lawyer/IssueDetails/chat';
import { BankDetailsPage } from './BankDetails';
// import { DocumentPage } from './Documents';
import { DocumentTemplatesPage } from './Admin/DocumentTemplates';
import { EditDocumentPage } from './Documents/edit';
import { DocumentPageWrapper } from './Documents/DocumentWrapper';
import { DocumentMetadataPage } from './Documents/metadata';
import { DocumentAccessPage } from './Documents/access';
import { MyInvoicesListPage } from './Invoices';
import { MyInvoiceDetailsPage } from './Invoices/details';
import { AdminInvoiceDetailsPage } from './Admin/Invoices/details';
import { SubscriptionsPage } from './Admin/Configs/subscriptions';
import { SubscriptionPage } from './Client/Subscription';
import { ClientInvoicesListPage } from './Admin/Invoices/client';
import { PayseraAccept, PayseraCancel } from './Paysera';
import { RegisterLawyerPage } from './RegisterLawyer';

// const Index = () => {
//   const { data, loading } = useMeQuery();
//   if (loading) {
//     return <Loader />;
//   }
//   if (data.me.role === AccountRole.Admin) {
//     return <Redirect to="/calls/overview" />;
//   }
//   if (data.me.role === AccountRole.Author) {
//     return <Redirect to="/articles" />;
//   }
//   if (
//     data.me.role === AccountRole.Doctor ||
//     data.me.role === AccountRole.MedicalSpecialist
//   ) {
//     return <Redirect to="/calls/my" />;
//   }
// };

export default function Routes() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Route path="/login" exact>
            <NotAuthenticated>
              <LoginPage />
            </NotAuthenticated>
          </Route>

          <Route path="/register" exact>
            <NotAuthenticated>
              <RegisterPage />
            </NotAuthenticated>
          </Route>

          <Route path="/register/lawyer" exact>
            <NotAuthenticated>
              <RegisterLawyerPage />
            </NotAuthenticated>
          </Route>

          <Route path="/reset-password-request" exact>
            <NotAuthenticated>
              <RequestPasswordResetPage />
            </NotAuthenticated>
          </Route>

          <Route path="/reset-password" exact>
            <NotAuthenticated>
              <ResetPasswordPage />
            </NotAuthenticated>
          </Route>

          <Route path="/activate-account" exact>
            <Private
              roles={[
                AccountRole.Admin,
                AccountRole.Client,
                AccountRole.Lawyer,
              ]}
            >
              <ActivateAccountPage />
            </Private>
          </Route>

          <Route path="/paysera/cancel" exact>
            <Private
              roles={[
                AccountRole.Admin,
                AccountRole.Client,
                AccountRole.Lawyer,
              ]}
            >
              <DashboardLayout>
                <PayseraCancel />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/paysera/accept" exact>
            <Private
              roles={[
                AccountRole.Admin,
                AccountRole.Client,
                AccountRole.Lawyer,
              ]}
            >
              <DashboardLayout>
                <PayseraAccept />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/home" exact>
            <Private
              roles={[
                AccountRole.Admin,
                AccountRole.Client,
                AccountRole.Lawyer,
              ]}
            >
              <DashboardLayout>
                <HomePage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/profile" exact>
            <Private
              roles={[
                AccountRole.Admin,
                AccountRole.Client,
                AccountRole.Lawyer,
              ]}
            >
              <DashboardLayout>
                <ProfilePage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/subscription" exact>
            <Private roles={[AccountRole.Client]}>
              <DashboardLayout>
                <SubscriptionPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Redirect exact from="/issues" to={`/issues/new`} />

          <Route path="/issues/new" exact>
            <Private roles={[AccountRole.Lawyer]}>
              <DashboardLayout>
                <IssuesPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/issues/mine" exact>
            <Private roles={[AccountRole.Lawyer]}>
              <DashboardLayout>
                <MyIssues />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/issues/details/:id" exact>
            <Private roles={[AccountRole.Lawyer]}>
              <DashboardLayout>
                <LawyerIssueDetailsPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/issues/details/:id/chat" exact>
            <Private roles={[AccountRole.Lawyer]}>
              <DashboardLayout>
                <LawyerIssueChatPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/issue/bids/:id" exact>
            <Private roles={[AccountRole.Client]}>
              <DashboardLayout>
                <IssueBidsPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/bank/details" exact>
            <Private
              roles={[
                AccountRole.Lawyer,
                AccountRole.Client,
                AccountRole.Admin,
              ]}
            >
              <DashboardLayout>
                <BankDetailsPage invoiceNumber={1} />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/invoices" exact>
            <Private roles={[AccountRole.Lawyer, AccountRole.Client]}>
              <DashboardLayout>
                <MyInvoicesListPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/invoice/:id" exact>
            <Private roles={[AccountRole.Lawyer, AccountRole.Client]}>
              <DashboardLayout>
                <MyInvoiceDetailsPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/documents" exact>
            <Private roles={[AccountRole.Client, AccountRole.Admin]}>
              <DashboardLayout>
                <DocumentsListPage />
              </DashboardLayout>
            </Private>
          </Route>

          {/* <Route path="/document/:id/edit" exact>
            <Private roles={[AccountRole.Client, AccountRole.Admin]}>
              <DashboardLayout>
                <DocumentPageWrapper>
                  <EditDocumentPage />
                </DocumentPageWrapper>
              </DashboardLayout>
            </Private>
          </Route> */}

          <Route path="/document/:id">
            <Private roles={[AccountRole.Client, AccountRole.Admin]}>
              <DashboardLayout>
                <DocumentPageWrapper>
                  <Route
                    path={`/document/:id/edit`}
                    component={EditDocumentPage}
                    exact
                  />
                  <Route
                    path={`/document/:id/metadata`}
                    component={DocumentMetadataPage}
                    exact
                  />
                  <Route
                    path={`/document/:id/access`}
                    component={DocumentAccessPage}
                    exact
                  />
                </DocumentPageWrapper>
              </DashboardLayout>
            </Private>
          </Route>

          <Route path="/find-lawyer" exact>
            <Private roles={[AccountRole.Client]}>
              <DashboardLayout>
                <ContactLawyerPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Route
            exact
            path="/find-lawyer/issue/:id"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Client]}>
                <DashboardLayout>
                  <ClientIssuePage />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route
            exact
            path="/find-lawyer/issue/:id/chat"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Client]}>
                <DashboardLayout>
                  <ClientIssueChatPage />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route path="/notifications" exact>
            <Private
              roles={[
                AccountRole.Client,
                AccountRole.Admin,
                AccountRole.Lawyer,
              ]}
            >
              <DashboardLayout>
                <NotificationsPage />
              </DashboardLayout>
            </Private>
          </Route>

          <Redirect exact from="/users" to={`/users/clients`} />

          {/*********** Admin Routes **********/}

          <Route
            path="/users"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Admin]}>
                <DashboardLayout>
                  <Route
                    path={`${url}/clients`}
                    component={ClientsPage}
                    exact
                  />
                  <Route
                    path={`${url}/lawyers`}
                    component={LawyersPage}
                    exact
                  />
                  <Route path={`${url}/admins`} component={AdminsPage} exact />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route
            path="/config"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Admin]}>
                <Redirect from="/config" to="/config/countries" />
                <DashboardLayout>
                  <Route
                    path={`${url}/countries`}
                    component={CountriesPage}
                    exact
                  />
                  <Route path={`${url}/cities`} component={CitiesPage} exact />
                  <Route
                    path={`${url}/subscriptions`}
                    component={SubscriptionsPage}
                    exact
                  />
                  <Route
                    path={`${url}/positions`}
                    component={PositionsPage}
                    exact
                  />
                  <Route
                    path={`${url}/specialities`}
                    component={SpecialitiesPage}
                    exact
                  />
                  <Route
                    path={`${url}/document-categories`}
                    component={DocumentCategoriesPage}
                    exact
                  />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route
            path="/account/:id"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Admin]}>
                <DashboardLayout>
                  <UserDetailsPage />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route
            path="/admin/issues"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Admin]}>
                <DashboardLayout>
                  <AdminIssues />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route
            path="/admin/issue/:id"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Admin]}>
                <DashboardLayout>
                  <AdminIssueDetailsPage />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route
            path="/admin/invoices"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Admin]}>
                <Redirect from="/admin/invoices" to="/admin/invoices/lawyer" />
                <DashboardLayout>
                  <Route
                    path={`${url}/lawyer`}
                    component={LawyerInvoicesListPage}
                    exact
                  />
                  <Route
                    path={`${url}/client`}
                    component={ClientInvoicesListPage}
                    exact
                  />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route
            path="/admin/invoice/:id"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Admin]}>
                <DashboardLayout>
                  <AdminInvoiceDetailsPage />
                </DashboardLayout>
              </Private>
            )}
          />

          <Route
            path="/admin/document-templates"
            render={({ match: { url } }) => (
              <Private roles={[AccountRole.Admin]}>
                <DashboardLayout>
                  <DocumentTemplatesPage />
                </DashboardLayout>
              </Private>
            )}
          />

          <Redirect from="*" to="/home" />
        </Switch>
      </QueryParamProvider>
    </Router>
  );
}
