import * as React from 'react';
import { Box } from '@chakra-ui/layout';
import { ClientProfile } from '../../graphql';
import { Property } from '../../components/Property';

export const ClientDetails = ({ profile }: { profile: ClientProfile }) => {
  return (
    <Box w="full" mt={10}>
      <Property label="Emri" value={profile.name} />
      <Property label="Mbiemri" value={profile.lastname} />
      <Property label="Shteti" value={profile.country.name} />
      <Property label="Qyteti" value={profile.city.name} />
      <Property label="Gjinia" value={profile.sex} />
    </Box>
  );
};
