import { Box } from '@chakra-ui/layout';
import React from 'react';
import { Loader } from '../../../components/Loader';
import { TablePagination } from '../../../components/Pagination';
import { CardHeader } from '../../../components/Card';
import { LawyerIssueCard } from '../../../components/LawyerIssueCard';
import { useIssuesThatLawyerCanBid } from '../../../hooks/useIssuesThatLawyerCanBid';
import { NoticeNoResults } from '../../../components/Notice';
import { MdOutlinePeopleOutline } from 'react-icons/md';

const IssuesPage: React.FC = () => {
  const {
    loading,
    items,
    getNextPage,
    totalCount,
    pageInfo,
    getPreviousPage,
    showEmptyResults,
  } = useIssuesThatLawyerCanBid();

  return (
    <Box>
      <CardHeader title="Kërkesa për ofertë"></CardHeader>
      {loading ? (
        <Loader />
      ) : showEmptyResults && !loading && items.length === 0 ? (
        <NoticeNoResults
          icon={MdOutlinePeopleOutline}
          title="Nuk ka asnjë kërkesë për ofertë"
          description="Momentalisht ju nuk keni asnjë kërkesë nga klientët për të vendosur një ofertë pune. Qëndroni të përditësuar në platformë dhe kontrolloni rregullisht kutinë tuaj të postës elektronike (email) për njoftime të reja."
        />
      ) : (
        items.map((item: any) => <LawyerIssueCard key={item.id} issue={item} />)
      )}

      {pageInfo && (
        <TablePagination
          total={totalCount}
          pageInfo={pageInfo}
          onNextPage={getNextPage}
          onPreviousPage={getPreviousPage}
        />
      )}
    </Box>
  );
};

export default IssuesPage;
