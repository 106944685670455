import React from 'react';
import { Redirect } from 'react-router';
import { useAuthToken } from '../../hooks';

export const NotAuthenticated: React.FC<{ children: any }> = ({ children }) => {
  const { user } = useAuthToken();

  if (!user) {
    return children;
  } else {
    return <Redirect to="/home" />;
  }
};

export default NotAuthenticated;
