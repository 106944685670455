import { Flex, useColorModeValue as mode } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Logo } from '../../components/Logo';
import { MobileHamburgerMenu } from '../../components/MobileHamburgerMenu';
import { HStack } from '@chakra-ui/layout';
import { NavMenu } from '../../components/NavMenu';
import { PageContent } from '../../components/PageContent';
import { PageHeader } from '../../components/PageHeader';
import { ProfileDropdown } from '../../components/ProfileDropdown';
import { NotificationDropdown } from '../../components/Notification';
import { useAuthToken, useMobileMenuState } from '../../hooks';
import { GoLaw } from 'react-icons/go';
import { IoIosPaper } from 'react-icons/io';
import { useLocation } from 'react-router';
import { AccountRole } from '../../graphql';
import { BsGearFill, BsPersonFill } from 'react-icons/bs';
import { FaMoneyBill } from 'react-icons/fa';

export type HeaderOptions = {
  label: string;
  href: string;
  title: string;
  icon?: React.ReactNode;
  subMenu?: HeaderOptions[];
};

const clientOptions: HeaderOptions[] = [
  {
    label: 'Dokumente ligjore',
    href: '/documents',
    title: 'Dokumente ligjore',
    icon: <IoIosPaper />,
  },
  {
    label: 'Gjej avokat',
    href: '/find-lawyer',
    title: 'Gjej avokat',
    icon: <GoLaw />,
  },
  {
    label: 'Faturat e mia',
    href: '/invoices',
    title: 'Faturat e mia',
    icon: <FaMoneyBill />,
  },
];

const lawyerOptions: HeaderOptions[] = [
  {
    label: 'Lista e çështjve',
    href: '/issues',
    title: 'Lista e çështjve',
    icon: <GoLaw />,
    subMenu: [
      {
        label: 'Kërkesa për ofertë',
        title: 'Kërkesa për ofertë',
        href: '/issues/new',
      },
      {
        label: 'Çështjet e mia',
        title: 'Çështjet e mia',
        href: '/issues/mine',
      },
    ],
  },
  {
    label: 'Faturat e mia',
    href: '/invoices',
    title: 'Faturat e mia',
    icon: <FaMoneyBill />,
  },
];

const adminOptions: HeaderOptions[] = [
  {
    label: 'Përdorues',
    href: '/users',
    title: 'Përdorues',
    icon: <BsPersonFill />,
    subMenu: [
      {
        label: 'Klientë',
        title: 'Klientë',
        href: '/users/clients',
      },
      {
        label: 'Avokatë',
        title: 'Avokatë',
        href: '/users/lawyers',
      },
      {
        label: 'Administratorë',
        title: 'Administratorë',
        href: '/users/admins',
      },
    ],
  },
  {
    label: 'Konfigurime',
    href: '/config',
    title: 'Konfigurime',
    icon: <BsGearFill />,
    subMenu: [
      {
        label: 'Shtete',
        title: 'Shtete',
        href: '/config/countries',
      },
      {
        label: 'Qytete',
        title: 'Qytete',
        href: '/config/cities',
      },
      {
        label: 'Pozicione',
        title: 'Pozicione',
        href: '/config/positions',
      },
      {
        label: 'Specialitete',
        title: 'Specialitete',
        href: '/config/specialities',
      },
      {
        label: 'Kategoritë e dokumenteve',
        title: 'Kategoritë e dokumenteve',
        href: '/config/document-categories',
      },
      {
        label: 'Abonimet',
        title: 'Abonimet',
        href: '/config/subscriptions',
      },
    ],
  },
  {
    label: 'Çështjet',
    href: '/admin/issues',
    title: 'Çështjet',
    icon: <GoLaw />,
  },
  {
    label: 'Faturat',
    href: '/admin/invoices',
    title: 'Faturat',
    icon: <FaMoneyBill />,
    subMenu: [
      {
        label: 'Avokat',
        title: 'Avokat',
        href: '/admin/invoices/lawyer',
      },
      {
        label: 'Klient',
        title: 'Klient',
        href: '/admin/invoices/client',
      },
    ],
  },
  {
    label: 'Shabllone',
    href: '/admin/document-templates',
    title: 'Dokumente',
    icon: <IoIosPaper />,
  },
  {
    label: 'Dokumente',
    href: '/documents',
    title: 'Dokumente',
    icon: <IoIosPaper />,
  },
];

interface IDashboardLayoutProps {
  title?: string;
}

const DashboardLayout: React.FC<IDashboardLayoutProps> = ({
  children,
  title: staticTitle,
}) => {
  const location = useLocation();
  const { isMenuOpen, toggle } = useMobileMenuState();
  const [currentRoute, setCurrentRoute] = useState<HeaderOptions | null>(null);
  const { user } = useAuthToken();
  const headerOptions = React.useMemo(() => {
    if (user && user.role === AccountRole.Admin) {
      return adminOptions;
    }
    if (user && user.role === AccountRole.Lawyer) {
      return lawyerOptions;
    }

    if (user && user.role === AccountRole.Client) {
      return clientOptions;
    }

    return [];
  }, [user]);

  useEffect(() => {
    let setRoute = false;
    for (const route of headerOptions) {
      if (location.pathname.startsWith(route.href)) {
        setRoute = true;
        setCurrentRoute(route);
        break;
      }
    }

    if (!setRoute) {
      setCurrentRoute(null);
    }
  }, [headerOptions, location.pathname]);

  return (
    <Flex direction="column" bg={mode('gray.100', 'gray.800')} height="100vh">
      <Flex align="center" bg="red.600" color="white" px="6" minH="16">
        <Flex justify="space-between" align="center" w="full">
          <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
          <NavMenu.Mobile
            setCurrentRoute={setCurrentRoute}
            isOpen={isMenuOpen}
            headerOptions={headerOptions}
          />

          {/* Desktop Logo placement */}
          <Logo transparent={true} />

          {/* Desktop Navigation Menu */}
          <NavMenu.Desktop
            setCurrentRoute={setCurrentRoute}
            headerOptions={headerOptions}
          />

          {/* Mobile Logo placement */}
          {/* <Logo
            flex={{ base: '1', lg: '0' }}
            display={{ lg: 'none' }}
            flexShrink={0}
            h="5"
            color="white"
          /> */}

          <HStack spacing="3">
            <NotificationDropdown />
            <ProfileDropdown />
          </HStack>
        </Flex>
      </Flex>

      {currentRoute && currentRoute.subMenu && (
        <PageHeader currentRoute={currentRoute} />
      )}
      <PageContent children={children} />
    </Flex>
  );
};

export default DashboardLayout;
