import { Box, Tag } from '@chakra-ui/react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { CardContent } from '../../../components/Card/CardContent';
import { Loader } from '../../../components/Loader';
import { Property } from '../../../components/Property';
import { useGetDocumentByIdQuery } from '../../../graphql';
import { SectionTitle } from '../../../typography';
import { getDocumentStatusColorCode, getDocumentStatusName } from '../util';

interface DocumentMetadataParams {
  id: number;
}

export const DocumentMetadata = ({ id }: DocumentMetadataParams) => {
  const { data: documentData, loading: documentLoading } =
    useGetDocumentByIdQuery({ variables: { getDocumentByIdId: Number(id) } });

  if (documentLoading || !documentData || !documentData.getDocumentById) {
    return <Loader />;
  }

  return (
    <Box>
      <SectionTitle>Dokumenti</SectionTitle>
      <CardContent marginBottom="5">
        <Property label="Titulli" value={documentData.getDocumentById.title} />
        {/* <Property
          label="Autori"
          value={
            <Wrap>
              <WrapItem>
                <Avatar
                  name="Dan Abrahmov"
                  src="https://bit.ly/dan-abramov"
                  size={'xs'}
                />
              </WrapItem>
              <WrapItem>
                <Text>Filan Fisteku</Text>
              </WrapItem>
            </Wrap>
          }
        /> */}
        <Property
          label="Krijuar më"
          value={moment(documentData.getDocumentById.createdAt).format(
            'DD/MM/YYYY HH:mm'
          )}
        />
        <Property
          label="Ndryshimi i fundit"
          value={moment(documentData.getDocumentById.updatedAt).format(
            'DD/MM/YYYY HH:mm'
          )}
        />
        <Property
          label="Statusi"
          value={
            <Tag
              size={'md'}
              variant="solid"
              bg={getDocumentStatusColorCode(
                documentData.getDocumentById.status
              )}
            >
              {getDocumentStatusName(documentData.getDocumentById.status)}
            </Tag>
          }
        />
      </CardContent>

      <SectionTitle>Shablloni</SectionTitle>
      <CardContent marginBottom="5">
        <Property
          label="Titulli"
          value={documentData.getDocumentById.template.title}
        />
        <Property
          label="Ndryshimi i fundit"
          value={moment(documentData.getDocumentById.template.updatedAt).format(
            'DD/MM/YYYY HH:mm'
          )}
        />
        <Property
          label="Versioni"
          value={`v${documentData.getDocumentById.templateVersionNumber}`}
        />
      </CardContent>
    </Box>
  );
};

export const DocumentMetadataPage = () => {
  // @ts-ignore
  const { id } = useParams();

  return <DocumentMetadata id={Number(id)} />;
};
