import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
  Tag,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BsSearch, BsThreeDotsVertical } from 'react-icons/bs';
import { FaDownload, FaEdit, FaList } from 'react-icons/fa';
import { HiPlus } from 'react-icons/hi';
import { Link, useHistory } from 'react-router-dom';
import { CardHeader } from '../../components/Card';
import { CardContent } from '../../components/Card/CardContent';
import { Loader } from '../../components/Loader';
import { Property } from '../../components/Property';
import {
  useGetActiveDocumentTemplatesLazyQuery,
  DocumentTemplate,
  useCreateEmptyDocumentMutation,
  Document,
  useDeleteDocumentMutation,
  useGetAllDocumentCategoriesQuery,
  DocumentStatus,
} from '../../graphql';
import { getDocumentStatusName, getOutlineColor } from './util';
import * as FontAwesomeIcons from 'react-icons/fa/index';
import { NoDocumentsList } from './empty';
import { useMyDocuments } from '../../hooks/useMyDocuments';
import { TablePagination } from '../../components/Pagination';

type DocumentItemProps = {
  template: Partial<DocumentTemplate>;
  checked: boolean;
  onClick: () => void;
};

const SelectDocumentItem = (props: DocumentItemProps) => {
  const { template, checked, onClick } = props;
  const colorScheme = checked ? 'red' : 'gray';
  const accentColor200 = checked ? 'red.200' : 'gray.200';
  const accentColor500 = checked ? 'red.500' : 'gray.500';
  const accentColor600 = checked ? 'red.600' : 'gray.600';
  const accentColor700 = checked ? 'red.700' : 'gray.700';

  return (
    <Stack
      direction="row"
      borderRadius={'lg'}
      border="1px"
      borderStyle={'soldi'}
      borderColor={accentColor200}
      p={3}
      onClick={onClick}
    >
      <Box h="50px" cursor={'pointer'}>
        <Text
          fontSize={'lg'}
          fontWeight={'bold'}
          noOfLines={1}
          color={accentColor700}
        >
          {template.title}
        </Text>
        <HStack spacing="10px" divider={<StackDivider />}>
          <Text color={accentColor600} fontSize={'sm'}>
            Ndryshimi i fundit:{' '}
            {moment(template.updatedAt).format('DD/MM/YYYY HH:mm')}
          </Text>
          <Text color={accentColor600} fontSize={'sm'}>
            Versioni: {template.versionNumber}
          </Text>
        </HStack>
      </Box>
      <Spacer />
      <Box h="50px">
        <Center h="50px">
          <Text color={accentColor500} fontSize={'sm'} mr={1}>
            Çmimi{' '}
          </Text>
          <Tag colorScheme={colorScheme} size="lg">
            {template.price} Euro
          </Tag>
        </Center>
      </Box>
    </Stack>
  );
};

type SelectDocumentStep1Props = {
  setSelectedTemplate: (template: DocumentTemplate | null) => void;
  selectedTemplate: DocumentTemplate | null;
};

const SelectDocumentStep1 = (props: SelectDocumentStep1Props) => {
  const { setSelectedTemplate, selectedTemplate } = props;
  const [activeCategory, setActiveCategory] = useState<null | number>(null);
  const { data: categories, loading: categoriesLoading } =
    useGetAllDocumentCategoriesQuery({
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });
  const [
    documentTemplatesRequest,
    { data: documentTemplates, loading: documentTemplatesLoading },
  ] = useGetActiveDocumentTemplatesLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    documentTemplatesRequest({
      variables: {
        categoryId: activeCategory!,
      },
    });
  }, [activeCategory]);

  if (categoriesLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={'30px'}
        divider={<StackDivider />}
        height={'50vh'}
      >
        <Box width={{ base: '100%', lg: '200px' }}>
          <Stack
            direction={{ base: 'row', lg: 'column' }}
            divider={<StackDivider />}
            fontSize={'lg'}
            spacing={'10px'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              color={!activeCategory ? 'red.600' : 'gray.600'}
              fontWeight={!activeCategory ? 'bold' : 'normal'}
              onClick={() => {
                setActiveCategory(null);
                setSelectedTemplate(null);
              }}
              cursor="pointer"
            >
              <Icon
                as={FaList}
                color={!activeCategory ? 'red.300' : 'gray.300'}
                mr={3}
              />{' '}
              Të gjitha
            </Box>
            {categories?.getAllDocumentCategories.map((category) => (
              <Box
                key={category.id}
                onClick={() => {
                  setActiveCategory(category.id);
                  setSelectedTemplate(null);
                }}
                display={'flex'}
                alignItems={'center'}
                color={activeCategory === category.id ? 'red.600' : 'gray.600'}
                fontWeight={activeCategory === category.id ? 'bold' : 'normal'}
                cursor="pointer"
              >
                <Icon
                  as={
                    FontAwesomeIcons[
                      category.icon as keyof typeof FontAwesomeIcons
                    ]
                  }
                  color={
                    activeCategory === category.id ? 'red.300' : 'gray.300'
                  }
                  mr={3}
                />
                {category.name}
              </Box>
            ))}
          </Stack>
        </Box>

        {documentTemplatesLoading ? (
          <Loader />
        ) : (
          <Box width="100%" overflow={'auto'} pr="30px">
            <Stack spacing={'14px'}>
              {documentTemplates?.getActiveDocumentTemplates.map((template) => (
                <SelectDocumentItem
                  template={template}
                  key={template.id}
                  onClick={() =>
                    setSelectedTemplate(template as DocumentTemplate)
                  }
                  checked={selectedTemplate?.id === template.id}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

type SelectDocumentStep2Props = {
  title: string;
  setTitle: (title: string) => void;
  selectedTemplate: DocumentTemplate | null;
};

type LinkifiableTextProps = {
  text: string;
  styles?: string;
};

export const LinkifiableText = (props: LinkifiableTextProps) => {
  const { text, styles } = props;
  // eslint-disable-next-line no-useless-escape
  const regex = /\[([^\]\[\r\n]*)\]\(([^()]*)\)/gim;
  const replacedText = useMemo(() => {
    return text.replaceAll(regex, (...matches) => {
      return `<a href="${matches[1]}" target="_blank" style="${styles || ''}">${
        matches[2]
      }</a>`;
    });
  }, [text]);
  return <Box dangerouslySetInnerHTML={{ __html: replacedText }} />;
};

const SelectDocumentStep2 = (props: SelectDocumentStep2Props) => {
  const { title, setTitle, selectedTemplate } = props;
  return (
    <Box>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={'30px'}
        divider={<StackDivider />}
        height={'50vh'}
      >
        <Box width={{ base: '100%', lg: '50%' }} bg="red.50">
          <Box p={5}>
            <Text color="red.700">
              <LinkifiableText
                text={selectedTemplate?.description || ''}
                styles="color: black; text-decoration: underline;"
              />
            </Text>
          </Box>
        </Box>
        <Box width={{ base: '100%', lg: '50%' }}>
          <Box mb={'30px'}>
            <Text fontSize={'lg'}>
              Ju lutem vendosini një titull dokumentit që po krijoni për ta
              gjetur me lehtësi në arkivën tuaj të dokumentave.
            </Text>
          </Box>
          <FormControl>
            <FormLabel htmlFor="email">Titulli i dokumentit</FormLabel>
            <Input
              name="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
        </Box>
      </Stack>
    </Box>
  );
};

export const DocumentsListPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState(1);
  const [selectedTemplate, setSelectedTemplate] =
    useState<null | DocumentTemplate>(null);
  const [title, setTitle] = useState('');
  const toast = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [createEmptyDocument] = useCreateEmptyDocumentMutation();
  const {
    totalCount,
    items: documents,
    loading: documentsLoading,
    showEmptyResults: documentsShowEmptyResults,
    setSearch,
    refetch: refetchDocuments,
    search: documentsSearch,
    getNextPage,
    getPreviousPage,
    pageInfo,
  } = useMyDocuments();
  // const [getDocuments, { data: documents, loading: documentsLoading }] =
  //   useGetDocumentsLazyQuery({ fetchPolicy: 'cache-and-network' });
  // useEffect(() => {
  //   getDocuments({ variables: { request: { search: null } } });
  // }, []);
  const history = useHistory();
  const [deleting, setDeleting] = useState(false);
  const cancelRef = useRef<any>();
  const [selectedDocument, setSelectedDocument] = useState<null | Document>(
    null
  );
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const [deleteDoc] = useDeleteDocumentMutation();
  // const [documentsSearch, setDocumentsSearch] = useState('');

  // const debouncedGetDocuments = useMemo(() => debounce(getDocuments, 500), []);

  // const setSearch = async (search: string) => {
  //   setDocumentsSearch(search);
  //   await debouncedGetDocuments({
  //     variables: {
  //       request: {
  //         search,
  //       },
  //     },
  //   });
  // };

  const createDocument = async () => {
    try {
      setSubmitting(true);
      const document = await createEmptyDocument({
        variables: {
          payload: {
            title,
            templateId: selectedTemplate!.id,
          },
        },
      });

      onClose();
      setStep(1);
      setSelectedTemplate(null);
      setTitle('');
      toast({
        title: 'Dokumenti u krijua me sukses',
        description: 'Dokumenti juaj u krijua me sukses',
        status: 'success',
      });
      history.push(`/document/${document.data?.createEmptyDocument.id}/edit`);
    } catch (e) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const selectDocumentForDelete = (document: Document) => {
    setSelectedDocument(document);
    onDeleteAlertOpen();
  };

  const deleteDocument = async () => {
    try {
      setDeleting(true);
      await deleteDoc({
        variables: {
          deleteDocumentId: selectedDocument!.id,
        },
      });
      await refetchDocuments();
      onDeleteAlertClose();
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onDeleteAlertClose}
        isOpen={isDeleteAlertOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Fshi Dokumentin?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            A jeni të sigurt që dëshironi të fshini këtë dokument?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onDeleteAlertClose}>
              Anullo
            </Button>
            <Button
              isLoading={deleting}
              onClick={deleteDocument}
              colorScheme="red"
              ml={3}
            >
              Aprovo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Modal isCentered={true} size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {step === 1 ? (
              <Text>Ju lutem zgjidhni dokumentin që dëshironi të krijoni</Text>
            ) : (
              <Text>{selectedTemplate?.title}</Text>
            )}
          </ModalHeader>
          <ModalBody>
            {step === 1 && (
              <SelectDocumentStep1
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate}
              />
            )}
            {step === 2 && (
              <SelectDocumentStep2
                selectedTemplate={selectedTemplate}
                title={title}
                setTitle={setTitle}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup variant="outline" spacing="6">
              <Button disabled={step < 2} onClick={() => setStep(1)}>
                Prapa
              </Button>
              {step === 1 && (
                <Button
                  disabled={!(step === 1) || !selectedTemplate}
                  colorScheme={'red'}
                  onClick={() => setStep(2)}
                >
                  Vazhdo
                </Button>
              )}
              {step === 2 && (
                <Button
                  onClick={createDocument}
                  colorScheme={'red'}
                  disabled={
                    submitting || !title || !selectedTemplate || !(step === 2)
                  }
                  isLoading={submitting}
                >
                  Krijo dokumentin
                </Button>
              )}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <CardHeader
        title="Dokumentet e mia"
        action={
          <Box className="issue-filters">
            <Stack
              spacing="4"
              direction={{ base: 'column', md: 'row' }}
              justify="space-between"
            >
              <HStack>
                <FormControl minW={{ md: '320px' }} id="search">
                  <InputGroup size="sm">
                    <FormLabel srOnly>Filter by name or email</FormLabel>
                    <InputLeftElement pointerEvents="none" color="gray.400">
                      <BsSearch />
                    </InputLeftElement>
                    <Input
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Kërko"
                      type="search"
                      value={documentsSearch || ''}
                    />
                  </InputGroup>
                </FormControl>
              </HStack>
              <ButtonGroup size="sm" variant="outline">
                <Button
                  iconSpacing="1"
                  leftIcon={<HiPlus fontSize="1.25em" />}
                  onClick={onOpen}
                >
                  Krijo dokument
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        }
      ></CardHeader>

      {documentsLoading ? (
        <Loader />
      ) : documentsShowEmptyResults ? (
        <NoDocumentsList onClick={() => onOpen()} />
      ) : (
        <>
          <Box
            display={{ base: 'none', lg: 'block' }}
            textTransform={'uppercase'}
          >
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              spacing="10px"
              p={2}
              my={3}
              borderLeft={`10px solid white`}
            >
              <Box w="44px"></Box>
              <Box w="55%">
                <Text color="gray.600" fontSize={'sm'}>
                  Titulli
                </Text>
              </Box>
              <Box w={{ base: '100%', lg: '15%' }}>
                <Text color="gray.600" fontSize={'sm'}>
                  Ndryshimi i fundit
                </Text>
              </Box>
              <Box w={{ base: '100%', lg: '15%' }}>
                <Text color="gray.600" fontSize={'sm'}>
                  Statusi
                </Text>
              </Box>
              <Box w={{ base: '100%', lg: '10%' }}></Box>
              <Box w={{ base: '100%', lg: '5%' }}></Box>
            </Stack>
          </Box>
          <Stack spacing={'15px'}>
            {documents?.map((document) => (
              <DocumentItem
                key={document.id}
                color={getOutlineColor(document.status)}
                document={document as Document}
                deleteDocument={selectDocumentForDelete}
              />
            ))}
          </Stack>
          {documents.length && (
            <TablePagination
              onNextPage={getNextPage}
              onPreviousPage={getPreviousPage}
              total={totalCount}
              pageInfo={pageInfo!}
            />
          )}
        </>
      )}
    </>
  );
};

const DocumentItem = ({
  color,
  document,
  deleteDocument,
}: {
  color: string;
  document: Document;
  deleteDocument: (document: Document) => void;
}) => {
  const [isBigScreen] = useMediaQuery('(min-width: 920px)');

  return isBigScreen ? (
    <DocumentItemDesktop
      color={color}
      document={document}
      deleteDocument={deleteDocument}
    />
  ) : (
    <DocumentItemMobile color={color} document={document} />
  );
};

const DocumentItemDesktop = ({
  color,
  document,
  deleteDocument,
}: {
  color: string;
  document: Document;
  deleteDocument: (document: Document) => void;
}) => {
  return (
    <Stack
      direction={'row'}
      spacing="10px"
      shadow="sm"
      borderRadius={'lg'}
      p={2}
      border={'1px solid #f1f0f0'}
      borderLeft={`10px solid ${color}`}
    >
      <Box w="44px" h="50px">
        <Center h="50px">
          <Icon
            as={
              FontAwesomeIcons[
                document.template.category
                  ?.icon as keyof typeof FontAwesomeIcons
              ]
            }
            fontSize={'2xl'}
            color={color}
          />
        </Center>
      </Box>
      <Box w="55%" h="50px">
        <Link to={`/document/${document.id}/edit`}>
          <Text
            fontSize={'lg'}
            fontWeight={'bold'}
            noOfLines={1}
            color="gray.700"
          >
            {document.title}
          </Text>
        </Link>
        <HStack spacing="10px" divider={<StackDivider />}>
          <Text color="gray.600" fontSize={'sm'}>
            Shablloni: {document.template.title}
          </Text>
          <Text color="gray.600" fontSize={'sm'}>
            Krijuar më: {moment(document.createdAt).format('DD/MM/YYYY HH:mm')}
          </Text>
        </HStack>
      </Box>
      <Box w={'15%'} h="50px">
        <Text color="gray.700" fontSize={'md'} lineHeight={'50px'}>
          {moment(document.updatedAt).format('DD/MM/YYYY HH:mm')}
        </Text>
      </Box>
      <Box w={'15%'} h="50px">
        <Box
          h="50px"
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Tag size={'md'} variant="solid" bg={color}>
            {getDocumentStatusName(document.status)}
          </Tag>
        </Box>
      </Box>
      <Box w={'10%'} h="50px">
        <Center textAlign={'left'} h="50px">
          <Button
            as={Link}
            to={`/document/${document.id}/edit`}
            colorScheme="gray"
            variant="ghost"
          >
            Redakto
          </Button>
        </Center>
      </Box>
      <Box w={'5%'}>
        <Center h="50px">
          <Menu isLazy>
            <MenuButton
              border={'none'}
              _hover={{
                bg: 'transparent',
                // focus: 'none',
              }}
              _active={{
                bg: 'transparent',
                // focus: 'none',
              }}
              as={IconButton}
              aria-label="Options"
              icon={<BsThreeDotsVertical />}
              variant="outline"
            />
            <MenuList>
              {/* MenuItems are not rendered unless Menu is open */}
              <MenuItem as={Link} to={`/document/${document.id}/edit`}>
                Redakto
              </MenuItem>
              <MenuItem
                disabled={document.status === DocumentStatus.InProgress}
                style={{
                  pointerEvents:
                    document.status === DocumentStatus.InProgress
                      ? 'none'
                      : 'all',
                }}
                as={Link}
                to={`/document/${document.id}/access`}
              >
                Shkarko
              </MenuItem>
              <MenuItem onClick={() => deleteDocument(document)}>Fshi</MenuItem>
            </MenuList>
          </Menu>
        </Center>
      </Box>
    </Stack>
  );
};

const DocumentItemMobile = ({
  color,
  document,
}: {
  color: string;
  document: Document;
}) => (
  <Stack
    direction={'column'}
    spacing="10px"
    shadow="sm"
    borderRadius={'lg'}
    p={2}
    border={'1px solid #f1f0f0'}
    borderLeft={`10px solid ${color}`}
  >
    <HStack>
      <Box w="44px" h="30px">
        <Center h="30px">
          <Icon
            as={
              FontAwesomeIcons[
                document.template.category
                  ?.icon as keyof typeof FontAwesomeIcons
              ]
            }
            fontSize={'2xl'}
            color={color}
          />
        </Center>
      </Box>
      <Box h="30px">
        <Text
          fontSize={'lg'}
          fontWeight={'bold'}
          noOfLines={1}
          color="gray.700"
        >
          {document.title}
        </Text>
      </Box>
    </HStack>

    <Box>
      <CardContent marginBottom="5">
        <Property label="Shablloni" value={document.template.title} />
        <Property
          label="Krijuar më"
          value={moment(document.createdAt).format('DD/MM/YYYY HH:mm')}
        />
        <Property
          label="Ndryshimi i fundit"
          value={moment(document.updatedAt).format('DD/MM/YYYY HH:mm')}
        />
        <Property
          label="Statusi"
          value={
            <Tag size={'md'} variant="solid" bg={color}>
              {getDocumentStatusName(document.status)}
            </Tag>
          }
        />
      </CardContent>
    </Box>

    <Center>
      <HStack divider={<StackDivider />}>
        <Button
          leftIcon={<FaEdit />}
          as={Link}
          to={`/document/${document.id}/edit`}
          colorScheme="gray"
          variant="ghost"
        >
          Redakto
        </Button>
        <Button
          as={Link}
          to={`/document/${document.id}/access`}
          disabled={document.status === DocumentStatus.InProgress}
          style={{
            pointerEvents:
              document.status === DocumentStatus.InProgress ? 'none' : 'all',
          }}
          leftIcon={<FaDownload />}
          colorScheme="gray"
          variant="ghost"
        >
          Shkarko
        </Button>
      </HStack>
    </Center>
  </Stack>
);
