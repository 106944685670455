import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, Box } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { DropzoneControl } from '../components/Form';

type CreateBidProps = {
  isOpen: boolean;
  onClose: () => void;
  sendFile: (values: {
    file: {
      key: string;
      contentType: string;
      url: string;
    };
  }) => Promise<void>;
  sending: boolean;
};

export const AddFileModal = (props: CreateBidProps) => {
  const { isOpen, onClose, sendFile, sending } = props;

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.file) {
      errors.file = 'Fushë e detyruar';
    }

    return errors;
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ngarko një dokument</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form
            onSubmit={sendFile}
            validate={validate}
            render={({ handleSubmit, form }) => (
              <Box>
                {/* @ts-ignore */}
                <DropzoneControl
                  label="Dokumenti"
                  name="file"
                  unauthenticated={false}
                />

                <Button
                  // @ts-ignore
                  onClick={form.submit}
                  disabled={sending}
                  type="submit"
                >
                  Dërgo
                </Button>
              </Box>
            )}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
