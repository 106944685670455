import { HTMLChakraProps } from '@chakra-ui/react';
import { Image } from '@chakra-ui/image';
import { Box } from '@chakra-ui/layout';
import * as React from 'react';

interface IProps extends HTMLChakraProps<'img'> {
  transparent?: boolean
}

export const Logo = (props: IProps) => {
  // const [white, black] = useToken('colors', ['white', 'gray.800']);
  if (props.transparent) {
    return (
      <Box>
        <Image
          h="12"
          src="/legit-logo-bw.png"
          px={6}
        ></Image>
      </Box>
    );
  }

  return (
    <Box >
      <Image
        h="12"
        src="/legit-normal.png"
        px={6}
        {...props}
      ></Image>
    </Box>
  );
};
