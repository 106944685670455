import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Steps } from '../../../components/DocumentSteps/Steps';
import { useSteps } from '../../../components/DocumentSteps/useSteps';
import { Loader } from '../../../components/Loader';
import {
  DocumentStatus,
  useFinishDocumentMutation,
  useGetDocumentByIdQuery,
  useUpdateDocumentVariablesMutation,
} from '../../../graphql';
import PdfViewer from '../pdf-viewer';
import { StepFromBlockProxy } from './inputs/step';

interface EditDocumentParams {
  id: number;
}

export const EditDocument = ({ id }: EditDocumentParams) => {
  const [pdfViewerKey, setPdfViewerKey] = useState(String(Date.now()));
  const [updateDocumentVariables] = useUpdateDocumentVariablesMutation();
  const [finishDocument] = useFinishDocumentMutation();
  const { nextStep, prevStep, activeStep, setActiveStep } = useSteps({
    initialStep: 0,
  });
  const history = useHistory();
  const [finishLoading, setFinishLoading] = useState(false);
  const { data: documentData, loading: documentLoading } =
    useGetDocumentByIdQuery({
      variables: {
        getDocumentByIdId: id,
      },
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    const step: number = documentData?.getDocumentById?.activeStep || 0;
    if (documentData?.getDocumentById.status === DocumentStatus.InProgress) {
      setActiveStep(step);
    } else {
      setActiveStep(documentData?.getDocumentById.blocks.blocks.length);
    }
  }, [documentData]);

  const preprocess = (
    block: Record<string, any>,
    ...processorFuncs: Array<(obj: Record<string, any>) => Record<string, any>>
  ): Record<string, any> => {
    const blockKey = Object.keys(block)[0];
    const blockValue = Object.values(block)[0];
    let processed = blockValue;
    for (const processor of processorFuncs) {
      processed = processor(processed);
    }

    return { [blockKey]: processed };
  };

  const convertPotentialValuesToNumbers = (
    obj: Record<string, any>
  ): Record<string, any> => {
    let returnVal: Record<string, any> = {};
    const isNumber = (n: any): boolean => {
      return !isNaN(parseFloat(n as string)) && isFinite(n as number);
    };

    Object.entries(obj).forEach(([key, value]) => {
      if (isNumber(value)) {
        returnVal[key] = Number(value);
      } else {
        returnVal[key] = value;
      }
    });

    return returnVal;
  };

  const onStepSubmit = (block: any) => {
    return async function (values: any) {
      try {
        const payload = {
          [block.title]: values,
        };

        const processedVariables = preprocess(
          payload,
          convertPotentialValuesToNumbers
        );

        await updateDocumentVariables({
          variables: {
            updateDocumentVariablesId: Number(id),
            variables: processedVariables,
            activeStep,
          },
        });
        setPdfViewerKey(String(Date.now()));
        nextStep();
      } catch (error) {
        console.error(error);
      }
    };
  };

  const onFinish = async () => {
    try {
      setFinishLoading(true);
      await finishDocument({
        variables: {
          finishDocumentId: Number(id),
        },
      });

      history.push(`/document/${id}/access`);
    } catch (error) {
      console.error(error);
    } finally {
      setFinishLoading(false);
    }
  };

  if (documentLoading || !documentData) {
    return <Loader />;
  }

  return (
    <>
      <Box my={6}>
        <Alert
          status="info"
          variant="left-accent"
          colorScheme={'gray'}
          fontSize={'sm'}
        >
          <AlertIcon />
          <Box>
            Ju lutem plotësoni me kujdes të gjitha fushat e informacionit dhe
            pyetjet për të gjeneruar një dokument të saktë. Ju mund ti
            riktheheni përgjigjeve sa herë të dëshironi për ti korrigjuar apo
            ndryshuar ato, para se të kryeni pagesën dhe shkarkimin e
            dokumentit. Dokumenti nuk mund të ndryshohet pasi është shkarkuar.
          </Box>
        </Alert>
      </Box>

      <Box
        display={'flex'}
        mt={5}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Box
          flex={{ base: '0 0 100%', lg: '0 0 446px' }}
          position={'relative'}
          zIndex={'1'}
        >
          <Box position={'sticky'} top="0" left="0">
            <PdfViewer
              width="500px"
              key={pdfViewerKey}
              url={`${documentData.getDocumentById.previewUrl}?key=${pdfViewerKey}`}
            />
          </Box>
        </Box>

        <Box
          paddingLeft={{ base: '0', lg: '30px' }}
          marginTop={{ base: '30px', lg: '0' }}
          width={{ base: '100%', lg: 'calc(100% - 300px)' }}
        >
          <Steps activeStep={activeStep}>
            {documentData.getDocumentById?.blocks.blocks.map(
              (block: any, index: number) => (
                <StepFromBlockProxy
                  key={block.title}
                  block={block}
                  prevButtonDisabled={index <= 0}
                  nextButtonDisabled={false}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  currentStep={() => setActiveStep(index)}
                  initialValues={
                    documentData.getDocumentById.variables[block.title] || {}
                  }
                  onStepSubmit={onStepSubmit(block)}
                />
              )
            )}
          </Steps>
          <HStack
            display={
              activeStep === documentData.getDocumentById?.blocks.blocks.length
                ? 'flex'
                : 'none'
            }
            mt="10"
            spacing="4"
            shouldWrapChildren
          >
            <Text>Të gjithë hapat u plotësuan</Text>
            <Button
              size="sm"
              variant="outline"
              verticalAlign="baseline"
              disabled={finishLoading}
              isLoading={finishLoading}
              onClick={onFinish}
            >
              Shkarko
            </Button>
          </HStack>
        </Box>
      </Box>
    </>
  );
};

export const EditDocumentPage = () => {
  // @ts-ignore
  const { id } = useParams();

  return <EditDocument id={Number(id)} />;
};
