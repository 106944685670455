import { useCallback, useEffect, useMemo } from 'react';
import { useGetAllIssuesLazyQuery } from '../graphql';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { debounce } from 'lodash';

const PER_PAGE = 25;
const INITIAL_PAGE = 1;

function useAdminIssues() {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [search, setSearchInQuery] = useQueryParam('search', StringParam);
  const [status, setStatusInQuery] = useQueryParam('status', StringParam);

  const variables = useMemo(
    () => ({
      filter: {
        // startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
        // endDate: endDate ? format(addHours(endDate, 1), 'yyyy-MM-dd') : null,
        startDate: null,
        endDate: null,
        page: page || INITIAL_PAGE,
        pageLimit: PER_PAGE,
        search: search || null,
        status: status || null,
      },
    }),
    [page, search, status]
  );

  const [request, { data, loading, refetch: refetchQuery }] =
    useGetAllIssuesLazyQuery({
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    if (!page) {
      setPage(INITIAL_PAGE);
    }
    request({ variables });
  }, []);

  const debouncedRequest = useCallback(debounce(request, 500), []);
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // const isActuallyLoading = useMemo(() => {
  //   return (
  //     loading && !client.readQuery({ query: GetAllIssuesDocument, variables })
  //   );
  // }, [loading, variables, client]);

  // const showEmptyResults = useMemo(() => !loading && called, [loading, called]);

  const setSearch = async (search: string) => {
    const vars = { ...variables };
    vars.filter.search = search || null;
    vars.filter.page = INITIAL_PAGE;

    await debouncedRequest({ variables: vars });

    if (!search) {
      setSearchInQuery(undefined);
    } else {
      setSearchInQuery(search);
    }
    setPage(INITIAL_PAGE);
  };

  const getPreviousPage = async () => {
    if (data?.getAllIssues?.pageInfo?.hasPreviousPage) {
      const page = data?.getAllIssues?.pageInfo?.previousPage || INITIAL_PAGE;
      const vars = { ...variables };
      vars.filter.page = page;
      scrollToTop();
      await refetchQuery({ filter: vars.filter });
      setPage(page);
    }
  };

  const getNextPage = async () => {
    if (data?.getAllIssues?.pageInfo?.hasNextPage) {
      const page = data?.getAllIssues?.pageInfo?.nextPage || INITIAL_PAGE;
      const vars = { ...variables };
      vars.filter.page = page;
      scrollToTop();
      await refetchQuery({ filter: vars.filter });
      setPage(page);
    }
  };

  const setStatus = async (status: string) => {
    if (status) {
      setStatusInQuery(status);
    } else {
      setStatusInQuery(undefined);
    }

    const vars = { ...variables };
    vars.filter.page = INITIAL_PAGE;
    vars.filter.status = status;

    await refetchQuery({ filter: vars.filter });
    setPage(INITIAL_PAGE);
  };

  const refetch = async () => {
    await refetchQuery();
  };

  return {
    totalCount: data?.getAllIssues?.totalCount || 0,
    items: data?.getAllIssues?.nodes || [],
    pageInfo: data?.getAllIssues?.pageInfo,
    loading,
    showEmptyResults: !loading,
    getNextPage,
    getPreviousPage,
    refetch,
    search,
    setSearch,
    status,
    setStatus,
  };
}

export { useAdminIssues };
