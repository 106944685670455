import React from 'react';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { Field, Form } from 'react-final-form';
import {
  Optional,
  InputControl,
  CheckboxControl,
  AdaptedSelect,
  TextareaControl,
} from '../components/Form';
import { useGetAllDocumentCategoriesQuery } from '../graphql';
import { Loader } from '../components/Loader';

export interface IUpdateDocumentTemplateValues {
  title: string;
  price: number;
  active: boolean;
  categoryId: number;
  description: string;
}

interface IDocumentTemplateForm {
  onSubmit: (values: IUpdateDocumentTemplateValues) => void;
  initialValues?: Optional<IUpdateDocumentTemplateValues>;
}

export const UpdateDocumentTemplateForm: React.FC<IDocumentTemplateForm> = ({
  onSubmit,
  initialValues,
}) => {
  const { data: categories, loading: categoriesLoading } =
    useGetAllDocumentCategoriesQuery();
  const validate = (values: IUpdateDocumentTemplateValues) => {
    const errors: { [key: string]: string } = {};

    if (!values.title) {
      errors.title = 'Fushë e detyruar';
    }

    if (Number.isNaN(Number(values.price))) {
      errors.price = 'Duhet të jetë numër';
    }

    if (Number(values.price) < 0) {
      errors.price = 'Duhet të jetë numër pozitiv';
    }

    if (values.categoryId <= 0) {
      errors.categoryId = 'Fushë e detyruar';
    }

    if (!values.description) {
      errors.description = 'Fushë e detyruar';
    }

    return errors;
  };

  if (categoriesLoading) {
    return <Loader />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        errors,
        submitting,
        pristine,
        values,
      }) => (
        <Box as="form" onSubmit={handleSubmit}>
          <InputControl name="title" label="Titulli" />
          <InputControl name="price" label="Çmimi" type="number" />
          <Alert
            status="info"
            variant="left-accent"
            colorScheme={'gray'}
            fontSize={'sm'}
          >
            <AlertDescription>
              Për të vendosur URL të jashtme në përshkrim përdorni formatin:
              [http://link.com](teksti që dëshironi të shfaqet)
            </AlertDescription>
          </Alert>
          <TextareaControl
            name="description"
            label="Përshkrimi"
            placeholder="Shkruani përshkrimin"
          />
          <Field
            name="categoryId"
            label="Kategoria"
            initialValue={initialValues?.categoryId}
            component={AdaptedSelect}
          >
            {categories?.getAllDocumentCategories.map((category) => (
              <option value={category.id}>{category.name}</option>
            ))}
          </Field>
          <CheckboxControl name="active" checked={values.active}>
            Ky dokument është aktiv dhe mund të shihet nga të gjithë.
          </CheckboxControl>

          <ButtonGroup py="6" spacing={4}>
            <Button
              isLoading={submitting}
              loadingText="Duke dërguar"
              variantColor="teal"
              type="submit"
            >
              Ruaj
            </Button>
            <Button
              variantColor="teal"
              variant="outline"
              // @ts-ignore
              onClick={form.reset}
              isDisabled={submitting || pristine}
            >
              Pastro
            </Button>
          </ButtonGroup>
        </Box>
      )}
    />
  );
};
