import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import moment from 'moment';
import * as React from 'react';
import { CardHeader } from '../../../components/Card';
import { Loader } from '../../../components/Loader';
import { PricingCard } from '../../../components/Pricing/PricingCard';
import {
  GetMySubscriptionQuery,
  Subscription as SubscriptionType,
  useCapturePaypalOrderPaymentMutation,
  useCreateUserSubscriptionMutation,
  useGetActiveSubscriptionsQuery,
  useGetMySubscriptionQuery,
} from '../../../graphql';

type ActiveSubscriptionInfoProps = {
  mySubscription: GetMySubscriptionQuery;
};

const ActiveSubscriptionInfo = (props: ActiveSubscriptionInfoProps) => {
  const { mySubscription } = props;

  return (
    <>
      <Box
        textAlign={{
          base: 'center',
          lg: 'start',
        }}
      >
        <Heading
          size="2xl"
          lineHeight="normal"
          color={useColorModeValue('red.600', 'red.400')}
          letterSpacing="tight"
          // fontWeight="extrabold"
        >
          Plani Premium
        </Heading>
        <Text
          fontSize={{
            base: 'lg',
            lg: 'xl',
          }}
          mt="4"
          maxW="xl"
          mx={{
            base: 'auto',
            lg: 'unset',
          }}
        >
          Urime ju jeni abonuar ne planin Premium ne{' '}
          {moment(mySubscription.getMySubscription?.createdAt).format(
            'DD/MM/YYYY'
          )}{' '}
          dhe dhe do të mund te perdorni te gjitha përfitimet e planit deri me
          date{' '}
          {moment(mySubscription.getMySubscription?.validUntil).format(
            'DD/MM/YYYY'
          )}
          .
        </Text>
      </Box>
    </>
  );
};

type MyPlansProps = {
  subscription: SubscriptionType;
};

export const MyPlans = (props: MyPlansProps) => {
  const { subscription } = props;
  return (
    <Box as="section" bg={useColorModeValue('white', 'gray.800')}>
      <Box
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
      >
        <Center>
          <PricingCard
            bg="red.600"
            color="white"
            button={null}
            data={{
              name: subscription.name,
              description: '',
              price: subscription.price,
              duration: subscription.period.toLocaleLowerCase(),
              benefits: subscription.description.split('\n'),
            }}
          />
        </Center>
        <Box marginTop={'15px'}></Box>
      </Box>
    </Box>
  );
};

interface ActiveSubscriptionParams {
  mySubscription: GetMySubscriptionQuery;
  subscriptionPlan: SubscriptionType;
}

const ActiveSubscription = (params: ActiveSubscriptionParams) => {
  const { mySubscription, subscriptionPlan } = params;

  return (
    <>
      <Box
        display={'flex'}
        mt={5}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Box
          flex={{ base: '0 0 100%', lg: '0 0 446px' }}
          position={'relative'}
          zIndex={'1'}
        >
          <MyPlans subscription={subscriptionPlan} />
        </Box>

        <Box
          paddingLeft={{ base: '0', lg: '30px' }}
          marginTop={{ base: '30px', lg: '0' }}
          width={{
            base: '100%',
            lg: 'calc(100% - 300px)',
          }}
        >
          <ActiveSubscriptionInfo
            mySubscription={mySubscription}
          ></ActiveSubscriptionInfo>
        </Box>
      </Box>
    </>
  );
};

interface InactiveSubscriptionParams {
  subscription: SubscriptionType;
  onRefresh: () => void
}

const InactiveSubscription = (params: InactiveSubscriptionParams) => {
  const toast = useToast();
  const { subscription } = params;
  const [createUserSubscription] = useCreateUserSubscriptionMutation();
  const [capturePaypalOrderPayment] = useCapturePaypalOrderPaymentMutation();

  const handleCreateUserSubscription = async (
    subscription: SubscriptionType
  ): Promise<string> => {
    try {
      const res = await createUserSubscription({
        variables: {
          subscriptionId: subscription.id,
        },
      });
     return res.data?.createUserSubscription!;
    } catch (err) {
      console.log(err);
    } finally {
    }

    return ""
  };

  return (
    <>
      <Box
        textAlign={{
          // base: 'center',
          lg: 'start',
        }}
      >
        <Heading
          size="2xl"
          lineHeight="normal"
          color={useColorModeValue('red.600', 'red.400')}
          letterSpacing="tight"
        >
          Abonohu në Planin Premium të Legit
        </Heading>
        <Text
          fontSize={{
            base: 'lg',
            lg: 'xl',
          }}
          mt="4"
          mx={{
            base: 'auto',
            lg: 'unset',
          }}
        >
          Dëshiron të çlirosh të gjitha superfuqitë e Legit? Mos prit, kryej
          abonimin vjetor në Legit.
        </Text>
      </Box>
      <Center>
        <SimpleGrid
          mt="5"
          columns={{
            base: 1,
            lg: 2,
          }}
          spacing="6"
        >
          <>
            <PricingCard
              bg="gray.100"
              color="gray.700"
              maxWidth={'330px'}
              minWidth={'330px'}
              button={null}
              data={{
                name: 'Abonim falas',
                description: '',
                price: 0,
                duration: 'none',
                benefits: [
                  'Dua të shkarkoj vetëm këtë dokument',
                  'Nuk dua akses në dokumenta të tjera',
                ],
              }}
            />

            <PricingCard
              bg="red.600"
              color="white"
              maxWidth={'330px'}
              minWidth={'330px'}
              button={
                <Box>
                  <Text fontSize={'sm'} color={'gray.50'} mb="10px">
                    Abonohu tani! Paguaj me PayPal.
                  </Text>

                  <PayPalButtons
                    style={{
                      layout: 'horizontal',
                      color: 'black',
                      tagline: false,
                    }}
                    createOrder={async (data, actions) => {
                      const id = await handleCreateUserSubscription(
                        subscription
                      );

                      return id;
                    }}
                    onApprove={(data, actions) => {
                      return actions!.order!.capture().then(async (details) => {
                        await capturePaypalOrderPayment({
                          variables: {
                            paypalOrderId: data.orderID,
                          },
                        });

                        params.onRefresh();
                      });
                    }}
                    onError={(err) => [
                      toast({
                        title: `Pati një problem në kryerjen e pagesës.`,
                        status: 'error',
                      }),
                    ]}
                    onCancel={(err) => [
                      toast({
                        title: `Kryerje e pagesës u anolua nga klienti.`,
                        status: 'info',
                      }),
                    ]}
                  />
                </Box>
              }
              data={{
                name: subscription.name,
                description: '',
                price: subscription.price,
                duration: subscription.period.toLocaleLowerCase(),
                benefits: subscription.description.split('\n'),
              }}
            />
          </>
        </SimpleGrid>
      </Center>
    </>
  );
};

interface SubscriptionParams {}

const Subscription = (params: SubscriptionParams) => {
  const { data: mySubscription, loading: mySubscriptionLoading, refetch } =
    useGetMySubscriptionQuery();
  const { data: activeSubscriptions, loading: getActiveSubscriptionsLoading } =
    useGetActiveSubscriptionsQuery();

  const yearlySubscription = React.useMemo(() => {
    return activeSubscriptions?.getActiveSubscriptions[0];
  }, [activeSubscriptions]);

  if (mySubscriptionLoading || getActiveSubscriptionsLoading) {
    return <Loader />;
  }

  return (
    <>
      <CardHeader title="Abonimi im"></CardHeader>

      {mySubscription?.getMySubscription ? (
        <ActiveSubscription
          mySubscription={mySubscription}
          subscriptionPlan={yearlySubscription as SubscriptionType}
        />
      ) : (
        <InactiveSubscription
          subscription={yearlySubscription as SubscriptionType}
          onRefresh={() => {
            refetch()
          }}
        />
      )}
    </>
  );
};

export const SubscriptionPage = () => {
  return <Subscription />;
};
