import * as React from 'react';
import { Box } from '@chakra-ui/layout';
import { AdminProfile } from '../../graphql';
import { Property } from '../../components/Property';

export const AdminDetails = ({ profile }: { profile: AdminProfile }) => {
  return (
    <Box w="full" mt={10}>
      <Property label="Emri" value={profile.name} />
      <Property label="Mbiemri" value={profile.lastname} />
    </Box>
  );
};
