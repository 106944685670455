import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import moment from 'moment';
import { FaDollarSign } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { CardContent } from '../../components/Card/CardContent';
import { CardHeader } from '../../components/Card/CardHeader';
import { Link } from '../../components/Link';
import { Loader } from '../../components/Loader';
import { Property } from '../../components/Property';
import {
  InvoiceDetails,
  InvoiceItemType,
  Issue,
  useCapturePaypalOrderPaymentMutation,
  useGetMyInvoiceByIdQuery,
  UserSubscription,
} from '../../graphql';
import { BankDetailsModal } from '../../modals/BankDetailsModal';
import { getPaymentTypeLabel } from '../../utils/payment-utils';
import { IColumn, SimpleTable } from '../../utils/table';

const invoiceDetailsColumns: IColumn[] = [
  {
    Header: 'Çmimi',
    accessor: '',
    tdParams: {
      width: '33%',
    },
    Cell: function priceCell(cell: any, row: InvoiceDetails) {
      return <Text>{row.price.toLocaleString()} EUR</Text>;
    },
  },
  {
    Header: 'Titulli/Emri',
    accessor: 'item.title',
    tdParams: {
      width: '33%',
    },
    Cell: function TitleCell(cell: any, row: InvoiceDetails) {
      if (row.type === InvoiceItemType.Subscription) {
        return <Text>{(row.item as UserSubscription).subscription.name}</Text>;
      } else {
        return <Text>{(row.item as Issue | Document).title}</Text>;
      }
    },
  },
  {
    Header: 'Detajet',
    accessor: '',
    Cell: function DetailsCell(cell: any, row: InvoiceDetails) {
      if (row.type === InvoiceItemType.Subscription) {
        return <Text>-</Text>;
      } else if (row.type === InvoiceItemType.Document) {
        return (
          <Link href={`/document/${row.item.id}/metadata`}>
            Detajet e dokumentit
          </Link>
        );
      } else if (row.type === InvoiceItemType.Issue) {
        return (
          <Link href={`/issues/details/${row.item.id}`}>
            Detajet e çështjes
          </Link>
        );
      } else {
        return <Box></Box>;
      }
    },
    tdParams: {
      width: '33%',
    },
  },
];

export const MyInvoiceDetailsPage = () => {
  const toast = useToast();
  const { id } = useParams<{ id?: string | undefined }>();
  const { data, loading, refetch } = useGetMyInvoiceByIdQuery({
    variables: { getInvoiceByIdId: Number(id) },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [capturePaypalOrderPayment] = useCapturePaypalOrderPaymentMutation();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <BankDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        invoiceNumber={data?.getMyInvoiceById?.id!}
      />
      <Box>
        <Box>
          <CardHeader
            title="Detajet e faturës"
            action={
              <Box>
                {
                  <Menu>
                    <MenuButton
                      disabled={Boolean(data?.getMyInvoiceById.payments.length)}
                    >
                      <Button
                        style={{ color: 'gray.600' }}
                        disabled={Boolean(
                          data?.getMyInvoiceById.payments.length
                        )}
                      >
                        Paguaj faturën
                      </Button>
                    </MenuButton>
                    <MenuList>
                      <PayPalButtons
                        style={{
                          layout: 'horizontal',
                          color: 'silver',
                          tagline: false,
                          shape: 'pill',
                        }}
                        createOrder={async (d, actions) => {
                          return data?.getMyInvoiceById?.payseraUrl!;
                        }}
                        onApprove={(data, actions) => {
                          return actions!
                            .order!.capture()
                            .then(async (details) => {
                              await capturePaypalOrderPayment({
                                variables: {
                                  paypalOrderId: data.orderID,
                                },
                              });

                              refetch({ getInvoiceByIdId: Number(id) });
                            });
                        }}
                        onError={(err) => [
                          toast({
                            title: `Pati një problem në kryerjen e pagesës.`,
                            status: 'error',
                          }),
                        ]}
                        onCancel={(err) => [
                          toast({
                            title: `Kryerje e pagesës u anolua nga klienti.`,
                            status: 'info',
                          }),
                        ]}
                      />
                      <MenuItem icon={<FaDollarSign />} onClick={onOpen}>
                        Transfertë Bankare
                      </MenuItem>
                    </MenuList>
                  </Menu>
                }
              </Box>
            }
          ></CardHeader>
          <CardContent marginBottom="5">
            <Property
              label="Totali"
              value={`${
                String(data?.getMyInvoiceById.total.toLocaleString()) || ''
              } EUR`}
            />
            <Property
              label="Data"
              value={
                moment(data?.getMyInvoiceById.createdAt).format('DD/MM/YYYY') ||
                ''
              }
            />
          </CardContent>

          <CardHeader title="Artikujt e faturës"></CardHeader>
          <SimpleTable
            columns={invoiceDetailsColumns}
            data={data?.getMyInvoiceById.invoiceDetails || []}
          />

          {Boolean(data?.getMyInvoiceById.payments.length)
            ? data?.getMyInvoiceById.payments.map((payment) => (
                <Box my="5">
                  <CardHeader
                    title={`Pagesa - ${moment(payment.createdAt).format(
                      'DD/MM/YYYY'
                    )}`}
                  ></CardHeader>
                  <CardContent marginBottom="5">
                    <Property
                      label="Tipi i pagesës"
                      value={getPaymentTypeLabel(payment.type) || ''}
                    />
                    <Property
                      label="Data e pagesës"
                      value={
                        moment(payment.createdAt).format(
                          'DD/MM/YYYY HH:mm:ss'
                        ) || ''
                      }
                    />
                  </CardContent>
                </Box>
              ))
            : null}
        </Box>
      </Box>
    </>
  );
};
