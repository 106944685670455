import { split, HttpLink, InMemoryCache, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { getToken, TOKEN_NAME } from './hooks';
import { onError } from '@apollo/client/link/error';
import { useToast } from '@chakra-ui/react';
// import { createBrowserHistory } from 'history';

// const history = createBrowserHistory();

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
});

export const useAppApolloClient = () => {
  const toast = useToast();

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors && graphQLErrors.length) {
        graphQLErrors.forEach(({ message, extensions }) => {
          if (extensions && extensions.code === `AUTHENTICATION_REQUIRED`) {
            localStorage.removeItem(TOKEN_NAME);
            const parser = new URL(window.location.href);
            parser.search = '';
            parser.searchParams.set(
              `redirect`,
              `${parser.pathname}${parser.search}`
            );
            parser.pathname = '/login';
            window.location.href = parser.href;
          }

          toast({
            title: 'Gabim',
            description: message,
            status: 'error',
          });
          return Observable.of(operation);
        });
      }
      if (networkError) {
        return Observable.of(operation);
      }
      return forward(operation);
    }
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition';
    },

    authLink.concat(errorLink).concat(httpLink)
  );

  const client = new ApolloClient({
    link,
    cache,
  });

  return { client };
};
