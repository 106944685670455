import { Box, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Link } from '../../components/Link';
import { Card } from '../../components/Card';
import { Logo } from '../../components/Logo';
import { LoginForm } from './LoginForm';

const LoginPage: React.FC = ({ children }) => {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo mx="auto" h="12" mb={{ base: '10', md: '20' }} />
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          Identifikohu
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Nuk keni një llogari në Legit?</Text>
          <Link href="/register">Regjistrohu</Link>
        </Text>
        <Card>
          <LoginForm />
        </Card>
      </Box>
    </Box>
  );
};

export default LoginPage;
