import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  HTMLChakraProps,
  Input,
  Stack,
} from '@chakra-ui/react';
import * as React from 'react';
import { PasswordField } from '../../components/PasswordField';
import { useAuthToken, useLogin } from '../../hooks';

export const LoginForm = (props: HTMLChakraProps<'form'>) => {
  const { removeAuthToken } = useAuthToken();

  const [login] = useLogin();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    removeAuthToken();
    try {
      setLoading(true);
      // @ts-ignore
      await login(email, password);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <chakra.form onSubmit={handleSubmit} {...props}>
      <Stack spacing="6">
        <FormControl id="email">
          <FormLabel>E-mail</FormLabel>
          <Input
            name="email"
            onChange={(event) => setEmail(event.currentTarget.value)}
            type="email"
            autoComplete="email"
            required
          />
        </FormControl>
        <PasswordField
          onChange={(event) => setPassword(event.currentTarget.value)}
        />
        <Button
          type="submit"
          isLoading={loading}
          colorScheme="red"
          size="lg"
          fontSize="md"
        >
          Identifikohu
        </Button>
      </Stack>
    </chakra.form>
  );
};
