import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { CardHeader } from '../../../components/Card';
import { Loader } from '../../../components/Loader';
import {
  ICreateSubscriptionValues,
  SubscriptionForm,
} from '../../../forms/SubscriptionForm';
import {
  GetAllSubscriptionsDocument,
  Subscription,
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetAllSubscriptionsQuery,
  useUpdateSubscriptionMutation,
} from '../../../graphql';
import { IColumn, SimpleTable } from '../../../utils/table';

export const SubscriptionsPage: React.FC = () => {
  const [currentItem, setCurrentItem] = useState<Subscription | any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, loading } = useGetAllSubscriptionsQuery();
  const [create] = useCreateSubscriptionMutation();
  const [update] = useUpdateSubscriptionMutation();
  const [deleteItem] = useDeleteSubscriptionMutation();

  const handleCreatEvent = async (data: ICreateSubscriptionValues) => {
    currentItem
      ? await update({
          variables: {
            updateSubscriptionId: currentItem.id,
            data: {
              name: data.name,
              price: Number(data.price),
              description: data.description,
              period: data.period,
            },
          },
          refetchQueries: [
            {
              query: GetAllSubscriptionsDocument,
            },
          ],
        })
      : await create({
          variables: {
            data: {
              name: data.name,
              price: Number(data.price),
              description: data.description,
              period: data.period,
            },
          },
          refetchQueries: [
            {
              query: GetAllSubscriptionsDocument,
            },
          ],
        });

    onClose();

    setCurrentItem(null);
  };

  const handleDeleteEvent = async (id: number) => {
    await deleteItem({
      variables: {
        deleteSubscriptionId: id,
      },
      refetchQueries: [
        {
          query: GetAllSubscriptionsDocument,
        },
      ],
    });

    onClose();
  };

  if (loading) {
    return <Loader />;
  }

  const columns: IColumn[] = [
    {
      Header: 'Emri i abonimit',
      accessor: 'name',
      tdParams: {
        width: '50%',
      },
    },
    {
      Header: 'Çmimi (EUR)',
      accessor: 'price',
      tdParams: {
        width: '50%',
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function EditCell(cell: any, row: Subscription) {
        return (
          <Button
            variant="link"
            colorScheme="blue"
            onClick={() => {
              setCurrentItem(row);
              onOpen();
            }}
            size="sm"
          >
            Redakto
          </Button>
        );
      },
      tdParams: {
        textAlign: 'right',
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function DeleteCell(cell: any, row: Subscription) {
        return (
          <Button
            variant="link"
            colorScheme="red"
            size="sm"
            onClick={() => handleDeleteEvent(row.id)}
          >
            Fshi
          </Button>
        );
      },
      tdParams: {
        textAlign: 'right',
      },
    },
  ];

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <CardHeader
              title="Shto një abonim"
              action={<ModalCloseButton />}
            ></CardHeader>
          </ModalHeader>
          <ModalBody>
            <SubscriptionForm
              onSubmit={(data) => handleCreatEvent(data)}
              initialValues={{ ...currentItem }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <CardHeader
        title="Lista e abonimeve"
        action={
          <ButtonGroup size="sm" variant="outline">
            <Button
              isDisabled={Number(data?.getAllSubscriptions.length) >= 1}
              iconSpacing="1"
              leftIcon={<HiPlus fontSize="1.25em" />}
              onClick={onOpen}
            >
              Shto
            </Button>
          </ButtonGroup>
        }
      ></CardHeader>

      <SimpleTable columns={columns} data={data?.getAllSubscriptions!} />
    </>
  );
};
