import * as React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { ResetPasswordForm } from '../forms/ResetPassword';
import { ResetPasswordFormValues } from '../forms/ResetPassword';
import {
  EditGeneralInfoForm,
  GeneralInfoFormValues,
} from '../forms/EditGeneralInfo';
import {
  useUpdateAccountMutation,
  MeDocument,
  useResetPasswordOfAuthenticatedUserMutation,
  AccountRole,
  MeQuery,
  LawyerProfile,
  Media,
  useUpdateLawyerCvMutation,
  useUpdateLawyerDiplomaMutation,
  useUpdateLawyerLicenseMutation,
} from '../graphql';
import {
  UpdateLawyerForm,
  UpdateLawyerFormValues,
} from '../forms/UpdateLawyerForm';
import { useToast } from '@chakra-ui/react';

type EditProfileProps = {
  isOpen: boolean;
  onClose: () => void;
  data: MeQuery;
};

export const EditProfileModal = (props: EditProfileProps) => {
  const { data } = props;
  const toast = useToast();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [updateAccount] = useUpdateAccountMutation();
  const [resetPassword] = useResetPasswordOfAuthenticatedUserMutation();
  const [updateLawyerCv] = useUpdateLawyerCvMutation();
  const [updateLawyerDiploma] = useUpdateLawyerDiplomaMutation();
  const [updateLawyerLicense] = useUpdateLawyerLicenseMutation();

  const editableGeneralInfo: GeneralInfoFormValues = {
    name: data.me.profile?.name || '',
    lastname: data.me.profile?.lastname || '',
    phoneNumber: data.me.phoneNumber,
    profilePhoto: (data.me.profile?.profilePictureRaw as Media) || undefined,
  };

  const handleUpdateGeneralInfo = async (payload: GeneralInfoFormValues) => {
    try {
      await updateAccount({
        variables: {
          data: {
            name: payload.name,
            lastname: payload.lastname,
            phoneNumber: payload.phoneNumber,
            profilePhoto: payload.profilePhoto?.key
              ? {
                  key: payload.profilePhoto.key,
                  contentType: (payload.profilePhoto as any).url
                    ? editableGeneralInfo.profilePhoto?.contentType ||
                      'application/image'
                    : payload.profilePhoto.contentType,
                }
              : null,
          },
        },
        refetchQueries: [{ query: MeDocument }],
      });
      onClose();
    } catch (error) {
      console.error('err', error);
    }
  };

  const handleResetPassword = async (payload: ResetPasswordFormValues) => {
    try {
      const requestPayload = {
        oldPassword: payload.oldPassword,
        newPassword: payload.confirmPassword,
      };

      await resetPassword({
        variables: {
          data: {
            ...requestPayload,
          },
        },
        refetchQueries: [{ query: MeDocument }],
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateLawyerCv = async (payload: UpdateLawyerFormValues) => {
    await updateLawyerCv({
      variables: {
        data: {
          cv: payload.file,
        },
      },
      refetchQueries: [{ query: MeDocument }],
    });
    toast({
      title: 'CV u ndryshua me sukses',
      status: 'success',
    });
    onClose();
  };

  const handleUpdateLawyerDiploma = async (payload: UpdateLawyerFormValues) => {
    await updateLawyerDiploma({
      variables: {
        data: {
          diploma: payload.file,
        },
      },
      refetchQueries: [{ query: MeDocument }],
    });
    toast({
      title: 'Diploma u ndryshua me sukses',
      status: 'success',
    });
    onClose();
  };

  const handleUpdateLawyerLicense = async (payload: UpdateLawyerFormValues) => {
    await updateLawyerLicense({
      variables: {
        data: {
          license: payload.file,
        },
      },
      refetchQueries: [{ query: MeDocument }],
    });
    toast({
      title: 'Licensa u ndryshua me sukses',
      status: 'success',
    });
    onClose();
  };

  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifiko të dhënat</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs colorScheme="red" index={tabIndex} onChange={setTabIndex}>
            <TabList>
              <Tab>Të dhëna të përgjithshme</Tab>
              <Tab>Fjalëkalimi</Tab>
              {data.me.role === AccountRole.Lawyer && <Tab>CV</Tab>}
              {data.me.role === AccountRole.Lawyer && <Tab>Diploma</Tab>}
              {data.me.role === AccountRole.Lawyer && <Tab>Licensa</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <EditGeneralInfoForm
                  onSubmit={handleUpdateGeneralInfo}
                  initialValues={editableGeneralInfo}
                />
              </TabPanel>
              <TabPanel>
                <ResetPasswordForm onSubmit={handleResetPassword} />
              </TabPanel>
              {data.me.role === AccountRole.Lawyer && (
                <TabPanel>
                  <UpdateLawyerForm
                    onSubmit={handleUpdateLawyerCv}
                    initialValues={{
                      fileUrl: (data.me.profile as LawyerProfile).cvUrl,
                    }}
                    fileKey="cv"
                  />
                </TabPanel>
              )}
              {data.me.role === AccountRole.Lawyer && (
                <TabPanel>
                  <UpdateLawyerForm
                    onSubmit={handleUpdateLawyerDiploma}
                    initialValues={{
                      fileUrl: (data.me.profile as LawyerProfile).diplomaUrl,
                    }}
                    fileKey="diploma"
                  />
                </TabPanel>
              )}
              {data.me.role === AccountRole.Lawyer && (
                <TabPanel>
                  <UpdateLawyerForm
                    onSubmit={handleUpdateLawyerLicense}
                    initialValues={{
                      fileUrl: (data.me.profile as LawyerProfile).licenseUrl,
                    }}
                    fileKey="license"
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
