import * as React from 'react';
import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import { Divider } from '@chakra-ui/react';
import { Field, Form } from 'react-final-form';
import {
  AdaptedSelect,
  InputControl,
  TextareaControl,
} from '../components/Form';
import {
  useCitiesByCountryLazyQuery,
  useCountriesQuery,
  useSpecialitiesQuery,
} from '../graphql';
import moment from 'moment';

export type CreateIssueFormValues = {
  title: string;
  description: string;
  deadline: Date;
  cityId: number;
  countryId: number;
  specialityId: number;
};

interface IGeneralInfoForm {
  onSubmit: (values: CreateIssueFormValues) => void;
  initialValues?: CreateIssueFormValues;
}

export const CreateIssueForm: React.FC<IGeneralInfoForm> = ({
  onSubmit,
  initialValues,
}) => {
  const { data: specialities, loading: specialititesLoading } =
    useSpecialitiesQuery();
  const { loading: loadingCountries, data: { countries = [] } = {} } =
    useCountriesQuery();
  const [
    loadCities,
    { data: { citiesByCountry = [] } = {}, loading: loadingCities },
  ] = useCitiesByCountryLazyQuery();

  const loadingGeo = loadingCountries || loadingCities;

  const [countryId, setCountryId] = React.useState();
  const [cityId, setCityId] = React.useState();

  React.useEffect(() => {
    if (countryId) {
      loadCities({
        variables: {
          countryId: parseInt(countryId),
        },
      });
    }
  }, [loadCities, countryId]);

  const validate = (values: CreateIssueFormValues) => {
    const errors: { [key: string]: string } = {};

    if (!values.title) {
      errors.title = 'Fushë e detyruar';
    }

    if (!values.description) {
      errors.description = 'Fushë e detyruar';
    }

    if (!values.countryId) {
      errors.countryId = 'Fushë e detyruar';
    }

    if (!values.cityId) {
      errors.cityId = 'Fushë e detyruar';
    }

    if (!values.specialityId) {
      errors.specialityId = 'Fushë e detyruar';
    }

    if (!values.deadline) {
      errors.deadline = 'Fushë e detyruar';
    }

    if (!moment(values.deadline).isAfter(moment().add(5, 'days'))) {
      errors.deadline =
        'Data e përfundimit duhet të jetë të paktën pas 5 ditësh';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" onSubmit={handleSubmit}>
          {/** Title */}
          <InputControl type="text" name="title" label="Titulli" />
          {/* Description */}
          <TextareaControl
            name="description"
            label="Përshkrimi"
            placeholder="Shkruani përshkrimin"
          />
          {/* Country */}
          <Field
            name="countryId"
            component={AdaptedSelect}
            label="Shteti"
            disabled={loadingGeo}
            onChange={(e: any) => setCountryId(e.target.value)}
            initialValue={countryId}
          >
            {countries.map((country) => (
              <option key={country!.id} value={country!.id}>
                {country!.name}
              </option>
            ))}
          </Field>
          {/* City */}
          <Field
            name="cityId"
            component={AdaptedSelect}
            label="Qyteti"
            disabled={loadingGeo}
            initialValue={cityId}
          >
            {(citiesByCountry || []).map((city) => (
              <option
                key={city!.id}
                onClick={(e: any) => setCityId(e.target.value)}
                value={city!.id}
              >
                {city!.name}
              </option>
            ))}
          </Field>
          {/* Speciality */}
          <Field
            name="specialityId"
            component={AdaptedSelect}
            label="Specialiteti"
            disabled={specialititesLoading}
          >
            {specialities?.specialities?.map((speciality) => (
              <option key={speciality!.id} value={speciality!.id}>
                {speciality!.name}
              </option>
            ))}
          </Field>
          {/* Deadline */}
          <InputControl type="date" name="deadline" label="Afati i punës" />

          <Divider my="3" />
          <Button
            float="right"
            isLoading={submitting}
            disabled={submitting}
            loadingText="Duke dërguar"
            colorScheme="red"
            type="submit"
          >
            Krijo
          </Button>
        </Box>
      )}
    />
  );
};
