import React from 'react';
import { Redirect } from 'react-router-dom';
import { AccountRole } from '../../graphql';
import { useAuthToken } from '../../hooks';

const HomePage: React.FC = () => {
  const { user } = useAuthToken();

  if (user?.role === AccountRole.Client) {
    return <Redirect to={`/documents`} />;
  } else if (user?.role === AccountRole.Admin) {
    return <Redirect to={`/users/clients`} />;
  } else if (user?.role === AccountRole.Lawyer) {
    return <Redirect to={`/issues/mine`} />;
  } else {
    return <div />;
  }
};

export default HomePage;
