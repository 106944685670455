import { Condition } from './types';

export const checkCondition = (
  condition: Condition,
  currentValues: any = {}
): boolean => {
  if (condition) {
    if (Array.isArray(condition)) {
      return condition.reduce((acc, c) => {
        return acc && evaluateConditionExpression(c, currentValues);
      }, true);
    } else {
      return evaluateConditionExpression(condition, currentValues);
    }
  }

  return true;
};

function evaluateConditionExpression(
  condition: Condition,
  variables: any
): boolean {
  const variable = variables[condition.if];

  if ('is' in condition) {
    const cmp = condition.is;
    // eslint-disable-next-line eqeqeq
    return variable == cmp;
  } else if ('isnt' in condition) {
    const cmp = condition.isnt;
    // eslint-disable-next-line eqeqeq
    return variable != cmp!;
  } else if ('gt' in condition) {
    const cmp = condition.gt;
    return variable > cmp!;
  } else if ('gte' in condition) {
    const cmp = condition.gte;
    return variable >= cmp!;
  } else if ('lt' in condition) {
    const cmp = condition.lt;
    return variable < cmp!;
  } else if ('lte' in condition) {
    const cmp = condition.lte;
    return variable <= cmp!;
  } else {
    return false;
  }
}
