import { Heading, HeadingProps } from '@chakra-ui/react';

export const SectionTitle = (props: HeadingProps) => {
  return (
    <Heading
      as="h2"
      fontSize="lg"
      px="5"
      py="2"
      my="5"
      borderBottom={'1px'}
      borderColor={'gray.100'}
    >
      {props.children}
    </Heading>
  );
};
