import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/react';
import {
  CreateIssueForm,
  CreateIssueFormValues,
} from '../forms/CreateIssueForm';
import { useCreateIssueMutation } from '../graphql';

type CreateIssueProps = {
  isOpen: boolean;
  onClose: () => void;
  refresh: () => Promise<void>;
};

export const CreateIssueModal = (props: CreateIssueProps) => {
  const toast = useToast();
  const [createIssue] = useCreateIssueMutation();

  const handleCreateIssue = async (payload: CreateIssueFormValues) => {
    try {
      await createIssue({
        variables: {
          issue: {
            title: payload.title,
            description: payload.description,
            countryId: Number(payload.countryId),
            cityId: Number(payload.cityId),
            deadline: payload.deadline,
            specialityId: Number(payload.specialityId),
          },
        },
      });
      await props.refresh();
      onClose();
      toast({
        title: 'Sukses',
        description: 'Çështja u krijua me sukses',
        status: 'success',
      });
    } catch (error) {
      console.error('err', error);
    }
  };

  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Hap një çështje</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateIssueForm onSubmit={handleCreateIssue} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
