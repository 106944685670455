import { Box, useColorModeValue } from '@chakra-ui/react';
import {
  LanguageCode,
  useRegisterLawyerMutation,
} from '../../graphql';
import { Heading, Text } from '@chakra-ui/layout';
import { useHistory } from 'react-router';
import { useAuthToken } from '../../hooks';
import {
  ILawyerSignupFormValues,
  LawyerSignupForm,
} from '../../forms/LawyerSignup';
import { Link } from '../../components/Link';
import { Logo } from '../../components/Logo';

export const RegisterLawyerPage: React.FC = () => {
  const history = useHistory();
  const { setAuthToken } = useAuthToken();
  const [lawyerSignup] = useRegisterLawyerMutation();

  const handleSignUp = async (user: ILawyerSignupFormValues) => {
    try {
      let response = await handleLawyerSignUp(user);
      if (response.data?.registerLawyer.token) {
        setAuthToken(response.data.registerLawyer.token);
        history.push('/activate-account');
      }
    } catch (error) {
      console.error('signup error', error);
    }
  };

  const handleLawyerSignUp = async (lawyer: ILawyerSignupFormValues) => {
    const payload: ILawyerSignupFormValues = { ...lawyer };
    delete (payload as any).repeatPassword;
    return lawyerSignup({
      variables: {
        data: {
          ...payload,
          cityId: Number(payload.cityId),
          countryId: Number(payload.countryId),
          languageCode: LanguageCode.Sq,
        },
      },
    });
  };

  return (
    <>
      <Box
        bg={useColorModeValue('gray.50', 'inherit')}
        minH="100vh"
        py="12"
        px={{ base: '4', lg: '8' }}
      >
        <Box maxW="2xl" mx="auto">
          <Logo mx="auto" h="12" mb={{ base: '10', md: '20' }} />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Regjistrimi i avokatit
          </Heading>
          <Box
            mt="4"
            mb="8"
            align="center"
            maxW="2xl"
            fontWeight="medium"
            textAlign="center"
          >
            <Text as="span">Keni një llogari në Legit?</Text>
            <Link href="/login">Identifikohu</Link>
          </Box>
          <Box
            background="white"
            px={{ base: '4', md: '10' }}
            maxWidth="2xl"
            mx="auto"
            my="5"
          >
            <LawyerSignupForm onSubmit={handleSignUp as any} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
