import { Box } from '@chakra-ui/react';
import { InputControl } from '../../../../components/Form';

export const ShortAddressInputControl = ({ name }: { name: string }) => {
  return (
    <Box>
      <InputControl name={`${name}.country`} label="Shteti" />
      <InputControl name={`${name}.city`} label="Qyteti" />
    </Box>
  );
};
