import React from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
  ClientProfile,
  IssueStatus,
  useGetAdminIssueByIdQuery,
} from '../../../graphql';
import { Loader } from '../../../components/Loader';
import { IColumn, SimpleTable } from '../../../utils/table';
import { CardHeader } from '../../../components/Card';
import { CardContent } from '../../../components/Card/CardContent';
import { Property } from '../../../components/Property';
import { SectionTitle } from '../../../typography';
import { getStatusLabel } from '../../../utils/issue-status';
import moment from 'moment';

const statusChangesColumns: IColumn[] = [
  {
    Header: 'Data',
    accessor: '',
    tdParams: {
      width: '25%',
    },
    Cell: function DateCell(cell: any, row: any) {
      return <Text>{moment(row.createdAt).format('DD/MM/YYYY')}</Text>;
    },
  },
  {
    Header: 'Statusi',
    accessor: '',
    tdParams: {
      width: '25%',
    },
    Cell: function StatusCell(cell: any, row: any) {
      return <Text>{getStatusLabel(row.status)}</Text>;
    },
  },
  {
    Header: 'Kryer nga',
    accessor: 'changedBy.email',
    tdParams: {
      width: '25%',
    },
  },
  {
    Header: 'Arsyeja',
    accessor: 'reason',
    tdParams: {
      width: '25%',
    },
  },
];

const bidsColumns: IColumn[] = [
  {
    Header: 'Titulli',
    accessor: 'title',
    tdParams: {
      width: '20%',
    },
  },
  {
    Header: 'Përshkrimi',
    accessor: 'description',
    tdParams: {
      width: '20%',
    },
  },
  {
    Header: 'Vlera',
    accessor: '',
    tdParams: {
      width: '20%',
    },
    Cell: function amountCell(cell: any, row: any) {
      return <Text>{row.amount.toLocaleString()} EUR</Text>;
    },
  },
  {
    Header: 'Data',
    accessor: 'createdAt',
    tdParams: {
      width: '15%',
    },
  },
  {
    Header: 'Emri',
    accessor: 'bidder.profile.name',
    tdParams: {
      width: '10%',
    },
  },
  {
    Header: 'Mbiemri',
    accessor: 'bidder.profile.lastname',
    tdParams: {
      width: '15%',
    },
  },
];

export const AdminIssueDetailsPage: React.FC = () => {
  const { id } = useParams<{ id?: string | undefined }>();
  const { data, loading } = useGetAdminIssueByIdQuery({
    variables: { getAdminIssueByIdId: Number(id) },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Loader />;
  }
  return (
    <Box>
      <CardHeader title="Detajet e çështjes"></CardHeader>
      <CardContent marginBottom="5">
        <Property
          label="Krijuesi"
          value={
            <Flex alignItems={'center'}>
              <Avatar
                size="sm"
                name={`${
                  (
                    data?.getAdminIssueById.issue.issuer
                      .profile as ClientProfile
                  ).name || ''
                } ${
                  (
                    data?.getAdminIssueById.issue.issuer
                      .profile as ClientProfile
                  ).lastname || ''
                }`}
                src={
                  (
                    data?.getAdminIssueById.issue.issuer
                      .profile as ClientProfile
                  ).profilePicture?.sm
                }
              />
              <Text mx="4">{`${
                (data?.getAdminIssueById.issue.issuer.profile as ClientProfile)
                  .name || ''
              } ${
                (data?.getAdminIssueById.issue.issuer.profile as ClientProfile)
                  .lastname || ''
              }`}</Text>
            </Flex>
          }
        />
        <Property
          label="Titulli"
          value={data?.getAdminIssueById.issue.title || ''}
        />
        <Property
          label="Përshkrimi"
          value={data?.getAdminIssueById.issue.description || ''}
        />
        <Property
          label="Statusi"
          value={getStatusLabel(
            (data?.getAdminIssueById.issue.status as IssueStatus) || ''
          )}
        />
        <Property
          label="Specialiteti"
          value={data?.getAdminIssueById.issue.speciality?.name || ''}
        />
        <Property
          label="Afati i punës"
          value={data?.getAdminIssueById.issue.deadline}
        />
      </CardContent>

      <SectionTitle>Lista e ofertave</SectionTitle>
      <SimpleTable
        columns={bidsColumns}
        data={data?.getAdminIssueById.issue.bids || []}
      />

      <SectionTitle>Ndryshimet e statusit</SectionTitle>
      <SimpleTable
        columns={statusChangesColumns}
        data={data?.getAdminIssueById.statusChanges || []}
      />
    </Box>
  );
};
