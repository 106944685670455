import { Box, Center, CenterProps, Menu } from '@chakra-ui/react';
import { FaBell } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useGetUnreadNotificationCountQuery } from '../../graphql';

const NotificationBadge = (props: CenterProps) => (
  <Center
    bg="blue.600"
    fontSize="xs"
    fontWeight="bold"
    position="absolute"
    rounded="full"
    textAlign="center"
    top="-2px"
    insetEnd="0"
    w="18px"
    h="18px"
    {...props}
  />
);

export const NotificationDropdown = () => {
  const history = useHistory();
  const { data } = useGetUnreadNotificationCountQuery();

  return (
    <Menu>
      <Center
        as="button"
        outline="0"
        w="8"
        h="8"
        position="relative"
        rounded="md"
        _hover={{ bg: 'whiteAlpha.200' }}
        _focus={{ shadow: 'outline' }}
        flexShrink={0}
        onClick={() => history.push('/notifications')}
      >
        {Boolean(data?.getUnreadNotificationCount) && (
          <NotificationBadge>
            {data!.getUnreadNotificationCount}
          </NotificationBadge>
        )}
        <Box as={FaBell} fontSize="lg" />
      </Center>
    </Menu>
  );
};
