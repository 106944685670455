import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  StackDivider,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { BsGear } from 'react-icons/bs';
import { FaDownload, FaEdit, FaInfoCircle, FaTrash } from 'react-icons/fa';
import { MdTitle } from 'react-icons/md';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { CardHeader } from '../../components/Card';
import { Loader } from '../../components/Loader';
import {
  DocumentStatus,
  useDeleteDocumentMutation,
  useGetDocumentByIdQuery,
} from '../../graphql';
import { ChangeDocumentTitleModal } from '../../modals/ChangeDocumentTitle';

interface DocumentPageWrapperParams {
  children: React.ReactNode;
}

export const DocumentPageWrapper = ({
  children,
}: DocumentPageWrapperParams) => {
  // @ts-ignore
  const { id } = useParams();
  const { data: documentData, loading: documentLoading } =
    useGetDocumentByIdQuery({
      variables: {
        getDocumentByIdId: Number(id),
      },
      fetchPolicy: 'cache-and-network',
    });

  const [deleteDoc] = useDeleteDocumentMutation({
    variables: { deleteDocumentId: Number(id) },
  });
  const history = useHistory();
  const [deleting, setDeleting] = useState(false);
  const toast = useToast();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const cancelRef = useRef<any>();
  const {
    isOpen: isChangeTitleModalOpen,
    onClose: onChangeTitleModalClose,
    onOpen: onChangeTitleModalOpen,
  } = useDisclosure();

  const deleteDocument = async () => {
    try {
      setDeleting(true);
      await deleteDoc();
      history.push('/documents');
      toast({
        title: 'Dokumenti u fshi',
        description: 'Dokumenti u fshi me sukses',
        status: 'success',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
    }
  };

  if (documentLoading || !documentData) {
    return <Loader />;
  }

  return (
    <>
      <ChangeDocumentTitleModal
        isOpen={isChangeTitleModalOpen}
        onClose={onChangeTitleModalClose}
        id={id}
        title={documentData.getDocumentById.title}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onDeleteAlertClose}
        isOpen={isDeleteAlertOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Fshi Dokumentin?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            A jeni të sigurt që dëshironi të fshini këtë dokument?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onDeleteAlertClose}>
              Anullo
            </Button>
            <Button
              isLoading={deleting}
              onClick={deleteDocument}
              colorScheme="red"
              ml={3}
            >
              Aprovo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <CardHeader
        title={documentData.getDocumentById.title}
        action={null}
      ></CardHeader>
      <HStack>
        <HStack divider={<StackDivider />}>
          <NavLink
            exact={true}
            to="/documents"
            style={{ color: 'gray.600' }}
            activeStyle={{ color: 'red' }}
          >
            <Button leftIcon={<AiOutlineLeft />} variant="ghost">
              Lista e dokumenteve
            </Button>
          </NavLink>
          <NavLink
            exact={true}
            activeClassName="active-link"
            to={`/document/${documentData.getDocumentById.id}/edit`}
            style={{ color: 'gray.600' }}
            // activeStyle={{ color: 'red' }}
          >
            <Button leftIcon={<FaEdit />} variant="ghost">
              Redakto
            </Button>
          </NavLink>
          <NavLink
            exact={true}
            activeClassName="active-link"
            to={`/document/${documentData.getDocumentById.id}/metadata`}
            style={{ color: 'gray.600' }}
            // activeStyle={{ color: 'red' }}
          >
            <Button leftIcon={<FaInfoCircle />} variant="ghost">
              Informacion
            </Button>
          </NavLink>
          <NavLink
            exact={true}
            activeClassName="active-link"
            to={`/document/${documentData.getDocumentById.id}/access`}
            style={{
              color: 'gray.600',
              pointerEvents:
                documentData.getDocumentById.status ===
                DocumentStatus.InProgress
                  ? 'none'
                  : 'auto',
            }}
            // activeStyle={{ color: 'red' }}
          >
            <Button
              disabled={
                documentData.getDocumentById.status ===
                DocumentStatus.InProgress
              }
              leftIcon={<FaDownload />}
              variant="ghost"
            >
              Shkarko
            </Button>
          </NavLink>
          {/* <NavLink
            exact={true}
            to={``}
            style={{ color: 'gray.600' }}
            activeStyle={{ color: 'red' }}
          > */}
          {/* <Button
            leftIcon={<FaDownload />}
            onClick={() =>
              window.open(documentData.getDocumentById.previewUrl, '_blank')
            }
            variant="ghost"
          >
            Shkarko
          </Button> */}
          {/* </NavLink> */}
        </HStack>
        <Spacer />
        {/* <Button
          leftIcon={<FaTrash />}
          disabled={deleting}
          onClick={onDeleteAlertOpen}
          variant="ghost"
        >
          Fshi dokumentin
        </Button> */}

        <Menu>
          <MenuButton>
            <Button
              leftIcon={<BsGear />}
              variant="ghost"
              style={{ color: 'gray.600' }}
            >
              Veprime
            </Button>
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<MdTitle />}
              onClick={onChangeTitleModalOpen}
              // isDisabled={!canClose || submitting}
              // onClick={onOpen}
            >
              Ndrysho titullin
            </MenuItem>
            <MenuItem
              icon={<FaTrash />}
              disabled={deleting}
              onClick={onDeleteAlertOpen}
            >
              Fshi dokumentin
            </MenuItem>
          </MenuList>
        </Menu>

        {/* <EditDocument id={Number(id)} /> */}
      </HStack>

      <Box mt={6}>{children}</Box>
    </>
  );
};
