import {
  Box,
  Flex,
  LightMode,
  ListItem,
  Text,
  UnorderedList,
  BoxProps,
} from '@chakra-ui/react';
import * as React from 'react';
import { LinkifiableText } from '../../pages/Documents';

const BillingBadge = (props: BoxProps) => (
  <Box
    rounded="full"
    fontSize="sm"
    bg="white"
    color="gray.900"
    px="3"
    py="1"
    pos="absolute"
    top="4"
    right="4"
    fontWeight="bold"
    {...props}
  />
);

interface PricingCardProps extends BoxProps {
  button: React.ReactNode;
  data: {
    name: string;
    description: string;
    price: number;
    amountSaved?: number;
    duration: string;
    benefits?: string[];
  };
}

export const PricingCard = (props: PricingCardProps) => {
  const { button, data, ...rest } = props;
  const { name, description, price, duration, benefits = [] } = data;
  const isFree = price === 0 || duration === 'none';
  return (
    <Flex
      direction="column"
      px="6"
      py="8"
      rounded="lg"
      pos="relative"
      maxW="2xl"
      mx="auto"
      {...rest}
    >
      <Box flex="1">
        {!isFree && (
          <BillingBadge>
            {duration === 'monthly' ? 'Pagesë mujore' : `Pagesë vjetore`}
          </BillingBadge>
        )}
        <Text fontSize="2xl" lineHeight="1" fontWeight="bold">
          {name}
        </Text>
        <Flex align="center" fontWeight="extrabold" mt="4" mb="3">
          <Text
            fontSize={{
              base: '6xl',
              xl: '7xl',
            }}
            lineHeight="1"
            flexShrink={0}
          >
            {price} €
            {/* {price} <Text display={"inline"} fontSize={"3xl"}>€</Text> */}
          </Text>
          {!isFree && (
            <>
              <Box mx="4">
                <Box
                  w="2px"
                  h="12"
                  bg="white"
                  opacity={0.5}
                  transform="rotate(15deg)"
                />
              </Box>
              <Text fontSize="xl" lineHeight="1.2">
                në <br /> {duration === 'monthly' ? 'muaj' : 'vit'}
              </Text>
            </>
          )}
        </Flex>
        {/* {!isFree && <Box>{isFree ? <>&nbsp;</> : `249 € në vit`}</Box>} */}
        <Box mt="6">
          <Text
            fontSize="md"
            fontWeight="semibold"
            mb={`${benefits.length ? '6' : '0'}`}
          >
            <LinkifiableText text={description} />
          </Text>
          <UnorderedList spacing="3">
            {benefits.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Box>
      <Box mt="10">
        <LightMode>{button}</LightMode>
      </Box>
    </Flex>
  );
};
