import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CardContent } from '../../../components/Card/CardContent';
import { CardHeader } from '../../../components/Card/CardHeader';
import { Link } from '../../../components/Link';
import { Loader } from '../../../components/Loader';
import { Property } from '../../../components/Property';
import {
  GetInvoiceByIdDocument,
  InvoiceDetails,
  InvoiceItemType,
  Issue,
  PaymentType,
  useGetInvoiceByIdQuery,
  usePayInvoiceMutation,
  UserSubscription,
} from '../../../graphql';
import { getPaymentTypeLabel } from '../../../utils/payment-utils';
import { IColumn, SimpleTable } from '../../../utils/table';

const invoiceDetailsColumns: IColumn[] = [
  {
    Header: 'Shuma',
    accessor: '',
    tdParams: {
      width: '33%',
    },
    Cell: function priceCell(cell: any, row: InvoiceDetails) {
      return <Text>{row.price.toLocaleString()} EUR</Text>;
    },
  },
  {
    Header: 'Titulli/Emri',
    accessor: '',
    Cell: function TitleCell(cell: any, row: InvoiceDetails) {
      if (row.type === InvoiceItemType.Subscription) {
        return <Text>{(row.item as UserSubscription).subscription.name}</Text>;
      } else {
        return <Text>{(row.item as Issue | Document).title}</Text>;
      }
    },
    tdParams: {
      width: '33%',
    },
  },
  {
    Header: 'Detaje',
    accessor: '',
    Cell: function DetailsCell(cell: any, row: InvoiceDetails) {
      if (row.type === InvoiceItemType.Subscription) {
        return <Text>-</Text>;
      } else if (row.type === InvoiceItemType.Document) {
        return <Text>-</Text>;
      } else if (row.type === InvoiceItemType.Issue) {
        return (
          <Link href={`/admin/issue/${row.item.id}`}>Detajet e çështjes</Link>
        );
      } else {
        return <Box></Box>;
      }
    },
    tdParams: {
      width: '33%',
    },
  },
];

export const AdminInvoiceDetailsPage = () => {
  const { id } = useParams<{ id?: string | undefined }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, loading } = useGetInvoiceByIdQuery({
    variables: { getInvoiceByIdId: Number(id) },
    fetchPolicy: 'cache-and-network',
  });
  const [payInvoiceManually] = usePayInvoiceMutation();
  const [submitting, setSubmitting] = useState(false);
  const [paymentType, setPaymentType] = useState(PaymentType.Bank);
  const toast = useToast();

  const handlePayInvoiceManually = async () => {
    try {
      setSubmitting(true);
      await payInvoiceManually({
        variables: {
          payInvoiceId: Number(id),
          type: paymentType,
        },
        refetchQueries: [
          {
            query: GetInvoiceByIdDocument,
            variables: {
              getInvoiceByIdId: Number(id),
            },
          },
        ],
      });
      onClose();
      toast({
        title: 'Sukses',
        description: 'Fatura u pagua me sukses',
        status: 'success',
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Modal size="lg" isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Paguaj faturën</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup
              value={paymentType}
              onChange={(e) => setPaymentType(e as PaymentType)}
            >
              <Box>
                <Radio value={PaymentType.Bank}>Bankë</Radio>
              </Box>
              <Box>
                <Radio my={3} value={PaymentType.Paysera}>
                  Paysera
                </Radio>
              </Box>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={submitting}
              disabled={submitting}
              onClick={handlePayInvoiceManually}
            >
              Paguaj
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        <CardHeader
          title="Detajet e faturës"
          action={
            <Box>
              {!Boolean(data?.getInvoiceById.payments.length) && (
                <Button onClick={onOpen}>Paguaj</Button>
              )}
            </Box>
          }
        ></CardHeader>
        <CardContent marginBottom="5">
          <Property
            label="Totali"
            value={
              <Text fontWeight={'bold'}>
                {`${String(data?.getInvoiceById.total.toLocaleString())} EUR` ||
                  ''}
              </Text>
            }
          />
          <Property
            label="Data"
            value={
              moment(data?.getInvoiceById.createdAt).format('DD/MM/YYYY') || ''
            }
          />
        </CardContent>

        <CardHeader title="Artikujt e faturës"></CardHeader>
        <SimpleTable
          columns={invoiceDetailsColumns}
          data={data?.getInvoiceById.invoiceDetails || []}
        />

        {Boolean(data?.getInvoiceById.payments.length) ? (
          <Box>
            <CardHeader title="Pagesa"></CardHeader>
            <CardContent marginBottom="5">
              <Property
                label="Tipi i pagesës"
                value={
                  getPaymentTypeLabel(data?.getInvoiceById.payments[0].type!) ||
                  ''
                }
              />
              <Property
                label="Data e pagesës"
                value={
                  moment(data?.getInvoiceById.payments[0]?.createdAt).format(
                    'DD/MM/YYYY HH:mm:ss'
                  ) || ''
                }
              />
            </CardContent>
          </Box>
        ) : null}

        <Box>
          <CardHeader title="Llogaria"></CardHeader>
          <CardContent marginBottom="5">
            <Property
              label="Emri"
              value={
                String((data?.getInvoiceById.account.profile as any).name) || ''
              }
            />
            <Property
              label="Mbiemri"
              value={
                String(
                  (data?.getInvoiceById.account.profile as any).lastname
                ) || ''
              }
            />
            <Property
              label="Email"
              value={data?.getInvoiceById.account.email || ''}
            />
            <Property
              label="Detajet e plota"
              value={
                <Link href={`/account/${data?.getInvoiceById.account.id}`}>
                  Shiko detajet
                </Link>
              }
            />
          </CardContent>
        </Box>
      </Box>
    </Box>
  );
};
