import * as React from 'react';
import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { chakra, HTMLChakraProps } from '@chakra-ui/system';
import { useRequestPasswordResetMutation } from '../../graphql';
import { useResetPassword } from '../../hooks';
import { useHistory } from 'react-router';

export const RequestPasswordResetForm = (props: HTMLChakraProps<'form'>) => {
  const history = useHistory();
  const { setEmail: setResetPasswordEmailInStorage } = useResetPassword();
  const [email, setEmail] = React.useState('');
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const [requestPasswordReset] = useRequestPasswordResetMutation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setSubmitLoading(true);
      await requestPasswordReset({ variables: { data: { email } } });
      setResetPasswordEmailInStorage(email);
      history.push('/reset-password');
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <chakra.form onSubmit={handleSubmit}>
      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          placeholder=""
          onChange={(event) => setEmail(event.currentTarget.value)}
        />
      </FormControl>
      <Button
        type="submit"
        colorScheme="red"
        size="lg"
        fontSize="md"
        width="full"
        isLoading={submitLoading}
        mt={4}
      >
        Vazhdo më tej
      </Button>
    </chakra.form>
  );
};
