import { Box, Flex, HStack } from '@chakra-ui/react';
import * as React from 'react';
import { HeaderOptions } from '../../layouts/Dashboard';
import { NavItem } from './NavItem';

const MobileNavMenu = (props: {
  isOpen?: boolean;
  setCurrentRoute: React.Dispatch<React.SetStateAction<HeaderOptions | null>>;
  headerOptions: HeaderOptions[];
}) => {
  const { isOpen } = props;
  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      bg="red.600"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      insetX="0"
      zIndex={10}
      w="full"
    >
      <Box px="4">
        {props.headerOptions.map((option) => (
          <NavItem.Mobile
            key={option.label}
            option={option}
            setCurrentRoute={props.setCurrentRoute}
          />
        ))}
      </Box>
    </Flex>
  );
};

const DesktopNavMenu = (props: {
  setCurrentRoute: React.Dispatch<React.SetStateAction<HeaderOptions | null>>;
  headerOptions: HeaderOptions[];
}) => (
  <HStack spacing="3" flex="1" display={{ base: 'none', lg: 'flex' }}>
    {props.headerOptions.map((option) => (
      <NavItem.Desktop
        key={option.label}
        option={option}
        setCurrentRoute={props.setCurrentRoute}
      />
    ))}
  </HStack>
);

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};
