import { PaymentType } from '../graphql';

export function getPaymentTypeLabel(type: PaymentType): string {
  switch (type) {
    case PaymentType.Bank:
      return 'Bankë';
    case PaymentType.Paysera:
      return 'Kartë';
    default:
      return '';
  }
}
