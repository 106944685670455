import { DocumentStatus } from '../../../graphql';

export const getDocumentStatusName = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.InProgress:
      return 'Në progres';
    case DocumentStatus.WaitingPayment:
      return 'Në pritje të pagesës';
    case DocumentStatus.Finished:
      return 'Përfunduar';
    default:
      return '';
  }
};

export const getDocumentStatusColorCode = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.InProgress:
      return 'gray.500';
    case DocumentStatus.WaitingPayment:
      return 'yellow.500';
    case DocumentStatus.Finished:
      return 'green.500';
    default:
      return '';
  }
};

export const getOutlineColor = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.InProgress:
      return '#C2C2C2';
    case DocumentStatus.WaitingPayment:
      // return a yellow hex color
      return '#FFC107';
    case DocumentStatus.Finished:
      // return a green/success hex color
      return '#4CAF50';
    default:
      return '';
  }
};
