import { Box, Center, Heading, Icon, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface Props {
  title: string;
  description: string;
  icon: IconType;
}

export function NoticeNoResults({ title, description, icon }: Props) {
  return (
    <Center>
      <Box textAlign="center" py={10} px={6} width={'70%'}>
        <Icon as={icon} boxSize={'50px'} color={'gray.500'} />
        <Heading as="h2" size="xl" mt={6} mb={2}>
          {title}
        </Heading>
        <Text color={'gray.500'}>{description}</Text>
      </Box>
    </Center>
  );
}
