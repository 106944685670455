import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Loader } from '../../components/Loader';
import { Property } from '../../components/Property';
import { useGetBankCredentialsQuery } from '../../graphql';

interface Props {
  invoiceNumber?: number;
  children?: ReactNode;
}

export const BankDetailsPage: React.FC<Props> = (props: Props) => {
  const { data, loading } = useGetBankCredentialsQuery();

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Property label="Titulli" value={`Fatura #${props.invoiceNumber}`} />
      <Property
        label="Subjekti tregtar"
        value={data?.getBankCredentials?.business_entity || ''}
      />
      <Property
        label="Adresa"
        value={data?.getBankCredentials?.address || ''}
      />
      <Property
        label="Telefon"
        value={data?.getBankCredentials?.telephone || ''}
      />
      <Property label="Banka" value={data?.getBankCredentials?.bank || ''} />
      <Property label="IBAN" value={data?.getBankCredentials?.iban || ''} />
    </Box>
  );
};
