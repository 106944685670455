import * as React from 'react';
import { useHistory } from 'react-router';
import { useResetPassword } from '../../hooks';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { Heading } from '@chakra-ui/layout';
import { ResetPasswordForm } from './ResetPasswordForm';
import { Card } from '../../components/Card';
import { Logo } from '../../components/Logo';

const ResetPasswordPage: React.FC = () => {
  const history = useHistory();
  const { emailEncrypted } = useResetPassword();
  if (!emailEncrypted) {
    history.replace('/reset-password-request');
  }

  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo mx="auto" h="12" mb={{ base: '10', md: '20' }} />
        <Heading textAlign="center" size="xl" mb="8" fontWeight="extrabold">
          Rivendos fjalëkalimin
        </Heading>

        <Card>
          <ResetPasswordForm />
        </Card>
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;
