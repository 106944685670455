import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  body {
    height: 100%;
    background: #f1f1f1;
    color: #333333;

  }
`
