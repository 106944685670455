import { Badge } from '@chakra-ui/layout';
import { AccountStatus } from '../../../../graphql';

const getColorScheme = (status: AccountStatus) => {
  switch (status) {
    case AccountStatus.Active:
      return 'green';
    case AccountStatus.Unconfirmed:
      return 'blue';
    case AccountStatus.PendingAdminVerification:
      return 'yellow';
    case AccountStatus.Blocked:
      return 'red';
    default:
      return 'gray';
  }
};

export const UserAccountStatus = ({ status }: { status: AccountStatus }) => {
  return (
    <Badge fontSize="xs" colorScheme={getColorScheme(status)}>
      {status}
    </Badge>
  );
};
