import React from 'react';
import Routes from './pages';

const App = React.memo(() => {
  return (
    <>
      <Routes />
    </>
  );
});

export default App;
