import {
  Box,
  Divider,
  Flex,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as mode,
  HStack,
  Avatar,
  Tag,
  Center,
} from '@chakra-ui/react';
import { Link } from '../Link';
import { Bid, ClientIssue, LawyerProfile } from '../../graphql';
import { Description } from '../Description';
import moment from 'moment';
import { getColorStatus, getStatusLabel } from '../../utils/issue-status';
interface BidItemProps {
  bid: Bid;
}

function BidItem(props: BidItemProps) {
  const { bid } = props;

  return (
    <Box p={5}>
      <Flex justify="space-between">
        <HStack spacing="16px">
          <Box>
            <Avatar
              name={`${bid.bidder?.profile?.name} ${bid.bidder?.profile?.name}`}
              size="sm"
              src={(bid.bidder?.profile as LawyerProfile)?.profilePicture?.sm}
            />
          </Box>
          <Box>
            <Box fontWeight="bold" maxW="xl">
              {`${bid.bidder?.profile?.name} ${bid.bidder?.profile?.lastname}`}
            </Box>
            <Box fontSize="xs">Avokat</Box>
          </Box>
        </HStack>
        <Box>
          <Stack direction={'row'} align={'center'}>
            <Text fontWeight={600}>
              <Text fontSize={'10'}>Oferta financiare</Text>{' '}
              {bid.amount.toLocaleString()} EUR
            </Text>
          </Stack>
        </Box>
      </Flex>
      <Text mt={4}>{bid.description}</Text>
    </Box>
  );
}

type IssueItemProps = {
  issue: ClientIssue;
};

export const IssueItemWithBids = (props: IssueItemProps) => {
  const { issue } = props;

  return (
    <Box>
      <Box
        rounded={{
          lg: 'lg',
        }}
        borderLeft={`10px solid ${getColorStatus(props.issue.status)}`}
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        bg={mode('white', 'gray.700')}
        shadow="base"
      >
        <Link href={`/find-lawyer/issue/${issue.id}`} display={'block'}>
          <Box color={'gray.600'}>
            <Flex align="center" justify="space-between" px="6" py="4">
              <Box display={'flex'} fontWeight="bold" maxW="xl">
                <Link href={`/find-lawyer/issue/${issue.id}`}>
                  <Text
                    noOfLines={1}
                    fontSize={'16'}
                    style={{ textDecoration: 'underline', color: 'black' }}
                  >
                    {issue.title}
                  </Text>
                </Link>
              </Box>
              <Center>
                <Tag
                  marginLeft="4"
                  size="md"
                  mr="5"
                  color="white"
                  backgroundColor={getColorStatus(props.issue.status)}
                >
                  {getStatusLabel(issue.status)}
                </Tag>
                <Tag size="md" mr={5} colorScheme="red">
                  {issue.speciality?.name}
                </Tag>
              </Center>
            </Flex>
            <Divider />
            <Stack spacing="6" py="5" px="8" divider={<StackDivider />}>
              <Description
                location={`${issue.city?.name}, ${issue.country?.name}`}
                deadline={moment(new Date(issue.createdAt)).format(
                  'DD/MM/YYYY'
                )}
              >
                {issue.description}
              </Description>
            </Stack>

            {issue.totalBids > 0 && (
              <Stack
                spacing={0}
                divider={<StackDivider variant="dashed" />}
                ml={10}
                fontSize={'90%'}
              >
                <Box fontWeight="bold" maxW="xl" mb={3}>
                  Oferta
                </Box>

                <Stack divider={<Divider />}>
                  {issue.bids.slice(0, 2).map((bid) => (
                    <BidItem key={bid.id} bid={bid} />
                  ))}
                </Stack>

                {issue.totalBids > 2 && (
                  <Box
                    p={3}
                    color="gray.700"
                    bg="gray.50"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    <Center>+{issue.totalBids - 2} oferta të tjera</Center>
                  </Box>
                )}
              </Stack>
            )}
          </Box>
        </Link>
      </Box>
    </Box>
  );
};
