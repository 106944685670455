import { Box, HStack } from '@chakra-ui/layout';
import { HiClock, HiLocationMarker } from 'react-icons/hi';
import { useColorModeValue as mode, Text } from '@chakra-ui/react';

interface DescriptionProps {
  title?: string;
  children?: React.ReactNode;
  location: string;
  deadline: string;
}

export const Description = (props: DescriptionProps) => {
  const { title, children, location, deadline } = props;
  return (
    <Box position="relative">
      {title ? (
        <Box fontWeight="bold" maxW="xl" mb={4}>
          {title}
        </Box>
      ) : null}

      <HStack
        fontSize="sm"
        fontWeight="medium"
        color={mode('gray.500', 'white')}
        mt="1"
      >
        <Box as={HiLocationMarker} fontSize="md" color="gray.400" />
        <span>{location}</span>
        <Box as={HiClock} fontSize="md" color="gray.400" />
        <span>{deadline}</span>
      </HStack>
      <Box mt="3" color={mode('gray.900', 'gray.200')}>
        <Text noOfLines={3}>{children}</Text>
      </Box>
    </Box>
  );
};
