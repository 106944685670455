import { useCallback, useEffect, useMemo } from 'react';
import { GetIssuesDocument, useGetIssuesLazyQuery } from '../graphql';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { debounce } from 'lodash';

const PER_PAGE = 10;
const INITIAL_PAGE = 1;

function useClientIssues() {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [search, setSearchInQuery] = useQueryParam('search', StringParam);

  const variables = useMemo(
    () => ({
      filter: {
        // startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
        // endDate: endDate ? format(addHours(endDate, 1), 'yyyy-MM-dd') : null,
        startDate: null,
        endDate: null,
        page: page || INITIAL_PAGE,
        pageLimit: PER_PAGE,
        search: search || null,
      },
    }),
    [page, search]
  );

  const [request, { data, loading, client, called, refetch: refetchQuery }] =
    useGetIssuesLazyQuery({
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    if (!page) {
      setPage(INITIAL_PAGE);
    }
    request({ variables });
  }, []);

  const debouncedRequest = useCallback(debounce(request, 500), []);
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const isActuallyLoading = useMemo(() => {
    return (
      loading && !client.readQuery({ query: GetIssuesDocument, variables })
    );
  }, [loading, variables, client]);

  const showEmptyResults = useMemo(() => !loading && called, [loading, called]);

  const setSearch = async (search: string) => {
    const vars = { ...variables };
    vars.filter.search = search || null;
    vars.filter.page = INITIAL_PAGE;

    await debouncedRequest({ variables: vars });
    if (!search) {
      setSearchInQuery(undefined);
    } else {
      setSearchInQuery(search);
    }
    setPage(INITIAL_PAGE);
  };

  const getPreviousPage = async () => {
    if (data?.getIssues?.pageInfo?.hasPreviousPage) {
      const page = data?.getIssues?.pageInfo?.previousPage || INITIAL_PAGE;
      const vars = { ...variables };
      vars.filter.page = page;
      scrollToTop();
      await refetchQuery({ filter: vars.filter });
      setPage(page);
    }
  };

  const getNextPage = async () => {
    if (data?.getIssues?.pageInfo?.hasNextPage) {
      const page = data?.getIssues?.pageInfo?.nextPage || INITIAL_PAGE;
      const vars = { ...variables };
      vars.filter.page = page;
      scrollToTop();
      await refetchQuery({ filter: vars.filter });
      setPage(page);
    }
  };

  const refetch = async () => {
    const vars = { ...variables };
    vars.filter.page = INITIAL_PAGE;
    vars.filter.search = null;
    await refetchQuery({ filter: vars.filter });
    setPage(INITIAL_PAGE);
    setSearchInQuery(undefined);
  };

  return {
    totalCount: data?.getIssues?.totalCount || 0,
    items: data?.getIssues?.nodes || [],
    pageInfo: data?.getIssues?.pageInfo,
    loading: isActuallyLoading,
    showEmptyResults,
    getNextPage,
    getPreviousPage,
    refetch,
    search,
    setSearch,
  };
}

export { useClientIssues };
