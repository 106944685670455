import { Flex } from '@chakra-ui/react';
import { Center, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';

export const Loader = () => {
  return (
    <Center padding="20">
      <Flex flexDirection="column" alignItems="center">
        <Spinner size="xl"></Spinner>
        <Text marginY="7">Duke ngarkuar</Text>
      </Flex>
    </Center>
  );
};
