import * as React from 'react';
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Heading,
  Avatar,
  AvatarBadge,
  AvatarProps,
  Icon,
  useColorModeValue,
  Stack,
  Text,
  HStack,
} from '@chakra-ui/react';
import { GoGlobe, GoVerified } from 'react-icons/go';
import {
  ClientProfile,
  GetChatInfoQuery,
  LawyerProfile,
  Profile,
} from '../../graphql';
import { BsPhone } from 'react-icons/bs';

const Card = (props: BoxProps) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    // maxWidth="2xl"
    mx="auto"
    p={{ base: '3', md: '4' }}
    // rounded={{ sm: 'lg' }}
    // border={"1px"}
    // shadow={{ md: 'base' }}
    {...props}
  />
);

const CardContent = (props: BoxProps) => <Box width="full" {...props} />;

interface CardHeaderProps extends FlexProps {
  title: string;
  action?: React.ReactNode;
}

const CardHeader = (props: CardHeaderProps) => {
  const { title, action, ...flexProps } = props;
  return (
    <Flex justifyContent="space-between" alignItems="center" {...flexProps}>
      <Heading
        size="sm"
        fontWeight="extrabold"
        letterSpacing="tight"
        marginEnd="6"
      >
        {title}
      </Heading>
      {action}
    </Flex>
  );
};

interface UserAvatarProps extends AvatarProps {
  isVerified?: boolean;
}

const UserAvatar = (props: UserAvatarProps) => {
  const { isVerified, ...avatarProps } = props;
  const avatarColor = useColorModeValue('white', 'gray.700');
  const iconColor = useColorModeValue('blue.500', 'blue.200');

  return (
    <Avatar size="lg" {...avatarProps}>
      {isVerified && (
        <AvatarBadge
          borderWidth="3px"
          borderColor={avatarColor}
          insetEnd="1"
          bottom="1"
          bg={avatarColor}
        >
          <Icon as={GoVerified} fontSize="sm" color={iconColor} />
        </AvatarBadge>
      )}
    </Avatar>
  );
};

interface Props {
  data: GetChatInfoQuery;
}

export const UserCardWithTags = ({ data }: Props) => {
  const recipient = data.getChatInfo.recipient;
  const recipientFullName = `${(recipient.profile as Profile)?.name} ${
    (recipient.profile as Profile)?.lastname
  }`;
  return (
    <Card my={'10px'} borderRadius={10} bg={'gray.50'} p={5}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        // spacing={{ base: '4', md: '10' }}
      >
        <UserAvatar
          marginRight={'10px'}
          name={recipientFullName}
          src={(recipient.profile as Profile)?.profilePicture?.lg}
          // src="https://images.unsplash.com/photo-1506935077180-46af676a2f6d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80"
          isVerified
        />
        <CardContent>
          <CardHeader title={recipientFullName} />
          <Stack spacing="1" mt="2">
            <HStack fontSize="sm">
              <Icon as={GoGlobe} color="gray.500" />
              <Text>
                {`${
                  (recipient.profile as ClientProfile | LawyerProfile)?.city
                    .name
                }, ${
                  (recipient.profile as ClientProfile | LawyerProfile)?.country
                    .name
                }`}
              </Text>
            </HStack>
            <HStack fontSize="sm">
              <Icon as={BsPhone} color="gray.500" />
              <Text>
                {recipient.phoneNumber} - {recipient.email}
              </Text>
            </HStack>
          </Stack>

          {/* <Text fontWeight="semibold" mt="8" mb="2">
            Interests
          </Text>
          <Wrap shouldWrapChildren>
            <Tag>Productivity</Tag>
            <Tag>Work</Tag>
            <Tag>Business</Tag>
            <Tag>Woman</Tag>
          </Wrap> */}
        </CardContent>
      </Stack>
    </Card>
  );
};
