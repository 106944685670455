import { Box, FormLabel } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { InputFromQuestion } from '.';
import { AdaptedMultiplechoice } from '../../../../components/Form';
import { Question } from '../types';

type AdaptedSelectProps = {
  options: Array<{
    label: string;
    value: string;
    children?: Array<Question>;
  }>;
  name: string;
  label: string;
  required: boolean;
  initialValue: any;
  allInitialValues: any;
  currentValues: any;
};

export const MultipleChoice = (props: AdaptedSelectProps) => {
  const {
    options,
    name,
    label,
    required,
    initialValue,
    currentValues,
    allInitialValues,
  } = props;
  const [selectedVal, setSelectedVal] = useState<null | Array<string>>(
    initialValue || ''
  );

  const selectedOption = useMemo(() => {
    return options.find((o) => String(o.value) === String(selectedVal));
  }, [selectedVal]);

  return (
    <Box>
      <FormLabel htmlFor={name}>{label}</FormLabel>

      <Field
        name={name}
        required={required}
        onChange={(e: any) => {
          setSelectedVal(e);
        }}
        component={AdaptedMultiplechoice}
        options={options}
      ></Field>

      {selectedOption?.children &&
        selectedOption?.children?.map((question) => (
          <InputFromQuestion
            question={question}
            key={question.label}
            initialValues={allInitialValues}
            currentValues={currentValues}
          />
        ))}
    </Box>
  );
};
