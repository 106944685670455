import { theme } from '@chakra-ui/react';

// @ts-ignore
const customTheme: any = {
  ...theme,
  fonts: {
    body: 'Roboto, system-ui, sans-serif',
    heading: 'Roboto, system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontWeights: {
    ...theme.fontWeights,
    normal: 400,
    medium: 600,
    bold: 700,
  },
  radii: {
    ...theme.radii,
    sm: '5px',
    md: '8px',
  },
  fontSizes: {
    ...theme.fontSizes,
  },
  colors: {
    ...theme.colors,
    // @ts-ignore

    gray: {
      ...theme.colors.gray,
      200: '#f3f3f3',
    },
    // @ts-ignore

    green: {
      ...theme.colors.green,
      500: '#51cf23',
    },
  },
};

export default customTheme;
