import { Box, Select, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useMemo } from 'react';
import { FaCheckCircle, FaFileInvoice } from 'react-icons/fa';
import { CardHeader } from '../../components/Card/CardHeader';
import { Link } from '../../components/Link';
import { Loader } from '../../components/Loader';
import { NoticeNoResults } from '../../components/Notice';
import { TablePagination } from '../../components/Pagination';
import { Invoice } from '../../graphql';
import { useMyInvoices } from '../../hooks/useMyInvoices';
import { getMonthName } from '../../utils/month-utils';
import { IColumn, SimpleTable } from '../../utils/table';

export const MyInvoicesListPage = () => {
  const {
    items,
    loading,
    getNextPage,
    getPreviousPage,
    pageInfo,
    totalCount,
    showEmptyResults,
    month,
    setMonth,
    year,
    setYear,
  } = useMyInvoices();

  const months = useMemo(() => {
    return [
      { name: 'Janar', value: 1 },
      { name: 'Shkurt', value: 2 },
      { name: 'Mars', value: 3 },
      { name: 'Prill', value: 4 },
      { name: 'Maj', value: 5 },
      { name: 'Qershor', value: 6 },
      { name: 'Korrik', value: 7 },
      { name: 'Gusht', value: 8 },
      { name: 'Shtator', value: 9 },
      { name: 'Tetor', value: 10 },
      { name: 'Nëntor', value: 11 },
      { name: 'Dhjetor', value: 12 },
    ];
  }, []);

  const years = useMemo(() => {
    const begin = 2022;
    const last = new Date().getFullYear();

    const generateConsequentNumbersBetween = (begin: number, last: number) => {
      const numbers = [];

      for (let i = begin; i <= last; i++) {
        numbers.push(i);
      }

      return numbers;
    };

    return generateConsequentNumbersBetween(begin, last);
  }, []);

  const columns: IColumn[] = [
    {
      Header: 'Titulli',
      accessor: 'title',
      tdParams: {
        width: '20%',
      },
    },
    {
      Header: 'Muaji',
      accessor: '',
      tdParams: {
        width: '10%',
      },
      Cell: function monthCell(cell: any, row: any) {
        return <Text>{getMonthName(row.month)}</Text>;
      },
    },
    {
      Header: 'Viti',
      accessor: 'year',
      tdParams: {
        width: '10%',
      },
    },
    {
      Header: 'Data e plotë',
      accessor: '',
      tdParams: {
        width: '20%',
      },
      Cell: function DetailsCell(cell: any, row: Invoice) {
        return <Text>{moment(row.createdAt).format('DD/MM/YYYY')}</Text>;
      },
    },
    {
      Header: 'Totali',
      accessor: '',
      tdParams: {
        width: '15%',
      },
      Cell: function totalCell(cell: any, row: any) {
        return <Text>{row.total.toLocaleString()} EUR</Text>;
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function DetailsCell(cell: any, row: Invoice) {
        return <Link href={`/invoice/${row.id}`}>Shiko detajet</Link>;
      },
      tdParams: {
        textAlign: 'right',
        width: '35%',
      },
    },
    {
      Header: 'Paguar',
      accessor: '',
      Cell: function PaymentCell(cell: any, row: Invoice) {
        return Boolean(row.payments.length) ? (
          <FaCheckCircle color="green"></FaCheckCircle>
        ) : (
          <Box></Box>
        );
      },
      tdParams: {
        width: '10%',
        textAlign: 'center',
      },
    },
  ];

  return (
    <Box>
      <CardHeader
        title="Lista e faturave"
        action={
          <Box className="issue-filters">
            <Stack
              spacing="4"
              direction={{ base: 'column', md: 'row' }}
              justify="space-between"
            >
              {/** Month Filter */}
              <Select
                size={'sm'}
                padding={1}
                value={month || undefined}
                onChange={(e) => setMonth(Number(e.target.value))}
                placeholder="Zgjidhni muajin"
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.name}
                  </option>
                ))}
              </Select>

              {/** Year Filter */}
              <Select
                size={'sm'}
                padding={1}
                value={year || undefined}
                onChange={(e) => setYear(Number(e.target.value))}
                placeholder="Zgjidhni vitin"
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </Stack>
          </Box>
        }
      />

      {loading ? (
        <Loader />
      ) : showEmptyResults && items.length === 0 && !loading ? (
        <NoticeNoResults
          icon={FaFileInvoice}
          title="Nuk ka asnjë faturë"
          description="Ju nuk keni asnjë faturë për të paguar për përdorimin e Legit."
        />
      ) : (
        <SimpleTable columns={columns} data={items} />
      )}
      {pageInfo && (
        <TablePagination
          total={totalCount}
          pageInfo={pageInfo}
          onNextPage={getNextPage}
          onPreviousPage={getPreviousPage}
        />
      )}
    </Box>
  );
};
