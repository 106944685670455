import { ClientIssue, IssueStatus } from '../graphql';

export function getColorStatus(status: ClientIssue['status']) {
  switch (status) {
    case IssueStatus.ChooseOfferStage:
      return '#B5D56C';
    case IssueStatus.ClosedSuccessfully:
      return '#6EB873';
    case IssueStatus.ClosedUnsuccessfully:
      return '#E38C8C';
    case IssueStatus.CooperationStage:
      return '#8CC5E3';
    case IssueStatus.InReview:
      return '#BBBBBB';
    case IssueStatus.RejectedByAdmin:
      return '#B83014';
    case IssueStatus.WaitingOffers:
      return '#6E9FB8';
  }
}

export function getStatusLabel(status: IssueStatus) {
  switch (status) {
    case IssueStatus.ChooseOfferStage:
      return 'Zgjedhja e ofertës';
    case IssueStatus.ClosedSuccessfully:
      return 'Përfunduar';
    case IssueStatus.ClosedUnsuccessfully:
      return 'Mbyllur';
    case IssueStatus.CooperationStage:
      return 'Në bashkëpunim';
    case IssueStatus.InReview:
      return 'Në kontroll';
    case IssueStatus.RejectedByAdmin:
      return 'Refuzuar';
    case IssueStatus.WaitingOffers:
      return 'Duke pritur oferta';
    default:
      return '';
  }
}
