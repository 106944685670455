import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import { Divider } from '@chakra-ui/react';
import * as React from 'react';
import { Form } from 'react-final-form';
import { DropzoneControl, InputControl } from '../components/Form';
import { Media } from '../graphql';

export type GeneralInfoFormValues = {
  name: string;
  lastname: string;
  phoneNumber?: string | null;
  profilePhoto?: Media;
};

interface IGeneralInfoForm {
  onSubmit: (values: GeneralInfoFormValues) => void;
  initialValues: GeneralInfoFormValues;
}

export const EditGeneralInfoForm: React.FC<IGeneralInfoForm> = ({
  onSubmit,
  initialValues,
}) => {
  const validate = (values: GeneralInfoFormValues) => {
    const errors: { [key: string]: string } = {};

    if (!values.name) {
      errors.name = 'Fushë e detyruar';
    }

    if (!values.lastname) {
      errors.lastname = 'Fushë e detyruar';
    }

    if (values.phoneNumber && !values.phoneNumber.match(/(\+|\d)*/g)) {
      errors.phoneNumber =
        'Numri i telefonit nuk është i saktë. Duhet të përmbajë vetëm karaktere numerike.';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" onSubmit={handleSubmit}>
          {/** Name */}
          <InputControl type="text" name="name" label="Emri" />
          {/* Lastname */}
          <InputControl type="text" name="lastname" label="Mbiemri" />
          {/* Phone number */}
          <InputControl
            type="text"
            name="phoneNumber"
            label="Numri i telefonit"
          />
          <DropzoneControl
            name="profilePhoto"
            unauthenticated={true}
            label="Foto profili"
            accept=".jpeg,.png,.jpg"
            meta={{}}
            initialValue={initialValues.profilePhoto}
          />

          <Divider my="3" />
          <Button
            float="right"
            isLoading={submitting}
            loadingText="Duke dërguar"
            colorScheme="red"
            type="submit"
          >
            Ruaj
          </Button>
        </Box>
      )}
    />
  );
};
