import {
  Box,
  Container,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';
import { useLocation } from 'react-router';
import { HeaderOptions } from '../../layouts/Dashboard';
import { Link } from '../Link';
import { TabLink } from '../TabLink';

type RouteProps = {
  currentRoute: HeaderOptions | null;
};

export const PageHeader = (props: RouteProps) => {
  const location = useLocation();

  if (!props.currentRoute) {
    return <></>;
  }

  return (
    <Box bg={mode('white', 'gray.900')} pt="0" shadow="sm">
      <Container maxW="7xl">
        <Stack direction="row" spacing="4">
          {(props.currentRoute.subMenu || []).map((option) => (
            <TabLink
              key={option.title}
              aria-current={
                location.pathname.startsWith(option.href) ? 'page' : undefined
              }
              as={Link}
              href={option.href}
            >
              {option.title}
            </TabLink>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};
