import { Box, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

export const PayseraAccept = function () {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Icon as={FaCheckCircle} boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Pagesa u krye me sukses
      </Heading>
      {/* <Text color={'gray.500'}>
        Falemindeit për blerjen e produktit në Legit. Ju lutemi të ktheheni në
      </Text> */}
    </Box>
  );
};

export const PayseraCancel = function () {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'red.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center"
        >
          <Icon as={FaTimesCircle} boxSize={'50px'} color={'white'} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Kryerja e pagesës dështoi.
      </Heading>
      <Text color={'gray.500'}>
        Ju lutem kontaktoni në info@legit.al nëse keni nevojë për detaje.
      </Text>
    </Box>
  );
};
