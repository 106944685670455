import { useState } from 'react';
import { AccountShortData } from '../graphql';

export const TOKEN_NAME = 'authToken';

const decodeUserFromJwt = (token: string): AccountShortData | null => {
  const base64Data = token.split('.');
  try {
    return JSON.parse(atob(base64Data[1])) as unknown as AccountShortData;
  } catch (error) {
    return null;
  }
};

// custom hook to handle authToken - we use compositon to decouple the auth system and it's storage
export const useAuthToken = () => {
  const [token, setToken] = useState(() => localStorage.getItem(TOKEN_NAME));
  const [user, setUser] = useState(decodeUserFromJwt(token || ''));

  // this function allows to save any string in our cookies, under the key "authToken"
  const setAuthToken = (authToken: string) => {
    localStorage.setItem(TOKEN_NAME, authToken);
    setUser(decodeUserFromJwt(authToken));
    setToken(authToken);
  };

  //this function removes the key "authToken" from our cookies. Useful to logout
  const removeAuthToken = () => {
    localStorage.removeItem(TOKEN_NAME);
    setUser(null);
    setToken(null);
  };

  return {
    token,
    setAuthToken,
    removeAuthToken,
    user,
  };
};

export const getToken = () => window.localStorage.getItem(TOKEN_NAME);
