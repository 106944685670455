import {
  Button,
  ButtonGroup,
  Flex,
  Text,
  useColorModeValue as mode,
  Divider,
} from '@chakra-ui/react';
import { PageInfo } from '../../graphql';

type TablePaginationProps = {
  pageInfo: PageInfo;
  total: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
};

export const TablePagination = (props: TablePaginationProps) => {
  const { onNextPage, onPreviousPage, total } = props;

  if (!props.pageInfo) {
    return <></>;
  }

  const { hasNextPage, hasPreviousPage } = props.pageInfo;
  return (
    <>
      <Divider my={5} />

      <Flex align="center" justify="space-between">
        <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
          {total} gjithësej
        </Text>
        <ButtonGroup variant="outline" size="sm">
          <Button
            onClick={onPreviousPage}
            disabled={!hasPreviousPage}
            as="a"
            rel="prev"
          >
            Prapa
          </Button>
          <Button
            onClick={onNextPage}
            disabled={!hasNextPage}
            as="a"
            rel="next"
          >
            Përpara
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  );
};
