import * as React from 'react';
import { Box, Divider } from '@chakra-ui/layout';
import { Form } from 'react-final-form';
import { DropzoneControl } from '../components/Form';
import { Button } from '@chakra-ui/button';
import { Tag, TagLabel, TagLeftIcon, Text, Flex } from '@chakra-ui/react';
import { MediaFile } from '../graphql';
import { FaFile } from 'react-icons/fa';

export type UpdateLawyerFormValues = {
  file: MediaFile;
};

export type UpdateLawyerInitialValues = {
  fileUrl: string;
};

interface IUpdateLawyerCvForm {
  onSubmit: (values: UpdateLawyerFormValues) => void;
  initialValues: UpdateLawyerInitialValues;
  fileKey: string;
}

export const UpdateLawyerForm: React.FC<IUpdateLawyerCvForm> = ({
  onSubmit,
  initialValues,
  fileKey,
}) => {
  const validate = (values: any) => {
    const errors: any = {};

    if (!values.file || !values.file.key) {
      errors.file = 'Fushë e detyruar';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" onSubmit={handleSubmit}>
          <Flex>
            <Text>
              Dokumenti ekzistues:{' '}
              <Tag mx="2" color="blue.600">
                <TagLeftIcon as={FaFile} />
                <TagLabel>
                  <a
                    href={initialValues.fileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dokument
                  </a>
                </TagLabel>
              </Tag>
            </Text>
          </Flex>

          <DropzoneControl
            name="file"
            unauthenticated={false}
            label="Ngarko një dokument të ri:"
            accept="application/pdf,.doc,.docx"
            meta={{}}
          />

          <Divider my="3" />
          <Button
            float="right"
            isLoading={submitting}
            loadingText="Duke dërguar"
            colorScheme="red"
            type="submit"
          >
            Ruaj
          </Button>
        </Box>
      )}
    />
  );
};
