import { Box, HStack } from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

interface NavItemProps {
  option: {
    href: string;
    label: string;
    icon?: React.ReactNode;
  };
  setCurrentRoute: any;
}

const DesktopNavItem = (props: NavItemProps) => {
  const { icon, label, href = '#' } = props.option;
  const location = useLocation();

  return (
    <HStack
      as={Link}
      to={href}
      aria-current={location.pathname.startsWith(href) ? 'page' : undefined}
      spacing="2"
      px="3"
      py="2"
      rounded="md"
      transition="all 0.2s"
      color="gray.200"
      _hover={{ bg: 'whiteAlpha.200' }}
      _activeLink={{ bg: 'blackAlpha.300', color: 'white' }}
      onClick={() => props.setCurrentRoute(props.option)}
    >
      {icon && (
        <Box aria-hidden fontSize="md">
          {icon}
        </Box>
      )}
      <Box fontWeight="semibold">{label}</Box>
    </HStack>
  );
};

const MobileNavItem = (props: NavItemProps) => {
  const { label, href = '#' } = props.option;
  const location = useLocation();

  return (
    <Box
      as={Link}
      display="block"
      to={href}
      px="3"
      py="3"
      rounded="md"
      fontWeight="semibold"
      aria-current={location.pathname.startsWith(href) ? 'page' : undefined}
      _hover={{ bg: 'whiteAlpha.200' }}
      _activeLink={{ bg: 'blackAlpha.300', color: 'white' }}
      onClick={() => props.setCurrentRoute(props.option)}
    >
      {label}
    </Box>
  );
};

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
};
