import { useColorModeValue as mode } from '@chakra-ui/react';
import { Avatar } from '@chakra-ui/avatar';
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Stack,
  StackDivider,
} from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/tag';
import { Description } from '../Description';
import { LawyerIssue } from '../../graphql';
import { Link } from '../Link';
import moment from 'moment';
import { getColorStatus, getStatusLabel } from '../../utils/issue-status';

type LawyerIssueCardProps = {
  issue: LawyerIssue;
};

export const LawyerIssueCard = (props: LawyerIssueCardProps) => {
  const { issue } = props;
  const issuerFullName = issue.issuer
    ? `${issue.issuer.profile?.name} ${issue.issuer.profile?.lastname}`
    : 'I Panjohur';

  return (
    <Box>
      <Box
        rounded={{
          lg: 'lg',
        }}
        borderLeft={`10px solid ${getColorStatus(props.issue.status)}`}
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        bg={mode('white', 'gray.700')}
        shadow="base"
        my="8"
      >
        <Link href={`/issues/details/${issue.id}`} display={'block'}>
          <Box color={'gray.600'}>
            <Flex align="center" justify="space-between" px="6" py="4">
              <HStack spacing="16px">
                <Box>
                  <Avatar
                    name={issuerFullName}
                    size="sm"
                    src={issue.issuer.profile?.profilePicture?.sm}
                  />
                </Box>
                <Box fontWeight="bold" maxW="xl">
                  {issuerFullName}
                </Box>
              </HStack>

              <Center>
                <Tag
                  marginLeft="4"
                  size="md"
                  mr="5"
                  color="white"
                  backgroundColor={getColorStatus(props.issue.status)}
                >
                  {getStatusLabel(issue.status)}
                </Tag>
                <Tag size="md" mr={5} colorScheme="red">
                  {issue.speciality?.name}
                </Tag>
              </Center>
            </Flex>
            <Divider />
            <Stack spacing="6" py="5" px="8" divider={<StackDivider />}>
              <Description
                title={issue.title}
                location={`${issue.city?.name}, ${issue?.country?.name}`}
                deadline={moment(new Date(issue.createdAt)).format(
                  'DD/MM/YYYY'
                )}
              >
                {issue.description}
              </Description>
            </Stack>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};
