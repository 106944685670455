// @ts-nocheck
import {
  Box,
  Button,
  Center,
  HStack,
  Spacer,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Loader } from '../../components/Loader';
//

export default function PdfViewer({ url, width = "500px" }) {
  const [isLoading, setLoading] = useState(false);
  var pdfjsLib = window['pdfjs-dist/build/pdf'];
  const canvasRef = useRef();
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';

  const [pdfRef, setPdfRef] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const renderPage = useCallback(
    (pageNum, pdf = pdfRef) => {
      pdf &&
        pdf.getPage(pageNum).then(function (page) {
          const canvas = canvasRef.current;
          const viewport = page.getViewport({
            scale: canvas.width / page.getViewport({ scale: 1 }).width,
          });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: canvas.getContext('2d'),
            viewport: viewport,
          };
          page.render(renderContext);
        });
    },
    [pdfRef]
  );

  useEffect(() => {
    renderPage(currentPage, pdfRef);
  }, [pdfRef, currentPage, renderPage]);

  useEffect(() => {
    setLoading(true);
    const loadingTask = pdfjsLib.getDocument(url);
    loadingTask.promise.then(
      (loadedPdf) => {
        setLoading(false);
        setPdfRef(loadedPdf);
      },
      function (reason) {
        setLoading(false);
        console.error(reason);
      }
    );
  }, [url]);

  const nextPage = () =>
    pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

  const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  return (
    <>
      <Box border={'1px solid lightgray'} width="100%">
        {isLoading && <Loader />}
        <Center>
          <canvas width={width} ref={canvasRef}></canvas>
        </Center>
      </Box>
      <Box mt={2}>
        <HStack>
          {pdfRef && (
            <Text>
              Faqja {currentPage} nga {pdfRef.numPages}
            </Text>
          )}
          <Spacer />
          <HStack divider={<StackDivider />}>
            <Button
              size="sm"
              onClick={prevPage}
              variant="outline"
              verticalAlign="baseline"
            >
              Pas
            </Button>
            <Button
              size="sm"
              onClick={nextPage}
              variant="outline"
              verticalAlign="baseline"
            >
              Para
            </Button>
          </HStack>
        </HStack>
      </Box>
    </>
  );
}
