// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    common: {
      loadMore: 'Ngarko më tepër',
    },
    login: {
      login: 'Identifikohu',
      noAccount: 'Nuk keni llogari?',
      haveAccount: 'Keni llogari?',
      register: 'Regjistrohu',
      email: 'Email',
      password: 'Fjalëkalimi',
      tfaCode: 'Kodi i verifikimit',
      confirmPassword: 'Konfirmo fjalëkalimin',
      forgotPassword: 'Keni harruar fjalëkalimin?',
      resetPassword: 'Rivendos fjalëkalimin',
      resetCode: 'Kodi i verifikimit',
      passwordDoesNotMatch: 'Fjalëkalimi nuk është i njëjtë',
      name: 'Emri',
      lastname: 'Mbiemri',
      sex: 'Gjinia',
      birthday: 'Datëlindja',
      mobileNumber: 'Numri i celularit',
      country: 'Shteti',
      city: 'Qyteti',
      healthCenter: 'Qëndra shëndetsore',
      licenceNumber: 'Numri i licensës',
      cv: 'Curriculum Vitae',
      language: 'Gjuha',
      accountStatus: 'Statusi i llogarisë',
      firstRegistration: 'Rregjistrimi i parë',
      speciality: 'Specialiteti',
      bio: 'Biografia',
      subscriptionStatus: 'Statusi i paketës',
      tier: 'Paketa',
      startDate: 'Data e fillimit',
      remainingDays: 'Ditë të mbetura',
    },
    forms: {
      slideOrClick: 'Kliko ose zhvendos një dokument',
      invalidFile: 'Dokumenti i pavlefshëm',
      delete: 'Fshij',
      upload: 'Ngarko',
      uploading: 'Duke ngarkuar',
      size: 'Madhësia',
    },
  },
};
