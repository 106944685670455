import { Button } from '@chakra-ui/button';
import { Box, Divider } from '@chakra-ui/layout';
import { Text, Link } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import {
  CurrencyALLInputControl,
  InputControl,
  TextareaControl,
} from '../components/Form';

export type CreateBidFormValues = {
  title: string;
  description: string;
  amount: number;
};

interface IGeneralInfoForm {
  onSubmit: (values: CreateBidFormValues) => void;
  initialValues?: CreateBidFormValues;
}

export const CreateBidForm: React.FC<IGeneralInfoForm> = ({
  onSubmit,
  initialValues,
}) => {
  const validate = (values: CreateBidFormValues) => {
    const errors: { [key: string]: string } = {};

    if (!values.title) {
      errors.title = 'Fushë e detyruar';
    }

    if (!values.description) {
      errors.description = 'Fushë e detyruar';
    }

    if (!values.amount || !Number.isSafeInteger(Number(values.amount))) {
      errors.amount = 'Fushë e detyruar';
    }

    if (Number(values.amount) <= 0) {
      errors.amount = 'Vlera duhet të jetë më e madhe se 0';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" onSubmit={handleSubmit}>
          {/** Title */}
          <InputControl type="text" name="title" label="Titulli" />
          {/* Description */}
          <TextareaControl
            name="description"
            label="Përshkrimi"
            placeholder="Shkruani përshkrimin"
          />
          {/** Amount */}
          <CurrencyALLInputControl name="amount" label="Shuma" />
          <Text fontSize={'sm'}>
            Shënim: Nëse bashkëpunimi me klientin në këtë çështje rezulton i
            suksesshëm, ju do të tarifoheni nga Legit në masën 10% të vlerës.
            Lutemi të lexoni{' '}
            <Link href="https://legit.al/kushtet-e-perdorimit/">
              Kushtet e Përdorimit
            </Link>
            .
          </Text>

          <Divider my="3" />
          <Button
            float="right"
            isLoading={submitting}
            loadingText="Duke dërguar"
            colorScheme="red"
            type="submit"
          >
            Dërgo
          </Button>
        </Box>
      )}
    />
  );
};
