import { Button, ButtonGroup } from '@chakra-ui/button';
import { Box, Stack, StackDivider, VStack } from '@chakra-ui/layout';
import moment from 'moment';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import {
  AdaptedCheckboxes,
  AdaptedSelect,
  CheckboxControl,
  DropzoneControl,
  FieldGroup,
  InputControl,
  Optional,
} from '../components/Form';
import {
  Gender,
  // LanguageCode,
  PresignedUrlResponse,
  useCitiesByCountryLazyQuery,
  useCountriesQuery,
  // useLanguagesQuery,
  usePositionsQuery,
  useSpecialitiesQuery,
} from '../graphql';

export interface ILawyerSignupFormValues {
  // languageCode: LanguageCode;
  email: string;
  name: string;
  lastname: string;
  birthday: Date;
  phoneNumber: string;
  sex: Gender;
  cityId: number;
  countryId: number;
  specialityIDs: Array<number>;
  positioinIDs: Array<number>;
  password: string;
  repeatPassword: string;
  cv: PresignedUrlResponse;
  license: PresignedUrlResponse;
  diploma: PresignedUrlResponse;
  // tierId: string;
  termsOfUse: boolean;
  privacyPolicy: boolean;
}

interface ILawyerSignupForm {
  onSubmit: (values: ILawyerSignupFormValues) => void;
  initialValues?: Optional<ILawyerSignupFormValues>;
}

export const LawyerSignupForm: React.FC<ILawyerSignupForm> = ({
  onSubmit,
  initialValues,
}) => {
  // const { data: languages, loading: languagesLoading } = useLanguagesQuery();
  const { data: specialities, loading: specialititesLoading } =
    useSpecialitiesQuery();
  const { data: positions, loading: positionsLoading } = usePositionsQuery();
  const { loading: loadingCountries, data: { countries = [] } = {} } =
    useCountriesQuery();
  const [
    loadCities,
    { data: { citiesByCountry = [] } = {}, loading: loadingCities },
  ] = useCitiesByCountryLazyQuery();

  const loadingGeo = loadingCountries || loadingCities;

  const [countryId, setCountryId] = React.useState();
  const [cityId, setCityId] = React.useState();

  React.useEffect(() => {
    if (countryId) {
      loadCities({
        variables: {
          countryId: parseInt(countryId),
        },
      });
    }
  }, [loadCities, countryId]);

  const validate = (values: ILawyerSignupFormValues) => {
    const errors: { [key: string]: string } = {};

    // if (!values.languageCode) {
    //   errors.languageCode = 'Fushë e detyruar';
    // }

    if (!values.email) {
      errors.email = 'Fushë e detyruar';
    }

    if (!values.name) {
      errors.name = 'Fushë e detyruar';
    }
    if (!values.lastname) {
      errors.lastname = 'Fushë e detyruar';
    }
    if (!values.birthday) {
      errors.birthday = 'Fushë e detyruar';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Fushë e detyruar';
    }
    if (!values.sex) {
      errors.sex = 'Fushë e detyruar';
    }

    if (!values.countryId) {
      errors.countryId = 'Fushë e detyruar';
    }

    if (!values.cityId) {
      errors.cityId = 'Fushë e detyruar';
    }

    if (!values.password) {
      errors.password = 'Fushë e detyruar';
    }

    if (!values.repeatPassword) {
      errors.repeatPassword = 'Fushë e detyruar';
    }

    if (!values.cv?.key) {
      errors.cv = 'Fushë e detyruar';
    }

    if (!values.diploma?.key) {
      errors.diploma = 'Fushë e detyruar';
    }

    if (!values.license?.key) {
      errors.license = 'Fushë e detyruar';
    }

    if (
      values.password &&
      values.repeatPassword &&
      values.password !== values.repeatPassword
    ) {
      errors.password = 'Fjalëkalimet nuk përputhen';
    }

    if (!values.specialityIDs || !values.specialityIDs.length) {
      errors.specialityIDs = 'Fushë e detyruar';
    }

    if (!values.positioinIDs || !values.positioinIDs.length) {
      errors.positions = 'Fushë e detyruar';
    }

    if (values.password?.length < 8) {
      errors.password = 'Fjalëkalimi duhet të ketë të paktën 8 karaktere';
    }

    if (!values.phoneNumber?.match(/(\+|\d)*/g)) {
      errors.phoneNumber =
        'Numri i telefonit nuk është i saktë. Duhet të përmbajë vetëm karaktere numerike.';
    }

    if (moment(values.birthday).isAfter(moment().subtract(18, 'years'))) {
      errors.birthday = 'Mosha minimale është 18 vjeç';
    }

    if (!values.termsOfUse) {
      errors.termsOfUse = 'Fushë e detyruar';
    }

    if (!values.privacyPolicy) {
      errors.privacyPolicy = 'Fushë e detyruar';
    }
    // if (!values.tierId) {
    //   errors.tierId = "Fushë e detyruar";
    // }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        errors,
        submitting,
        pristine,
        values,
      }) => (
        <Box as="form" onSubmit={handleSubmit}>
          <Stack spacing="4" divider={<StackDivider />}>
            <FieldGroup title="Të dhëna vetjake">
              <VStack width="full" spacing="6">
                {/* Emri */}
                <InputControl type="text" name="name" label="Emri" />
                {/* Mbiemri */}
                <InputControl type="text" name="lastname" label="Mbiemri" />
                {/* Email */}
                <InputControl type="email" name="email" label="Email" />
                {/* Gjinia */}
                <Field name="sex" component={AdaptedSelect} label="Gjinia">
                  <option value={Gender.Male}>Mashkull</option>
                  <option value={Gender.Female}>Femër</option>
                </Field>
                {/* Ditelindja */}
                <InputControl type="date" name="birthday" label="Datëlindja" />
                {/* Numri i telefonit */}
                <InputControl
                  type="text"
                  name="phoneNumber"
                  label="Numri i celularit"
                />
                {/* Shteti */}
                <Field
                  name="countryId"
                  component={AdaptedSelect}
                  label="Shteti"
                  disabled={loadingGeo}
                  onChange={(e: any) => setCountryId(e.target.value)}
                  initialValue={countryId}
                >
                  {countries.map((country) => (
                    <option key={country!.id} value={country!.id}>
                      {country!.name}
                    </option>
                  ))}
                </Field>
                {/* Qyteti */}
                <Field
                  name="cityId"
                  component={AdaptedSelect}
                  label="Qyteti"
                  disabled={loadingGeo}
                  initialValue={cityId}
                >
                  {(citiesByCountry || []).map((city) => (
                    <option
                      key={city!.id}
                      onClick={(e: any) => setCityId(e.target.value)}
                      value={city!.id}
                    >
                      {city!.name}
                    </option>
                  ))}
                </Field>
                {/* Profile picture */}
                {/* @ts-ignore */}
                <DropzoneControl
                  label="Foto profili (opsionale)"
                  name="profilePhoto"
                  unauthenticated={true}
                  accept=".jpeg,.png,.jpg"
                />
              </VStack>
            </FieldGroup>

            <FieldGroup title="Siguria">
              <VStack width="full" spacing="6">
                {/* Fjalekalimi */}
                <InputControl
                  type="password"
                  name="password"
                  label="Fjalëkalimi"
                />
                {/* Riperserit fjalekalimin */}
                <InputControl
                  type="password"
                  name="repeatPassword"
                  label="Ripërsërit fjalëkalimin"
                />
              </VStack>
            </FieldGroup>

            <FieldGroup title="Të dhëna profesionale">
              <VStack w="full">
                {/* Specialiteti */}
                <Field
                  name="specialityIDs"
                  component={AdaptedCheckboxes}
                  label="Specialiteti"
                  disabled={specialititesLoading}
                  options={
                    specialities?.specialities.map((i) => ({
                      label: i?.name,
                      value: i?.id,
                    })) || []
                  }
                ></Field>

                {/* Pozicioni */}
                <Field
                  name="positioinIDs"
                  component={AdaptedCheckboxes}
                  label="Pozicioni"
                  disabled={positionsLoading}
                  options={
                    positions?.positions.map((i) => ({
                      label: i?.name,
                      value: i?.id,
                    })) || []
                  }
                ></Field>

                {/* CV */}
                {/* @ts-ignore */}
                <DropzoneControl
                  label="Curriculum Vitae"
                  name="cv"
                  unauthenticated={true}
                  accept="application/pdf"
                />

                {/* Diploma */}
                {/* @ts-ignore */}
                <DropzoneControl
                  label="Diploma"
                  name="diploma"
                  unauthenticated={true}
                  accept="application/pdf"
                />

                {/* License */}
                {/* @ts-ignore */}
                <DropzoneControl
                  label="Licensa"
                  name="license"
                  unauthenticated={true}
                  accept="application/pdf"
                />
              </VStack>
            </FieldGroup>

            <FieldGroup title="Politikat dhe Kushtet e Përdorimit">
              <VStack w="full">
                {/* Terms Of Use */}
                <CheckboxControl name="termsOfUse" checked={values.termsOfUse}>
                  Bie dakord me{' '}
                  <a
                    href="https://legit.al/kushtet-e-perdorimit/"
                    target={'_blank'}
                    rel="noreferrer"
                    style={{ textDecoration: 'underline', color: '#cf4b4bF' }}
                  >
                    Kushtet e Përdorimit
                  </a>
                </CheckboxControl>

                {/* Terms Of Use */}
                <CheckboxControl
                  name="privacyPolicy"
                  checked={values.privacyPolicy}
                >
                  Bie dakord me{' '}
                  <a
                    href="https://legit.al/politikat-e-privatesise/"
                    target={'_blank'}
                    rel="noreferrer"
                    style={{ textDecoration: 'underline', color: '#cf4b4bF' }}
                  >
                    Politikat e Privatësisë
                  </a>
                </CheckboxControl>
              </VStack>
            </FieldGroup>

            {/* <FieldGroup title="Gjuha">
              <VStack w="full">
                <Field
                  name="languageCode"
                  component={AdaptedSelect}
                  label="Gjuha e aplikacionit"
                  disabled={languagesLoading}
                >
                  {languages?.languages?.map((language) => (
                    <option key={language!.code} value={language!.code}>
                      {language!.name}
                    </option>
                  ))}
                </Field>
              </VStack>
            </FieldGroup> */}

            <FieldGroup>
              <VStack>
                <ButtonGroup spacing={4}>
                  <Button
                    isLoading={submitting}
                    loadingText="Duke dërguar"
                    colorScheme="red"
                    type="submit"
                  >
                    Regjistrohu
                  </Button>
                  <Button
                    variant="outline"
                    // @ts-ignore
                    onClick={form.reset}
                    isDisabled={submitting || pristine}
                  >
                    Pastro
                  </Button>
                </ButtonGroup>
              </VStack>
            </FieldGroup>
          </Stack>
        </Box>
      )}
    />
  );
};
