import { chakra, HTMLChakraProps, useColorModeValue } from '@chakra-ui/system';
import { Link as ReactLink } from 'react-router-dom';
import * as React from 'react';

export const Link = (props: HTMLChakraProps<'a'>) => (
  <chakra.a
    as={ReactLink}
    marginStart="1"
    to={props.href}
    color={useColorModeValue('red.500', 'red.200')}
    _hover={{ color: useColorModeValue('red.600', 'red.300') }}
    display={{ base: 'block', sm: 'inline' }}
    {...props}
  />
);
