import { Avatar } from '@chakra-ui/avatar';
import { Button } from '@chakra-ui/button';
import { Box, Divider, Flex, Heading, HStack, Stack } from '@chakra-ui/layout';
import { useToast, Text, Icon } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router';
import { CardContent } from '../../../components/Card/CardContent';
import { ClientIssueNavigation } from '../../../components/IssueNavigation';
import { Loader } from '../../../components/Loader';
import { Property } from '../../../components/Property';
import { Step } from '../../../components/Step';
import {
  GetIssueByIdDocument,
  IssueStatus,
  useAcceptBidMutation,
  useGetIssueByIdQuery,
  LawyerProfile,
  AccountRole,
} from '../../../graphql';
import { useAuthToken } from '../../../hooks';
import { SectionTitle } from '../../../typography';
import { getStatusLabel } from '../../../utils/issue-status';

interface IStatusMessageProps {
  status: IssueStatus;
  type: AccountRole;
}

const StatusMessage = (props: IStatusMessageProps): React.ReactElement => {
  const { status, type } = props;

  switch (status) {
    case IssueStatus.InReview:
      return (
        <Text>
          Detajet e çështjes suaj janë regjistruar në platformë dhe po
          shqyrtohen për miratim.
        </Text>
      );
    case IssueStatus.RejectedByAdmin:
      return (
        <Text>
          Çështja juaj u refuzua për shkak të shkeljes së një ose më shumë
          kritereve. Lutemi të rihapni çështjen duke e shprehur më qartë ose na
          kontakotni në info@legit.al për më tepër detaje.
        </Text>
      );
    case IssueStatus.WaitingOffers:
      return (
        <Text>
          Detajet e çështjes u janë njoftuar profesionistëve të ligjit për të
          paraqitur oferta.
        </Text>
      );
    case IssueStatus.ChooseOfferStage:
      if (type === AccountRole.Client) {
        return (
          <Text>
            Gjeni të listuara ofertat e dërguara nga profesionistët e ligjit për
            të punuar mbi çështjen tuaj dhe zgjidhni ofertën më të pëlqyeshme
            nga ju për të kaluar në fazën tjetër të bashkëpunimit. Nëse nuk
            përzgjidhni një ofertë brenda tre ditësh, çështja juaj do të fshihet
            automatikisht nga platforma. Për të rimarrë oferta do ju duhet të
            rihapni çështjen si një çështje të re.{' '}
          </Text>
        );
      } else {
        return (
          <Text>
            Detajet e çështjes u janë njoftuar profesionistëve të ligjit për të
            paraqitur oferta.
          </Text>
        );
      }
    case IssueStatus.CooperationStage:
      if (type === AccountRole.Client) {
        return (
          <Text>
            Çështja juaj është duke u trajtuar me profesionistin e ligjit që ju
            zgjodhët. Ju mund të kontaktoni avokatin në menunë "Kontakto avokatin".
          </Text>
        );
      } else {
        return <Text>Çështja juaj është duke u trajtuar. Ju mund të kontaktoni klientin në menunë "Kontakto".</Text>;
      }
    case IssueStatus.ClosedSuccessfully:
      return <Text>Çështja juaj është përfunduar me sukses.</Text>;
    case IssueStatus.ClosedUnsuccessfully:
      return <Text>Çështja u mbyll pa sukses.</Text>;
  }
};

export const ClientIssuePage = () => {
  //@ts-ignore
  const { id } = useParams();
  const toast = useToast();
  const { data, loading } = useGetIssueByIdQuery({
    variables: { getIssueByIdId: Number(id) },
  });
  const [submitting, setSubmitting] = useState(false);
  const issueStatus = data?.getIssueById?.status;
  const [acceptBid] = useAcceptBidMutation();
  const {  user } = useAuthToken();

  const handleAcceptBid = async (issueId: number, bidId: number) => {
    try {
      setSubmitting(true);
      await acceptBid({
        variables: {
          issueId,
          bidId,
        },
        refetchQueries: [
          {
            query: GetIssueByIdDocument,
            variables: { getIssueByIdId: Number(id) },
          },
        ],
      });
      toast({
        title: 'Oferta u pranua me sukses',
        description: 'Avokati përkatës do të njoftohet menjëherë',
        status: 'success',
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading || !data?.getIssueById) {
    return <Loader />;
  }

  return (
    <>
      <ClientIssueNavigation
        id={data?.getIssueById.id!}
        title={data?.getIssueById.title!}
        canCancel={data?.getIssueById.canCloseUnsuccessfully!}
        canClose={data?.getIssueById.canCloseSuccessfully!}
        canChat={data?.getIssueById.canChat!}
      />

      <Box borderRadius={10} bg={'gray.50'} p={5} my={5}>
        <Heading as="h2" fontSize="lg" mb="5">
          Ecuria e çështjes
        </Heading>
        <Box
          mx="auto"
          maxW="4xl"
          pb="5"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <nav aria-label="Progress steps">
            <HStack as="ol" listStyleType="none" spacing="0">
              <Step isCurrent={issueStatus === IssueStatus.InReview}>
                {getStatusLabel(IssueStatus.InReview)}
              </Step>
              <Step isCurrent={issueStatus === IssueStatus.WaitingOffers}>
                {getStatusLabel(IssueStatus.WaitingOffers)}
              </Step>
              <Step isCurrent={issueStatus === IssueStatus.ChooseOfferStage}>
                {getStatusLabel(IssueStatus.ChooseOfferStage)}
              </Step>
              <Step isCurrent={issueStatus === IssueStatus.CooperationStage}>
                {getStatusLabel(IssueStatus.CooperationStage)}
              </Step>
              {issueStatus !== IssueStatus.ClosedUnsuccessfully && (
                <Step
                  isCurrent={issueStatus === IssueStatus.ClosedSuccessfully}
                >
                  {getStatusLabel(IssueStatus.ClosedSuccessfully)}
                </Step>
              )}
              {issueStatus === IssueStatus.ClosedUnsuccessfully && (
                <Step
                  isCurrent={issueStatus === IssueStatus.ClosedUnsuccessfully}
                >
                  {getStatusLabel(IssueStatus.ClosedUnsuccessfully)}
                </Step>
              )}
            </HStack>
          </nav>
        </Box>

        <StatusMessage
          status={data.getIssueById.status}
          type={user?.role!}
        />
      </Box>

      <SectionTitle>Detajet e çështjes</SectionTitle>
      <CardContent>
        <Property label="Titulli" value={data?.getIssueById.title || ''} />
        <Property
          label="Përshkrimi"
          value={data?.getIssueById.description || ''}
        />
        <Property
          label="Statusi"
          value={
            data?.getIssueById?.status
              ? getStatusLabel(data?.getIssueById?.status)
              : ''
          }
        />
        <Property
          label="Afati"
          value={moment(data?.getIssueById.deadline).format('DD/MM/YYYY')}
        />
      </CardContent>

      <SectionTitle>Oferta</SectionTitle>
      <Stack maxW="7xl" mx="auto" divider={<Divider />}>
        {!data?.getIssueById.bids.length && (
          <Text color="gray.600" my="5" fontWeight="medium" align="center">
            Nuk ka asnjë ofertë!
          </Text>
        )}
        {data?.getIssueById.bids.map((bid) => {
          const isAccepted = data?.getIssueById.acceptedBid?.id === bid.id;
          return (
            <Box
              key={bid.id}
              border={isAccepted ? '1px solid' : '1px dotted'}
              borderColor={isAccepted ? 'gray.300' : 'gray.200'}
              borderRadius={'xl'}
              shadow={isAccepted ? 'lg' : 'none'}
              pb={4}
            >
              <Flex
                align="center"
                justify="space-between"
                px="6"
                py="4"
                borderBottomWidth="1px"
              >
                <HStack spacing="16px">
                  <Box>
                    <Avatar
                      name={`${(bid.bidder.profile as LawyerProfile)?.name} ${
                        (bid.bidder.profile as LawyerProfile)?.lastname
                      }`}
                      size="sm"
                      src={
                        (bid.bidder.profile as LawyerProfile).profilePicture?.sm
                      }
                    />
                  </Box>
                  <Box>
                    <Flex>
                      <Box fontWeight="bold" marginRight="2">
                        {`${(bid.bidder?.profile as LawyerProfile).name} ${
                          (bid.bidder?.profile as LawyerProfile).lastname
                        }`}
                      </Box>
                      {isAccepted && (
                        <>
                          <Icon
                            as={FaCheckCircle}
                            color="green"
                            display={'inline'}
                            mt={1}
                            ml={3}
                            mr={1}
                          />
                          <Text color="green.600">Oferta e zgjedhur</Text>
                        </>
                      )}
                    </Flex>
                    <Box fontSize="xs">Avokat</Box>
                  </Box>
                </HStack>
                {data.getIssueById.canAcceptBid && (
                  <Button
                    variant="outline"
                    minW="20"
                    size="sm"
                    disabled={submitting}
                    leftIcon={<FaCheckCircle />}
                    onClick={() =>
                      handleAcceptBid(data?.getIssueById.id, bid.id)
                    }
                  >
                    Prano ofertën
                  </Button>
                )}
              </Flex>

              <CardContent>
                <Property
                  py="2"
                  label="Data"
                  value={moment(bid.createdAt).format('DD/MM/YYYY HH:mm')}
                />
                <Property
                  bold
                  py="2"
                  label="Çmimi"
                  value={`${bid.amount.toLocaleString()} EUR`}
                />
                <Property py="2" label="Mesazhi" value={bid.description} />
              </CardContent>
            </Box>
          );
        })}
      </Stack>
    </>
  );
};
