import { Button } from '@chakra-ui/button';
import { HStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  StackDivider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spacer,
} from '@chakra-ui/react';

import { useState } from 'react';
import {
  FaCheck,
  FaComment,
  FaEdit,
  FaInfoCircle,
  FaPlus,
  FaTrash,
} from 'react-icons/fa';
import { AiOutlineLeft } from 'react-icons/ai';
import {
  GetIssueByIdDocument,
  GetLawyerIssueByIdDocument,
  useCloseIssueSuccessfullyByLawyerMutation,
  useCloseIssueSuccessfullyMutation,
  useCloseIssueUnsuccessfullyByLawyerMutation,
  useCloseIssueUnsuccessfullyMutation,
} from '../../graphql';
import { NavLink } from 'react-router-dom';
import { CardHeader } from '../Card';
import { CreateBidModal } from '../../modals/CreateBidModal';

interface IssueNavigationProps {
  id: number;
  title: string;
  canChat: boolean;
  canClose: boolean;
  canCancel: boolean;
  canBid?: boolean;
}

export const ClientIssueNavigation = ({
  id,
  title,
  canChat,
  canCancel,
  canClose,
}: IssueNavigationProps) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reason, setReason] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [closeUnsuccessfully] = useCloseIssueUnsuccessfullyMutation();
  const [closeSuccessfully] = useCloseIssueSuccessfullyMutation();

  const handleCloseUnsuccessfully = async () => {
    try {
      setSubmitting(true);
      if (!reason) {
        throw new Error(`Arsyeja është e detyruar`);
      }
      await closeUnsuccessfully({
        variables: {
          closeIssueUnsuccessfullyId: Number(id),
          reason,
        },
        refetchQueries: [
          {
            query: GetIssueByIdDocument,
            variables: { getIssueByIdId: Number(id) },
          },
        ],
      });
      toast({
        title: 'Çështja u mbyll me sukses',
        status: 'success',
      });
      setReason('');
      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseSuccessfully = async () => {
    try {
      setSubmitting(true);
      await closeSuccessfully({
        variables: {
          closeIssueSuccessfullyId: Number(id),
        },
        refetchQueries: [
          {
            query: GetIssueByIdDocument,
            variables: { getIssueByIdId: Number(id) },
          },
        ],
      });
      toast({
        title: 'Çështja u përfundua me sukses',
        status: 'success',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {/** Give a reason to close issue */}
      <Modal isCentered={true} size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Ju lutem, shkruani arsyen e anulimit të çështjes:</Text>
          </ModalHeader>
          <ModalBody>
            <Textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Shkruani arsyen e anulimit të çështjes"
            ></Textarea>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={submitting || !reason}
              isLoading={submitting}
              onClick={handleCloseUnsuccessfully}
            >
              Konfirmo
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <CardHeader title={title} action={null}></CardHeader>

      <HStack>
        <HStack divider={<StackDivider />}>
          <NavLink
            exact={true}
            to="/find-lawyer"
            style={{ color: 'gray.600' }}
            activeStyle={{ color: 'red' }}
          >
            <Button leftIcon={<AiOutlineLeft />} variant="ghost">
              Lista e çështjeve
            </Button>
          </NavLink>
          <NavLink
            exact={true}
            to={`/find-lawyer/issue/${id}`}
            style={{ color: 'gray.600' }}
            activeStyle={{ color: 'red' }}
          >
            <Button leftIcon={<FaInfoCircle />} variant="ghost">
              Detajet e çështjes
            </Button>
          </NavLink>
          <NavLink
            exact={true}
            to={`/find-lawyer/issue/${id}/chat`}
            style={{ color: 'gray.600' }}
            activeStyle={{ color: 'red' }}
          >
            <Button
              disabled={!canChat}
              leftIcon={<FaComment />}
              variant="ghost"
            >
              Kontakto avokatin
            </Button>
          </NavLink>
        </HStack>
        <Spacer />
        <Menu>
          <MenuButton disabled={!canCancel || !canClose}>
            <Button
              leftIcon={<FaEdit />}
              variant="ghost"
              style={{ color: 'gray.600' }}
              disabled={!canCancel || !canClose}
            >
              Statusi i çështjes
            </Button>
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<FaCheck />}
              isDisabled={!canCancel || submitting}
              onClick={handleCloseSuccessfully}
            >
              Përfundo
            </MenuItem>
            <MenuItem
              icon={<FaTrash />}
              isDisabled={!canClose || submitting}
              onClick={onOpen}
            >
              Anulo çështjen
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
};

export const LawyerIssueNavigation = ({
  id,
  title,
  canChat,
  canCancel,
  canClose,
  canBid,
}: IssueNavigationProps) => {
  const {
    isOpen: isSetBidModalOpen,
    onOpen: onSetBidModalOpen,
    onClose: onSetBidModalClose,
  } = useDisclosure();
  const {
    isOpen: isSetReasonModalOpen,
    onOpen: onSetReasonModalOpen,
    onClose: onSetReasonModalClose,
  } = useDisclosure();

  const toast = useToast();
  const [reason, setReason] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [closeSuccessfully] = useCloseIssueSuccessfullyByLawyerMutation();
  const [closeUnsuccessfully] = useCloseIssueUnsuccessfullyByLawyerMutation();

  const handleCloseSuccessfully = async () => {
    setSubmitting(true);
    try {
      await closeSuccessfully({
        variables: {
          closeIssueSuccessfullyByLawyerId: Number(id),
        },
        refetchQueries: [
          {
            query: GetLawyerIssueByIdDocument,
            variables: {
              getLawyerIssueByIdId: Number(id),
            },
          },
        ],
      });
      toast({
        title: 'Sukses',
        description: 'Çështja u mbyll me sukses',
        status: 'success',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseUnsuccessfully = async () => {
    setSubmitting(true);
    try {
      await closeUnsuccessfully({
        variables: {
          closeIssueUnsuccessfullyByLawyerId: Number(id),
          reason,
        },
        refetchQueries: [
          {
            query: GetLawyerIssueByIdDocument,
            variables: {
              getLawyerIssueByIdId: Number(id),
            },
          },
        ],
      });
      toast({
        title: 'Sukses',
        description: 'Çështja u mbyll me sukses',
        status: 'success',
      });
      onSetReasonModalClose();
      setReason('');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {/** Give a reason to close issue */}
      <Modal
        isCentered={true}
        size="md"
        isOpen={isSetReasonModalOpen}
        onClose={onSetReasonModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Jepni një arsye:</Text>
          </ModalHeader>
          <ModalBody>
            <Textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            ></Textarea>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={submitting}
              isLoading={submitting}
              onClick={handleCloseUnsuccessfully}
            >
              Konfirmo
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <CreateBidModal
        isOpen={isSetBidModalOpen}
        onClose={onSetBidModalClose}
        id={id}
        title={title}
        refresh={() => {}}
      />

      <CardHeader title={title} action={null}></CardHeader>

      <HStack>
        <HStack divider={<StackDivider />}>
          <NavLink
            exact={true}
            to="/issues/mine"
            style={{ color: 'gray.600' }}
            activeStyle={{ color: 'red' }}
          >
            <Button leftIcon={<AiOutlineLeft />} variant="ghost">
              Lista e çështjeve
            </Button>
          </NavLink>
          <NavLink
            exact={true}
            to={`/issues/details/${id}`}
            style={{ color: 'gray.600' }}
            activeStyle={{ color: 'red' }}
          >
            <Button leftIcon={<FaInfoCircle />} variant="ghost">
              Detajet e çështjes
            </Button>
          </NavLink>
          <NavLink
            exact={true}
            to={`/issues/details/${id}/chat`}
            style={{ color: 'gray.600' }}
            activeStyle={{ color: 'red' }}
          >
            <Button
              disabled={!canChat}
              leftIcon={<FaComment />}
              variant="ghost"
            >
              Kontakto klientin
            </Button>
          </NavLink>
        </HStack>
        <Spacer />

        <HStack>
          {canBid && (
            <Button
              onClick={onSetBidModalOpen}
              leftIcon={<FaPlus />}
              variant="ghost"
              style={{ color: 'gray.600' }}
              disabled={!canBid}
            >
              Dërgo ofertë
            </Button>
          )}
          {canCancel ||
            (canClose && (
              <Menu>
                <MenuButton disabled={!canCancel || !canClose}>
                  <Button
                    leftIcon={<FaEdit />}
                    variant="ghost"
                    style={{ color: 'gray.600' }}
                    disabled={!canCancel || !canClose}
                  >
                    Statusi i çështjes
                  </Button>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    icon={<FaCheck />}
                    isDisabled={!canCancel || submitting}
                    onClick={handleCloseSuccessfully}
                  >
                    Përfundo
                  </MenuItem>
                  <MenuItem
                    icon={<FaTrash />}
                    isDisabled={!canClose || submitting}
                    onClick={onSetReasonModalOpen}
                  >
                    Anulo çështjen
                  </MenuItem>
                </MenuList>
              </Menu>
            ))}
        </HStack>
      </HStack>
    </>
  );
};
