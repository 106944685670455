import { useAuthToken } from './useAuthToken';
import { useLoginMutation, useMeLazyQuery } from '../graphql';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetPassword } from '.';

export const useLogin = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const { setAuthToken } = useAuthToken();
  const { removeResetPasswordEmail } = useResetPassword();

  const [getMe, { data }] = useMeLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.me?.id) {
      const params = new URLSearchParams(window.location.search);
      const redirectUrl = params.get('redirect') || '/home';
      localStorage.setItem('user-language', data.me.language.code);
      i18n.changeLanguage(data.me.language.code);
      history.push(redirectUrl);
    }
  }, [data, history, i18n]);

  const [mutation, mutationResults] = useLoginMutation({
    onCompleted: (data) => {
      setAuthToken(data.login.token as string);
      getMe();
    },
  });

  //we have rewritten the function to have a cleaner interface
  const login = async (email: string, password: string) => {
    removeResetPasswordEmail();
    return mutation({
      variables: {
        email,
        password,
      },
    });
  };

  return [login, mutationResults];
};
