import { Box, Stack, HStack } from '@chakra-ui/layout';
import React from 'react';
import { Loader } from '../../../components/Loader';
import { TablePagination } from '../../../components/Pagination';
import { CardHeader } from '../../../components/Card';
import { LawyerIssueCard } from '../../../components/LawyerIssueCard';
import { useIssuesThatLawyerHasBid } from '../../../hooks/useIssuesThatLawyerHasBid';
import {
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from '@chakra-ui/react';
import { BillingStatus } from '../../../graphql';
import { BsSearch } from 'react-icons/bs';
import { NoticeNoResults } from '../../../components/Notice';
import { MdOutlinePeopleOutline } from 'react-icons/md';

const MyIssues: React.FC = () => {
  const {
    loading,
    items,
    getNextPage,
    totalCount,
    pageInfo,
    getPreviousPage,
    showEmptyResults,
    billingStatus,
    setBillingStatus,
    search,
    setSearch,
  } = useIssuesThatLawyerHasBid();

  return (
    <Box>
      <CardHeader
        title="Çështjet e mia"
        action={
          <Box className="issue-filters">
            <Stack
              spacing="4"
              direction={{ base: 'column', md: 'row' }}
              justify="space-between"
            >
              <HStack>
                <FormControl minW={{ md: '320px' }} id="search">
                  <InputGroup size="sm">
                    <InputLeftElement pointerEvents="none" color="gray.400">
                      <BsSearch />
                    </InputLeftElement>
                    <Input
                      onChange={(e) => setSearch(e.target.value)}
                      value={search || ''}
                      placeholder="Kërko"
                      type="search"
                    />
                  </InputGroup>
                </FormControl>

                <Select
                  size={'sm'}
                  padding={1}
                  value={billingStatus || undefined}
                  onChange={(e) =>
                    setBillingStatus(e.target.value as BillingStatus)
                  }
                  placeholder="Zgjidhni Statusin e faturimit"
                >
                  <option value={BillingStatus.Billed}>Faturuar</option>
                  <option value={BillingStatus.BilledButNotPaid}>
                    Faturuar, pa paguar
                  </option>
                  <option value={BillingStatus.NotBilled}>Pa faturuar</option>
                  <option value={BillingStatus.Paid}>Paguar</option>
                </Select>
              </HStack>
            </Stack>
          </Box>
        }
      ></CardHeader>

      {loading ? (
        <Loader />
      ) : showEmptyResults && !loading && items.length === 0 ? (
        <NoticeNoResults
          icon={MdOutlinePeopleOutline}
          title="Nuk keni asnjë çështje aktive"
          description="Momentalisht ju nuk keni asnjë çështje/ kërkesë të hapur nga klientët."
        />
      ) : (
        items.map((item: any) => <LawyerIssueCard key={item.id} issue={item} />)
      )}

      {pageInfo && (
        <TablePagination
          total={totalCount}
          pageInfo={pageInfo}
          onNextPage={getNextPage}
          onPreviousPage={getPreviousPage}
        />
      )}
    </Box>
  );
};

export default MyIssues;
