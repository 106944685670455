import React from 'react';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { Field, Form } from 'react-final-form';
import {
  Optional,
  InputControl,
  TextareaControl,
  AdaptedSelect,
} from '../components/Form';
import { SubscriptionPeriod } from '../graphql';

export interface ICreateSubscriptionValues {
  name: string;
  price: number;
  description: string;
  period: SubscriptionPeriod;
}

interface ISubscriptionForm {
  onSubmit: (values: ICreateSubscriptionValues) => void;
  initialValues?: Optional<ICreateSubscriptionValues>;
}

export const SubscriptionForm: React.FC<ISubscriptionForm> = ({
  onSubmit,
  initialValues,
}) => {
  const validate = (values: ICreateSubscriptionValues) => {
    const errors: { [key: string]: string } = {};

    if (!values.name) {
      errors.name = 'Fushë e detyruar';
    }

    if (!values.description) {
      errors.description = 'Fushë e detyruar';
    }

    if (!values.price) {
      errors.price = 'Fushë e detyruar';
    }

    if (values.price <= 0) {
      errors.price = 'Duhet të jetë numër pozitiv';
    }

    if (!values.period) {
      errors.period = 'Fushë e detyruar';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <Box as="form" onSubmit={handleSubmit}>
          <InputControl name="name" label="Emri" type="text" />
          <TextareaControl
            name="description"
            label="Përshkrimi"
            placeholder={''}
          />
          <InputControl name="price" label="Çmimi" type="number" />
          <Field name="period" component={AdaptedSelect} label="Kohëzgjatja">
            <option value={SubscriptionPeriod.Monthly}>Abonim Mujor</option>
            <option value={SubscriptionPeriod.Yearly}>Abonim Vjetor</option>
          </Field>

          <ButtonGroup py="6" spacing={4}>
            <Button
              isLoading={submitting}
              loadingText="Duke dërguar"
              variantColor="teal"
              type="submit"
            >
              Ruaj
            </Button>
            <Button
              variantColor="teal"
              variant="outline"
              // @ts-ignore
              onClick={form.reset}
              isDisabled={submitting || pristine}
            >
              Pastro
            </Button>
          </ButtonGroup>
        </Box>
      )}
    />
  );
};
