import React, { createContext } from 'react';

const initialState = {
  unreadNotificationCount: 0,
};

export const GlobalStore = ({ children }: any) => {
  // const { data } = useGetUnreadNotificationCountQuery();

  return (
    <GlobalContext.Provider value={{ unreadNotificationCount: 0 }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalContext = createContext(initialState);
