import { Box, useColorModeValue, Heading } from '@chakra-ui/react';
import React from 'react';
import { Card } from '../../components/Card';
import { Logo } from '../../components/Logo';
import { useAuthToken } from '../../hooks';
import { useLogout } from '../../hooks/useLogout';
import { ActivateAccountForm } from './ActivateAccountForm';
import { Text, Button } from '@chakra-ui/react';

const ActivateAccountPage: React.FC = ({ children }) => {
  const { user } = useAuthToken();
  const logout = useLogout();
  const [logoutLoading, setLogoutLoading] = React.useState(false);

  const handleLogout = async () => {
    try {
      setLogoutLoading(true);
      await logout();
    } catch (error) {
      console.error(error);
    } finally {
      setLogoutLoading(false);
    }
  };

  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo mx="auto" h="12" mb={{ base: '10', md: '20' }} />
        <Heading textAlign="center" size="xl" mb="4" fontWeight="extrabold">
          Verifiko llogarinë
        </Heading>

        <Text as="span" fontSize={'sm'} align="center" mb="12">
          <Text color={'legit'}>
            Ju lutem, përdorni kodin e dërguar <br /> në adresën tuaj të
            e-mailit për të verifikuar llogarinë.
          </Text>
        </Text>

        <Text mt="4" mb="8" align="center" maxW="md" fontSize={'sm'}>
          <Text as="span">
            Jeni i identifikuar me llogarinë:
            <Text color={'legit'} fontWeight="bold">
              {user?.email || ''}
            </Text>
          </Text>
          <br />
          {/* <Text as="span">Dëshironi të dilni? </Text> */}
          <Button
            ml={2}
            isLoading={logoutLoading}
            colorScheme="red"
            variant="link"
            onClick={handleLogout}
          >
            Dil nga kjo llogari
          </Button>
        </Text>
        <Card>
          <ActivateAccountForm localStorageUser={user} />
        </Card>
      </Box>
    </Box>
  );
};

export default ActivateAccountPage;
