import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { CardHeader } from '../../../components/Card/CardHeader';
import { Loader } from '../../../components/Loader';
import {
  DocumentTemplate,
  useGetAllDocumentTemplatesQuery,
} from '../../../graphql';
import { UpdateDocumentTemplateModal } from '../../../modals/UpdateDocumentTemplateModal';
import { IColumn, SimpleTable } from '../../../utils/table';

export const DocumentTemplatesPage = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data, loading } = useGetAllDocumentTemplatesQuery({});
  const [selectedTemplate, setSelectedTemplate] = useState<DocumentTemplate>();

  const columns: IColumn[] = [
    {
      Header: 'Titulli',
      accessor: 'title',
      tdParams: {
        width: '15%',
      },
    },
    {
      Header: 'Kategoria',
      accessor: 'category.name',
      tdParams: {
        width: '15%',
      },
    },
    {
      Header: 'Versioni',
      accessor: 'versionNumber',
      tdParams: {
        width: '15%',
        textAlign: 'center',
      },
    },
    {
      Header: 'Data e krijimit',
      accessor: '',
      tdParams: {
        width: '20%',
      },
      Cell: function dateCell(cell: any, row: DocumentTemplate) {
        return <Text>{moment(row.createdAt).format('DD/MM/YYYY')}</Text>;
      },
    },
    {
      Header: 'Data e modifikimit',
      accessor: '',
      tdParams: {
        width: '20%',
      },
      Cell: function dateCell(cell: any, row: DocumentTemplate) {
        return <Text>{moment(row.updatedAt).format('DD/MM/YYYY')}</Text>;
      },
    },
    {
      Header: '',
      accessor: '',
      tdParams: {
        width: '20%',
      },
      Cell: function DetailsCell(cell: any, row: DocumentTemplate) {
        return (
          <Button
            onClick={() => {
              setSelectedTemplate(row);
              onOpen();
            }}
          >
            Rifresko
          </Button>
        );
      },
    },
    {
      Header: 'Aktiv',
      accessor: '',
      Cell: function PaymentCell(cell: any, row: DocumentTemplate) {
        return row.active ? (
          <FaCheckCircle color="green"></FaCheckCircle>
        ) : (
          <Box></Box>
        );
      },
      tdParams: {
        width: '10%',
      },
    },
  ];

  return (
    <Box>
      <CardHeader title="Lista e Dokumenteve" />

      {loading ? (
        <Loader />
      ) : (
        <SimpleTable
          columns={columns}
          data={data?.getAllDocumentTemplates || []}
        />
      )}

      <UpdateDocumentTemplateModal
        isOpen={isOpen}
        onClose={onClose}
        template={selectedTemplate!}
      />
    </Box>
  );
};
