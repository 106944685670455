import * as React from 'react';
import { Box, Heading, useColorModeValue, Text } from '@chakra-ui/react';
import { Card } from '../../components/Card';
import { RequestPasswordResetForm } from './RequestPasswordResetForm';
import { Logo } from '../../components/Logo';

const RequestPasswordResetPage: React.FC = () => {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo mx="auto" h="12" mb={{ base: '10', md: '20' }} />
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          Dërgo kodin e konfirmimit
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Vendosni adresën e-mail me të cilën jeni regjistruar në mënyrë që të mund t'iu dërgojmë kodin e verifikimit.</Text>
          {/* <Link href="/contact">Na kontaktoni këtu</Link> */}
        </Text>

        <Card>
          <Box my={4} textAlign="left">
            <RequestPasswordResetForm />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default RequestPasswordResetPage;
