import { Box, BoxProps, Circle, Collapse, Heading, HStack, Icon, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { FaCheck } from 'react-icons/fa'
import { useStep } from './useStep'

interface StepProps extends BoxProps {
  title: string;
  onClick: () => void;
  children: React.ReactElement
}

export const Step = (props: StepProps) => {
  const { title, children, onClick, ...boxProps } = props;
  const { isActive, isCompleted, step } = useStep();
  const accentColor = useColorModeValue('red.500', 'red.300');
  const mutedColor = useColorModeValue('gray.600', 'whiteAlpha.800');
  const activeColor = useColorModeValue('white', 'black');
  return (
    <Box {...boxProps}>
      <HStack spacing="4" onClick={onClick} style={{cursor: "pointer"}}>
        <Circle
          size="8"
          fontWeight="bold"
          color={
            isActive ? activeColor : isCompleted ? accentColor : mutedColor
          }
          bg={isActive ? accentColor : 'transparent'}
          borderColor={isCompleted ? accentColor : 'inherit'}
          borderWidth={isActive ? '0px' : '1px'}
        >
          {isCompleted ? <Icon as={FaCheck} /> : step}
        </Circle>
        <Heading
          fontSize="lg"
          fontWeight="semibold"
          color={isActive || isCompleted ? 'inherit' : mutedColor}
        >
          {title}
        </Heading>
      </HStack>
      <Collapse in={isActive}>{children}</Collapse>
    </Box>
  );
};
