import { Button, ButtonGroup } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Center } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Spinner } from '@chakra-ui/spinner';
import React, { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { CardHeader } from '../../../components/Card';
import {
  PositionForm,
  ICreatePositionValues,
} from '../../../forms/PositionForm';
import {
  PositionsDocument,
  Position,
  usePositionsQuery,
  useCreatePositionMutation,
  useDeletePositionMutation,
  useUpdatePositionMutation,
} from '../../../graphql';
import { IColumn, SimpleTable } from '../../../utils/table';

export const PositionsPage = () => {
  const [currentItem, setCurrentItem] = useState<Position | any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, loading } = usePositionsQuery();
  const [create] = useCreatePositionMutation();
  const [update] = useUpdatePositionMutation();
  const [deleteItem] = useDeletePositionMutation();

  const handleCreatEvent = async (data: ICreatePositionValues) => {
    currentItem
      ? await update({
          variables: {
            id: currentItem.id,
            data: data,
          },
          refetchQueries: [
            {
              query: PositionsDocument,
            },
          ],
        })
      : await create({
          variables: {
            data: data,
          },
          refetchQueries: [
            {
              query: PositionsDocument,
            },
          ],
        });

    onClose();

    setCurrentItem(null);
  };

  const handleDeleteEvent = async (id: number) => {
    await deleteItem({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: PositionsDocument,
        },
      ],
    });

    onClose();
  };

  if (loading) {
    return (
      <Center>
        <Spinner></Spinner>
      </Center>
    );
  }

  const columns: IColumn[] = [
    {
      Header: 'Pozicioni',
      accessor: 'name',
      tdParams: {
        width: '100%',
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function EditCell(cell: any, row: Position) {
        return (
          <Button
            variant="link"
            colorScheme="blue"
            onClick={() => {
              setCurrentItem(row);
              onOpen();
            }}
            size="sm"
          >
            Redakto
          </Button>
        );
      },
      tdParams: {
        textAlign: 'right',
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function DeleteCell(cell: any, row: Position) {
        return (
          <Button
            variant="link"
            colorScheme="red"
            size="sm"
            onClick={() => handleDeleteEvent(row.id)}
          >
            Fshi
          </Button>
        );
      },
      tdParams: {
        textAlign: 'right',
      },
    },
  ];

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <CardHeader
              title="Shto një pozicion"
              action={<ModalCloseButton />}
            ></CardHeader>
          </ModalHeader>
          <ModalBody>
            <PositionForm
              onSubmit={(data) => handleCreatEvent(data)}
              initialValues={{
                name: currentItem?.rawName
                  ? currentItem.rawName
                  : { sq: '', en: '' },
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <CardHeader
        title="Lista e pozicioneve"
        action={
          <ButtonGroup size="sm" variant="outline">
            <Button
              iconSpacing="1"
              leftIcon={<HiPlus fontSize="1.25em" />}
              onClick={onOpen}
            >
              Shto
            </Button>
          </ButtonGroup>
        }
      ></CardHeader>

      <SimpleTable columns={columns} data={data?.positions!} />
    </>
  );
};
