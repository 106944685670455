import { Button, ButtonGroup } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Input } from '@chakra-ui/react';
import { HiPlus } from 'react-icons/hi';
import { CardHeader } from '../../../components/Card';
import { IssueItemWithBids } from '../../../components/ClientIssueCard';
import { Loader } from '../../../components/Loader';
import { TablePagination } from '../../../components/Pagination';
import { useClientIssues } from '../../../hooks/useClientIssues';
import { CreateIssueModal } from '../../../modals/CreateIssueModal';
import { NoIssueList } from './empty';
import {
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/react';
import { BsSearch } from 'react-icons/bs';

export const ContactLawyerPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    loading,
    items,
    getNextPage,
    getPreviousPage,
    showEmptyResults,
    pageInfo,
    totalCount,
    refetch,
    search,
    setSearch,
  } = useClientIssues();

  return (
    <>
      <CardHeader
        title="Lista e çështjeve"
        action={
          <Box className="issue-filters">
            <Stack
              spacing="4"
              direction={{ base: 'column', md: 'row' }}
              justify="space-between"
            >
              <HStack>
                <FormControl minW={{ md: '320px' }} id="search">
                  <InputGroup size="sm">
                    <FormLabel srOnly>Filter by name or email</FormLabel>
                    <InputLeftElement pointerEvents="none" color="gray.400">
                      <BsSearch />
                    </InputLeftElement>
                    <Input
                      onChange={(e) => setSearch(e.target.value)}
                      value={search || ''}
                      placeholder="Kërko"
                      type="search"
                    />
                  </InputGroup>
                </FormControl>
              </HStack>
              <ButtonGroup size="sm" variant="outline">
                <Button
                  iconSpacing="1"
                  leftIcon={<HiPlus fontSize="1.25em" />}
                  onClick={onOpen}
                >
                  Hap çështje
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
        }
      ></CardHeader>

      {loading ? (
        <Loader />
      ) : showEmptyResults && !loading && items.length === 0 ? (
        <NoIssueList onClick={onOpen} />
      ) : (
        <Box>
          <Stack
            divider={<Box style={{ height: '10px', border: 'none' }}></Box>}
          >
            {items.map((issue: any) => (
              <IssueItemWithBids key={issue.id} issue={issue} />
            ))}
          </Stack>
          <TablePagination
            total={totalCount}
            pageInfo={pageInfo as any}
            onNextPage={getNextPage}
            onPreviousPage={getPreviousPage}
          />
        </Box>
      )}

      <CreateIssueModal isOpen={isOpen} onClose={onClose} refresh={refetch} />
    </>
  );
};
