import { Box } from '@chakra-ui/layout';
import * as React from 'react';
import { useParams } from 'react-router';
import { Loader } from '../../../components/Loader';
import { useGetBidsForIssueQuery } from '../../../graphql';

const IssueBidsPage: React.FC = () => {
  //@ts-ignore
  const { id } = useParams();
  const { data, loading } = useGetBidsForIssueQuery({
    variables: { issueId: Number(id) },
  });

  if (loading) {
    return <Loader />;
  }

  return <Box>{JSON.stringify(data)}</Box>;
};

export default IssueBidsPage;
