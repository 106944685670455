import React, { useMemo, useState } from 'react';
import { Box, Button, ButtonGroup, Flex, Icon } from '@chakra-ui/react';
import { Field, Form } from 'react-final-form';
import {
  Optional,
  MultiLanguageInputControl,
  AdaptedSelect,
} from '../components/Form';
import * as FontAwesomeIcons from 'react-icons/fa/index';

interface MultiLangString {
  sq: string;
  en: string;
}
export interface ICreateDocumentCategoryValues {
  name: MultiLangString;
  icon: keyof typeof FontAwesomeIcons;
}

interface IDocumentCategoryForm {
  onSubmit: (values: ICreateDocumentCategoryValues) => void;
  initialValues?: Optional<ICreateDocumentCategoryValues>;
}

export const DocumentCategoryForm: React.FC<IDocumentCategoryForm> = ({
  onSubmit,
  initialValues,
}) => {
  const icons = useMemo(() => {
    return Object.keys(FontAwesomeIcons).filter((key) => key.startsWith('Fa'));
  }, []);
  const [selectedIcon, setSelectedIcon] = useState<
    keyof typeof FontAwesomeIcons | null
  >(null);
  const validate = (values: ICreateDocumentCategoryValues) => {
    const errors: { [key: string]: string } = {};

    if (!values.name) {
      errors.name = 'Fushë e detyruar';
    }

    if (!values.name?.sq || !values.name?.en) {
      errors.name = 'Fushë e detyruar';
    }

    if (!values.icon) {
      errors.icon = 'Fushë e detyruar';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        errors,
        submitting,
        pristine,
        values,
      }) => (
        <Box as="form" onSubmit={handleSubmit}>
          <MultiLanguageInputControl name="name" label="Emri" />
          <Flex justifyContent="center" alignItems="center">
            <Field
              component={AdaptedSelect}
              name="icon"
              label="Ikona"
              onChange={(e: any) => setSelectedIcon(e.target.value)}
            >
              {icons.map((icon) => (
                <option key={icon} value={icon}>
                  {icon}
                </option>
              ))}
            </Field>
            {selectedIcon && (
              <Box justifySelf="center" padding="5" marginTop="5">
                <Icon
                  as={FontAwesomeIcons[selectedIcon]}
                  color="GrayText"
                  fontSize="xl"
                  marginTop="2.5"
                />
              </Box>
            )}
          </Flex>

          <ButtonGroup py="6" spacing={4}>
            <Button
              isLoading={submitting}
              loadingText="Duke dërguar"
              variantColor="teal"
              type="submit"
            >
              Ruaj
            </Button>
            <Button
              variantColor="teal"
              variant="outline"
              // @ts-ignore
              onClick={form.reset}
              isDisabled={submitting || pristine}
            >
              Pastro
            </Button>
          </ButtonGroup>
        </Box>
      )}
    />
  );
};
