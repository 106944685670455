import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  amount_Int_NotNull_exclusiveMin_0: any;
  email_String_NotNull_format_email: any;
  newPasswordRepeat_String_NotNull_minLength_8: any;
  newPassword_String_NotNull_minLength_8: any;
  oldPassword_String_NotNull_minLength_8: any;
  password_String_NotNull_minLength_8: any;
  phoneNumber_String_NotNull_pattern_3556098: any;
  phoneNumber_String_pattern_3556098: any;
  price_Float_NotNull_exclusiveMin_0: any;
  price_Float_NotNull_min_0: any;
};

export type Account = {
  __typename?: 'Account';
  accountStatus: AccountStatus;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['Int'];
  language: Language;
  phoneNumber?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  role: AccountRole;
};

export type AccountQuery = {
  id: Scalars['Int'];
};

export enum AccountRole {
  Admin = 'ADMIN',
  Client = 'CLIENT',
  Lawyer = 'LAWYER'
}

export type AccountShortData = {
  __typename?: 'AccountShortData';
  accountStatus?: Maybe<AccountStatus>;
  email: Scalars['String'];
  id: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['String']>;
  role: AccountRole;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  PendingAdminVerification = 'PENDING_ADMIN_VERIFICATION',
  Unconfirmed = 'UNCONFIRMED'
}

export enum AccountType {
  AdminProfile = 'AdminProfile',
  ClientProfile = 'ClientProfile',
  LawyerProfile = 'LawyerProfile'
}

export type AdminIssue = {
  __typename?: 'AdminIssue';
  accountId: Scalars['Int'];
  bids: Array<Bid>;
  city?: Maybe<City>;
  cityId: Scalars['Int'];
  country?: Maybe<Country>;
  countryId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deadline: Scalars['Date'];
  description: Scalars['String'];
  id: Scalars['Int'];
  issuer: Account;
  speciality?: Maybe<Speciality>;
  specialityId: Scalars['Int'];
  status: IssueStatus;
  title: Scalars['String'];
  totalBids: Scalars['Int'];
};

export type AdminIssueById = {
  __typename?: 'AdminIssueById';
  issue: AdminIssue;
  statusChanges: Array<IssueStatusChange>;
};

export type AdminProfile = {
  __typename?: 'AdminProfile';
  id: Scalars['Int'];
  lastname: Scalars['String'];
  name: Scalars['String'];
  profilePicture?: Maybe<ProfilePicture>;
  profilePictureRaw?: Maybe<Media>;
  type: AccountType;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token?: Maybe<Scalars['String']>;
};

export type BankCredentials = {
  __typename?: 'BankCredentials';
  address: Scalars['String'];
  bank: Scalars['String'];
  business_entity: Scalars['String'];
  iban: Scalars['String'];
  telephone: Scalars['String'];
  title: Scalars['String'];
};

export type Bid = {
  __typename?: 'Bid';
  amount: Scalars['Int'];
  bidder: Account;
  bidderId: Scalars['Int'];
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  id: Scalars['Int'];
  issueId: Scalars['Int'];
  title: Scalars['String'];
};

export type BidCreatePayload = {
  amount: Scalars['amount_Int_NotNull_exclusiveMin_0'];
  description: Scalars['String'];
  issueId: Scalars['Int'];
  title: Scalars['String'];
};

export enum BillingStatus {
  Billed = 'BILLED',
  BilledButNotPaid = 'BILLED_BUT_NOT_PAID',
  NotBilled = 'NOT_BILLED',
  Paid = 'PAID'
}

export type ChatInfo = {
  __typename?: 'ChatInfo';
  recipient: Account;
  threadId: Scalars['String'];
};

export type City = {
  __typename?: 'City';
  country?: Maybe<Country>;
  id: Scalars['Int'];
  name: Scalars['String'];
  rawName?: Maybe<MultiLangStringType>;
};

export type ClientInvoicePaginatedRequest = {
  month?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  pageLimit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type ClientIssue = {
  __typename?: 'ClientIssue';
  acceptedBid?: Maybe<Bid>;
  acceptedBidId?: Maybe<Scalars['Int']>;
  bids: Array<Bid>;
  canAcceptBid: Scalars['Boolean'];
  canChat: Scalars['Boolean'];
  canCloseSuccessfully: Scalars['Boolean'];
  canCloseUnsuccessfully: Scalars['Boolean'];
  city?: Maybe<City>;
  cityId: Scalars['Int'];
  country?: Maybe<Country>;
  countryId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deadline: Scalars['Date'];
  description: Scalars['String'];
  id: Scalars['Int'];
  speciality?: Maybe<Speciality>;
  specialityId: Scalars['Int'];
  status: IssueStatus;
  title: Scalars['String'];
  totalBids: Scalars['Int'];
};

export type ClientProfile = {
  __typename?: 'ClientProfile';
  birthday: Scalars['Date'];
  city: City;
  clientType: ClientType;
  country: Country;
  id: Scalars['Int'];
  lastname: Scalars['String'];
  name: Scalars['String'];
  profilePicture?: Maybe<ProfilePicture>;
  profilePictureRaw?: Maybe<Media>;
  sex: Gender;
  type: AccountType;
};

export type ClientSignupPayload = {
  birthday: Scalars['Date'];
  cityId: Scalars['Int'];
  clientType: ClientType;
  countryId: Scalars['Int'];
  email: Scalars['email_String_NotNull_format_email'];
  languageCode: LanguageCode;
  lastname: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['password_String_NotNull_minLength_8'];
  phoneNumber: Scalars['phoneNumber_String_NotNull_pattern_3556098'];
  privacyPolicy?: InputMaybe<Scalars['Boolean']>;
  profilePhoto?: InputMaybe<MediaFile>;
  sex: Gender;
  termsOfUse?: InputMaybe<Scalars['Boolean']>;
};

export enum ClientType {
  Business = 'BUSINESS',
  Individual = 'INDIVIDUAL'
}

export type ClientTypeValue = {
  __typename?: 'ClientTypeValue';
  name: Scalars['String'];
  value: ClientType;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['Int'];
  name: Scalars['String'];
  rawName?: Maybe<MultiLangStringType>;
};

export type CreateEmptyDocumentInput = {
  templateId: Scalars['Int'];
  title: Scalars['String'];
};

export type CreateUpdateCityPayload = {
  countryId: Scalars['Int'];
  name: MultiLangString;
};

export type CreateUpdateCountryPayload = {
  name: MultiLangString;
};

export type CreateUpdateDocumentCategoryPayload = {
  icon: Scalars['String'];
  name: MultiLangString;
};

export type CreateUpdatePositionPayload = {
  name: MultiLangString;
};

export type CreateUpdateSpecialityPayload = {
  name: MultiLangString;
};

export type CreateUpdateSubscriptionPayload = {
  description: Scalars['String'];
  name: Scalars['String'];
  period: SubscriptionPeriod;
  price: Scalars['price_Float_NotNull_exclusiveMin_0'];
};

export type Document = {
  __typename?: 'Document';
  activeStep: Scalars['Int'];
  blocks: Scalars['JSONObject'];
  canPay: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  info: Scalars['JSONObject'];
  invoice?: Maybe<Invoice>;
  previewUrl: Scalars['String'];
  price: Scalars['Float'];
  status: DocumentStatus;
  template: DocumentTemplate;
  templateVersionNumber: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  variables: Scalars['JSONObject'];
};

export type DocumentCategory = {
  __typename?: 'DocumentCategory';
  country?: Maybe<Country>;
  icon: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  rawName?: Maybe<MultiLangStringType>;
};

export type DocumentRequest = {
  page: Scalars['Int'];
  pageLimit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
};

export enum DocumentStatus {
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  WaitingPayment = 'WAITING_PAYMENT'
}

export type DocumentTemplate = {
  __typename?: 'DocumentTemplate';
  active: Scalars['Boolean'];
  blocks: Scalars['JSONObject'];
  category?: Maybe<DocumentCategory>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['Int'];
  info: Scalars['JSONObject'];
  price: Scalars['Float'];
  template: Scalars['JSONObject'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionNumber: Scalars['Int'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Invoice = {
  __typename?: 'Invoice';
  account: Account;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  invoiceDetails: Array<InvoiceDetails>;
  month: Scalars['Int'];
  payments: Array<Payment>;
  payseraUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  total: Scalars['Float'];
  year: Scalars['Int'];
};

export type InvoiceDetails = {
  __typename?: 'InvoiceDetails';
  account: Account;
  createdAt: Scalars['DateTime'];
  invoice: Invoice;
  item: InvoiceItem;
  price: Scalars['Float'];
  type: InvoiceItemType;
};

export type InvoiceItem = AdminIssue | Document | LawyerIssue | UserSubscription;

export enum InvoiceItemType {
  Document = 'DOCUMENT',
  Issue = 'ISSUE',
  Subscription = 'SUBSCRIPTION'
}

export type InvoicePaginatedRequest = {
  accountId?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  pageLimit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type InvoicePaginatedRequestForLawyer = {
  month?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  pageLimit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type Issue = AdminIssue | ClientIssue | LawyerIssue;

export type IssueCreatePayload = {
  cityId: Scalars['Int'];
  countryId: Scalars['Int'];
  deadline: Scalars['Date'];
  description: Scalars['String'];
  specialityId: Scalars['Int'];
  title: Scalars['String'];
};

export type IssuePaginatedRequest = {
  billingStatus?: InputMaybe<BillingStatus>;
  endDate?: InputMaybe<Scalars['Date']>;
  page: Scalars['Int'];
  pageLimit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Scalars['String']>;
};

export enum IssueStatus {
  ChooseOfferStage = 'CHOOSE_OFFER_STAGE',
  ClosedSuccessfully = 'CLOSED_SUCCESSFULLY',
  ClosedUnsuccessfully = 'CLOSED_UNSUCCESSFULLY',
  CooperationStage = 'COOPERATION_STAGE',
  InReview = 'IN_REVIEW',
  RejectedByAdmin = 'REJECTED_BY_ADMIN',
  WaitingOffers = 'WAITING_OFFERS'
}

export type IssueStatusChange = {
  __typename?: 'IssueStatusChange';
  changedBy: Account;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  reason: Scalars['String'];
  status: IssueStatus;
};

export type Language = {
  __typename?: 'Language';
  code: LanguageCode;
  name: Scalars['String'];
};

export enum LanguageCode {
  En = 'en',
  Sq = 'sq'
}

export type LawyerIssue = {
  __typename?: 'LawyerIssue';
  accountId: Scalars['Int'];
  canBid: Scalars['Boolean'];
  canChat: Scalars['Boolean'];
  canCloseSuccessfully: Scalars['Boolean'];
  canCloseUnsuccessfully: Scalars['Boolean'];
  city?: Maybe<City>;
  cityId: Scalars['Int'];
  country?: Maybe<Country>;
  countryId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deadline: Scalars['Date'];
  description: Scalars['String'];
  hasBid: Scalars['Boolean'];
  id: Scalars['Int'];
  issuer: Account;
  myBid?: Maybe<Bid>;
  speciality?: Maybe<Speciality>;
  specialityId: Scalars['Int'];
  status: IssueStatus;
  title: Scalars['String'];
};

export type LawyerProfile = {
  __typename?: 'LawyerProfile';
  birthday: Scalars['Date'];
  city: City;
  country: Country;
  cvRaw: Media;
  cvUrl: Scalars['String'];
  diplomaRaw: Media;
  diplomaUrl: Scalars['String'];
  id: Scalars['Int'];
  lastname: Scalars['String'];
  licenseRaw: Media;
  licenseUrl: Scalars['String'];
  name: Scalars['String'];
  payseraEmail?: Maybe<Scalars['String']>;
  positions: Array<Position>;
  profilePicture?: Maybe<ProfilePicture>;
  profilePictureRaw?: Maybe<Media>;
  sex: Gender;
  specialities: Array<Speciality>;
  type: AccountType;
};

export type LawyerSignupPayload = {
  birthday: Scalars['Date'];
  cityId: Scalars['Int'];
  countryId: Scalars['Int'];
  cv: MediaFile;
  diploma: MediaFile;
  email: Scalars['email_String_NotNull_format_email'];
  languageCode: LanguageCode;
  lastname: Scalars['String'];
  license: MediaFile;
  name: Scalars['String'];
  password: Scalars['password_String_NotNull_minLength_8'];
  phoneNumber: Scalars['phoneNumber_String_NotNull_pattern_3556098'];
  positioinIDs?: InputMaybe<Array<Scalars['Int']>>;
  privacyPolicy?: InputMaybe<Scalars['Boolean']>;
  profilePhoto?: InputMaybe<MediaFile>;
  sex: Gender;
  specialityIDs?: InputMaybe<Array<Scalars['Int']>>;
  termsOfUse?: InputMaybe<Scalars['Boolean']>;
};

export type LoginPayload = {
  email: Scalars['email_String_NotNull_format_email'];
  password: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  accountId: Scalars['Int'];
  contentType: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  key: Scalars['String'];
};

export type MediaFile = {
  contentType: Scalars['String'];
  key: Scalars['String'];
};

export type MultiLangString = {
  en: Scalars['String'];
  sq: Scalars['String'];
};

export type MultiLangStringType = {
  __typename?: 'MultiLangStringType';
  en: Scalars['String'];
  sq: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptBid: ClientIssue;
  addBidToIssue: Bid;
  approveIssue: AdminIssue;
  capturePaypalOrderPayment: Scalars['Boolean'];
  changeDocumentTitle: Document;
  changeIssueStatus: AdminIssue;
  closeIssueSuccessfully: ClientIssue;
  closeIssueSuccessfullyByLawyer: LawyerIssue;
  closeIssueUnsuccessfully: ClientIssue;
  closeIssueUnsuccessfullyByLawyer: LawyerIssue;
  createCity: City;
  createCountry: Country;
  createDocumentCategory: DocumentCategory;
  createEmptyDocument: Document;
  createIssue: ClientIssue;
  createPosition: Position;
  createSpeciality: Speciality;
  createSubscription: Subscription;
  createUserSubscription: Scalars['String'];
  deleteCity: City;
  deleteCountry: Country;
  deleteDocument: Document;
  deleteDocumentCategory: DocumentCategory;
  deleteIssue: AdminIssue;
  deletePosition: Position;
  deleteSpeciality: Speciality;
  deleteSubscription: Subscription;
  deleteUserSubscription: UserSubscription;
  downloadDocument: Scalars['String'];
  finishDocument: Document;
  generateFirebaseAuthToken: Scalars['String'];
  getDocumentRawHtml: Scalars['String'];
  login: AuthResponse;
  payInvoice: Array<Payment>;
  readUserNotifications: SuccessfulAction;
  registerClient: AuthResponse;
  registerLawyer: AuthResponse;
  requestDocumentPaymentLink: Scalars['String'];
  requestPasswordReset: SuccessfulAction;
  resendActivationCode: SuccessfulAction;
  resendPasswordResetCode: SuccessfulAction;
  resetPassword: SuccessfulAction;
  resetPasswordOfAuthenticatedUser: SuccessfulAction;
  updateAccount: Account;
  updateCity: City;
  updateCountry: Country;
  updateDocumentCategory: DocumentCategory;
  updateDocumentTemplate: DocumentTemplate;
  updateDocumentVariables: Document;
  updateLawyerCv: Account;
  updateLawyerDiploma: Account;
  updateLawyerLicense: Account;
  updatePosition: Position;
  updateSpeciality: Speciality;
  updateSubscription: Subscription;
  verifyAccount: SuccessfulAction;
  verifyLawyer: SuccessfulAction;
};


export type MutationAcceptBidArgs = {
  bidId: Scalars['Int'];
  issueId: Scalars['Int'];
};


export type MutationAddBidToIssueArgs = {
  bid: BidCreatePayload;
};


export type MutationApproveIssueArgs = {
  id: Scalars['Int'];
};


export type MutationCapturePaypalOrderPaymentArgs = {
  paypalOrderId: Scalars['String'];
};


export type MutationChangeDocumentTitleArgs = {
  id: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationChangeIssueStatusArgs = {
  id: Scalars['Int'];
  status: IssueStatus;
};


export type MutationCloseIssueSuccessfullyArgs = {
  id: Scalars['Int'];
};


export type MutationCloseIssueSuccessfullyByLawyerArgs = {
  id: Scalars['Int'];
};


export type MutationCloseIssueUnsuccessfullyArgs = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationCloseIssueUnsuccessfullyByLawyerArgs = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationCreateCityArgs = {
  data: CreateUpdateCityPayload;
};


export type MutationCreateCountryArgs = {
  data: CreateUpdateCountryPayload;
};


export type MutationCreateDocumentCategoryArgs = {
  data: CreateUpdateDocumentCategoryPayload;
};


export type MutationCreateEmptyDocumentArgs = {
  payload: CreateEmptyDocumentInput;
};


export type MutationCreateIssueArgs = {
  issue: IssueCreatePayload;
};


export type MutationCreatePositionArgs = {
  data: CreateUpdatePositionPayload;
};


export type MutationCreateSpecialityArgs = {
  data: CreateUpdateSpecialityPayload;
};


export type MutationCreateSubscriptionArgs = {
  data: CreateUpdateSubscriptionPayload;
};


export type MutationCreateUserSubscriptionArgs = {
  subscriptionId: Scalars['Int'];
};


export type MutationDeleteCityArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCountryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDocumentCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteIssueArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePositionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSpecialityArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSubscriptionArgs = {
  id: Scalars['Int'];
};


export type MutationDownloadDocumentArgs = {
  id: Scalars['Int'];
};


export type MutationFinishDocumentArgs = {
  id: Scalars['Int'];
};


export type MutationGetDocumentRawHtmlArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  data: LoginPayload;
};


export type MutationPayInvoiceArgs = {
  id: Scalars['Int'];
  type: PaymentType;
};


export type MutationRegisterClientArgs = {
  data: ClientSignupPayload;
};


export type MutationRegisterLawyerArgs = {
  data: LawyerSignupPayload;
};


export type MutationRequestDocumentPaymentLinkArgs = {
  id: Scalars['Int'];
};


export type MutationRequestPasswordResetArgs = {
  data: ResetPasswordRequest;
};


export type MutationResendPasswordResetCodeArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordPayload;
};


export type MutationResetPasswordOfAuthenticatedUserArgs = {
  data: ResetPasswordAuthenticatedUserPayload;
};


export type MutationUpdateAccountArgs = {
  data: UpdateAccountPayload;
};


export type MutationUpdateCityArgs = {
  data: CreateUpdateCityPayload;
  id: Scalars['Int'];
};


export type MutationUpdateCountryArgs = {
  data: CreateUpdateCountryPayload;
  id: Scalars['Int'];
};


export type MutationUpdateDocumentCategoryArgs = {
  data: CreateUpdateDocumentCategoryPayload;
  id: Scalars['Int'];
};


export type MutationUpdateDocumentTemplateArgs = {
  id: Scalars['Int'];
  payload: UpdateDocumentInput;
};


export type MutationUpdateDocumentVariablesArgs = {
  activeStep: Scalars['Int'];
  id: Scalars['Int'];
  variables: Scalars['JSONObject'];
};


export type MutationUpdateLawyerCvArgs = {
  data: UpdateLawyerCvPayload;
};


export type MutationUpdateLawyerDiplomaArgs = {
  data: UpdateLawyerDiplomaPayload;
};


export type MutationUpdateLawyerLicenseArgs = {
  data: UpdateLawyerLicensePayload;
};


export type MutationUpdatePositionArgs = {
  data: CreateUpdatePositionPayload;
  id: Scalars['Int'];
};


export type MutationUpdateSpecialityArgs = {
  data: CreateUpdateSpecialityPayload;
  id: Scalars['Int'];
};


export type MutationUpdateSubscriptionArgs = {
  data: CreateUpdateSubscriptionPayload;
  id: Scalars['Int'];
};


export type MutationVerifyAccountArgs = {
  activationCode: Scalars['String'];
};


export type MutationVerifyLawyerArgs = {
  id: Scalars['Int'];
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  id: Scalars['Int'];
  sender?: Maybe<NotificationSender>;
  status: NotificationStatus;
  title: Scalars['String'];
  type: NotificationType;
  url: Scalars['String'];
};

export type NotificationSender = {
  __typename?: 'NotificationSender';
  lastname: Scalars['String'];
  name: Scalars['String'];
};

export enum NotificationStatus {
  Read = 'READ',
  Unread = 'UNREAD'
}

export enum NotificationType {
  Bid = 'BID',
  Chat = 'CHAT',
  Issue = 'ISSUE'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nextPage: Scalars['Int'];
  previousPage: Scalars['Int'];
};

export type PaginatedAdminIssues = {
  __typename?: 'PaginatedAdminIssues';
  nodes: Array<AdminIssue>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedClientIssues = {
  __typename?: 'PaginatedClientIssues';
  nodes: Array<Maybe<ClientIssue>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedDocuments = {
  __typename?: 'PaginatedDocuments';
  nodes: Array<Document>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedInvoices = {
  __typename?: 'PaginatedInvoices';
  nodes: Array<Invoice>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedLawyerIssues = {
  __typename?: 'PaginatedLawyerIssues';
  nodes: Array<LawyerIssue>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaginatedNotificationRequest = {
  endDate?: InputMaybe<Scalars['Date']>;
  page: Scalars['Int'];
  pageLimit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type PaginatedNotifications = {
  __typename?: 'PaginatedNotifications';
  nodes: Array<Notification>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  account: Account;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  type: PaymentType;
};

export enum PaymentType {
  Bank = 'BANK',
  Paysera = 'PAYSERA'
}

export type PaypalOrder = {
  __typename?: 'PaypalOrder';
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type Position = {
  __typename?: 'Position';
  id: Scalars['Int'];
  name: Scalars['String'];
  rawName?: Maybe<MultiLangStringType>;
};

export type PresignedUrlInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type PresignedUrlResponse = {
  __typename?: 'PresignedUrlResponse';
  contentType: Scalars['String'];
  expiry: Scalars['Int'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type Profile = AdminProfile | ClientProfile | LawyerProfile;

export type ProfilePicture = {
  __typename?: 'ProfilePicture';
  lg: Scalars['String'];
  md: Scalars['String'];
  sm: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  cities: Array<Maybe<City>>;
  citiesByCountry?: Maybe<Array<Maybe<City>>>;
  clientTypes: Array<Maybe<ClientTypeValue>>;
  countries: Array<Maybe<Country>>;
  getActiveDocumentTemplates: Array<DocumentTemplate>;
  getActiveSubscriptions: Array<Subscription>;
  getAdminIssueById: AdminIssueById;
  getAllClientInvoices: PaginatedInvoices;
  getAllDocumentCategories: Array<DocumentCategory>;
  getAllDocumentTemplates: Array<DocumentTemplate>;
  getAllInvoices: PaginatedInvoices;
  getAllIssues: PaginatedAdminIssues;
  getAllSubscriptions: Array<Subscription>;
  getBankCredentials: BankCredentials;
  getBidsForIssue: Array<Maybe<Bid>>;
  getChatInfo: ChatInfo;
  getDocumentById: Document;
  getDocumentCategoryById: DocumentCategory;
  getDocuments: PaginatedDocuments;
  getInvoiceById: Invoice;
  getIssueById: ClientIssue;
  getIssues: PaginatedClientIssues;
  getIssuesThatLawyerCanBid: PaginatedLawyerIssues;
  getIssuesThatLawyerHasBid: PaginatedLawyerIssues;
  getLawyerIssueById: LawyerIssue;
  getMyInvoiceById: Invoice;
  getMyInvoices: PaginatedInvoices;
  getMySubscription?: Maybe<UserSubscription>;
  getNotifications: PaginatedNotifications;
  getPresignedPublicS3Url?: Maybe<PresignedUrlResponse>;
  getPresignedS3Url?: Maybe<PresignedUrlResponse>;
  getUnreadNotificationCount: Scalars['Int'];
  getUrlFromKey: Scalars['String'];
  languages: Array<Maybe<Language>>;
  me: Account;
  positions: Array<Maybe<Position>>;
  specialities: Array<Maybe<Speciality>>;
  users: Array<Maybe<Account>>;
};


export type QueryAccountArgs = {
  where: AccountQuery;
};


export type QueryCitiesByCountryArgs = {
  countryId: Scalars['Int'];
};


export type QueryGetActiveDocumentTemplatesArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAdminIssueByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllClientInvoicesArgs = {
  filter: ClientInvoicePaginatedRequest;
};


export type QueryGetAllInvoicesArgs = {
  filter: InvoicePaginatedRequest;
};


export type QueryGetAllIssuesArgs = {
  filter: IssuePaginatedRequest;
};


export type QueryGetBidsForIssueArgs = {
  issueId: Scalars['Int'];
};


export type QueryGetChatInfoArgs = {
  issueId: Scalars['Int'];
};


export type QueryGetDocumentByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetDocumentCategoryByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetDocumentsArgs = {
  request: DocumentRequest;
};


export type QueryGetInvoiceByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetIssueByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetIssuesArgs = {
  filter: IssuePaginatedRequest;
};


export type QueryGetIssuesThatLawyerCanBidArgs = {
  filter: IssuePaginatedRequest;
};


export type QueryGetIssuesThatLawyerHasBidArgs = {
  filter: IssuePaginatedRequest;
};


export type QueryGetLawyerIssueByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetMyInvoiceByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetMyInvoicesArgs = {
  filter: InvoicePaginatedRequestForLawyer;
};


export type QueryGetNotificationsArgs = {
  filter: PaginatedNotificationRequest;
};


export type QueryGetPresignedPublicS3UrlArgs = {
  data?: InputMaybe<PresignedUrlInput>;
};


export type QueryGetPresignedS3UrlArgs = {
  data?: InputMaybe<PresignedUrlInput>;
};


export type QueryGetUrlFromKeyArgs = {
  key: Scalars['String'];
};


export type QueryUsersArgs = {
  type: AccountRole;
};

export type ResetPasswordAuthenticatedUserPayload = {
  newPassword: Scalars['newPassword_String_NotNull_minLength_8'];
  oldPassword: Scalars['oldPassword_String_NotNull_minLength_8'];
};

export type ResetPasswordPayload = {
  email: Scalars['email_String_NotNull_format_email'];
  newPassword: Scalars['newPassword_String_NotNull_minLength_8'];
  newPasswordRepeat: Scalars['newPasswordRepeat_String_NotNull_minLength_8'];
  resetCode: Scalars['String'];
};

export type ResetPasswordRequest = {
  email: Scalars['email_String_NotNull_format_email'];
};

export type Speciality = {
  __typename?: 'Speciality';
  id: Scalars['Int'];
  name: Scalars['String'];
  rawName?: Maybe<MultiLangStringType>;
};

export type Subscription = {
  __typename?: 'Subscription';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  period: SubscriptionPeriod;
  price: Scalars['Float'];
};

export enum SubscriptionPeriod {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type SuccessfulAction = {
  __typename?: 'SuccessfulAction';
  message: Scalars['String'];
};

export type UpdateAccountPayload = {
  lastname: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['phoneNumber_String_pattern_3556098']>;
  profilePhoto?: InputMaybe<MediaFile>;
};

export type UpdateDocumentInput = {
  active: Scalars['Boolean'];
  categoryId: Scalars['Int'];
  description: Scalars['String'];
  price: Scalars['price_Float_NotNull_min_0'];
  title: Scalars['String'];
};

export type UpdateLawyerCvPayload = {
  cv: MediaFile;
};

export type UpdateLawyerDiplomaPayload = {
  diploma: MediaFile;
};

export type UpdateLawyerLicensePayload = {
  license: MediaFile;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  account: Account;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  invoice: Invoice;
  isActive?: Maybe<Scalars['Boolean']>;
  subscription: Subscription;
  validUntil: Scalars['DateTime'];
};

export type VerifyAccountMutationVariables = Exact<{
  activationCode: Scalars['String'];
}>;


export type VerifyAccountMutation = { __typename?: 'Mutation', verifyAccount: { __typename?: 'SuccessfulAction', message: string } };

export type CreateCityMutationVariables = Exact<{
  data: CreateUpdateCityPayload;
}>;


export type CreateCityMutation = { __typename?: 'Mutation', createCity: { __typename?: 'City', id: number, name: string, country?: { __typename?: 'Country', id: number, name: string } | null | undefined } };

export type DeleteCityMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCityMutation = { __typename?: 'Mutation', deleteCity: { __typename?: 'City', id: number, name: string } };

export type UpdateCityMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CreateUpdateCityPayload;
}>;


export type UpdateCityMutation = { __typename?: 'Mutation', updateCity: { __typename?: 'City', id: number, name: string, country?: { __typename?: 'Country', id: number, name: string } | null | undefined } };

export type CreateCountryMutationVariables = Exact<{
  data: CreateUpdateCountryPayload;
}>;


export type CreateCountryMutation = { __typename?: 'Mutation', createCountry: { __typename?: 'Country', id: number, name: string } };

export type DeleteCountryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCountryMutation = { __typename?: 'Mutation', deleteCountry: { __typename?: 'Country', id: number, name: string } };

export type UpdateCountryMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CreateUpdateCountryPayload;
}>;


export type UpdateCountryMutation = { __typename?: 'Mutation', updateCountry: { __typename?: 'Country', id: number, name: string } };

export type CreateDocumentCategoryMutationVariables = Exact<{
  data: CreateUpdateDocumentCategoryPayload;
}>;


export type CreateDocumentCategoryMutation = { __typename?: 'Mutation', createDocumentCategory: { __typename?: 'DocumentCategory', id: number } };

export type DeleteDocumentCategoryMutationVariables = Exact<{
  deleteDocumentCategoryId: Scalars['Int'];
}>;


export type DeleteDocumentCategoryMutation = { __typename?: 'Mutation', deleteDocumentCategory: { __typename?: 'DocumentCategory', id: number } };

export type UpdateDocumentCategoryMutationVariables = Exact<{
  updateDocumentCategoryId: Scalars['Int'];
  data: CreateUpdateDocumentCategoryPayload;
}>;


export type UpdateDocumentCategoryMutation = { __typename?: 'Mutation', updateDocumentCategory: { __typename?: 'DocumentCategory', id: number } };

export type UpdateDocumentTemplateMutationVariables = Exact<{
  updateDocumentTemplateId: Scalars['Int'];
  payload: UpdateDocumentInput;
}>;


export type UpdateDocumentTemplateMutation = { __typename?: 'Mutation', updateDocumentTemplate: { __typename?: 'DocumentTemplate', id: number } };

export type CapturePaypalOrderPaymentMutationVariables = Exact<{
  paypalOrderId: Scalars['String'];
}>;


export type CapturePaypalOrderPaymentMutation = { __typename?: 'Mutation', capturePaypalOrderPayment: boolean };

export type PayInvoiceMutationVariables = Exact<{
  payInvoiceId: Scalars['Int'];
  type: PaymentType;
}>;


export type PayInvoiceMutation = { __typename?: 'Mutation', payInvoice: Array<{ __typename?: 'Payment', id: number }> };

export type ApproveIssueMutationVariables = Exact<{
  approveIssueId: Scalars['Int'];
}>;


export type ApproveIssueMutation = { __typename?: 'Mutation', approveIssue: { __typename?: 'AdminIssue', id: number, title: string, description: string, createdAt: any, deadline: any, status: IssueStatus, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined, city?: { __typename?: 'City', name: string } | null | undefined } };

export type DeleteIssueMutationVariables = Exact<{
  deleteIssueId: Scalars['Int'];
}>;


export type DeleteIssueMutation = { __typename?: 'Mutation', deleteIssue: { __typename?: 'AdminIssue', id: number, title: string, description: string, createdAt: any, deadline: any, status: IssueStatus, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined, city?: { __typename?: 'City', name: string } | null | undefined } };

export type VerifyLawyerMutationVariables = Exact<{
  verifyLawyerId: Scalars['Int'];
}>;


export type VerifyLawyerMutation = { __typename?: 'Mutation', verifyLawyer: { __typename?: 'SuccessfulAction', message: string } };

export type CreatePositionMutationVariables = Exact<{
  data: CreateUpdatePositionPayload;
}>;


export type CreatePositionMutation = { __typename?: 'Mutation', createPosition: { __typename?: 'Position', id: number, name: string } };

export type DeletePositionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePositionMutation = { __typename?: 'Mutation', deletePosition: { __typename?: 'Position', id: number, name: string } };

export type UpdatePositionMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CreateUpdatePositionPayload;
}>;


export type UpdatePositionMutation = { __typename?: 'Mutation', updatePosition: { __typename?: 'Position', id: number, name: string } };

export type CreateSpecialityMutationVariables = Exact<{
  data: CreateUpdateSpecialityPayload;
}>;


export type CreateSpecialityMutation = { __typename?: 'Mutation', createSpeciality: { __typename?: 'Speciality', id: number, name: string } };

export type DeleteSpecialityMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSpecialityMutation = { __typename?: 'Mutation', deleteSpeciality: { __typename?: 'Speciality', id: number, name: string } };

export type UpdateSpecialityMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CreateUpdateSpecialityPayload;
}>;


export type UpdateSpecialityMutation = { __typename?: 'Mutation', updateSpeciality: { __typename?: 'Speciality', id: number, name: string } };

export type CreateSubscriptionMutationVariables = Exact<{
  data: CreateUpdateSubscriptionPayload;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'Subscription', id: number } };

export type DeleteSubscriptionMutationVariables = Exact<{
  deleteSubscriptionId: Scalars['Int'];
}>;


export type DeleteSubscriptionMutation = { __typename?: 'Mutation', deleteSubscription: { __typename?: 'Subscription', id: number } };

export type UpdateSubscriptionMutationVariables = Exact<{
  updateSubscriptionId: Scalars['Int'];
  data: CreateUpdateSubscriptionPayload;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: { __typename?: 'Subscription', id: number } };

export type AcceptBidMutationVariables = Exact<{
  issueId: Scalars['Int'];
  bidId: Scalars['Int'];
}>;


export type AcceptBidMutation = { __typename?: 'Mutation', acceptBid: { __typename?: 'ClientIssue', id: number, title: string, description: string } };

export type ChangeDocumentTitleMutationVariables = Exact<{
  changeDocumentTitleId: Scalars['Int'];
  title: Scalars['String'];
}>;


export type ChangeDocumentTitleMutation = { __typename?: 'Mutation', changeDocumentTitle: { __typename?: 'Document', id: number } };

export type RegisterClientMutationVariables = Exact<{
  data: ClientSignupPayload;
}>;


export type RegisterClientMutation = { __typename?: 'Mutation', registerClient: { __typename?: 'AuthResponse', token?: string | null | undefined } };

export type CloseIssueSuccessfullyMutationVariables = Exact<{
  closeIssueSuccessfullyId: Scalars['Int'];
}>;


export type CloseIssueSuccessfullyMutation = { __typename?: 'Mutation', closeIssueSuccessfully: { __typename?: 'ClientIssue', id: number, title: string, description: string, createdAt: any, deadline: any, status: IssueStatus, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined, city?: { __typename?: 'City', name: string } | null | undefined } };

export type CloseIssueUnsuccessfullyMutationVariables = Exact<{
  closeIssueUnsuccessfullyId: Scalars['Int'];
  reason: Scalars['String'];
}>;


export type CloseIssueUnsuccessfullyMutation = { __typename?: 'Mutation', closeIssueUnsuccessfully: { __typename?: 'ClientIssue', id: number, title: string, description: string, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined, city?: { __typename?: 'City', name: string } | null | undefined } };

export type CreateEmptyDocumentMutationVariables = Exact<{
  payload: CreateEmptyDocumentInput;
}>;


export type CreateEmptyDocumentMutation = { __typename?: 'Mutation', createEmptyDocument: { __typename?: 'Document', id: number, title: string } };

export type CreateIssueMutationVariables = Exact<{
  issue: IssueCreatePayload;
}>;


export type CreateIssueMutation = { __typename?: 'Mutation', createIssue: { __typename?: 'ClientIssue', title: string, description: string, createdAt: any, deadline: any, status: IssueStatus, totalBids: number, speciality?: { __typename?: 'Speciality', id: number, name: string } | null | undefined, country?: { __typename?: 'Country', id: number, name: string } | null | undefined, city?: { __typename?: 'City', id: number, name: string } | null | undefined } };

export type CreateUserSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['Int'];
}>;


export type CreateUserSubscriptionMutation = { __typename?: 'Mutation', createUserSubscription: string };

export type DeleteDocumentMutationVariables = Exact<{
  deleteDocumentId: Scalars['Int'];
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument: { __typename?: 'Document', id: number } };

export type DeleteUserSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserSubscriptionMutation = { __typename?: 'Mutation', deleteUserSubscription: { __typename?: 'UserSubscription', id: number } };

export type DownloadDocumentMutationVariables = Exact<{
  downloadDocumentId: Scalars['Int'];
}>;


export type DownloadDocumentMutation = { __typename?: 'Mutation', downloadDocument: string };

export type FinishDocumentMutationVariables = Exact<{
  finishDocumentId: Scalars['Int'];
}>;


export type FinishDocumentMutation = { __typename?: 'Mutation', finishDocument: { __typename?: 'Document', id: number } };

export type GetActiveSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveSubscriptionsQuery = { __typename?: 'Query', getActiveSubscriptions: Array<{ __typename?: 'Subscription', id: number, name: string, description: string, price: number, period: SubscriptionPeriod }> };

export type GetDocumentRawHtmlMutationVariables = Exact<{
  getRawHtmlId: Scalars['Int'];
}>;


export type GetDocumentRawHtmlMutation = { __typename?: 'Mutation', getDocumentRawHtml: string };

export type GetMySubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMySubscriptionQuery = { __typename?: 'Query', getMySubscription?: { __typename?: 'UserSubscription', id: number, validUntil: any, isActive?: boolean | null | undefined, createdAt: any, subscription: { __typename?: 'Subscription', name: string, description: string } } | null | undefined };

export type RequestDocumentPaymentLinkMutationVariables = Exact<{
  requestDocumentPaymentLinkId: Scalars['Int'];
}>;


export type RequestDocumentPaymentLinkMutation = { __typename?: 'Mutation', requestDocumentPaymentLink: string };

export type UpdateDocumentVariablesMutationVariables = Exact<{
  updateDocumentVariablesId: Scalars['Int'];
  variables: Scalars['JSONObject'];
  activeStep: Scalars['Int'];
}>;


export type UpdateDocumentVariablesMutation = { __typename?: 'Mutation', updateDocumentVariables: { __typename?: 'Document', id: number } };

export type GetAllDocumentCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDocumentCategoriesQuery = { __typename?: 'Query', getAllDocumentCategories: Array<{ __typename?: 'DocumentCategory', id: number, name: string, icon: string, rawName?: { __typename?: 'MultiLangStringType', sq: string, en: string } | null | undefined }> };

export type GetFirebaseTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type GetFirebaseTokenMutation = { __typename?: 'Mutation', generateFirebaseAuthToken: string };

export type AddBidToIssueMutationVariables = Exact<{
  bid: BidCreatePayload;
}>;


export type AddBidToIssueMutation = { __typename?: 'Mutation', addBidToIssue: { __typename?: 'Bid', issueId: number, amount: number, createdAt: any, id: number } };

export type CloseIssueSuccessfullyByLawyerMutationVariables = Exact<{
  closeIssueSuccessfullyByLawyerId: Scalars['Int'];
}>;


export type CloseIssueSuccessfullyByLawyerMutation = { __typename?: 'Mutation', closeIssueSuccessfullyByLawyer: { __typename?: 'LawyerIssue', title: string, description: string } };

export type CloseIssueUnsuccessfullyByLawyerMutationVariables = Exact<{
  closeIssueUnsuccessfullyByLawyerId: Scalars['Int'];
  reason: Scalars['String'];
}>;


export type CloseIssueUnsuccessfullyByLawyerMutation = { __typename?: 'Mutation', closeIssueUnsuccessfullyByLawyer: { __typename?: 'LawyerIssue', title: string, description: string } };

export type RegisterLawyerMutationVariables = Exact<{
  data: LawyerSignupPayload;
}>;


export type RegisterLawyerMutation = { __typename?: 'Mutation', registerLawyer: { __typename?: 'AuthResponse', token?: string | null | undefined } };

export type UpdateLawyerCvMutationVariables = Exact<{
  data: UpdateLawyerCvPayload;
}>;


export type UpdateLawyerCvMutation = { __typename?: 'Mutation', updateLawyerCv: { __typename?: 'Account', id: number } };

export type UpdateLawyerDiplomaMutationVariables = Exact<{
  data: UpdateLawyerDiplomaPayload;
}>;


export type UpdateLawyerDiplomaMutation = { __typename?: 'Mutation', updateLawyerDiploma: { __typename?: 'Account', id: number } };

export type UpdateLawyerLicenseMutationVariables = Exact<{
  data: UpdateLawyerLicensePayload;
}>;


export type UpdateLawyerLicenseMutation = { __typename?: 'Mutation', updateLawyerLicense: { __typename?: 'Account', id: number } };

export type LoginMutationVariables = Exact<{
  email: Scalars['email_String_NotNull_format_email'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', token?: string | null | undefined } };

export type ReadUserNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type ReadUserNotificationsMutation = { __typename?: 'Mutation', readUserNotifications: { __typename?: 'SuccessfulAction', message: string } };

export type RequestPasswordResetMutationVariables = Exact<{
  data: ResetPasswordRequest;
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: { __typename?: 'SuccessfulAction', message: string } };

export type ResendActivationCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendActivationCodeMutation = { __typename?: 'Mutation', resendActivationCode: { __typename?: 'SuccessfulAction', message: string } };

export type ResendPasswordResetCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendPasswordResetCodeMutation = { __typename?: 'Mutation', resendPasswordResetCode: { __typename?: 'SuccessfulAction', message: string } };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordPayload;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'SuccessfulAction', message: string } };

export type ResetPasswordOfAuthenticatedUserMutationVariables = Exact<{
  data: ResetPasswordAuthenticatedUserPayload;
}>;


export type ResetPasswordOfAuthenticatedUserMutation = { __typename?: 'Mutation', resetPasswordOfAuthenticatedUser: { __typename?: 'SuccessfulAction', message: string } };

export type UpdateAccountMutationVariables = Exact<{
  data: UpdateAccountPayload;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount: { __typename?: 'Account', id: number, email: string } };

export type GetAccountByIdQueryVariables = Exact<{
  where: AccountQuery;
}>;


export type GetAccountByIdQuery = { __typename?: 'Query', account?: { __typename?: 'Account', email: string, phoneNumber?: string | null | undefined, createdAt: any, role: AccountRole, accountStatus: AccountStatus, profile?: { __typename?: 'AdminProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'ClientProfile', name: string, lastname: string, sex: Gender, clientType: ClientType, city: { __typename?: 'City', name: string }, country: { __typename?: 'Country', name: string }, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile', name: string, lastname: string, sex: Gender, cvUrl: string, diplomaUrl: string, licenseUrl: string, city: { __typename?: 'City', name: string }, country: { __typename?: 'Country', name: string }, specialities: Array<{ __typename?: 'Speciality', name: string }>, positions: Array<{ __typename?: 'Position', name: string }>, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | null | undefined } | null | undefined };

export type GetAllClientInvoicesQueryVariables = Exact<{
  filter: ClientInvoicePaginatedRequest;
}>;


export type GetAllClientInvoicesQuery = { __typename?: 'Query', getAllClientInvoices: { __typename?: 'PaginatedInvoices', totalCount: number, pageInfo: { __typename?: 'PageInfo', previousPage: number, currentPage: number, nextPage: number, hasPreviousPage: boolean, hasNextPage: boolean }, nodes: Array<{ __typename?: 'Invoice', id: number, total: number, createdAt: any, month: number, year: number, title: string, payments: Array<{ __typename?: 'Payment', id: number, type: PaymentType }>, account: { __typename?: 'Account', email: string } }> } };

export type GetAllDocumentTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDocumentTemplatesQuery = { __typename?: 'Query', getAllDocumentTemplates: Array<{ __typename?: 'DocumentTemplate', id: number, title: string, blocks: any, description: string, template: any, info: any, price: number, versionNumber: number, hash: string, createdAt: any, updatedAt: any, active: boolean, category?: { __typename?: 'DocumentCategory', id: number, name: string, icon: string } | null | undefined }> };

export type GetAllIssuesQueryVariables = Exact<{
  filter: IssuePaginatedRequest;
}>;


export type GetAllIssuesQuery = { __typename?: 'Query', getAllIssues: { __typename?: 'PaginatedAdminIssues', totalCount: number, nodes: Array<{ __typename?: 'AdminIssue', cityId: number, title: string, id: number, description: string, countryId: number, specialityId: number, createdAt: any, deadline: any, status: IssueStatus, totalBids: number, speciality?: { __typename?: 'Speciality', name: string, id: number, rawName?: { __typename?: 'MultiLangStringType', sq: string, en: string } | null | undefined } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined, city?: { __typename?: 'City', name: string } | null | undefined, issuer: { __typename?: 'Account', profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile' } | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', previousPage: number, nextPage: number, currentPage: number, hasPreviousPage: boolean, hasNextPage: boolean } } };

export type GetAllSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSubscriptionsQuery = { __typename?: 'Query', getAllSubscriptions: Array<{ __typename?: 'Subscription', id: number, name: string, price: number, description: string, period: SubscriptionPeriod }> };

export type GetDocumentsQueryVariables = Exact<{
  request: DocumentRequest;
}>;


export type GetDocumentsQuery = { __typename?: 'Query', getDocuments: { __typename?: 'PaginatedDocuments', totalCount: number, nodes: Array<{ __typename?: 'Document', id: number, title: string, updatedAt: any, createdAt: any, previewUrl: string, status: DocumentStatus, template: { __typename?: 'DocumentTemplate', title: string, category?: { __typename?: 'DocumentCategory', icon: string } | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, nextPage: number, currentPage: number, previousPage: number } } };

export type GetInvoiceByIdQueryVariables = Exact<{
  getInvoiceByIdId: Scalars['Int'];
}>;


export type GetInvoiceByIdQuery = { __typename?: 'Query', getInvoiceById: { __typename?: 'Invoice', id: number, total: number, createdAt: any, account: { __typename?: 'Account', id: number, email: string, phoneNumber?: string | null | undefined, profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | null | undefined }, invoiceDetails: Array<{ __typename?: 'InvoiceDetails', createdAt: any, price: number, type: InvoiceItemType, item: { __typename?: 'AdminIssue', id: number, title: string, description: string } | { __typename?: 'Document', id: number, title: string } | { __typename?: 'LawyerIssue' } | { __typename?: 'UserSubscription', id: number, subscription: { __typename?: 'Subscription', name: string } } }>, payments: Array<{ __typename?: 'Payment', id: number, type: PaymentType, createdAt: any }> } };

export type GetAllInvoicesQueryVariables = Exact<{
  filter: InvoicePaginatedRequest;
}>;


export type GetAllInvoicesQuery = { __typename?: 'Query', getAllInvoices: { __typename?: 'PaginatedInvoices', totalCount: number, pageInfo: { __typename?: 'PageInfo', previousPage: number, currentPage: number, nextPage: number, hasPreviousPage: boolean, hasNextPage: boolean }, nodes: Array<{ __typename?: 'Invoice', id: number, total: number, createdAt: any, month: number, year: number, title: string, payments: Array<{ __typename?: 'Payment', id: number, type: PaymentType }>, account: { __typename?: 'Account', email: string } }> } };

export type GetAdminIssueByIdQueryVariables = Exact<{
  getAdminIssueByIdId: Scalars['Int'];
}>;


export type GetAdminIssueByIdQuery = { __typename?: 'Query', getAdminIssueById: { __typename?: 'AdminIssueById', issue: { __typename?: 'AdminIssue', id: number, title: string, description: string, createdAt: any, deadline: any, status: IssueStatus, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined, city?: { __typename?: 'City', name: string } | null | undefined, issuer: { __typename?: 'Account', profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile' } | null | undefined }, bids: Array<{ __typename?: 'Bid', title: string, description: string, createdAt: any, amount: number, bidder: { __typename?: 'Account', profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile' } | { __typename?: 'LawyerProfile', lastname: string, name: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | null | undefined } }> }, statusChanges: Array<{ __typename?: 'IssueStatusChange', id: number, reason: string, createdAt: any, status: IssueStatus, changedBy: { __typename?: 'Account', email: string } }> } };

export type UsersByRoleQueryVariables = Exact<{
  type: AccountRole;
}>;


export type UsersByRoleQuery = { __typename?: 'Query', users: Array<{ __typename?: 'Account', id: number, email: string, accountStatus: AccountStatus, createdAt: any, role: AccountRole, phoneNumber?: string | null | undefined, language: { __typename?: 'Language', code: LanguageCode, name: string }, profile?: { __typename?: 'AdminProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'ClientProfile', name: string, lastname: string, id: number, birthday: any, sex: Gender, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | null | undefined } | null | undefined> };

export type CitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CitiesQuery = { __typename?: 'Query', cities: Array<{ __typename?: 'City', id: number, name: string, country?: { __typename?: 'Country', id: number, name: string } | null | undefined, rawName?: { __typename?: 'MultiLangStringType', sq: string, en: string } | null | undefined } | null | undefined> };

export type CitiesByCountryQueryVariables = Exact<{
  countryId: Scalars['Int'];
}>;


export type CitiesByCountryQuery = { __typename?: 'Query', citiesByCountry?: Array<{ __typename?: 'City', name: string, id: number } | null | undefined> | null | undefined };

export type GetActiveDocumentTemplatesQueryVariables = Exact<{
  categoryId?: InputMaybe<Scalars['Int']>;
}>;


export type GetActiveDocumentTemplatesQuery = { __typename?: 'Query', getActiveDocumentTemplates: Array<{ __typename?: 'DocumentTemplate', id: number, title: string, price: number, versionNumber: number, description: string, createdAt: any, updatedAt: any, category?: { __typename?: 'DocumentCategory', id: number, name: string, icon: string } | null | undefined }> };

export type GetDocumentByIdQueryVariables = Exact<{
  getDocumentByIdId: Scalars['Int'];
}>;


export type GetDocumentByIdQuery = { __typename?: 'Query', getDocumentById: { __typename?: 'Document', id: number, title: string, blocks: any, info: any, variables: any, price: number, createdAt: any, updatedAt: any, status: DocumentStatus, templateVersionNumber: number, canPay: boolean, activeStep: number, previewUrl: string, template: { __typename?: 'DocumentTemplate', id: number, title: string, updatedAt: any, description: string }, invoice?: { __typename?: 'Invoice', id: number, payseraUrl?: string | null | undefined, payments: Array<{ __typename?: 'Payment', id: number }> } | null | undefined } };

export type GetBidsForIssueQueryVariables = Exact<{
  issueId: Scalars['Int'];
}>;


export type GetBidsForIssueQuery = { __typename?: 'Query', getBidsForIssue: Array<{ __typename?: 'Bid', id: number, amount: number, issueId: number } | null | undefined> };

export type GetIssueByIdQueryVariables = Exact<{
  getIssueByIdId: Scalars['Int'];
}>;


export type GetIssueByIdQuery = { __typename?: 'Query', getIssueById: { __typename?: 'ClientIssue', id: number, title: string, description: string, status: IssueStatus, deadline: any, createdAt: any, canChat: boolean, canCloseSuccessfully: boolean, canCloseUnsuccessfully: boolean, canAcceptBid: boolean, acceptedBid?: { __typename?: 'Bid', id: number, amount: number, createdAt: any, title: string, description: string } | null | undefined, bids: Array<{ __typename?: 'Bid', id: number, amount: number, title: string, description: string, createdAt: any, bidder: { __typename?: 'Account', profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile' } | { __typename?: 'LawyerProfile', lastname: string, name: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | null | undefined } }> } };

export type GetIssuesQueryVariables = Exact<{
  filter: IssuePaginatedRequest;
}>;


export type GetIssuesQuery = { __typename?: 'Query', getIssues: { __typename?: 'PaginatedClientIssues', totalCount: number, nodes: Array<{ __typename?: 'ClientIssue', totalBids: number, title: string, description: string, createdAt: any, status: IssueStatus, deadline: any, id: number, city?: { __typename?: 'City', name: string } | null | undefined, bids: Array<{ __typename?: 'Bid', amount: number, description: string, title: string, createdAt: any, id: number, bidder: { __typename?: 'Account', profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile' } | { __typename?: 'LawyerProfile', lastname: string, name: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | null | undefined } }>, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined } | null | undefined>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, currentPage: number, previousPage: number, nextPage: number } } };

export type ClientTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientTypesQuery = { __typename?: 'Query', clientTypes: Array<{ __typename?: 'ClientTypeValue', name: string, value: ClientType } | null | undefined> };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'Country', name: string, id: number, rawName?: { __typename?: 'MultiLangStringType', sq: string, en: string } | null | undefined } | null | undefined> };

export type GetBankCredentialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBankCredentialsQuery = { __typename?: 'Query', getBankCredentials: { __typename?: 'BankCredentials', iban: string, telephone: string, bank: string, address: string, business_entity: string, title: string } };

export type GetChatInfoQueryVariables = Exact<{
  issueId: Scalars['Int'];
}>;


export type GetChatInfoQuery = { __typename?: 'Query', getChatInfo: { __typename?: 'ChatInfo', threadId: string, recipient: { __typename?: 'Account', id: number, email: string, phoneNumber?: string | null | undefined, profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile', name: string, lastname: string, city: { __typename?: 'City', name: string }, country: { __typename?: 'Country', name: string }, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile', name: string, lastname: string, city: { __typename?: 'City', name: string }, country: { __typename?: 'Country', name: string }, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | null | undefined } } };

export type GetMyInvoiceByIdQueryVariables = Exact<{
  getInvoiceByIdId: Scalars['Int'];
}>;


export type GetMyInvoiceByIdQuery = { __typename?: 'Query', getMyInvoiceById: { __typename?: 'Invoice', id: number, total: number, payseraUrl?: string | null | undefined, createdAt: any, account: { __typename?: 'Account', id: number, email: string, phoneNumber?: string | null | undefined, profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | null | undefined }, invoiceDetails: Array<{ __typename?: 'InvoiceDetails', createdAt: any, price: number, type: InvoiceItemType, item: { __typename?: 'AdminIssue' } | { __typename?: 'Document', id: number, title: string } | { __typename?: 'LawyerIssue', id: number, title: string, description: string } | { __typename?: 'UserSubscription', id: number, subscription: { __typename?: 'Subscription', name: string } } }>, payments: Array<{ __typename?: 'Payment', id: number, type: PaymentType, createdAt: any }> } };

export type GetMyInvoicesQueryVariables = Exact<{
  filter: InvoicePaginatedRequestForLawyer;
}>;


export type GetMyInvoicesQuery = { __typename?: 'Query', getMyInvoices: { __typename?: 'PaginatedInvoices', totalCount: number, nodes: Array<{ __typename?: 'Invoice', id: number, total: number, createdAt: any, title: string, month: number, year: number, payments: Array<{ __typename?: 'Payment', id: number, type: PaymentType }> }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, nextPage: number, currentPage: number, previousPage: number } } };

export type GetNotificationsQueryVariables = Exact<{
  filter: PaginatedNotificationRequest;
}>;


export type GetNotificationsQuery = { __typename?: 'Query', getNotifications: { __typename?: 'PaginatedNotifications', totalCount: number, nodes: Array<{ __typename?: 'Notification', id: number, type: NotificationType, createdAt: any, url: string, title: string, description: string, status: NotificationStatus, sender?: { __typename?: 'NotificationSender', name: string, lastname: string } | null | undefined }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, nextPage: number, currentPage: number, previousPage: number } } };

export type GetPresignedPublicS3UrlQueryVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type GetPresignedPublicS3UrlQuery = { __typename?: 'Query', getPresignedPublicS3Url?: { __typename?: 'PresignedUrlResponse', key: string, url: string, expiry: number, contentType: string } | null | undefined };

export type GetPresignedS3UrlQueryVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type GetPresignedS3UrlQuery = { __typename?: 'Query', getPresignedS3Url?: { __typename?: 'PresignedUrlResponse', key: string, url: string, expiry: number, contentType: string } | null | undefined };

export type GetUnreadNotificationCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnreadNotificationCountQuery = { __typename?: 'Query', getUnreadNotificationCount: number };

export type GetUrlFromKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetUrlFromKeyQuery = { __typename?: 'Query', getUrlFromKey: string };

export type LanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguagesQuery = { __typename?: 'Query', languages: Array<{ __typename?: 'Language', code: LanguageCode, name: string } | null | undefined> };

export type GetIssuesThatLawyerCanBidQueryVariables = Exact<{
  filter: IssuePaginatedRequest;
}>;


export type GetIssuesThatLawyerCanBidQuery = { __typename?: 'Query', getIssuesThatLawyerCanBid: { __typename?: 'PaginatedLawyerIssues', totalCount: number, nodes: Array<{ __typename?: 'LawyerIssue', id: number, title: string, description: string, hasBid: boolean, deadline: any, status: IssueStatus, createdAt: any, issuer: { __typename?: 'Account', profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile' } | null | undefined }, city?: { __typename?: 'City', name: string } | null | undefined, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, currentPage: number, nextPage: number, previousPage: number } } };

export type GetIssuesThatLawyerHasBidQueryVariables = Exact<{
  filter: IssuePaginatedRequest;
}>;


export type GetIssuesThatLawyerHasBidQuery = { __typename?: 'Query', getIssuesThatLawyerHasBid: { __typename?: 'PaginatedLawyerIssues', totalCount: number, nodes: Array<{ __typename?: 'LawyerIssue', id: number, title: string, description: string, deadline: any, status: IssueStatus, createdAt: any, issuer: { __typename?: 'Account', profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile' } | null | undefined }, city?: { __typename?: 'City', name: string } | null | undefined, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, currentPage: number, nextPage: number, previousPage: number } } };

export type GetLawyerIssueByIdQueryVariables = Exact<{
  getLawyerIssueByIdId: Scalars['Int'];
}>;


export type GetLawyerIssueByIdQuery = { __typename?: 'Query', getLawyerIssueById: { __typename?: 'LawyerIssue', id: number, title: string, description: string, hasBid: boolean, cityId: number, countryId: number, specialityId: number, canChat: boolean, createdAt: any, deadline: any, status: IssueStatus, canCloseSuccessfully: boolean, canCloseUnsuccessfully: boolean, canBid: boolean, speciality?: { __typename?: 'Speciality', name: string } | null | undefined, country?: { __typename?: 'Country', name: string } | null | undefined, city?: { __typename?: 'City', name: string } | null | undefined, myBid?: { __typename?: 'Bid', title: string, description: string, amount: number, createdAt: any } | null | undefined, issuer: { __typename?: 'Account', profile?: { __typename?: 'AdminProfile' } | { __typename?: 'ClientProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined } | { __typename?: 'LawyerProfile' } | null | undefined } } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'Account', id: number, email: string, accountStatus: AccountStatus, createdAt: any, role: AccountRole, phoneNumber?: string | null | undefined, language: { __typename?: 'Language', code: LanguageCode, name: string }, profile?: { __typename?: 'AdminProfile', name: string, lastname: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined, profilePictureRaw?: { __typename?: 'Media', key: string, contentType: string } | null | undefined } | { __typename?: 'ClientProfile', name: string, lastname: string, id: number, birthday: any, sex: Gender, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined, profilePictureRaw?: { __typename?: 'Media', key: string, contentType: string } | null | undefined } | { __typename?: 'LawyerProfile', name: string, lastname: string, cvUrl: string, diplomaUrl: string, licenseUrl: string, profilePicture?: { __typename?: 'ProfilePicture', sm: string, md: string, lg: string } | null | undefined, profilePictureRaw?: { __typename?: 'Media', key: string, contentType: string } | null | undefined } | null | undefined } };

export type PositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PositionsQuery = { __typename?: 'Query', positions: Array<{ __typename?: 'Position', name: string, id: number, rawName?: { __typename?: 'MultiLangStringType', sq: string, en: string } | null | undefined } | null | undefined> };

export type SpecialitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type SpecialitiesQuery = { __typename?: 'Query', specialities: Array<{ __typename?: 'Speciality', name: string, id: number, rawName?: { __typename?: 'MultiLangStringType', sq: string, en: string } | null | undefined } | null | undefined> };


export const VerifyAccountDocument = gql`
    mutation VerifyAccount($activationCode: String!) {
  verifyAccount(activationCode: $activationCode) {
    message
  }
}
    `;
export type VerifyAccountMutationFn = Apollo.MutationFunction<VerifyAccountMutation, VerifyAccountMutationVariables>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      activationCode: // value for 'activationCode'
 *   },
 * });
 */
export function useVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(VerifyAccountDocument, options);
      }
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<VerifyAccountMutation, VerifyAccountMutationVariables>;
export const CreateCityDocument = gql`
    mutation CreateCity($data: CreateUpdateCityPayload!) {
  createCity(data: $data) {
    id
    name
    country {
      id
      name
    }
  }
}
    `;
export type CreateCityMutationFn = Apollo.MutationFunction<CreateCityMutation, CreateCityMutationVariables>;

/**
 * __useCreateCityMutation__
 *
 * To run a mutation, you first call `useCreateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCityMutation, { data, loading, error }] = useCreateCityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCityMutation(baseOptions?: Apollo.MutationHookOptions<CreateCityMutation, CreateCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCityMutation, CreateCityMutationVariables>(CreateCityDocument, options);
      }
export type CreateCityMutationHookResult = ReturnType<typeof useCreateCityMutation>;
export type CreateCityMutationResult = Apollo.MutationResult<CreateCityMutation>;
export type CreateCityMutationOptions = Apollo.BaseMutationOptions<CreateCityMutation, CreateCityMutationVariables>;
export const DeleteCityDocument = gql`
    mutation DeleteCity($id: Int!) {
  deleteCity(id: $id) {
    id
    name
  }
}
    `;
export type DeleteCityMutationFn = Apollo.MutationFunction<DeleteCityMutation, DeleteCityMutationVariables>;

/**
 * __useDeleteCityMutation__
 *
 * To run a mutation, you first call `useDeleteCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCityMutation, { data, loading, error }] = useDeleteCityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCityMutation, DeleteCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCityMutation, DeleteCityMutationVariables>(DeleteCityDocument, options);
      }
export type DeleteCityMutationHookResult = ReturnType<typeof useDeleteCityMutation>;
export type DeleteCityMutationResult = Apollo.MutationResult<DeleteCityMutation>;
export type DeleteCityMutationOptions = Apollo.BaseMutationOptions<DeleteCityMutation, DeleteCityMutationVariables>;
export const UpdateCityDocument = gql`
    mutation UpdateCity($id: Int!, $data: CreateUpdateCityPayload!) {
  updateCity(id: $id, data: $data) {
    country {
      id
      name
    }
    id
    name
  }
}
    `;
export type UpdateCityMutationFn = Apollo.MutationFunction<UpdateCityMutation, UpdateCityMutationVariables>;

/**
 * __useUpdateCityMutation__
 *
 * To run a mutation, you first call `useUpdateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCityMutation, { data, loading, error }] = useUpdateCityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCityMutation, UpdateCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCityMutation, UpdateCityMutationVariables>(UpdateCityDocument, options);
      }
export type UpdateCityMutationHookResult = ReturnType<typeof useUpdateCityMutation>;
export type UpdateCityMutationResult = Apollo.MutationResult<UpdateCityMutation>;
export type UpdateCityMutationOptions = Apollo.BaseMutationOptions<UpdateCityMutation, UpdateCityMutationVariables>;
export const CreateCountryDocument = gql`
    mutation CreateCountry($data: CreateUpdateCountryPayload!) {
  createCountry(data: $data) {
    id
    name
  }
}
    `;
export type CreateCountryMutationFn = Apollo.MutationFunction<CreateCountryMutation, CreateCountryMutationVariables>;

/**
 * __useCreateCountryMutation__
 *
 * To run a mutation, you first call `useCreateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountryMutation, { data, loading, error }] = useCreateCountryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCountryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCountryMutation, CreateCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCountryMutation, CreateCountryMutationVariables>(CreateCountryDocument, options);
      }
export type CreateCountryMutationHookResult = ReturnType<typeof useCreateCountryMutation>;
export type CreateCountryMutationResult = Apollo.MutationResult<CreateCountryMutation>;
export type CreateCountryMutationOptions = Apollo.BaseMutationOptions<CreateCountryMutation, CreateCountryMutationVariables>;
export const DeleteCountryDocument = gql`
    mutation DeleteCountry($id: Int!) {
  deleteCountry(id: $id) {
    id
    name
  }
}
    `;
export type DeleteCountryMutationFn = Apollo.MutationFunction<DeleteCountryMutation, DeleteCountryMutationVariables>;

/**
 * __useDeleteCountryMutation__
 *
 * To run a mutation, you first call `useDeleteCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCountryMutation, { data, loading, error }] = useDeleteCountryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCountryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCountryMutation, DeleteCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCountryMutation, DeleteCountryMutationVariables>(DeleteCountryDocument, options);
      }
export type DeleteCountryMutationHookResult = ReturnType<typeof useDeleteCountryMutation>;
export type DeleteCountryMutationResult = Apollo.MutationResult<DeleteCountryMutation>;
export type DeleteCountryMutationOptions = Apollo.BaseMutationOptions<DeleteCountryMutation, DeleteCountryMutationVariables>;
export const UpdateCountryDocument = gql`
    mutation UpdateCountry($id: Int!, $data: CreateUpdateCountryPayload!) {
  updateCountry(id: $id, data: $data) {
    id
    name
  }
}
    `;
export type UpdateCountryMutationFn = Apollo.MutationFunction<UpdateCountryMutation, UpdateCountryMutationVariables>;

/**
 * __useUpdateCountryMutation__
 *
 * To run a mutation, you first call `useUpdateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCountryMutation, { data, loading, error }] = useUpdateCountryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCountryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCountryMutation, UpdateCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCountryMutation, UpdateCountryMutationVariables>(UpdateCountryDocument, options);
      }
export type UpdateCountryMutationHookResult = ReturnType<typeof useUpdateCountryMutation>;
export type UpdateCountryMutationResult = Apollo.MutationResult<UpdateCountryMutation>;
export type UpdateCountryMutationOptions = Apollo.BaseMutationOptions<UpdateCountryMutation, UpdateCountryMutationVariables>;
export const CreateDocumentCategoryDocument = gql`
    mutation CreateDocumentCategory($data: CreateUpdateDocumentCategoryPayload!) {
  createDocumentCategory(data: $data) {
    id
  }
}
    `;
export type CreateDocumentCategoryMutationFn = Apollo.MutationFunction<CreateDocumentCategoryMutation, CreateDocumentCategoryMutationVariables>;

/**
 * __useCreateDocumentCategoryMutation__
 *
 * To run a mutation, you first call `useCreateDocumentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentCategoryMutation, { data, loading, error }] = useCreateDocumentCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDocumentCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentCategoryMutation, CreateDocumentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentCategoryMutation, CreateDocumentCategoryMutationVariables>(CreateDocumentCategoryDocument, options);
      }
export type CreateDocumentCategoryMutationHookResult = ReturnType<typeof useCreateDocumentCategoryMutation>;
export type CreateDocumentCategoryMutationResult = Apollo.MutationResult<CreateDocumentCategoryMutation>;
export type CreateDocumentCategoryMutationOptions = Apollo.BaseMutationOptions<CreateDocumentCategoryMutation, CreateDocumentCategoryMutationVariables>;
export const DeleteDocumentCategoryDocument = gql`
    mutation DeleteDocumentCategory($deleteDocumentCategoryId: Int!) {
  deleteDocumentCategory(id: $deleteDocumentCategoryId) {
    id
  }
}
    `;
export type DeleteDocumentCategoryMutationFn = Apollo.MutationFunction<DeleteDocumentCategoryMutation, DeleteDocumentCategoryMutationVariables>;

/**
 * __useDeleteDocumentCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentCategoryMutation, { data, loading, error }] = useDeleteDocumentCategoryMutation({
 *   variables: {
 *      deleteDocumentCategoryId: // value for 'deleteDocumentCategoryId'
 *   },
 * });
 */
export function useDeleteDocumentCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentCategoryMutation, DeleteDocumentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentCategoryMutation, DeleteDocumentCategoryMutationVariables>(DeleteDocumentCategoryDocument, options);
      }
export type DeleteDocumentCategoryMutationHookResult = ReturnType<typeof useDeleteDocumentCategoryMutation>;
export type DeleteDocumentCategoryMutationResult = Apollo.MutationResult<DeleteDocumentCategoryMutation>;
export type DeleteDocumentCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentCategoryMutation, DeleteDocumentCategoryMutationVariables>;
export const UpdateDocumentCategoryDocument = gql`
    mutation UpdateDocumentCategory($updateDocumentCategoryId: Int!, $data: CreateUpdateDocumentCategoryPayload!) {
  updateDocumentCategory(id: $updateDocumentCategoryId, data: $data) {
    id
  }
}
    `;
export type UpdateDocumentCategoryMutationFn = Apollo.MutationFunction<UpdateDocumentCategoryMutation, UpdateDocumentCategoryMutationVariables>;

/**
 * __useUpdateDocumentCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentCategoryMutation, { data, loading, error }] = useUpdateDocumentCategoryMutation({
 *   variables: {
 *      updateDocumentCategoryId: // value for 'updateDocumentCategoryId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDocumentCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentCategoryMutation, UpdateDocumentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentCategoryMutation, UpdateDocumentCategoryMutationVariables>(UpdateDocumentCategoryDocument, options);
      }
export type UpdateDocumentCategoryMutationHookResult = ReturnType<typeof useUpdateDocumentCategoryMutation>;
export type UpdateDocumentCategoryMutationResult = Apollo.MutationResult<UpdateDocumentCategoryMutation>;
export type UpdateDocumentCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentCategoryMutation, UpdateDocumentCategoryMutationVariables>;
export const UpdateDocumentTemplateDocument = gql`
    mutation UpdateDocumentTemplate($updateDocumentTemplateId: Int!, $payload: UpdateDocumentInput!) {
  updateDocumentTemplate(id: $updateDocumentTemplateId, payload: $payload) {
    id
  }
}
    `;
export type UpdateDocumentTemplateMutationFn = Apollo.MutationFunction<UpdateDocumentTemplateMutation, UpdateDocumentTemplateMutationVariables>;

/**
 * __useUpdateDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentTemplateMutation, { data, loading, error }] = useUpdateDocumentTemplateMutation({
 *   variables: {
 *      updateDocumentTemplateId: // value for 'updateDocumentTemplateId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateDocumentTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentTemplateMutation, UpdateDocumentTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentTemplateMutation, UpdateDocumentTemplateMutationVariables>(UpdateDocumentTemplateDocument, options);
      }
export type UpdateDocumentTemplateMutationHookResult = ReturnType<typeof useUpdateDocumentTemplateMutation>;
export type UpdateDocumentTemplateMutationResult = Apollo.MutationResult<UpdateDocumentTemplateMutation>;
export type UpdateDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentTemplateMutation, UpdateDocumentTemplateMutationVariables>;
export const CapturePaypalOrderPaymentDocument = gql`
    mutation CapturePaypalOrderPayment($paypalOrderId: String!) {
  capturePaypalOrderPayment(paypalOrderId: $paypalOrderId)
}
    `;
export type CapturePaypalOrderPaymentMutationFn = Apollo.MutationFunction<CapturePaypalOrderPaymentMutation, CapturePaypalOrderPaymentMutationVariables>;

/**
 * __useCapturePaypalOrderPaymentMutation__
 *
 * To run a mutation, you first call `useCapturePaypalOrderPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCapturePaypalOrderPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [capturePaypalOrderPaymentMutation, { data, loading, error }] = useCapturePaypalOrderPaymentMutation({
 *   variables: {
 *      paypalOrderId: // value for 'paypalOrderId'
 *   },
 * });
 */
export function useCapturePaypalOrderPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CapturePaypalOrderPaymentMutation, CapturePaypalOrderPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CapturePaypalOrderPaymentMutation, CapturePaypalOrderPaymentMutationVariables>(CapturePaypalOrderPaymentDocument, options);
      }
export type CapturePaypalOrderPaymentMutationHookResult = ReturnType<typeof useCapturePaypalOrderPaymentMutation>;
export type CapturePaypalOrderPaymentMutationResult = Apollo.MutationResult<CapturePaypalOrderPaymentMutation>;
export type CapturePaypalOrderPaymentMutationOptions = Apollo.BaseMutationOptions<CapturePaypalOrderPaymentMutation, CapturePaypalOrderPaymentMutationVariables>;
export const PayInvoiceDocument = gql`
    mutation PayInvoice($payInvoiceId: Int!, $type: PaymentType!) {
  payInvoice(id: $payInvoiceId, type: $type) {
    id
  }
}
    `;
export type PayInvoiceMutationFn = Apollo.MutationFunction<PayInvoiceMutation, PayInvoiceMutationVariables>;

/**
 * __usePayInvoiceMutation__
 *
 * To run a mutation, you first call `usePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoiceMutation, { data, loading, error }] = usePayInvoiceMutation({
 *   variables: {
 *      payInvoiceId: // value for 'payInvoiceId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePayInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<PayInvoiceMutation, PayInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayInvoiceMutation, PayInvoiceMutationVariables>(PayInvoiceDocument, options);
      }
export type PayInvoiceMutationHookResult = ReturnType<typeof usePayInvoiceMutation>;
export type PayInvoiceMutationResult = Apollo.MutationResult<PayInvoiceMutation>;
export type PayInvoiceMutationOptions = Apollo.BaseMutationOptions<PayInvoiceMutation, PayInvoiceMutationVariables>;
export const ApproveIssueDocument = gql`
    mutation ApproveIssue($approveIssueId: Int!) {
  approveIssue(id: $approveIssueId) {
    id
    title
    description
    speciality {
      name
    }
    country {
      name
    }
    city {
      name
    }
    createdAt
    deadline
    status
  }
}
    `;
export type ApproveIssueMutationFn = Apollo.MutationFunction<ApproveIssueMutation, ApproveIssueMutationVariables>;

/**
 * __useApproveIssueMutation__
 *
 * To run a mutation, you first call `useApproveIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveIssueMutation, { data, loading, error }] = useApproveIssueMutation({
 *   variables: {
 *      approveIssueId: // value for 'approveIssueId'
 *   },
 * });
 */
export function useApproveIssueMutation(baseOptions?: Apollo.MutationHookOptions<ApproveIssueMutation, ApproveIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveIssueMutation, ApproveIssueMutationVariables>(ApproveIssueDocument, options);
      }
export type ApproveIssueMutationHookResult = ReturnType<typeof useApproveIssueMutation>;
export type ApproveIssueMutationResult = Apollo.MutationResult<ApproveIssueMutation>;
export type ApproveIssueMutationOptions = Apollo.BaseMutationOptions<ApproveIssueMutation, ApproveIssueMutationVariables>;
export const DeleteIssueDocument = gql`
    mutation DeleteIssue($deleteIssueId: Int!) {
  deleteIssue(id: $deleteIssueId) {
    id
    title
    description
    speciality {
      name
    }
    country {
      name
    }
    city {
      name
    }
    createdAt
    deadline
    status
  }
}
    `;
export type DeleteIssueMutationFn = Apollo.MutationFunction<DeleteIssueMutation, DeleteIssueMutationVariables>;

/**
 * __useDeleteIssueMutation__
 *
 * To run a mutation, you first call `useDeleteIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIssueMutation, { data, loading, error }] = useDeleteIssueMutation({
 *   variables: {
 *      deleteIssueId: // value for 'deleteIssueId'
 *   },
 * });
 */
export function useDeleteIssueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIssueMutation, DeleteIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIssueMutation, DeleteIssueMutationVariables>(DeleteIssueDocument, options);
      }
export type DeleteIssueMutationHookResult = ReturnType<typeof useDeleteIssueMutation>;
export type DeleteIssueMutationResult = Apollo.MutationResult<DeleteIssueMutation>;
export type DeleteIssueMutationOptions = Apollo.BaseMutationOptions<DeleteIssueMutation, DeleteIssueMutationVariables>;
export const VerifyLawyerDocument = gql`
    mutation VerifyLawyer($verifyLawyerId: Int!) {
  verifyLawyer(id: $verifyLawyerId) {
    message
  }
}
    `;
export type VerifyLawyerMutationFn = Apollo.MutationFunction<VerifyLawyerMutation, VerifyLawyerMutationVariables>;

/**
 * __useVerifyLawyerMutation__
 *
 * To run a mutation, you first call `useVerifyLawyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyLawyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyLawyerMutation, { data, loading, error }] = useVerifyLawyerMutation({
 *   variables: {
 *      verifyLawyerId: // value for 'verifyLawyerId'
 *   },
 * });
 */
export function useVerifyLawyerMutation(baseOptions?: Apollo.MutationHookOptions<VerifyLawyerMutation, VerifyLawyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyLawyerMutation, VerifyLawyerMutationVariables>(VerifyLawyerDocument, options);
      }
export type VerifyLawyerMutationHookResult = ReturnType<typeof useVerifyLawyerMutation>;
export type VerifyLawyerMutationResult = Apollo.MutationResult<VerifyLawyerMutation>;
export type VerifyLawyerMutationOptions = Apollo.BaseMutationOptions<VerifyLawyerMutation, VerifyLawyerMutationVariables>;
export const CreatePositionDocument = gql`
    mutation CreatePosition($data: CreateUpdatePositionPayload!) {
  createPosition(data: $data) {
    id
    name
  }
}
    `;
export type CreatePositionMutationFn = Apollo.MutationFunction<CreatePositionMutation, CreatePositionMutationVariables>;

/**
 * __useCreatePositionMutation__
 *
 * To run a mutation, you first call `useCreatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPositionMutation, { data, loading, error }] = useCreatePositionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePositionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePositionMutation, CreatePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePositionMutation, CreatePositionMutationVariables>(CreatePositionDocument, options);
      }
export type CreatePositionMutationHookResult = ReturnType<typeof useCreatePositionMutation>;
export type CreatePositionMutationResult = Apollo.MutationResult<CreatePositionMutation>;
export type CreatePositionMutationOptions = Apollo.BaseMutationOptions<CreatePositionMutation, CreatePositionMutationVariables>;
export const DeletePositionDocument = gql`
    mutation DeletePosition($id: Int!) {
  deletePosition(id: $id) {
    id
    name
  }
}
    `;
export type DeletePositionMutationFn = Apollo.MutationFunction<DeletePositionMutation, DeletePositionMutationVariables>;

/**
 * __useDeletePositionMutation__
 *
 * To run a mutation, you first call `useDeletePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePositionMutation, { data, loading, error }] = useDeletePositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePositionMutation(baseOptions?: Apollo.MutationHookOptions<DeletePositionMutation, DeletePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePositionMutation, DeletePositionMutationVariables>(DeletePositionDocument, options);
      }
export type DeletePositionMutationHookResult = ReturnType<typeof useDeletePositionMutation>;
export type DeletePositionMutationResult = Apollo.MutationResult<DeletePositionMutation>;
export type DeletePositionMutationOptions = Apollo.BaseMutationOptions<DeletePositionMutation, DeletePositionMutationVariables>;
export const UpdatePositionDocument = gql`
    mutation UpdatePosition($id: Int!, $data: CreateUpdatePositionPayload!) {
  updatePosition(id: $id, data: $data) {
    id
    name
  }
}
    `;
export type UpdatePositionMutationFn = Apollo.MutationFunction<UpdatePositionMutation, UpdatePositionMutationVariables>;

/**
 * __useUpdatePositionMutation__
 *
 * To run a mutation, you first call `useUpdatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionMutation, { data, loading, error }] = useUpdatePositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePositionMutation, UpdatePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePositionMutation, UpdatePositionMutationVariables>(UpdatePositionDocument, options);
      }
export type UpdatePositionMutationHookResult = ReturnType<typeof useUpdatePositionMutation>;
export type UpdatePositionMutationResult = Apollo.MutationResult<UpdatePositionMutation>;
export type UpdatePositionMutationOptions = Apollo.BaseMutationOptions<UpdatePositionMutation, UpdatePositionMutationVariables>;
export const CreateSpecialityDocument = gql`
    mutation CreateSpeciality($data: CreateUpdateSpecialityPayload!) {
  createSpeciality(data: $data) {
    id
    name
  }
}
    `;
export type CreateSpecialityMutationFn = Apollo.MutationFunction<CreateSpecialityMutation, CreateSpecialityMutationVariables>;

/**
 * __useCreateSpecialityMutation__
 *
 * To run a mutation, you first call `useCreateSpecialityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpecialityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpecialityMutation, { data, loading, error }] = useCreateSpecialityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSpecialityMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpecialityMutation, CreateSpecialityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpecialityMutation, CreateSpecialityMutationVariables>(CreateSpecialityDocument, options);
      }
export type CreateSpecialityMutationHookResult = ReturnType<typeof useCreateSpecialityMutation>;
export type CreateSpecialityMutationResult = Apollo.MutationResult<CreateSpecialityMutation>;
export type CreateSpecialityMutationOptions = Apollo.BaseMutationOptions<CreateSpecialityMutation, CreateSpecialityMutationVariables>;
export const DeleteSpecialityDocument = gql`
    mutation DeleteSpeciality($id: Int!) {
  deleteSpeciality(id: $id) {
    id
    name
  }
}
    `;
export type DeleteSpecialityMutationFn = Apollo.MutationFunction<DeleteSpecialityMutation, DeleteSpecialityMutationVariables>;

/**
 * __useDeleteSpecialityMutation__
 *
 * To run a mutation, you first call `useDeleteSpecialityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpecialityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpecialityMutation, { data, loading, error }] = useDeleteSpecialityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSpecialityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSpecialityMutation, DeleteSpecialityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSpecialityMutation, DeleteSpecialityMutationVariables>(DeleteSpecialityDocument, options);
      }
export type DeleteSpecialityMutationHookResult = ReturnType<typeof useDeleteSpecialityMutation>;
export type DeleteSpecialityMutationResult = Apollo.MutationResult<DeleteSpecialityMutation>;
export type DeleteSpecialityMutationOptions = Apollo.BaseMutationOptions<DeleteSpecialityMutation, DeleteSpecialityMutationVariables>;
export const UpdateSpecialityDocument = gql`
    mutation UpdateSpeciality($id: Int!, $data: CreateUpdateSpecialityPayload!) {
  updateSpeciality(id: $id, data: $data) {
    id
    name
  }
}
    `;
export type UpdateSpecialityMutationFn = Apollo.MutationFunction<UpdateSpecialityMutation, UpdateSpecialityMutationVariables>;

/**
 * __useUpdateSpecialityMutation__
 *
 * To run a mutation, you first call `useUpdateSpecialityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpecialityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpecialityMutation, { data, loading, error }] = useUpdateSpecialityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSpecialityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpecialityMutation, UpdateSpecialityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpecialityMutation, UpdateSpecialityMutationVariables>(UpdateSpecialityDocument, options);
      }
export type UpdateSpecialityMutationHookResult = ReturnType<typeof useUpdateSpecialityMutation>;
export type UpdateSpecialityMutationResult = Apollo.MutationResult<UpdateSpecialityMutation>;
export type UpdateSpecialityMutationOptions = Apollo.BaseMutationOptions<UpdateSpecialityMutation, UpdateSpecialityMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($data: CreateUpdateSubscriptionPayload!) {
  createSubscription(data: $data) {
    id
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const DeleteSubscriptionDocument = gql`
    mutation DeleteSubscription($deleteSubscriptionId: Int!) {
  deleteSubscription(id: $deleteSubscriptionId) {
    id
  }
}
    `;
export type DeleteSubscriptionMutationFn = Apollo.MutationFunction<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;

/**
 * __useDeleteSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscriptionMutation, { data, loading, error }] = useDeleteSubscriptionMutation({
 *   variables: {
 *      deleteSubscriptionId: // value for 'deleteSubscriptionId'
 *   },
 * });
 */
export function useDeleteSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>(DeleteSubscriptionDocument, options);
      }
export type DeleteSubscriptionMutationHookResult = ReturnType<typeof useDeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationResult = Apollo.MutationResult<DeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($updateSubscriptionId: Int!, $data: CreateUpdateSubscriptionPayload!) {
  updateSubscription(id: $updateSubscriptionId, data: $data) {
    id
  }
}
    `;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      updateSubscriptionId: // value for 'updateSubscriptionId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const AcceptBidDocument = gql`
    mutation AcceptBid($issueId: Int!, $bidId: Int!) {
  acceptBid(issueId: $issueId, bidId: $bidId) {
    id
    title
    description
  }
}
    `;
export type AcceptBidMutationFn = Apollo.MutationFunction<AcceptBidMutation, AcceptBidMutationVariables>;

/**
 * __useAcceptBidMutation__
 *
 * To run a mutation, you first call `useAcceptBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBidMutation, { data, loading, error }] = useAcceptBidMutation({
 *   variables: {
 *      issueId: // value for 'issueId'
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useAcceptBidMutation(baseOptions?: Apollo.MutationHookOptions<AcceptBidMutation, AcceptBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptBidMutation, AcceptBidMutationVariables>(AcceptBidDocument, options);
      }
export type AcceptBidMutationHookResult = ReturnType<typeof useAcceptBidMutation>;
export type AcceptBidMutationResult = Apollo.MutationResult<AcceptBidMutation>;
export type AcceptBidMutationOptions = Apollo.BaseMutationOptions<AcceptBidMutation, AcceptBidMutationVariables>;
export const ChangeDocumentTitleDocument = gql`
    mutation ChangeDocumentTitle($changeDocumentTitleId: Int!, $title: String!) {
  changeDocumentTitle(id: $changeDocumentTitleId, title: $title) {
    id
  }
}
    `;
export type ChangeDocumentTitleMutationFn = Apollo.MutationFunction<ChangeDocumentTitleMutation, ChangeDocumentTitleMutationVariables>;

/**
 * __useChangeDocumentTitleMutation__
 *
 * To run a mutation, you first call `useChangeDocumentTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDocumentTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDocumentTitleMutation, { data, loading, error }] = useChangeDocumentTitleMutation({
 *   variables: {
 *      changeDocumentTitleId: // value for 'changeDocumentTitleId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useChangeDocumentTitleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDocumentTitleMutation, ChangeDocumentTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeDocumentTitleMutation, ChangeDocumentTitleMutationVariables>(ChangeDocumentTitleDocument, options);
      }
export type ChangeDocumentTitleMutationHookResult = ReturnType<typeof useChangeDocumentTitleMutation>;
export type ChangeDocumentTitleMutationResult = Apollo.MutationResult<ChangeDocumentTitleMutation>;
export type ChangeDocumentTitleMutationOptions = Apollo.BaseMutationOptions<ChangeDocumentTitleMutation, ChangeDocumentTitleMutationVariables>;
export const RegisterClientDocument = gql`
    mutation RegisterClient($data: ClientSignupPayload!) {
  registerClient(data: $data) {
    token
  }
}
    `;
export type RegisterClientMutationFn = Apollo.MutationFunction<RegisterClientMutation, RegisterClientMutationVariables>;

/**
 * __useRegisterClientMutation__
 *
 * To run a mutation, you first call `useRegisterClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerClientMutation, { data, loading, error }] = useRegisterClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterClientMutation(baseOptions?: Apollo.MutationHookOptions<RegisterClientMutation, RegisterClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterClientMutation, RegisterClientMutationVariables>(RegisterClientDocument, options);
      }
export type RegisterClientMutationHookResult = ReturnType<typeof useRegisterClientMutation>;
export type RegisterClientMutationResult = Apollo.MutationResult<RegisterClientMutation>;
export type RegisterClientMutationOptions = Apollo.BaseMutationOptions<RegisterClientMutation, RegisterClientMutationVariables>;
export const CloseIssueSuccessfullyDocument = gql`
    mutation CloseIssueSuccessfully($closeIssueSuccessfullyId: Int!) {
  closeIssueSuccessfully(id: $closeIssueSuccessfullyId) {
    id
    title
    description
    speciality {
      name
    }
    country {
      name
    }
    city {
      name
    }
    createdAt
    deadline
    status
  }
}
    `;
export type CloseIssueSuccessfullyMutationFn = Apollo.MutationFunction<CloseIssueSuccessfullyMutation, CloseIssueSuccessfullyMutationVariables>;

/**
 * __useCloseIssueSuccessfullyMutation__
 *
 * To run a mutation, you first call `useCloseIssueSuccessfullyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseIssueSuccessfullyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeIssueSuccessfullyMutation, { data, loading, error }] = useCloseIssueSuccessfullyMutation({
 *   variables: {
 *      closeIssueSuccessfullyId: // value for 'closeIssueSuccessfullyId'
 *   },
 * });
 */
export function useCloseIssueSuccessfullyMutation(baseOptions?: Apollo.MutationHookOptions<CloseIssueSuccessfullyMutation, CloseIssueSuccessfullyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseIssueSuccessfullyMutation, CloseIssueSuccessfullyMutationVariables>(CloseIssueSuccessfullyDocument, options);
      }
export type CloseIssueSuccessfullyMutationHookResult = ReturnType<typeof useCloseIssueSuccessfullyMutation>;
export type CloseIssueSuccessfullyMutationResult = Apollo.MutationResult<CloseIssueSuccessfullyMutation>;
export type CloseIssueSuccessfullyMutationOptions = Apollo.BaseMutationOptions<CloseIssueSuccessfullyMutation, CloseIssueSuccessfullyMutationVariables>;
export const CloseIssueUnsuccessfullyDocument = gql`
    mutation CloseIssueUnsuccessfully($closeIssueUnsuccessfullyId: Int!, $reason: String!) {
  closeIssueUnsuccessfully(id: $closeIssueUnsuccessfullyId, reason: $reason) {
    id
    title
    description
    speciality {
      name
    }
    country {
      name
    }
    city {
      name
    }
  }
}
    `;
export type CloseIssueUnsuccessfullyMutationFn = Apollo.MutationFunction<CloseIssueUnsuccessfullyMutation, CloseIssueUnsuccessfullyMutationVariables>;

/**
 * __useCloseIssueUnsuccessfullyMutation__
 *
 * To run a mutation, you first call `useCloseIssueUnsuccessfullyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseIssueUnsuccessfullyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeIssueUnsuccessfullyMutation, { data, loading, error }] = useCloseIssueUnsuccessfullyMutation({
 *   variables: {
 *      closeIssueUnsuccessfullyId: // value for 'closeIssueUnsuccessfullyId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCloseIssueUnsuccessfullyMutation(baseOptions?: Apollo.MutationHookOptions<CloseIssueUnsuccessfullyMutation, CloseIssueUnsuccessfullyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseIssueUnsuccessfullyMutation, CloseIssueUnsuccessfullyMutationVariables>(CloseIssueUnsuccessfullyDocument, options);
      }
export type CloseIssueUnsuccessfullyMutationHookResult = ReturnType<typeof useCloseIssueUnsuccessfullyMutation>;
export type CloseIssueUnsuccessfullyMutationResult = Apollo.MutationResult<CloseIssueUnsuccessfullyMutation>;
export type CloseIssueUnsuccessfullyMutationOptions = Apollo.BaseMutationOptions<CloseIssueUnsuccessfullyMutation, CloseIssueUnsuccessfullyMutationVariables>;
export const CreateEmptyDocumentDocument = gql`
    mutation CreateEmptyDocument($payload: CreateEmptyDocumentInput!) {
  createEmptyDocument(payload: $payload) {
    id
    title
  }
}
    `;
export type CreateEmptyDocumentMutationFn = Apollo.MutationFunction<CreateEmptyDocumentMutation, CreateEmptyDocumentMutationVariables>;

/**
 * __useCreateEmptyDocumentMutation__
 *
 * To run a mutation, you first call `useCreateEmptyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyDocumentMutation, { data, loading, error }] = useCreateEmptyDocumentMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateEmptyDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmptyDocumentMutation, CreateEmptyDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmptyDocumentMutation, CreateEmptyDocumentMutationVariables>(CreateEmptyDocumentDocument, options);
      }
export type CreateEmptyDocumentMutationHookResult = ReturnType<typeof useCreateEmptyDocumentMutation>;
export type CreateEmptyDocumentMutationResult = Apollo.MutationResult<CreateEmptyDocumentMutation>;
export type CreateEmptyDocumentMutationOptions = Apollo.BaseMutationOptions<CreateEmptyDocumentMutation, CreateEmptyDocumentMutationVariables>;
export const CreateIssueDocument = gql`
    mutation CreateIssue($issue: IssueCreatePayload!) {
  createIssue(issue: $issue) {
    title
    description
    speciality {
      id
      name
    }
    country {
      id
      name
    }
    city {
      id
      name
    }
    createdAt
    deadline
    status
    totalBids
  }
}
    `;
export type CreateIssueMutationFn = Apollo.MutationFunction<CreateIssueMutation, CreateIssueMutationVariables>;

/**
 * __useCreateIssueMutation__
 *
 * To run a mutation, you first call `useCreateIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueMutation, { data, loading, error }] = useCreateIssueMutation({
 *   variables: {
 *      issue: // value for 'issue'
 *   },
 * });
 */
export function useCreateIssueMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssueMutation, CreateIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIssueMutation, CreateIssueMutationVariables>(CreateIssueDocument, options);
      }
export type CreateIssueMutationHookResult = ReturnType<typeof useCreateIssueMutation>;
export type CreateIssueMutationResult = Apollo.MutationResult<CreateIssueMutation>;
export type CreateIssueMutationOptions = Apollo.BaseMutationOptions<CreateIssueMutation, CreateIssueMutationVariables>;
export const CreateUserSubscriptionDocument = gql`
    mutation CreateUserSubscription($subscriptionId: Int!) {
  createUserSubscription(subscriptionId: $subscriptionId)
}
    `;
export type CreateUserSubscriptionMutationFn = Apollo.MutationFunction<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>;

/**
 * __useCreateUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscriptionMutation, { data, loading, error }] = useCreateUserSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCreateUserSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>(CreateUserSubscriptionDocument, options);
      }
export type CreateUserSubscriptionMutationHookResult = ReturnType<typeof useCreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationResult = Apollo.MutationResult<CreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($deleteDocumentId: Int!) {
  deleteDocument(id: $deleteDocumentId) {
    id
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      deleteDocumentId: // value for 'deleteDocumentId'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const DeleteUserSubscriptionDocument = gql`
    mutation DeleteUserSubscription {
  deleteUserSubscription {
    id
  }
}
    `;
export type DeleteUserSubscriptionMutationFn = Apollo.MutationFunction<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>;

/**
 * __useDeleteUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubscriptionMutation, { data, loading, error }] = useDeleteUserSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>(DeleteUserSubscriptionDocument, options);
      }
export type DeleteUserSubscriptionMutationHookResult = ReturnType<typeof useDeleteUserSubscriptionMutation>;
export type DeleteUserSubscriptionMutationResult = Apollo.MutationResult<DeleteUserSubscriptionMutation>;
export type DeleteUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>;
export const DownloadDocumentDocument = gql`
    mutation DownloadDocument($downloadDocumentId: Int!) {
  downloadDocument(id: $downloadDocumentId)
}
    `;
export type DownloadDocumentMutationFn = Apollo.MutationFunction<DownloadDocumentMutation, DownloadDocumentMutationVariables>;

/**
 * __useDownloadDocumentMutation__
 *
 * To run a mutation, you first call `useDownloadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadDocumentMutation, { data, loading, error }] = useDownloadDocumentMutation({
 *   variables: {
 *      downloadDocumentId: // value for 'downloadDocumentId'
 *   },
 * });
 */
export function useDownloadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DownloadDocumentMutation, DownloadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadDocumentMutation, DownloadDocumentMutationVariables>(DownloadDocumentDocument, options);
      }
export type DownloadDocumentMutationHookResult = ReturnType<typeof useDownloadDocumentMutation>;
export type DownloadDocumentMutationResult = Apollo.MutationResult<DownloadDocumentMutation>;
export type DownloadDocumentMutationOptions = Apollo.BaseMutationOptions<DownloadDocumentMutation, DownloadDocumentMutationVariables>;
export const FinishDocumentDocument = gql`
    mutation FinishDocument($finishDocumentId: Int!) {
  finishDocument(id: $finishDocumentId) {
    id
  }
}
    `;
export type FinishDocumentMutationFn = Apollo.MutationFunction<FinishDocumentMutation, FinishDocumentMutationVariables>;

/**
 * __useFinishDocumentMutation__
 *
 * To run a mutation, you first call `useFinishDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishDocumentMutation, { data, loading, error }] = useFinishDocumentMutation({
 *   variables: {
 *      finishDocumentId: // value for 'finishDocumentId'
 *   },
 * });
 */
export function useFinishDocumentMutation(baseOptions?: Apollo.MutationHookOptions<FinishDocumentMutation, FinishDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishDocumentMutation, FinishDocumentMutationVariables>(FinishDocumentDocument, options);
      }
export type FinishDocumentMutationHookResult = ReturnType<typeof useFinishDocumentMutation>;
export type FinishDocumentMutationResult = Apollo.MutationResult<FinishDocumentMutation>;
export type FinishDocumentMutationOptions = Apollo.BaseMutationOptions<FinishDocumentMutation, FinishDocumentMutationVariables>;
export const GetActiveSubscriptionsDocument = gql`
    query GetActiveSubscriptions {
  getActiveSubscriptions {
    id
    name
    description
    price
    period
  }
}
    `;

/**
 * __useGetActiveSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetActiveSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>(GetActiveSubscriptionsDocument, options);
      }
export function useGetActiveSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>(GetActiveSubscriptionsDocument, options);
        }
export type GetActiveSubscriptionsQueryHookResult = ReturnType<typeof useGetActiveSubscriptionsQuery>;
export type GetActiveSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetActiveSubscriptionsLazyQuery>;
export type GetActiveSubscriptionsQueryResult = Apollo.QueryResult<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>;
export const GetDocumentRawHtmlDocument = gql`
    mutation GetDocumentRawHtml($getRawHtmlId: Int!) {
  getDocumentRawHtml(id: $getRawHtmlId)
}
    `;
export type GetDocumentRawHtmlMutationFn = Apollo.MutationFunction<GetDocumentRawHtmlMutation, GetDocumentRawHtmlMutationVariables>;

/**
 * __useGetDocumentRawHtmlMutation__
 *
 * To run a mutation, you first call `useGetDocumentRawHtmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentRawHtmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDocumentRawHtmlMutation, { data, loading, error }] = useGetDocumentRawHtmlMutation({
 *   variables: {
 *      getRawHtmlId: // value for 'getRawHtmlId'
 *   },
 * });
 */
export function useGetDocumentRawHtmlMutation(baseOptions?: Apollo.MutationHookOptions<GetDocumentRawHtmlMutation, GetDocumentRawHtmlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDocumentRawHtmlMutation, GetDocumentRawHtmlMutationVariables>(GetDocumentRawHtmlDocument, options);
      }
export type GetDocumentRawHtmlMutationHookResult = ReturnType<typeof useGetDocumentRawHtmlMutation>;
export type GetDocumentRawHtmlMutationResult = Apollo.MutationResult<GetDocumentRawHtmlMutation>;
export type GetDocumentRawHtmlMutationOptions = Apollo.BaseMutationOptions<GetDocumentRawHtmlMutation, GetDocumentRawHtmlMutationVariables>;
export const GetMySubscriptionDocument = gql`
    query GetMySubscription {
  getMySubscription {
    id
    validUntil
    isActive
    createdAt
    subscription {
      name
      description
    }
  }
}
    `;

/**
 * __useGetMySubscriptionQuery__
 *
 * To run a query within a React component, call `useGetMySubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetMySubscriptionQuery, GetMySubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMySubscriptionQuery, GetMySubscriptionQueryVariables>(GetMySubscriptionDocument, options);
      }
export function useGetMySubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMySubscriptionQuery, GetMySubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMySubscriptionQuery, GetMySubscriptionQueryVariables>(GetMySubscriptionDocument, options);
        }
export type GetMySubscriptionQueryHookResult = ReturnType<typeof useGetMySubscriptionQuery>;
export type GetMySubscriptionLazyQueryHookResult = ReturnType<typeof useGetMySubscriptionLazyQuery>;
export type GetMySubscriptionQueryResult = Apollo.QueryResult<GetMySubscriptionQuery, GetMySubscriptionQueryVariables>;
export const RequestDocumentPaymentLinkDocument = gql`
    mutation RequestDocumentPaymentLink($requestDocumentPaymentLinkId: Int!) {
  requestDocumentPaymentLink(id: $requestDocumentPaymentLinkId)
}
    `;
export type RequestDocumentPaymentLinkMutationFn = Apollo.MutationFunction<RequestDocumentPaymentLinkMutation, RequestDocumentPaymentLinkMutationVariables>;

/**
 * __useRequestDocumentPaymentLinkMutation__
 *
 * To run a mutation, you first call `useRequestDocumentPaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDocumentPaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDocumentPaymentLinkMutation, { data, loading, error }] = useRequestDocumentPaymentLinkMutation({
 *   variables: {
 *      requestDocumentPaymentLinkId: // value for 'requestDocumentPaymentLinkId'
 *   },
 * });
 */
export function useRequestDocumentPaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<RequestDocumentPaymentLinkMutation, RequestDocumentPaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestDocumentPaymentLinkMutation, RequestDocumentPaymentLinkMutationVariables>(RequestDocumentPaymentLinkDocument, options);
      }
export type RequestDocumentPaymentLinkMutationHookResult = ReturnType<typeof useRequestDocumentPaymentLinkMutation>;
export type RequestDocumentPaymentLinkMutationResult = Apollo.MutationResult<RequestDocumentPaymentLinkMutation>;
export type RequestDocumentPaymentLinkMutationOptions = Apollo.BaseMutationOptions<RequestDocumentPaymentLinkMutation, RequestDocumentPaymentLinkMutationVariables>;
export const UpdateDocumentVariablesDocument = gql`
    mutation UpdateDocumentVariables($updateDocumentVariablesId: Int!, $variables: JSONObject!, $activeStep: Int!) {
  updateDocumentVariables(
    id: $updateDocumentVariablesId
    variables: $variables
    activeStep: $activeStep
  ) {
    id
  }
}
    `;
export type UpdateDocumentVariablesMutationFn = Apollo.MutationFunction<UpdateDocumentVariablesMutation, UpdateDocumentVariablesMutationVariables>;

/**
 * __useUpdateDocumentVariablesMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentVariablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentVariablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentVariablesMutation, { data, loading, error }] = useUpdateDocumentVariablesMutation({
 *   variables: {
 *      updateDocumentVariablesId: // value for 'updateDocumentVariablesId'
 *      variables: // value for 'variables'
 *      activeStep: // value for 'activeStep'
 *   },
 * });
 */
export function useUpdateDocumentVariablesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentVariablesMutation, UpdateDocumentVariablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentVariablesMutation, UpdateDocumentVariablesMutationVariables>(UpdateDocumentVariablesDocument, options);
      }
export type UpdateDocumentVariablesMutationHookResult = ReturnType<typeof useUpdateDocumentVariablesMutation>;
export type UpdateDocumentVariablesMutationResult = Apollo.MutationResult<UpdateDocumentVariablesMutation>;
export type UpdateDocumentVariablesMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentVariablesMutation, UpdateDocumentVariablesMutationVariables>;
export const GetAllDocumentCategoriesDocument = gql`
    query GetAllDocumentCategories {
  getAllDocumentCategories {
    id
    name
    rawName {
      sq
      en
    }
    icon
  }
}
    `;

/**
 * __useGetAllDocumentCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllDocumentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDocumentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDocumentCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDocumentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDocumentCategoriesQuery, GetAllDocumentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDocumentCategoriesQuery, GetAllDocumentCategoriesQueryVariables>(GetAllDocumentCategoriesDocument, options);
      }
export function useGetAllDocumentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDocumentCategoriesQuery, GetAllDocumentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDocumentCategoriesQuery, GetAllDocumentCategoriesQueryVariables>(GetAllDocumentCategoriesDocument, options);
        }
export type GetAllDocumentCategoriesQueryHookResult = ReturnType<typeof useGetAllDocumentCategoriesQuery>;
export type GetAllDocumentCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllDocumentCategoriesLazyQuery>;
export type GetAllDocumentCategoriesQueryResult = Apollo.QueryResult<GetAllDocumentCategoriesQuery, GetAllDocumentCategoriesQueryVariables>;
export const GetFirebaseTokenDocument = gql`
    mutation GetFirebaseToken {
  generateFirebaseAuthToken
}
    `;
export type GetFirebaseTokenMutationFn = Apollo.MutationFunction<GetFirebaseTokenMutation, GetFirebaseTokenMutationVariables>;

/**
 * __useGetFirebaseTokenMutation__
 *
 * To run a mutation, you first call `useGetFirebaseTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFirebaseTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFirebaseTokenMutation, { data, loading, error }] = useGetFirebaseTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetFirebaseTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetFirebaseTokenMutation, GetFirebaseTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetFirebaseTokenMutation, GetFirebaseTokenMutationVariables>(GetFirebaseTokenDocument, options);
      }
export type GetFirebaseTokenMutationHookResult = ReturnType<typeof useGetFirebaseTokenMutation>;
export type GetFirebaseTokenMutationResult = Apollo.MutationResult<GetFirebaseTokenMutation>;
export type GetFirebaseTokenMutationOptions = Apollo.BaseMutationOptions<GetFirebaseTokenMutation, GetFirebaseTokenMutationVariables>;
export const AddBidToIssueDocument = gql`
    mutation AddBidToIssue($bid: BidCreatePayload!) {
  addBidToIssue(bid: $bid) {
    issueId
    amount
    createdAt
    id
  }
}
    `;
export type AddBidToIssueMutationFn = Apollo.MutationFunction<AddBidToIssueMutation, AddBidToIssueMutationVariables>;

/**
 * __useAddBidToIssueMutation__
 *
 * To run a mutation, you first call `useAddBidToIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBidToIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBidToIssueMutation, { data, loading, error }] = useAddBidToIssueMutation({
 *   variables: {
 *      bid: // value for 'bid'
 *   },
 * });
 */
export function useAddBidToIssueMutation(baseOptions?: Apollo.MutationHookOptions<AddBidToIssueMutation, AddBidToIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBidToIssueMutation, AddBidToIssueMutationVariables>(AddBidToIssueDocument, options);
      }
export type AddBidToIssueMutationHookResult = ReturnType<typeof useAddBidToIssueMutation>;
export type AddBidToIssueMutationResult = Apollo.MutationResult<AddBidToIssueMutation>;
export type AddBidToIssueMutationOptions = Apollo.BaseMutationOptions<AddBidToIssueMutation, AddBidToIssueMutationVariables>;
export const CloseIssueSuccessfullyByLawyerDocument = gql`
    mutation CloseIssueSuccessfullyByLawyer($closeIssueSuccessfullyByLawyerId: Int!) {
  closeIssueSuccessfullyByLawyer(id: $closeIssueSuccessfullyByLawyerId) {
    title
    description
  }
}
    `;
export type CloseIssueSuccessfullyByLawyerMutationFn = Apollo.MutationFunction<CloseIssueSuccessfullyByLawyerMutation, CloseIssueSuccessfullyByLawyerMutationVariables>;

/**
 * __useCloseIssueSuccessfullyByLawyerMutation__
 *
 * To run a mutation, you first call `useCloseIssueSuccessfullyByLawyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseIssueSuccessfullyByLawyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeIssueSuccessfullyByLawyerMutation, { data, loading, error }] = useCloseIssueSuccessfullyByLawyerMutation({
 *   variables: {
 *      closeIssueSuccessfullyByLawyerId: // value for 'closeIssueSuccessfullyByLawyerId'
 *   },
 * });
 */
export function useCloseIssueSuccessfullyByLawyerMutation(baseOptions?: Apollo.MutationHookOptions<CloseIssueSuccessfullyByLawyerMutation, CloseIssueSuccessfullyByLawyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseIssueSuccessfullyByLawyerMutation, CloseIssueSuccessfullyByLawyerMutationVariables>(CloseIssueSuccessfullyByLawyerDocument, options);
      }
export type CloseIssueSuccessfullyByLawyerMutationHookResult = ReturnType<typeof useCloseIssueSuccessfullyByLawyerMutation>;
export type CloseIssueSuccessfullyByLawyerMutationResult = Apollo.MutationResult<CloseIssueSuccessfullyByLawyerMutation>;
export type CloseIssueSuccessfullyByLawyerMutationOptions = Apollo.BaseMutationOptions<CloseIssueSuccessfullyByLawyerMutation, CloseIssueSuccessfullyByLawyerMutationVariables>;
export const CloseIssueUnsuccessfullyByLawyerDocument = gql`
    mutation CloseIssueUnsuccessfullyByLawyer($closeIssueUnsuccessfullyByLawyerId: Int!, $reason: String!) {
  closeIssueUnsuccessfullyByLawyer(
    id: $closeIssueUnsuccessfullyByLawyerId
    reason: $reason
  ) {
    title
    description
  }
}
    `;
export type CloseIssueUnsuccessfullyByLawyerMutationFn = Apollo.MutationFunction<CloseIssueUnsuccessfullyByLawyerMutation, CloseIssueUnsuccessfullyByLawyerMutationVariables>;

/**
 * __useCloseIssueUnsuccessfullyByLawyerMutation__
 *
 * To run a mutation, you first call `useCloseIssueUnsuccessfullyByLawyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseIssueUnsuccessfullyByLawyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeIssueUnsuccessfullyByLawyerMutation, { data, loading, error }] = useCloseIssueUnsuccessfullyByLawyerMutation({
 *   variables: {
 *      closeIssueUnsuccessfullyByLawyerId: // value for 'closeIssueUnsuccessfullyByLawyerId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCloseIssueUnsuccessfullyByLawyerMutation(baseOptions?: Apollo.MutationHookOptions<CloseIssueUnsuccessfullyByLawyerMutation, CloseIssueUnsuccessfullyByLawyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseIssueUnsuccessfullyByLawyerMutation, CloseIssueUnsuccessfullyByLawyerMutationVariables>(CloseIssueUnsuccessfullyByLawyerDocument, options);
      }
export type CloseIssueUnsuccessfullyByLawyerMutationHookResult = ReturnType<typeof useCloseIssueUnsuccessfullyByLawyerMutation>;
export type CloseIssueUnsuccessfullyByLawyerMutationResult = Apollo.MutationResult<CloseIssueUnsuccessfullyByLawyerMutation>;
export type CloseIssueUnsuccessfullyByLawyerMutationOptions = Apollo.BaseMutationOptions<CloseIssueUnsuccessfullyByLawyerMutation, CloseIssueUnsuccessfullyByLawyerMutationVariables>;
export const RegisterLawyerDocument = gql`
    mutation RegisterLawyer($data: LawyerSignupPayload!) {
  registerLawyer(data: $data) {
    token
  }
}
    `;
export type RegisterLawyerMutationFn = Apollo.MutationFunction<RegisterLawyerMutation, RegisterLawyerMutationVariables>;

/**
 * __useRegisterLawyerMutation__
 *
 * To run a mutation, you first call `useRegisterLawyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterLawyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerLawyerMutation, { data, loading, error }] = useRegisterLawyerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterLawyerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterLawyerMutation, RegisterLawyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterLawyerMutation, RegisterLawyerMutationVariables>(RegisterLawyerDocument, options);
      }
export type RegisterLawyerMutationHookResult = ReturnType<typeof useRegisterLawyerMutation>;
export type RegisterLawyerMutationResult = Apollo.MutationResult<RegisterLawyerMutation>;
export type RegisterLawyerMutationOptions = Apollo.BaseMutationOptions<RegisterLawyerMutation, RegisterLawyerMutationVariables>;
export const UpdateLawyerCvDocument = gql`
    mutation UpdateLawyerCv($data: UpdateLawyerCvPayload!) {
  updateLawyerCv(data: $data) {
    id
  }
}
    `;
export type UpdateLawyerCvMutationFn = Apollo.MutationFunction<UpdateLawyerCvMutation, UpdateLawyerCvMutationVariables>;

/**
 * __useUpdateLawyerCvMutation__
 *
 * To run a mutation, you first call `useUpdateLawyerCvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLawyerCvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLawyerCvMutation, { data, loading, error }] = useUpdateLawyerCvMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLawyerCvMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLawyerCvMutation, UpdateLawyerCvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLawyerCvMutation, UpdateLawyerCvMutationVariables>(UpdateLawyerCvDocument, options);
      }
export type UpdateLawyerCvMutationHookResult = ReturnType<typeof useUpdateLawyerCvMutation>;
export type UpdateLawyerCvMutationResult = Apollo.MutationResult<UpdateLawyerCvMutation>;
export type UpdateLawyerCvMutationOptions = Apollo.BaseMutationOptions<UpdateLawyerCvMutation, UpdateLawyerCvMutationVariables>;
export const UpdateLawyerDiplomaDocument = gql`
    mutation UpdateLawyerDiploma($data: UpdateLawyerDiplomaPayload!) {
  updateLawyerDiploma(data: $data) {
    id
  }
}
    `;
export type UpdateLawyerDiplomaMutationFn = Apollo.MutationFunction<UpdateLawyerDiplomaMutation, UpdateLawyerDiplomaMutationVariables>;

/**
 * __useUpdateLawyerDiplomaMutation__
 *
 * To run a mutation, you first call `useUpdateLawyerDiplomaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLawyerDiplomaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLawyerDiplomaMutation, { data, loading, error }] = useUpdateLawyerDiplomaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLawyerDiplomaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLawyerDiplomaMutation, UpdateLawyerDiplomaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLawyerDiplomaMutation, UpdateLawyerDiplomaMutationVariables>(UpdateLawyerDiplomaDocument, options);
      }
export type UpdateLawyerDiplomaMutationHookResult = ReturnType<typeof useUpdateLawyerDiplomaMutation>;
export type UpdateLawyerDiplomaMutationResult = Apollo.MutationResult<UpdateLawyerDiplomaMutation>;
export type UpdateLawyerDiplomaMutationOptions = Apollo.BaseMutationOptions<UpdateLawyerDiplomaMutation, UpdateLawyerDiplomaMutationVariables>;
export const UpdateLawyerLicenseDocument = gql`
    mutation UpdateLawyerLicense($data: UpdateLawyerLicensePayload!) {
  updateLawyerLicense(data: $data) {
    id
  }
}
    `;
export type UpdateLawyerLicenseMutationFn = Apollo.MutationFunction<UpdateLawyerLicenseMutation, UpdateLawyerLicenseMutationVariables>;

/**
 * __useUpdateLawyerLicenseMutation__
 *
 * To run a mutation, you first call `useUpdateLawyerLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLawyerLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLawyerLicenseMutation, { data, loading, error }] = useUpdateLawyerLicenseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLawyerLicenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLawyerLicenseMutation, UpdateLawyerLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLawyerLicenseMutation, UpdateLawyerLicenseMutationVariables>(UpdateLawyerLicenseDocument, options);
      }
export type UpdateLawyerLicenseMutationHookResult = ReturnType<typeof useUpdateLawyerLicenseMutation>;
export type UpdateLawyerLicenseMutationResult = Apollo.MutationResult<UpdateLawyerLicenseMutation>;
export type UpdateLawyerLicenseMutationOptions = Apollo.BaseMutationOptions<UpdateLawyerLicenseMutation, UpdateLawyerLicenseMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: email_String_NotNull_format_email!, $password: String!) {
  login(data: {email: $email, password: $password}) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ReadUserNotificationsDocument = gql`
    mutation ReadUserNotifications {
  readUserNotifications {
    message
  }
}
    `;
export type ReadUserNotificationsMutationFn = Apollo.MutationFunction<ReadUserNotificationsMutation, ReadUserNotificationsMutationVariables>;

/**
 * __useReadUserNotificationsMutation__
 *
 * To run a mutation, you first call `useReadUserNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadUserNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readUserNotificationsMutation, { data, loading, error }] = useReadUserNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useReadUserNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ReadUserNotificationsMutation, ReadUserNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadUserNotificationsMutation, ReadUserNotificationsMutationVariables>(ReadUserNotificationsDocument, options);
      }
export type ReadUserNotificationsMutationHookResult = ReturnType<typeof useReadUserNotificationsMutation>;
export type ReadUserNotificationsMutationResult = Apollo.MutationResult<ReadUserNotificationsMutation>;
export type ReadUserNotificationsMutationOptions = Apollo.BaseMutationOptions<ReadUserNotificationsMutation, ReadUserNotificationsMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($data: ResetPasswordRequest!) {
  requestPasswordReset(data: $data) {
    message
  }
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResendActivationCodeDocument = gql`
    mutation ResendActivationCode {
  resendActivationCode {
    message
  }
}
    `;
export type ResendActivationCodeMutationFn = Apollo.MutationFunction<ResendActivationCodeMutation, ResendActivationCodeMutationVariables>;

/**
 * __useResendActivationCodeMutation__
 *
 * To run a mutation, you first call `useResendActivationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationCodeMutation, { data, loading, error }] = useResendActivationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendActivationCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendActivationCodeMutation, ResendActivationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendActivationCodeMutation, ResendActivationCodeMutationVariables>(ResendActivationCodeDocument, options);
      }
export type ResendActivationCodeMutationHookResult = ReturnType<typeof useResendActivationCodeMutation>;
export type ResendActivationCodeMutationResult = Apollo.MutationResult<ResendActivationCodeMutation>;
export type ResendActivationCodeMutationOptions = Apollo.BaseMutationOptions<ResendActivationCodeMutation, ResendActivationCodeMutationVariables>;
export const ResendPasswordResetCodeDocument = gql`
    mutation ResendPasswordResetCode($email: String!) {
  resendPasswordResetCode(email: $email) {
    message
  }
}
    `;
export type ResendPasswordResetCodeMutationFn = Apollo.MutationFunction<ResendPasswordResetCodeMutation, ResendPasswordResetCodeMutationVariables>;

/**
 * __useResendPasswordResetCodeMutation__
 *
 * To run a mutation, you first call `useResendPasswordResetCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPasswordResetCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPasswordResetCodeMutation, { data, loading, error }] = useResendPasswordResetCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendPasswordResetCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendPasswordResetCodeMutation, ResendPasswordResetCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendPasswordResetCodeMutation, ResendPasswordResetCodeMutationVariables>(ResendPasswordResetCodeDocument, options);
      }
export type ResendPasswordResetCodeMutationHookResult = ReturnType<typeof useResendPasswordResetCodeMutation>;
export type ResendPasswordResetCodeMutationResult = Apollo.MutationResult<ResendPasswordResetCodeMutation>;
export type ResendPasswordResetCodeMutationOptions = Apollo.BaseMutationOptions<ResendPasswordResetCodeMutation, ResendPasswordResetCodeMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordPayload!) {
  resetPassword(data: $data) {
    message
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetPasswordOfAuthenticatedUserDocument = gql`
    mutation ResetPasswordOfAuthenticatedUser($data: ResetPasswordAuthenticatedUserPayload!) {
  resetPasswordOfAuthenticatedUser(data: $data) {
    message
  }
}
    `;
export type ResetPasswordOfAuthenticatedUserMutationFn = Apollo.MutationFunction<ResetPasswordOfAuthenticatedUserMutation, ResetPasswordOfAuthenticatedUserMutationVariables>;

/**
 * __useResetPasswordOfAuthenticatedUserMutation__
 *
 * To run a mutation, you first call `useResetPasswordOfAuthenticatedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordOfAuthenticatedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordOfAuthenticatedUserMutation, { data, loading, error }] = useResetPasswordOfAuthenticatedUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordOfAuthenticatedUserMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordOfAuthenticatedUserMutation, ResetPasswordOfAuthenticatedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordOfAuthenticatedUserMutation, ResetPasswordOfAuthenticatedUserMutationVariables>(ResetPasswordOfAuthenticatedUserDocument, options);
      }
export type ResetPasswordOfAuthenticatedUserMutationHookResult = ReturnType<typeof useResetPasswordOfAuthenticatedUserMutation>;
export type ResetPasswordOfAuthenticatedUserMutationResult = Apollo.MutationResult<ResetPasswordOfAuthenticatedUserMutation>;
export type ResetPasswordOfAuthenticatedUserMutationOptions = Apollo.BaseMutationOptions<ResetPasswordOfAuthenticatedUserMutation, ResetPasswordOfAuthenticatedUserMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($data: UpdateAccountPayload!) {
  updateAccount(data: $data) {
    id
    email
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const GetAccountByIdDocument = gql`
    query GetAccountById($where: AccountQuery!) {
  account(where: $where) {
    email
    phoneNumber
    createdAt
    role
    accountStatus
    profile {
      ... on ClientProfile {
        name
        lastname
        sex
        clientType
        city {
          name
        }
        country {
          name
        }
        profilePicture {
          sm
          md
          lg
        }
      }
      ... on LawyerProfile {
        name
        lastname
        sex
        city {
          name
        }
        country {
          name
        }
        specialities {
          name
        }
        positions {
          name
        }
        cvUrl
        diplomaUrl
        licenseUrl
        profilePicture {
          sm
          md
          lg
        }
      }
      ... on AdminProfile {
        name
        lastname
        profilePicture {
          sm
          md
          lg
        }
      }
    }
  }
}
    `;

/**
 * __useGetAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAccountByIdQuery, GetAccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountByIdQuery, GetAccountByIdQueryVariables>(GetAccountByIdDocument, options);
      }
export function useGetAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountByIdQuery, GetAccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountByIdQuery, GetAccountByIdQueryVariables>(GetAccountByIdDocument, options);
        }
export type GetAccountByIdQueryHookResult = ReturnType<typeof useGetAccountByIdQuery>;
export type GetAccountByIdLazyQueryHookResult = ReturnType<typeof useGetAccountByIdLazyQuery>;
export type GetAccountByIdQueryResult = Apollo.QueryResult<GetAccountByIdQuery, GetAccountByIdQueryVariables>;
export const GetAllClientInvoicesDocument = gql`
    query GetAllClientInvoices($filter: ClientInvoicePaginatedRequest!) {
  getAllClientInvoices(filter: $filter) {
    totalCount
    pageInfo {
      previousPage
      currentPage
      nextPage
      hasPreviousPage
      hasNextPage
    }
    nodes {
      id
      total
      createdAt
      month
      year
      title
      payments {
        id
        type
      }
      account {
        email
      }
    }
  }
}
    `;

/**
 * __useGetAllClientInvoicesQuery__
 *
 * To run a query within a React component, call `useGetAllClientInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllClientInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllClientInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllClientInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetAllClientInvoicesQuery, GetAllClientInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllClientInvoicesQuery, GetAllClientInvoicesQueryVariables>(GetAllClientInvoicesDocument, options);
      }
export function useGetAllClientInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllClientInvoicesQuery, GetAllClientInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllClientInvoicesQuery, GetAllClientInvoicesQueryVariables>(GetAllClientInvoicesDocument, options);
        }
export type GetAllClientInvoicesQueryHookResult = ReturnType<typeof useGetAllClientInvoicesQuery>;
export type GetAllClientInvoicesLazyQueryHookResult = ReturnType<typeof useGetAllClientInvoicesLazyQuery>;
export type GetAllClientInvoicesQueryResult = Apollo.QueryResult<GetAllClientInvoicesQuery, GetAllClientInvoicesQueryVariables>;
export const GetAllDocumentTemplatesDocument = gql`
    query GetAllDocumentTemplates {
  getAllDocumentTemplates {
    id
    title
    category {
      id
      name
      icon
    }
    blocks
    description
    template
    info
    price
    versionNumber
    hash
    createdAt
    updatedAt
    active
  }
}
    `;

/**
 * __useGetAllDocumentTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAllDocumentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDocumentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDocumentTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDocumentTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDocumentTemplatesQuery, GetAllDocumentTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDocumentTemplatesQuery, GetAllDocumentTemplatesQueryVariables>(GetAllDocumentTemplatesDocument, options);
      }
export function useGetAllDocumentTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDocumentTemplatesQuery, GetAllDocumentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDocumentTemplatesQuery, GetAllDocumentTemplatesQueryVariables>(GetAllDocumentTemplatesDocument, options);
        }
export type GetAllDocumentTemplatesQueryHookResult = ReturnType<typeof useGetAllDocumentTemplatesQuery>;
export type GetAllDocumentTemplatesLazyQueryHookResult = ReturnType<typeof useGetAllDocumentTemplatesLazyQuery>;
export type GetAllDocumentTemplatesQueryResult = Apollo.QueryResult<GetAllDocumentTemplatesQuery, GetAllDocumentTemplatesQueryVariables>;
export const GetAllIssuesDocument = gql`
    query GetAllIssues($filter: IssuePaginatedRequest!) {
  getAllIssues(filter: $filter) {
    nodes {
      cityId
      title
      id
      description
      speciality {
        name
        id
        rawName {
          sq
          en
        }
      }
      countryId
      specialityId
      country {
        name
      }
      city {
        name
      }
      createdAt
      deadline
      status
      issuer {
        profile {
          ... on ClientProfile {
            name
            lastname
            profilePicture {
              sm
              md
              lg
            }
          }
        }
      }
      totalBids
    }
    pageInfo {
      previousPage
      nextPage
      currentPage
      hasPreviousPage
      hasNextPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetAllIssuesQuery__
 *
 * To run a query within a React component, call `useGetAllIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIssuesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllIssuesQuery(baseOptions: Apollo.QueryHookOptions<GetAllIssuesQuery, GetAllIssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllIssuesQuery, GetAllIssuesQueryVariables>(GetAllIssuesDocument, options);
      }
export function useGetAllIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllIssuesQuery, GetAllIssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllIssuesQuery, GetAllIssuesQueryVariables>(GetAllIssuesDocument, options);
        }
export type GetAllIssuesQueryHookResult = ReturnType<typeof useGetAllIssuesQuery>;
export type GetAllIssuesLazyQueryHookResult = ReturnType<typeof useGetAllIssuesLazyQuery>;
export type GetAllIssuesQueryResult = Apollo.QueryResult<GetAllIssuesQuery, GetAllIssuesQueryVariables>;
export const GetAllSubscriptionsDocument = gql`
    query GetAllSubscriptions {
  getAllSubscriptions {
    id
    name
    price
    description
    period
  }
}
    `;

/**
 * __useGetAllSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetAllSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSubscriptionsQuery, GetAllSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSubscriptionsQuery, GetAllSubscriptionsQueryVariables>(GetAllSubscriptionsDocument, options);
      }
export function useGetAllSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSubscriptionsQuery, GetAllSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSubscriptionsQuery, GetAllSubscriptionsQueryVariables>(GetAllSubscriptionsDocument, options);
        }
export type GetAllSubscriptionsQueryHookResult = ReturnType<typeof useGetAllSubscriptionsQuery>;
export type GetAllSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetAllSubscriptionsLazyQuery>;
export type GetAllSubscriptionsQueryResult = Apollo.QueryResult<GetAllSubscriptionsQuery, GetAllSubscriptionsQueryVariables>;
export const GetDocumentsDocument = gql`
    query GetDocuments($request: DocumentRequest!) {
  getDocuments(request: $request) {
    nodes {
      id
      title
      updatedAt
      createdAt
      template {
        title
        category {
          icon
        }
      }
      previewUrl
      status
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      nextPage
      currentPage
      previousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, options);
      }
export function useGetDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, options);
        }
export type GetDocumentsQueryHookResult = ReturnType<typeof useGetDocumentsQuery>;
export type GetDocumentsLazyQueryHookResult = ReturnType<typeof useGetDocumentsLazyQuery>;
export type GetDocumentsQueryResult = Apollo.QueryResult<GetDocumentsQuery, GetDocumentsQueryVariables>;
export const GetInvoiceByIdDocument = gql`
    query GetInvoiceById($getInvoiceByIdId: Int!) {
  getInvoiceById(id: $getInvoiceByIdId) {
    id
    total
    createdAt
    account {
      id
      email
      phoneNumber
      profile {
        ... on LawyerProfile {
          name
          lastname
          profilePicture {
            sm
            md
            lg
          }
        }
        ... on ClientProfile {
          name
          lastname
          profilePicture {
            sm
            md
            lg
          }
        }
      }
    }
    invoiceDetails {
      createdAt
      price
      item {
        ... on AdminIssue {
          id
          title
          description
        }
        ... on Document {
          id
          title
        }
        ... on UserSubscription {
          id
          subscription {
            name
          }
        }
      }
      type
    }
    payments {
      id
      type
      createdAt
    }
  }
}
    `;

/**
 * __useGetInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useGetInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceByIdQuery({
 *   variables: {
 *      getInvoiceByIdId: // value for 'getInvoiceByIdId'
 *   },
 * });
 */
export function useGetInvoiceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(GetInvoiceByIdDocument, options);
      }
export function useGetInvoiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(GetInvoiceByIdDocument, options);
        }
export type GetInvoiceByIdQueryHookResult = ReturnType<typeof useGetInvoiceByIdQuery>;
export type GetInvoiceByIdLazyQueryHookResult = ReturnType<typeof useGetInvoiceByIdLazyQuery>;
export type GetInvoiceByIdQueryResult = Apollo.QueryResult<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>;
export const GetAllInvoicesDocument = gql`
    query GetAllInvoices($filter: InvoicePaginatedRequest!) {
  getAllInvoices(filter: $filter) {
    totalCount
    pageInfo {
      previousPage
      currentPage
      nextPage
      hasPreviousPage
      hasNextPage
    }
    nodes {
      id
      total
      createdAt
      month
      year
      title
      payments {
        id
        type
      }
      account {
        email
      }
    }
  }
}
    `;

/**
 * __useGetAllInvoicesQuery__
 *
 * To run a query within a React component, call `useGetAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(GetAllInvoicesDocument, options);
      }
export function useGetAllInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(GetAllInvoicesDocument, options);
        }
export type GetAllInvoicesQueryHookResult = ReturnType<typeof useGetAllInvoicesQuery>;
export type GetAllInvoicesLazyQueryHookResult = ReturnType<typeof useGetAllInvoicesLazyQuery>;
export type GetAllInvoicesQueryResult = Apollo.QueryResult<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>;
export const GetAdminIssueByIdDocument = gql`
    query GetAdminIssueById($getAdminIssueByIdId: Int!) {
  getAdminIssueById(id: $getAdminIssueByIdId) {
    issue {
      id
      title
      description
      speciality {
        name
      }
      country {
        name
      }
      city {
        name
      }
      createdAt
      deadline
      status
      issuer {
        profile {
          ... on ClientProfile {
            name
            lastname
            profilePicture {
              sm
              md
              lg
            }
          }
        }
      }
      bids {
        title
        description
        createdAt
        amount
        bidder {
          profile {
            ... on LawyerProfile {
              lastname
              name
              profilePicture {
                sm
                md
                lg
              }
            }
          }
        }
      }
    }
    statusChanges {
      id
      reason
      createdAt
      status
      changedBy {
        email
      }
    }
  }
}
    `;

/**
 * __useGetAdminIssueByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminIssueByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminIssueByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminIssueByIdQuery({
 *   variables: {
 *      getAdminIssueByIdId: // value for 'getAdminIssueByIdId'
 *   },
 * });
 */
export function useGetAdminIssueByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminIssueByIdQuery, GetAdminIssueByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminIssueByIdQuery, GetAdminIssueByIdQueryVariables>(GetAdminIssueByIdDocument, options);
      }
export function useGetAdminIssueByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminIssueByIdQuery, GetAdminIssueByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminIssueByIdQuery, GetAdminIssueByIdQueryVariables>(GetAdminIssueByIdDocument, options);
        }
export type GetAdminIssueByIdQueryHookResult = ReturnType<typeof useGetAdminIssueByIdQuery>;
export type GetAdminIssueByIdLazyQueryHookResult = ReturnType<typeof useGetAdminIssueByIdLazyQuery>;
export type GetAdminIssueByIdQueryResult = Apollo.QueryResult<GetAdminIssueByIdQuery, GetAdminIssueByIdQueryVariables>;
export const UsersByRoleDocument = gql`
    query UsersByRole($type: AccountRole!) {
  users(type: $type) {
    id
    email
    accountStatus
    createdAt
    role
    phoneNumber
    language {
      code
      name
    }
    profile {
      ... on ClientProfile {
        name
        lastname
        id
        birthday
        sex
        profilePicture {
          sm
          md
          lg
        }
      }
      ... on LawyerProfile {
        name
        lastname
        profilePicture {
          sm
          md
          lg
        }
      }
      ... on AdminProfile {
        name
        lastname
        profilePicture {
          sm
          md
          lg
        }
      }
    }
  }
}
    `;

/**
 * __useUsersByRoleQuery__
 *
 * To run a query within a React component, call `useUsersByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByRoleQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUsersByRoleQuery(baseOptions: Apollo.QueryHookOptions<UsersByRoleQuery, UsersByRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersByRoleQuery, UsersByRoleQueryVariables>(UsersByRoleDocument, options);
      }
export function useUsersByRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersByRoleQuery, UsersByRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersByRoleQuery, UsersByRoleQueryVariables>(UsersByRoleDocument, options);
        }
export type UsersByRoleQueryHookResult = ReturnType<typeof useUsersByRoleQuery>;
export type UsersByRoleLazyQueryHookResult = ReturnType<typeof useUsersByRoleLazyQuery>;
export type UsersByRoleQueryResult = Apollo.QueryResult<UsersByRoleQuery, UsersByRoleQueryVariables>;
export const CitiesDocument = gql`
    query Cities {
  cities {
    id
    name
    country {
      id
      name
    }
    rawName {
      sq
      en
    }
  }
}
    `;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CitiesByCountryDocument = gql`
    query CitiesByCountry($countryId: Int!) {
  citiesByCountry(countryId: $countryId) {
    name
    id
  }
}
    `;

/**
 * __useCitiesByCountryQuery__
 *
 * To run a query within a React component, call `useCitiesByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesByCountryQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useCitiesByCountryQuery(baseOptions: Apollo.QueryHookOptions<CitiesByCountryQuery, CitiesByCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesByCountryQuery, CitiesByCountryQueryVariables>(CitiesByCountryDocument, options);
      }
export function useCitiesByCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesByCountryQuery, CitiesByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesByCountryQuery, CitiesByCountryQueryVariables>(CitiesByCountryDocument, options);
        }
export type CitiesByCountryQueryHookResult = ReturnType<typeof useCitiesByCountryQuery>;
export type CitiesByCountryLazyQueryHookResult = ReturnType<typeof useCitiesByCountryLazyQuery>;
export type CitiesByCountryQueryResult = Apollo.QueryResult<CitiesByCountryQuery, CitiesByCountryQueryVariables>;
export const GetActiveDocumentTemplatesDocument = gql`
    query GetActiveDocumentTemplates($categoryId: Int) {
  getActiveDocumentTemplates(categoryId: $categoryId) {
    id
    title
    category {
      id
      name
      icon
    }
    price
    versionNumber
    description
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetActiveDocumentTemplatesQuery__
 *
 * To run a query within a React component, call `useGetActiveDocumentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveDocumentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveDocumentTemplatesQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetActiveDocumentTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveDocumentTemplatesQuery, GetActiveDocumentTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveDocumentTemplatesQuery, GetActiveDocumentTemplatesQueryVariables>(GetActiveDocumentTemplatesDocument, options);
      }
export function useGetActiveDocumentTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveDocumentTemplatesQuery, GetActiveDocumentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveDocumentTemplatesQuery, GetActiveDocumentTemplatesQueryVariables>(GetActiveDocumentTemplatesDocument, options);
        }
export type GetActiveDocumentTemplatesQueryHookResult = ReturnType<typeof useGetActiveDocumentTemplatesQuery>;
export type GetActiveDocumentTemplatesLazyQueryHookResult = ReturnType<typeof useGetActiveDocumentTemplatesLazyQuery>;
export type GetActiveDocumentTemplatesQueryResult = Apollo.QueryResult<GetActiveDocumentTemplatesQuery, GetActiveDocumentTemplatesQueryVariables>;
export const GetDocumentByIdDocument = gql`
    query GetDocumentById($getDocumentByIdId: Int!) {
  getDocumentById(id: $getDocumentByIdId) {
    id
    title
    blocks
    info
    variables
    price
    createdAt
    updatedAt
    status
    templateVersionNumber
    canPay
    template {
      id
      title
      updatedAt
      description
    }
    activeStep
    previewUrl
    invoice {
      id
      payseraUrl
      payments {
        id
      }
    }
  }
}
    `;

/**
 * __useGetDocumentByIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByIdQuery({
 *   variables: {
 *      getDocumentByIdId: // value for 'getDocumentByIdId'
 *   },
 * });
 */
export function useGetDocumentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>(GetDocumentByIdDocument, options);
      }
export function useGetDocumentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>(GetDocumentByIdDocument, options);
        }
export type GetDocumentByIdQueryHookResult = ReturnType<typeof useGetDocumentByIdQuery>;
export type GetDocumentByIdLazyQueryHookResult = ReturnType<typeof useGetDocumentByIdLazyQuery>;
export type GetDocumentByIdQueryResult = Apollo.QueryResult<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>;
export const GetBidsForIssueDocument = gql`
    query GetBidsForIssue($issueId: Int!) {
  getBidsForIssue(issueId: $issueId) {
    id
    amount
    issueId
  }
}
    `;

/**
 * __useGetBidsForIssueQuery__
 *
 * To run a query within a React component, call `useGetBidsForIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBidsForIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBidsForIssueQuery({
 *   variables: {
 *      issueId: // value for 'issueId'
 *   },
 * });
 */
export function useGetBidsForIssueQuery(baseOptions: Apollo.QueryHookOptions<GetBidsForIssueQuery, GetBidsForIssueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBidsForIssueQuery, GetBidsForIssueQueryVariables>(GetBidsForIssueDocument, options);
      }
export function useGetBidsForIssueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBidsForIssueQuery, GetBidsForIssueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBidsForIssueQuery, GetBidsForIssueQueryVariables>(GetBidsForIssueDocument, options);
        }
export type GetBidsForIssueQueryHookResult = ReturnType<typeof useGetBidsForIssueQuery>;
export type GetBidsForIssueLazyQueryHookResult = ReturnType<typeof useGetBidsForIssueLazyQuery>;
export type GetBidsForIssueQueryResult = Apollo.QueryResult<GetBidsForIssueQuery, GetBidsForIssueQueryVariables>;
export const GetIssueByIdDocument = gql`
    query GetIssueById($getIssueByIdId: Int!) {
  getIssueById(id: $getIssueByIdId) {
    id
    title
    description
    status
    deadline
    createdAt
    acceptedBid {
      id
      amount
      createdAt
      title
      description
    }
    canChat
    canCloseSuccessfully
    canCloseUnsuccessfully
    canAcceptBid
    acceptedBid {
      id
    }
    bids {
      id
      amount
      title
      description
      createdAt
      bidder {
        profile {
          ... on LawyerProfile {
            lastname
            name
            profilePicture {
              sm
              md
              lg
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetIssueByIdQuery__
 *
 * To run a query within a React component, call `useGetIssueByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueByIdQuery({
 *   variables: {
 *      getIssueByIdId: // value for 'getIssueByIdId'
 *   },
 * });
 */
export function useGetIssueByIdQuery(baseOptions: Apollo.QueryHookOptions<GetIssueByIdQuery, GetIssueByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssueByIdQuery, GetIssueByIdQueryVariables>(GetIssueByIdDocument, options);
      }
export function useGetIssueByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssueByIdQuery, GetIssueByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssueByIdQuery, GetIssueByIdQueryVariables>(GetIssueByIdDocument, options);
        }
export type GetIssueByIdQueryHookResult = ReturnType<typeof useGetIssueByIdQuery>;
export type GetIssueByIdLazyQueryHookResult = ReturnType<typeof useGetIssueByIdLazyQuery>;
export type GetIssueByIdQueryResult = Apollo.QueryResult<GetIssueByIdQuery, GetIssueByIdQueryVariables>;
export const GetIssuesDocument = gql`
    query GetIssues($filter: IssuePaginatedRequest!) {
  getIssues(filter: $filter) {
    nodes {
      city {
        name
      }
      totalBids
      bids {
        amount
        description
        title
        createdAt
        id
        bidder {
          profile {
            ... on LawyerProfile {
              lastname
              name
              profilePicture {
                sm
                md
                lg
              }
            }
          }
        }
      }
      title
      description
      totalBids
      createdAt
      speciality {
        name
      }
      country {
        name
      }
      status
      deadline
      id
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      currentPage
      previousPage
      nextPage
    }
  }
}
    `;

/**
 * __useGetIssuesQuery__
 *
 * To run a query within a React component, call `useGetIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetIssuesQuery(baseOptions: Apollo.QueryHookOptions<GetIssuesQuery, GetIssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssuesQuery, GetIssuesQueryVariables>(GetIssuesDocument, options);
      }
export function useGetIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuesQuery, GetIssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssuesQuery, GetIssuesQueryVariables>(GetIssuesDocument, options);
        }
export type GetIssuesQueryHookResult = ReturnType<typeof useGetIssuesQuery>;
export type GetIssuesLazyQueryHookResult = ReturnType<typeof useGetIssuesLazyQuery>;
export type GetIssuesQueryResult = Apollo.QueryResult<GetIssuesQuery, GetIssuesQueryVariables>;
export const ClientTypesDocument = gql`
    query ClientTypes {
  clientTypes {
    name
    value
  }
}
    `;

/**
 * __useClientTypesQuery__
 *
 * To run a query within a React component, call `useClientTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientTypesQuery(baseOptions?: Apollo.QueryHookOptions<ClientTypesQuery, ClientTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientTypesQuery, ClientTypesQueryVariables>(ClientTypesDocument, options);
      }
export function useClientTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientTypesQuery, ClientTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientTypesQuery, ClientTypesQueryVariables>(ClientTypesDocument, options);
        }
export type ClientTypesQueryHookResult = ReturnType<typeof useClientTypesQuery>;
export type ClientTypesLazyQueryHookResult = ReturnType<typeof useClientTypesLazyQuery>;
export type ClientTypesQueryResult = Apollo.QueryResult<ClientTypesQuery, ClientTypesQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    name
    id
    rawName {
      sq
      en
    }
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const GetBankCredentialsDocument = gql`
    query GetBankCredentials {
  getBankCredentials {
    iban
    telephone
    bank
    address
    business_entity
    title
  }
}
    `;

/**
 * __useGetBankCredentialsQuery__
 *
 * To run a query within a React component, call `useGetBankCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBankCredentialsQuery(baseOptions?: Apollo.QueryHookOptions<GetBankCredentialsQuery, GetBankCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBankCredentialsQuery, GetBankCredentialsQueryVariables>(GetBankCredentialsDocument, options);
      }
export function useGetBankCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankCredentialsQuery, GetBankCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBankCredentialsQuery, GetBankCredentialsQueryVariables>(GetBankCredentialsDocument, options);
        }
export type GetBankCredentialsQueryHookResult = ReturnType<typeof useGetBankCredentialsQuery>;
export type GetBankCredentialsLazyQueryHookResult = ReturnType<typeof useGetBankCredentialsLazyQuery>;
export type GetBankCredentialsQueryResult = Apollo.QueryResult<GetBankCredentialsQuery, GetBankCredentialsQueryVariables>;
export const GetChatInfoDocument = gql`
    query GetChatInfo($issueId: Int!) {
  getChatInfo(issueId: $issueId) {
    threadId
    recipient {
      id
      email
      phoneNumber
      profile {
        ... on ClientProfile {
          name
          lastname
          city {
            name
          }
          country {
            name
          }
          profilePicture {
            sm
            md
            lg
          }
        }
        ... on LawyerProfile {
          name
          lastname
          city {
            name
          }
          country {
            name
          }
          profilePicture {
            sm
            md
            lg
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetChatInfoQuery__
 *
 * To run a query within a React component, call `useGetChatInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatInfoQuery({
 *   variables: {
 *      issueId: // value for 'issueId'
 *   },
 * });
 */
export function useGetChatInfoQuery(baseOptions: Apollo.QueryHookOptions<GetChatInfoQuery, GetChatInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatInfoQuery, GetChatInfoQueryVariables>(GetChatInfoDocument, options);
      }
export function useGetChatInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatInfoQuery, GetChatInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatInfoQuery, GetChatInfoQueryVariables>(GetChatInfoDocument, options);
        }
export type GetChatInfoQueryHookResult = ReturnType<typeof useGetChatInfoQuery>;
export type GetChatInfoLazyQueryHookResult = ReturnType<typeof useGetChatInfoLazyQuery>;
export type GetChatInfoQueryResult = Apollo.QueryResult<GetChatInfoQuery, GetChatInfoQueryVariables>;
export const GetMyInvoiceByIdDocument = gql`
    query GetMyInvoiceById($getInvoiceByIdId: Int!) {
  getMyInvoiceById(id: $getInvoiceByIdId) {
    id
    total
    payseraUrl
    createdAt
    account {
      id
      email
      phoneNumber
      profile {
        ... on LawyerProfile {
          name
          lastname
          profilePicture {
            sm
            md
            lg
          }
        }
        ... on ClientProfile {
          name
          lastname
          profilePicture {
            sm
            md
            lg
          }
        }
      }
    }
    invoiceDetails {
      createdAt
      price
      type
      item {
        ... on LawyerIssue {
          id
          title
          description
        }
        ... on Document {
          id
          title
        }
        ... on UserSubscription {
          id
          subscription {
            name
          }
        }
      }
    }
    payments {
      id
      type
      createdAt
    }
  }
}
    `;

/**
 * __useGetMyInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useGetMyInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInvoiceByIdQuery({
 *   variables: {
 *      getInvoiceByIdId: // value for 'getInvoiceByIdId'
 *   },
 * });
 */
export function useGetMyInvoiceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMyInvoiceByIdQuery, GetMyInvoiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyInvoiceByIdQuery, GetMyInvoiceByIdQueryVariables>(GetMyInvoiceByIdDocument, options);
      }
export function useGetMyInvoiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyInvoiceByIdQuery, GetMyInvoiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyInvoiceByIdQuery, GetMyInvoiceByIdQueryVariables>(GetMyInvoiceByIdDocument, options);
        }
export type GetMyInvoiceByIdQueryHookResult = ReturnType<typeof useGetMyInvoiceByIdQuery>;
export type GetMyInvoiceByIdLazyQueryHookResult = ReturnType<typeof useGetMyInvoiceByIdLazyQuery>;
export type GetMyInvoiceByIdQueryResult = Apollo.QueryResult<GetMyInvoiceByIdQuery, GetMyInvoiceByIdQueryVariables>;
export const GetMyInvoicesDocument = gql`
    query GetMyInvoices($filter: InvoicePaginatedRequestForLawyer!) {
  getMyInvoices(filter: $filter) {
    nodes {
      id
      total
      createdAt
      title
      month
      year
      payments {
        id
        type
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      nextPage
      currentPage
      previousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetMyInvoicesQuery__
 *
 * To run a query within a React component, call `useGetMyInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMyInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>(GetMyInvoicesDocument, options);
      }
export function useGetMyInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>(GetMyInvoicesDocument, options);
        }
export type GetMyInvoicesQueryHookResult = ReturnType<typeof useGetMyInvoicesQuery>;
export type GetMyInvoicesLazyQueryHookResult = ReturnType<typeof useGetMyInvoicesLazyQuery>;
export type GetMyInvoicesQueryResult = Apollo.QueryResult<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($filter: PaginatedNotificationRequest!) {
  getNotifications(filter: $filter) {
    nodes {
      id
      sender {
        name
        lastname
      }
      type
      createdAt
      url
      title
      description
      status
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      nextPage
      currentPage
      previousPage
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetPresignedPublicS3UrlDocument = gql`
    query GetPresignedPublicS3Url($fileName: String!) {
  getPresignedPublicS3Url(data: {fileName: $fileName}) {
    key
    url
    expiry
    contentType
  }
}
    `;

/**
 * __useGetPresignedPublicS3UrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedPublicS3UrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedPublicS3UrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedPublicS3UrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetPresignedPublicS3UrlQuery(baseOptions: Apollo.QueryHookOptions<GetPresignedPublicS3UrlQuery, GetPresignedPublicS3UrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresignedPublicS3UrlQuery, GetPresignedPublicS3UrlQueryVariables>(GetPresignedPublicS3UrlDocument, options);
      }
export function useGetPresignedPublicS3UrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresignedPublicS3UrlQuery, GetPresignedPublicS3UrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresignedPublicS3UrlQuery, GetPresignedPublicS3UrlQueryVariables>(GetPresignedPublicS3UrlDocument, options);
        }
export type GetPresignedPublicS3UrlQueryHookResult = ReturnType<typeof useGetPresignedPublicS3UrlQuery>;
export type GetPresignedPublicS3UrlLazyQueryHookResult = ReturnType<typeof useGetPresignedPublicS3UrlLazyQuery>;
export type GetPresignedPublicS3UrlQueryResult = Apollo.QueryResult<GetPresignedPublicS3UrlQuery, GetPresignedPublicS3UrlQueryVariables>;
export const GetPresignedS3UrlDocument = gql`
    query GetPresignedS3Url($fileName: String!) {
  getPresignedS3Url(data: {fileName: $fileName}) {
    key
    url
    expiry
    contentType
  }
}
    `;

/**
 * __useGetPresignedS3UrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedS3UrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedS3UrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedS3UrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetPresignedS3UrlQuery(baseOptions: Apollo.QueryHookOptions<GetPresignedS3UrlQuery, GetPresignedS3UrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPresignedS3UrlQuery, GetPresignedS3UrlQueryVariables>(GetPresignedS3UrlDocument, options);
      }
export function useGetPresignedS3UrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPresignedS3UrlQuery, GetPresignedS3UrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPresignedS3UrlQuery, GetPresignedS3UrlQueryVariables>(GetPresignedS3UrlDocument, options);
        }
export type GetPresignedS3UrlQueryHookResult = ReturnType<typeof useGetPresignedS3UrlQuery>;
export type GetPresignedS3UrlLazyQueryHookResult = ReturnType<typeof useGetPresignedS3UrlLazyQuery>;
export type GetPresignedS3UrlQueryResult = Apollo.QueryResult<GetPresignedS3UrlQuery, GetPresignedS3UrlQueryVariables>;
export const GetUnreadNotificationCountDocument = gql`
    query GetUnreadNotificationCount {
  getUnreadNotificationCount
}
    `;

/**
 * __useGetUnreadNotificationCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadNotificationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadNotificationCountQuery(baseOptions?: Apollo.QueryHookOptions<GetUnreadNotificationCountQuery, GetUnreadNotificationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadNotificationCountQuery, GetUnreadNotificationCountQueryVariables>(GetUnreadNotificationCountDocument, options);
      }
export function useGetUnreadNotificationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadNotificationCountQuery, GetUnreadNotificationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadNotificationCountQuery, GetUnreadNotificationCountQueryVariables>(GetUnreadNotificationCountDocument, options);
        }
export type GetUnreadNotificationCountQueryHookResult = ReturnType<typeof useGetUnreadNotificationCountQuery>;
export type GetUnreadNotificationCountLazyQueryHookResult = ReturnType<typeof useGetUnreadNotificationCountLazyQuery>;
export type GetUnreadNotificationCountQueryResult = Apollo.QueryResult<GetUnreadNotificationCountQuery, GetUnreadNotificationCountQueryVariables>;
export const GetUrlFromKeyDocument = gql`
    query GetUrlFromKey($key: String!) {
  getUrlFromKey(key: $key)
}
    `;

/**
 * __useGetUrlFromKeyQuery__
 *
 * To run a query within a React component, call `useGetUrlFromKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlFromKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlFromKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetUrlFromKeyQuery(baseOptions: Apollo.QueryHookOptions<GetUrlFromKeyQuery, GetUrlFromKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlFromKeyQuery, GetUrlFromKeyQueryVariables>(GetUrlFromKeyDocument, options);
      }
export function useGetUrlFromKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlFromKeyQuery, GetUrlFromKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlFromKeyQuery, GetUrlFromKeyQueryVariables>(GetUrlFromKeyDocument, options);
        }
export type GetUrlFromKeyQueryHookResult = ReturnType<typeof useGetUrlFromKeyQuery>;
export type GetUrlFromKeyLazyQueryHookResult = ReturnType<typeof useGetUrlFromKeyLazyQuery>;
export type GetUrlFromKeyQueryResult = Apollo.QueryResult<GetUrlFromKeyQuery, GetUrlFromKeyQueryVariables>;
export const LanguagesDocument = gql`
    query Languages {
  languages {
    code
    name
  }
}
    `;

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
      }
export function useLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
        }
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>;
export const GetIssuesThatLawyerCanBidDocument = gql`
    query GetIssuesThatLawyerCanBid($filter: IssuePaginatedRequest!) {
  getIssuesThatLawyerCanBid(filter: $filter) {
    nodes {
      id
      title
      description
      hasBid
      issuer {
        profile {
          ... on ClientProfile {
            name
            lastname
            profilePicture {
              sm
              md
              lg
            }
          }
        }
      }
      city {
        name
      }
      speciality {
        name
      }
      deadline
      status
      createdAt
      country {
        name
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      currentPage
      nextPage
      previousPage
    }
  }
}
    `;

/**
 * __useGetIssuesThatLawyerCanBidQuery__
 *
 * To run a query within a React component, call `useGetIssuesThatLawyerCanBidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesThatLawyerCanBidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesThatLawyerCanBidQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetIssuesThatLawyerCanBidQuery(baseOptions: Apollo.QueryHookOptions<GetIssuesThatLawyerCanBidQuery, GetIssuesThatLawyerCanBidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssuesThatLawyerCanBidQuery, GetIssuesThatLawyerCanBidQueryVariables>(GetIssuesThatLawyerCanBidDocument, options);
      }
export function useGetIssuesThatLawyerCanBidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuesThatLawyerCanBidQuery, GetIssuesThatLawyerCanBidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssuesThatLawyerCanBidQuery, GetIssuesThatLawyerCanBidQueryVariables>(GetIssuesThatLawyerCanBidDocument, options);
        }
export type GetIssuesThatLawyerCanBidQueryHookResult = ReturnType<typeof useGetIssuesThatLawyerCanBidQuery>;
export type GetIssuesThatLawyerCanBidLazyQueryHookResult = ReturnType<typeof useGetIssuesThatLawyerCanBidLazyQuery>;
export type GetIssuesThatLawyerCanBidQueryResult = Apollo.QueryResult<GetIssuesThatLawyerCanBidQuery, GetIssuesThatLawyerCanBidQueryVariables>;
export const GetIssuesThatLawyerHasBidDocument = gql`
    query GetIssuesThatLawyerHasBid($filter: IssuePaginatedRequest!) {
  getIssuesThatLawyerHasBid(filter: $filter) {
    nodes {
      id
      title
      description
      issuer {
        profile {
          ... on ClientProfile {
            name
            lastname
            profilePicture {
              sm
              md
              lg
            }
          }
        }
      }
      city {
        name
      }
      speciality {
        name
      }
      deadline
      status
      createdAt
      country {
        name
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      currentPage
      nextPage
      previousPage
    }
  }
}
    `;

/**
 * __useGetIssuesThatLawyerHasBidQuery__
 *
 * To run a query within a React component, call `useGetIssuesThatLawyerHasBidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesThatLawyerHasBidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesThatLawyerHasBidQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetIssuesThatLawyerHasBidQuery(baseOptions: Apollo.QueryHookOptions<GetIssuesThatLawyerHasBidQuery, GetIssuesThatLawyerHasBidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssuesThatLawyerHasBidQuery, GetIssuesThatLawyerHasBidQueryVariables>(GetIssuesThatLawyerHasBidDocument, options);
      }
export function useGetIssuesThatLawyerHasBidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuesThatLawyerHasBidQuery, GetIssuesThatLawyerHasBidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssuesThatLawyerHasBidQuery, GetIssuesThatLawyerHasBidQueryVariables>(GetIssuesThatLawyerHasBidDocument, options);
        }
export type GetIssuesThatLawyerHasBidQueryHookResult = ReturnType<typeof useGetIssuesThatLawyerHasBidQuery>;
export type GetIssuesThatLawyerHasBidLazyQueryHookResult = ReturnType<typeof useGetIssuesThatLawyerHasBidLazyQuery>;
export type GetIssuesThatLawyerHasBidQueryResult = Apollo.QueryResult<GetIssuesThatLawyerHasBidQuery, GetIssuesThatLawyerHasBidQueryVariables>;
export const GetLawyerIssueByIdDocument = gql`
    query GetLawyerIssueById($getLawyerIssueByIdId: Int!) {
  getLawyerIssueById(id: $getLawyerIssueByIdId) {
    id
    title
    description
    speciality {
      name
    }
    hasBid
    cityId
    countryId
    specialityId
    country {
      name
    }
    city {
      name
    }
    canChat
    createdAt
    deadline
    status
    canCloseSuccessfully
    canCloseUnsuccessfully
    canBid
    myBid {
      title
      description
      amount
      createdAt
    }
    issuer {
      profile {
        ... on ClientProfile {
          name
          lastname
          profilePicture {
            sm
            md
            lg
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetLawyerIssueByIdQuery__
 *
 * To run a query within a React component, call `useGetLawyerIssueByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLawyerIssueByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLawyerIssueByIdQuery({
 *   variables: {
 *      getLawyerIssueByIdId: // value for 'getLawyerIssueByIdId'
 *   },
 * });
 */
export function useGetLawyerIssueByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLawyerIssueByIdQuery, GetLawyerIssueByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLawyerIssueByIdQuery, GetLawyerIssueByIdQueryVariables>(GetLawyerIssueByIdDocument, options);
      }
export function useGetLawyerIssueByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLawyerIssueByIdQuery, GetLawyerIssueByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLawyerIssueByIdQuery, GetLawyerIssueByIdQueryVariables>(GetLawyerIssueByIdDocument, options);
        }
export type GetLawyerIssueByIdQueryHookResult = ReturnType<typeof useGetLawyerIssueByIdQuery>;
export type GetLawyerIssueByIdLazyQueryHookResult = ReturnType<typeof useGetLawyerIssueByIdLazyQuery>;
export type GetLawyerIssueByIdQueryResult = Apollo.QueryResult<GetLawyerIssueByIdQuery, GetLawyerIssueByIdQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    accountStatus
    createdAt
    role
    phoneNumber
    language {
      code
      name
    }
    profile {
      ... on ClientProfile {
        name
        lastname
        id
        birthday
        sex
        profilePicture {
          sm
          md
          lg
        }
        profilePictureRaw {
          key
          contentType
        }
      }
      ... on LawyerProfile {
        name
        lastname
        profilePicture {
          sm
          md
          lg
        }
        profilePictureRaw {
          key
          contentType
        }
        cvUrl
        diplomaUrl
        licenseUrl
      }
      ... on AdminProfile {
        name
        lastname
        profilePicture {
          sm
          md
          lg
        }
        profilePictureRaw {
          key
          contentType
        }
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PositionsDocument = gql`
    query Positions {
  positions {
    name
    id
    rawName {
      sq
      en
    }
  }
}
    `;

/**
 * __usePositionsQuery__
 *
 * To run a query within a React component, call `usePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePositionsQuery(baseOptions?: Apollo.QueryHookOptions<PositionsQuery, PositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositionsQuery, PositionsQueryVariables>(PositionsDocument, options);
      }
export function usePositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositionsQuery, PositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositionsQuery, PositionsQueryVariables>(PositionsDocument, options);
        }
export type PositionsQueryHookResult = ReturnType<typeof usePositionsQuery>;
export type PositionsLazyQueryHookResult = ReturnType<typeof usePositionsLazyQuery>;
export type PositionsQueryResult = Apollo.QueryResult<PositionsQuery, PositionsQueryVariables>;
export const SpecialitiesDocument = gql`
    query Specialities {
  specialities {
    name
    id
    rawName {
      sq
      en
    }
  }
}
    `;

/**
 * __useSpecialitiesQuery__
 *
 * To run a query within a React component, call `useSpecialitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpecialitiesQuery(baseOptions?: Apollo.QueryHookOptions<SpecialitiesQuery, SpecialitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecialitiesQuery, SpecialitiesQueryVariables>(SpecialitiesDocument, options);
      }
export function useSpecialitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialitiesQuery, SpecialitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecialitiesQuery, SpecialitiesQueryVariables>(SpecialitiesDocument, options);
        }
export type SpecialitiesQueryHookResult = ReturnType<typeof useSpecialitiesQuery>;
export type SpecialitiesLazyQueryHookResult = ReturnType<typeof useSpecialitiesLazyQuery>;
export type SpecialitiesQueryResult = Apollo.QueryResult<SpecialitiesQuery, SpecialitiesQueryVariables>;