import { Box, Select, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useMemo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { CardHeader } from '../../../components/Card/CardHeader';
import { Link } from '../../../components/Link';
import { Loader } from '../../../components/Loader';
import { TablePagination } from '../../../components/Pagination';
import { AccountRole, Invoice, useUsersByRoleQuery } from '../../../graphql';
import { useInvoices } from '../../../hooks/useInvoices';
import { getMonthName } from '../../../utils/month-utils';
import { IColumn, SimpleTable } from '../../../utils/table';

export const LawyerInvoicesListPage = () => {
  const {
    items,
    loading,
    getNextPage,
    getPreviousPage,
    pageInfo,
    totalCount,
    showEmptyResults,
    month,
    setMonth,
    year,
    setYear,
    accountId,
    setAccount,
  } = useInvoices();

  const { data: lawyers, loading: lawyersLoading } = useUsersByRoleQuery({
    variables: { type: AccountRole.Lawyer },
  });

  const months = useMemo(() => {
    return [
      { name: 'Janar', value: 1 },
      { name: 'Shkurt', value: 2 },
      { name: 'Mars', value: 3 },
      { name: 'Prill', value: 4 },
      { name: 'Maj', value: 5 },
      { name: 'Qershor', value: 6 },
      { name: 'Korrik', value: 7 },
      { name: 'Gusht', value: 8 },
      { name: 'Shtator', value: 9 },
      { name: 'Tetor', value: 10 },
      { name: 'Nëntor', value: 11 },
      { name: 'Dhjetor', value: 12 },
    ];
  }, []);

  const years = useMemo(() => {
    const begin = 2022;
    const last = new Date().getFullYear();

    const generateConsequentNumbersBetween = (begin: number, last: number) => {
      const numbers = [];

      for (let i = begin; i <= last; i++) {
        numbers.push(i);
      }

      return numbers;
    };

    return generateConsequentNumbersBetween(begin, last);
  }, []);

  const columns: IColumn[] = [
    {
      Header: 'Titulli',
      accessor: 'title',
      tdParams: {
        width: '20%',
      },
    },
    {
      Header: 'Email i Avokatit',
      accessor: 'account.email',
      tdParams: {
        width: '15%',
      },
    },
    {
      Header: 'Totali',
      accessor: '',
      tdParams: {
        width: '20%',
        textAlign: 'right',
      },
      Cell: function totalCell(cell: any, row: any) {
        return <Text>{row.total.toLocaleString()} EUR</Text>;
      },
    },
    {
      Header: 'Muaji',
      accessor: '',
      tdParams: {
        width: '10%',
        textAlign: 'center',
      },
      Cell: function monthCell(cell: any, row: any) {
        return <Text>{getMonthName(row.month)}</Text>;
      },
    },
    {
      Header: 'Viti',
      accessor: 'year',
      tdParams: {
        width: '10%',
      },
    },
    {
      Header: 'Data',
      accessor: '',
      tdParams: {
        width: '20%',
      },
      Cell: function dateCell(cell: any, row: any) {
        return (
          <Text>{moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
        );
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function DetailsCell(cell: any, row: Invoice) {
        return <Link href={`/admin/invoice/${row.id}`}>Shiko detajet</Link>;
      },
      tdParams: {
        textAlign: 'right',
        width: '15%',
      },
    },
    {
      Header: 'Paguar',
      accessor: '',
      Cell: function PaymentCell(cell: any, row: Invoice) {
        return Boolean(row.payments.length) ? (
          <FaCheckCircle color="green"></FaCheckCircle>
        ) : (
          <Box></Box>
        );
      },
      tdParams: {
        width: '10%',
      },
    },
  ];

  return (
    <Box>
      <CardHeader
        title="Lista e faturave të avokatëve"
        action={
          <Box className="issue-filters">
            <Stack
              spacing="4"
              direction={{ base: 'column', md: 'row' }}
              justify="space-between"
            >
              <Select
                size={'sm'}
                padding={1}
                value={accountId || undefined}
                onChange={(e) => setAccount(Number(e.target.value))}
                placeholder="Zgjidhni Avokatin"
                disabled={lawyersLoading}
              >
                {(lawyers?.users || []).map((lawyer) => (
                  <option key={lawyer!.id} value={lawyer!.id}>
                    {lawyer?.profile?.name} {lawyer?.profile?.lastname}
                  </option>
                ))}
              </Select>

              {/** Month Filter */}
              <Select
                size={'sm'}
                padding={1}
                value={month || undefined}
                onChange={(e) => setMonth(Number(e.target.value))}
                placeholder="Zgjidhni muajin"
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.name}
                  </option>
                ))}
              </Select>

              {/** Year Filter */}
              <Select
                size={'sm'}
                padding={1}
                value={year || undefined}
                onChange={(e) => setYear(Number(e.target.value))}
                placeholder="Zgjidhni vitin"
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </Stack>
          </Box>
        }
      />

      {loading ? (
        <Loader />
      ) : showEmptyResults && items.length === 0 && !loading ? null : (
        <SimpleTable columns={columns} data={items} />
      )}
      {pageInfo && (
        <TablePagination
          total={totalCount}
          pageInfo={pageInfo}
          onNextPage={getNextPage}
          onPreviousPage={getPreviousPage}
        />
      )}
    </Box>
  );
};
