import React from 'react';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { Optional, MultiLanguageInputControl } from '../components/Form';

interface MultiLangString {
  sq: string;
  en: string;
}

export interface ICreatePositionValues {
  name: MultiLangString;
}

interface IPositionForm {
  onSubmit: (values: ICreatePositionValues) => void;
  initialValues?: Optional<ICreatePositionValues>;
}

export const PositionForm: React.FC<IPositionForm> = ({
  onSubmit,
  initialValues,
}) => {
  const validate = (values: ICreatePositionValues) => {
    const errors: { [key: string]: string } = {};

    if (!values.name) {
      errors.name = 'Fushë e detyruar';
    }

    if (!values.name?.sq || !values.name?.en) {
      errors.name = 'Fushë e detyruar';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        errors,
        submitting,
        pristine,
        values,
      }) => (
        <Box as="form" onSubmit={handleSubmit}>
          <MultiLanguageInputControl name="name" label="Pozicioni" />

          <ButtonGroup py="6" spacing={4}>
            <Button
              isLoading={submitting}
              loadingText="Duke dërguar"
              variantColor="teal"
              type="submit"
            >
              Ruaj
            </Button>
            <Button
              variantColor="teal"
              variant="outline"
              // @ts-ignore
              onClick={form.reset}
              isDisabled={submitting || pristine}
            >
              Pastro
            </Button>
          </ButtonGroup>
        </Box>
      )}
    />
  );
};
