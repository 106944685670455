import { Box } from '@chakra-ui/layout';
import { useEffect, useRef, useState } from 'react';
import {
  getFirestore,
  collection,
  query,
  orderBy,
  addDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { initializeApp } from 'firebase/app';
import {
  ChatInfo,
  GetChatInfoQuery,
  useGetFirebaseTokenMutation,
  useGetUrlFromKeyLazyQuery,
} from '../../graphql';
import { Loader } from '../../components/Loader';
import {
  Button,
  Input,
  useToast,
  Flex,
  Link,
  Text,
  useDisclosure,
  Tag,
  TagLeftIcon,
  TagLabel,
  Image,
} from '@chakra-ui/react';
import './styles.css';
import { FaFile, FaPlus } from 'react-icons/fa';
import moment from 'moment';
import { AddFileModal } from '../../modals/AddFileModal';
import Linkify from 'linkify-react';

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const firestore = getFirestore(app);
const auth = getAuth(app);

interface Props {
  data: GetChatInfoQuery;
}
export const ChatPage = ({ data }: Props) => {
  const [user] = useAuthState(auth);
  const [getFirebaseToken] = useGetFirebaseTokenMutation();

  useEffect(() => {
    if (data?.getChatInfo.threadId) {
      if (!user) {
        getFirebaseToken().then((res) => {
          if (res.errors) {
            throw new Error(res.errors[0].message);
          }

          const firebaseToken = res.data!.generateFirebaseAuthToken;
          signInWithCustomToken(auth, firebaseToken).then(({ user }) => {
            auth.updateCurrentUser(user);
          });
        });
      }
    }

    return () => {
      auth.signOut();
    };
  }, [data]);

  if (!user || !data?.getChatInfo.threadId) {
    return <Loader />;
  } else {
    return (
      <Box>
        {data?.getChatInfo && <ChatRoom info={data.getChatInfo as ChatInfo} />}
      </Box>
    );
  }
};

type ChatRoomProps = {
  info: ChatInfo;
};

type ChatMessage = {
  type: 'link' | 'text' | 'file';
  text: string;
};

type FileMessage = ChatMessage & {
  contentType: string;
  key: string;
};

const ChatRoom = (props: ChatRoomProps) => {
  const { threadId, recipient } = props.info;
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const dummy = useRef<HTMLDivElement>();
  const input = useRef<HTMLInputElement>();
  const messagesRef = collection(firestore, 'threads', threadId, 'messages');
  const messagesQuery = query(messagesRef, orderBy('createdAt', 'asc'));
  const [messages, loading] = useCollectionData(messagesQuery, {
    idField: 'id',
  });
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [getS3UrlFromKey] = useGetUrlFromKeyLazyQuery();

  useEffect(() => {
    if (dummy.current) {
      dummy.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  useEffect(() => {
    if (messages) {
      const filteredMessages = messages.filter(
        (message) =>
          !message.read &&
          Number(message.senderId) !== Number(auth.currentUser?.uid)
      );

      for (const message of filteredMessages) {
        updateDoc(doc(firestore, 'threads', threadId, 'messages', message.id), {
          read: true,
        }).catch(console.error);
      }
    }
  }, [messages]);

  const handleSendMessage = async (e: any) => {
    e.preventDefault();

    if (!sending && message) {
      setSending(true);
      try {
        setMessage('');
        await sendMessage({
          type: 'text',
          text: message,
        });
        dummy.current!.scrollIntoView({ behavior: 'smooth' });
        input.current?.focus();
      } catch (error) {
        console.error(error);
        toast({
          title: 'Error',
          description: 'Mesazhi nuk mund të dërgohej',
          status: 'error',
          duration: 1000,
        });
      } finally {
        setSending(false);
      }
    }
  };

  const sendFile = async (values: {
    file: { key: string; contentType: string; url: string };
  }) => {
    if (!sending) {
      const { file } = values;
      setSending(true);
      try {
        const url = await getS3UrlFromKey({
          variables: {
            key: file.key,
          },
        });
        await sendMessage({
          type: 'file',
          text: url.data!.getUrlFromKey,
          contentType: file.contentType,
          key: file.key,
        });
        dummy.current!.scrollIntoView({ behavior: 'smooth' });
        onClose();
      } catch (error: any) {
        console.error(error);
        toast({
          title: 'Error',
          description: 'Mesazhi nuk mund të dërgohej',
          status: 'error',
          duration: 1000,
        });

        throw new Error(error.message);
      } finally {
        setSending(false);
      }
    }
  };

  const sendMessage = async (message: ChatMessage | FileMessage) => {
    const uid = auth.currentUser?.uid;

    await addDoc(messagesRef, {
      message,
      senderId: Number(uid),
      recipientId: Number(recipient.id),
      createdAt: new Date(),
      read: false,
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box className="legit-chat">
      {/* <Flex marginY={3} alignItems="center">
        <Avatar
          size={'sm'}
          name={recipient.name}
          lastname={recipient.lastname}
        />
        <Box marginX={3}>
          <Text
            fontSize={'md'}
            fontWeight="semibold"
          >{`${recipient.name} ${recipient.lastname}`}</Text>
        </Box>
      </Flex> */}

      <Box
        className="messages-container"
        background="gray.50"
        borderRadius="md"
        paddingTop="2"
        paddingBottom="2"
        paddingLeft="6"
        paddingRight="6"
        marginBottom="2"
      >
        {(messages || []).map((message) => (
          <SingleMessage message={message as any} key={message.id} />
        ))}
        <Box ref={dummy as any}></Box>
      </Box>

      <Flex width="100%">
        <form style={{ flexGrow: 1 }} onSubmit={handleSendMessage}>
          <Input
            ref={input as any}
            placeholder="Shkruani mesazhin tuaj"
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </form>

        <Button leftIcon={<FaPlus />} onClick={onOpen} marginLeft="5">
          Ngarko dokument
        </Button>
        <AddFileModal
          isOpen={isOpen}
          onClose={onClose}
          sendFile={sendFile}
          sending={sending}
        />
      </Flex>
    </Box>
  );
};

type Message = {
  id: string;
  message: ChatMessage | FileMessage;
  senderId: number;
  recipientId: number;
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
};

const SingleMessage = (props: { message: Message }) => {
  const { message } = props;
  const messageClass =
    message.senderId === Number(auth.currentUser?.uid) ? 'sent' : 'received';

  return (
    <Box className={`single-message ${messageClass}`}>
      <Box>{renderMessage(message.message)}</Box>
      <Text
        fontSize={'smaller'}
        float={'right'}
        marginTop={1}
        colorScheme={'gray'}
      >
        {moment(message.createdAt.seconds * 1000).format('HH:mm DD/MM/YYYY')}
      </Text>
    </Box>
  );
};

const trimStringInMiddle = (str: string) => {
  if (str.length > 30) {
    return `${str.substring(0, 15)}...${str.substring(str.length - 15)}`;
  }
  return str;
};

const renderMessage = (msg: ChatMessage | FileMessage) => {
  switch (msg.type) {
    case 'file':
      if (
        (msg as FileMessage).contentType &&
        (msg as FileMessage).contentType.startsWith('image')
      ) {
        return <Image borderRadius={5} src={msg.text} alt="Chat photo" />;
      }

      return (
        <Link href={msg.text} target="_blank">
          <Tag
            marginY="2"
            cursor={'pointer'}
            size={'lg'}
            variant="subtle"
            colorScheme="gray"
          >
            <TagLeftIcon boxSize="12px" as={FaFile} />
            <TagLabel>
              {trimStringInMiddle((msg as FileMessage).key.split('/')[1])}
            </TagLabel>
          </Tag>
        </Link>
      );
    default:
      return (
        <Linkify
          tagName="p"
          options={{
            attributes: {
              target: '_blank',
              style: { textDecoration: 'underline' },
            },
          }}
        >
          {msg.text}
        </Linkify>
      );
  }
};
