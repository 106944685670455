import { Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/react';
import { CreateBidForm, CreateBidFormValues } from '../forms/CreateBidForm';
import {
  useAddBidToIssueMutation,
  GetLawyerIssueByIdDocument,
} from '../graphql';

type CreateBidProps = {
  isOpen: boolean;
  id: number;
  title: string;
  onClose: () => void;
  refresh: () => void;
};

export const CreateBidModal = (props: CreateBidProps) => {
  const toast = useToast();
  const { isOpen, onClose, id, title } = props;
  const [createBid] = useAddBidToIssueMutation();

  const handleCreateBid = async (payload: CreateBidFormValues) => {
    try {
      await createBid({
        variables: {
          bid: {
            title: payload.title,
            description: payload.description,
            amount: Number(payload.amount),
            issueId: id,
          },
        },
        refetchQueries: [
          {
            query: GetLawyerIssueByIdDocument,
            variables: {
              getLawyerIssueByIdId: id,
            },
          },
        ],
      });
      props.refresh();
      onClose();
      toast({
        title: 'Sukses',
        description: 'Oferta juaj u krijua me sukses',
        status: 'success',
      });
    } catch (error) {
      console.error('err', error);
    }
  };

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dërgo ofertë</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>Po vendosni ofertë mbi çështjen {title}</Text>
          <CreateBidForm onSubmit={handleCreateBid} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
