import * as React from 'react';
import {
  useResendPasswordResetCodeMutation,
  useResetPasswordMutation,
} from '../../graphql';
import { useResetPassword } from '../../hooks';
import { useHistory } from 'react-router';
import { Button } from '@chakra-ui/button';
import { Text } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { InputControl } from '../../components/Form';

export const ResetPasswordForm = () => {
  const history = useHistory();
  const { email, removeResetPasswordEmail, emailEncrypted } =
    useResetPassword();
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [resetPassword] = useResetPasswordMutation();
  const [resendLoading, setResendLoading] = React.useState(false);
  const [resendResetPasswordCode] = useResendPasswordResetCodeMutation();

  const handleResetPassword = async (values: any) => {
    try {
      setSubmitLoading(true);
      await resetPassword({
        variables: {
          data: {
            email: email as string,
            newPassword: values.newPassword,
            newPasswordRepeat: values.newPasswordRepeat,
            resetCode: values.resetCode,
          },
        },
      });
      removeResetPasswordEmail();
      history.push('/login');
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleResendResetPasswordCode = async () => {
    try {
      setResendLoading(true);
      await resendResetPasswordCode({ variables: { email: email as string } });
    } catch (error) {
      console.error(error);
    } finally {
      setResendLoading(false);
    }
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.newPassword) {
      errors.newPassword = 'Fushë e detyruar';
    }

    if (!values.newPasswordRepeat) {
      errors.newPasswordRepeat = 'Fushë e detyruar';
    }

    if (!values.resetCode) {
      errors.resetCode = 'Fushë e detyruar';
    }

    if (values.newPassword !== values.newPasswordRepeat) {
      errors.newPasswordRepeat = 'Fjalëkalimet nuk përputhen';
    }

    if (values.newPassword?.length < 8) {
      errors.newPassword = 'Fjalëkalimi duhet të ketë të paktën 8 karaktere';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={handleResetPassword}
      validate={validate}
      render={({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit}>
          {emailEncrypted && (
            <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
              <Text as="span">
                U dërgua një kod konfirmimi tek email: {emailEncrypted}
              </Text>
            </Text>
          )}

          <InputControl
            label="Fjalëkalimi"
            name="newPassword"
            type="password"
          />

          <Box mt={5}>
            <InputControl
              label="Rivendos fjalëkalimin"
              name="newPasswordRepeat"
              type="password"
            />
          </Box>

          <Box mt={5}>
            <InputControl label="Kodi" name="resetCode" type="text" />
          </Box>

          <Button
            type="submit"
            colorScheme="red"
            size="lg"
            fontSize="md"
            width="full"
            isLoading={submitLoading}
            disabled={submitLoading || resendLoading}
            mt={4}
          >
            Rivendos fjalëkalimin
          </Button>

          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">Nuk ka ardhur ende kodi i konfirmimit?</Text>
            <Button
              width="full"
              variant="link"
              colorScheme="red"
              isLoading={resendLoading}
              onClick={handleResendResetPasswordCode}
              disabled={submitLoading || resendLoading}
              mt={4}
            >
              Ridërgo kodin
            </Button>
          </Text>
        </Box>
      )}
    />
  );
};
