import { ClientSignupForm } from '../../forms/ClientSignup';
import { Alert, AlertIcon, Box, useColorModeValue } from '@chakra-ui/react';
import {
  ClientSignupPayload,
  LanguageCode,
  LawyerSignupPayload,
  useRegisterClientMutation,
} from '../../graphql';
import { Heading, Text } from '@chakra-ui/layout';
import { useHistory } from 'react-router';
import { useAuthToken } from '../../hooks';
import { Link } from '../../components/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Logo } from '../../components/Logo';

export const RegisterPage: React.FC = () => {
  const history = useHistory();
  const { setAuthToken } = useAuthToken();
  const [clientSignup] = useRegisterClientMutation();

  const handleSignUp = async (
    user: ClientSignupPayload | LawyerSignupPayload
  ) => {
    try {
      let response = await clientSignUp(user as ClientSignupPayload);
      if (response.data?.registerClient.token) {
        setAuthToken(response.data.registerClient.token);
        history.push('/activate-account');
      }
    } catch (error) {
      console.error('signup error', error);
    }
  };

  const clientSignUp = async (client: ClientSignupPayload) => {
    const payload = { ...client };
    delete (payload as any).repeatPassword;
    return clientSignup({
      variables: {
        data: {
          ...payload,
          cityId: Number((payload as ClientSignupPayload).cityId),
          countryId: Number((payload as ClientSignupPayload).countryId),
          languageCode: LanguageCode.Sq,
        } as ClientSignupPayload,
      },
    });
  };

  return (
    <>
      <Box
        bg={useColorModeValue('gray.50', 'inherit')}
        minH="100vh"
        py="12"
        px={{ base: '4', lg: '8' }}
      >
        <Box maxW="2xl" mx="auto">
          <Logo mx="auto" h="12" mb={{ base: '10', md: '20' }} />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Regjistrohu
          </Heading>
          <Box
            mt="4"
            mb="8"
            align="center"
            maxW="2xl"
            fontWeight="medium"
            textAlign="center"
          >
            <Text as="span">Keni një llogari në Legit?</Text>
            <Link href="/login">Identifikohu</Link>
          </Box>
          <Box
            background="white"
            px={{ base: '4', md: '10' }}
            maxWidth="2xl"
            mx="auto"
            my="5"
          >
            <Box display={'block'} paddingTop={6}>
              <Alert status="info" colorScheme={'gray'} variant="top-accent">
                <AlertIcon />
                Përfaqësues ligjor?{' '}
                <Text marginLeft={'10px'}>
                  {' '}
                  <RouterLink to="/register/lawyer">
                    Regjistrohu këtu
                  </RouterLink>
                </Text>
              </Alert>
            </Box>

            <ClientSignupForm onSubmit={handleSignUp as any} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
