import { Flex, Text } from '@chakra-ui/react';

export const TableEmpty = () => {
  return (
    <Flex
      justifyContent="center"
      width="full"
      alignItems="center"
      flexDirection="column"
    >
      <svg
        width="295"
        height="198"
        viewBox="0 0 295 198"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            opacity="0.7"
            d="M202.154 18.2656H93.4976C90.7531 18.2656 88.5283 20.5373 88.5283 23.3395V154.748C88.5283 157.55 90.7531 159.822 93.4976 159.822H202.154C204.898 159.822 207.123 157.55 207.123 154.748V23.3395C207.123 20.5373 204.898 18.2656 202.154 18.2656Z"
            fill="url(#paint0_linear)"
            fillOpacity="0.6"
          />
          <path
            d="M192.19 31.1768H103.47C101.196 31.1768 99.3525 33.059 99.3525 35.3808V144.925C99.3525 147.247 101.196 149.129 103.47 149.129H192.19C194.464 149.129 196.308 147.247 196.308 144.925V35.3808C196.308 33.059 194.464 31.1768 192.19 31.1768Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M184.223 42.9961H111.07V74.8122H184.223V42.9961Z"
            fill="#C4C4D7"
          />
          <path
            d="M184.406 85.0537H111.253V92.8137H184.406V85.0537Z"
            fill="white"
          />
          <path
            d="M138.238 103.055H111.07V130.795H138.238V103.055Z"
            fill="#C4C4D7"
          />
          <path
            d="M184.056 103.055H142.64V108.367H184.056V103.055Z"
            fill="#C4C4D7"
          />
          <path
            d="M184.056 111.616H142.64V116.929H184.056V111.616Z"
            fill="#C4C4D7"
          />
          <path
            d="M184.223 120.178H142.807V125.49H184.223V120.178Z"
            fill="#C4C4D7"
          />
          <path
            d="M202.154 159.789H193.477L223.902 184.655C224.756 185.352 225.846 185.674 226.933 185.551C228.02 185.428 229.014 184.87 229.698 183.998L281.537 117.859C282.217 116.987 282.532 115.876 282.411 114.768C282.291 113.661 281.745 112.647 280.894 111.949L227.56 68.3653C226.706 67.6708 225.618 67.35 224.533 67.473C223.449 67.5961 222.456 68.153 221.772 69.0219L207.123 87.7057V154.715C207.112 156.057 206.585 157.341 205.656 158.29C204.726 159.239 203.469 159.777 202.154 159.789Z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M221.822 84.3025L266.913 121.147L270.665 116.36L225.574 79.5154L221.822 84.3025Z"
            fill="white"
          />
          <path
            d="M207.605 152.79L228.751 170.069L242.152 152.972L221.006 135.692L207.605 152.79Z"
            fill="#C4C4D7"
          />
          <path
            opacity="0.7"
            d="M293.146 108.887L227.802 55.4968C226.769 54.6653 225.456 54.2823 224.148 54.4307C222.84 54.5791 221.642 55.2469 220.812 56.2899L207.123 73.7542V87.7052L221.772 69.0214C222.456 68.1525 223.449 67.5956 224.533 67.4725C225.618 67.3495 226.706 67.6703 227.56 68.3648L280.894 111.949C281.745 112.647 282.291 113.66 282.411 114.768C282.532 115.876 282.218 116.987 281.537 117.858L229.673 183.998C228.989 184.869 227.995 185.428 226.908 185.551C225.821 185.674 224.731 185.351 223.877 184.654L193.452 159.788H180.256L224.746 196.132C225.779 196.964 227.092 197.347 228.4 197.198C229.708 197.05 230.906 196.382 231.736 195.339L293.915 116.034C294.733 114.979 295.111 113.637 294.967 112.299C294.823 110.962 294.169 109.736 293.146 108.887Z"
            fill="url(#paint3_linear)"
            fillOpacity="0.6"
          />
          <path
            d="M216.143 137.088L218.715 133.813L207.123 124.348V129.72L216.143 137.088Z"
            fill="white"
          />
          <path
            d="M207.875 147.645L210.439 144.371L207.123 141.659V147.032L207.875 147.645Z"
            fill="white"
          />
          <path
            d="M262.169 127.214L217.078 90.3662L207.123 103.064V114.405L246.794 146.818L262.169 127.214Z"
            fill="#C4C4D7"
          />
          <path
            opacity="0.7"
            d="M88.5284 154.714V85.5307L75.1656 67.802C74.4984 66.9207 73.5159 66.3457 72.4339 66.2034C71.3519 66.0611 70.2587 66.3631 69.3946 67.0431L14.3232 110.388C13.4595 111.071 12.8962 112.076 12.7569 113.182C12.6175 114.288 12.9135 115.406 13.5799 116.289L65.1269 184.509C65.794 185.391 66.7765 185.966 67.8586 186.108C68.9406 186.25 70.0338 185.948 70.8979 185.268L103.278 159.771H93.5395C92.2205 159.771 90.9547 159.24 90.0166 158.294C89.0785 157.347 88.5438 156.061 88.5284 154.714Z"
            fill="url(#paint4_linear)"
            fillOpacity="0.6"
          />
          <path
            opacity="0.7"
            d="M70.8983 185.286C70.0342 185.966 68.9411 186.268 67.859 186.125C66.777 185.983 65.7945 185.408 65.1273 184.527L13.5804 116.307C12.914 115.423 12.618 114.306 12.7573 113.199C12.8967 112.093 13.46 111.089 14.3237 110.406L69.37 67.0435C70.2341 66.3635 71.3272 66.0615 72.4093 66.2038C73.4913 66.3461 74.4738 66.9211 75.141 67.8024L88.5038 85.5311V71.0344L76.3353 54.8833C75.524 53.8258 74.338 53.1365 73.0332 52.9642C71.7285 52.7919 70.4097 53.1504 69.3616 53.9623L1.93806 107.063C0.902366 107.891 0.227263 109.102 0.0585049 110.435C-0.110253 111.767 0.240873 113.113 1.03608 114.183L62.8389 196.047C63.6481 197.113 64.8363 197.808 66.1452 197.984C67.4541 198.16 68.7781 197.801 69.8293 196.986L117.017 159.831H103.278L70.8983 185.286Z"
            fill="url(#paint5_linear)"
            fillOpacity="0.6"
          />
          <path
            d="M88.5284 154.714V85.5307L75.1656 67.802C74.4984 66.9207 73.5159 66.3457 72.4339 66.2034C71.3519 66.0611 70.2587 66.3631 69.3946 67.0431L14.3232 110.388C13.4595 111.071 12.8962 112.076 12.7569 113.182C12.6175 114.288 12.9135 115.406 13.5799 116.289L65.1269 184.509C65.794 185.391 66.7765 185.966 67.8586 186.108C68.9406 186.25 70.0338 185.948 70.8979 185.268L103.278 159.771H93.5395C92.2205 159.771 90.9547 159.24 90.0166 158.294C89.0785 157.347 88.5438 156.061 88.5284 154.714Z"
            fill="url(#paint6_linear)"
          />
          <path
            d="M88.5284 124.74L52.9834 152.736L68.242 172.929L88.9126 156.659C88.6615 156.042 88.531 155.382 88.5284 154.714V124.74Z"
            fill="#C4C4D7"
          />
          <path
            d="M44.4648 141.216L48.1814 146.145L88.5286 114.363V106.509L44.4648 141.216Z"
            fill="white"
          />
          <path
            d="M42.3948 102.313L25.147 115.895L38.4488 133.505L55.6966 119.924L42.3948 102.313Z"
            fill="#C4C4D7"
          />
          <path
            d="M71.4925 79.4021L45.1909 100.113L47.736 103.483L74.0376 82.7717L71.4925 79.4021Z"
            fill="#C4C4D7"
          />
          <path
            d="M77.0873 86.3611L50.7856 107.072L53.3307 110.442L79.6324 89.7307L77.0873 86.3611Z"
            fill="#C4C4D7"
          />
          <path
            d="M82.1382 93.4512L55.8984 114.139L58.4406 117.5L84.6804 96.8128L82.1382 93.4512Z"
            fill="#C4C4D7"
          />
          <path
            d="M231.176 3.75654e-06H64.4839C54.4233 3.75654e-06 44.7747 4.08068 37.6608 11.3443C30.5469 18.608 26.5503 28.4596 26.5503 38.7319V87.9271L69.545 54.0729C70.5931 53.2609 71.9119 52.9024 73.2166 53.0747C74.5214 53.247 75.7074 53.9364 76.5187 54.9939L88.7123 71.1364V23.4421C88.7233 22.0925 89.2561 20.802 90.1946 19.8517C91.1332 18.9014 92.4015 18.3682 93.7233 18.3682H202.379C203.694 18.3794 204.952 18.9175 205.881 19.8667C206.811 20.8158 207.338 22.0999 207.349 23.4421V126.872V73.8567L221.037 56.4009C221.444 55.88 221.948 55.4462 222.519 55.1243C223.09 54.8024 223.718 54.5988 224.367 54.5251C225.016 54.4515 225.672 54.5092 226.299 54.695C226.925 54.8808 227.51 55.191 228.019 55.6078L269.118 89.1891C269.118 88.848 269.118 88.4984 269.118 88.1488V38.7319C269.119 33.6445 268.138 28.6068 266.232 23.9066C264.326 19.2063 261.531 14.9357 258.007 11.3388C254.484 7.74188 250.3 4.88913 245.697 2.94356C241.093 0.998002 236.159 -0.00223701 231.176 3.75654e-06Z"
            fill="url(#paint7_linear)"
            fillOpacity="0.3"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="148.586"
            y1="21.5061"
            x2="146.879"
            y2="167.223"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.36" stopColor="#DFDFEE" />
            <stop offset="1" stopColor="#DFDFEE" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="17260.4"
            y1="4216.13"
            x2="17260.4"
            y2="22282.9"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.36" stopColor="#DFDFEE" />
            <stop offset="1" stopColor="#DFDFEE" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="-58800.4"
            y1="-14804.8"
            x2="-67695.8"
            y2="-3844.54"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.36" stopColor="#DFDFEE" />
            <stop offset="1" stopColor="#DFDFEE" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="35301.3"
            y1="15043"
            x2="19482.8"
            y2="30439.7"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.36" stopColor="#DFDFEE" />
            <stop offset="1" stopColor="#DFDFEE" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="182142"
            y1="21715"
            x2="190390"
            y2="32517.4"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.36" stopColor="#DFDFEE" />
            <stop offset="1" stopColor="#DFDFEE" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="5655.78"
            y1="14958.2"
            x2="21696.2"
            y2="31368.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.36" stopColor="#DFDFEE" />
            <stop offset="1" stopColor="#DFDFEE" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="181032"
            y1="20238.3"
            x2="190076"
            y2="31724.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.36" stopColor="#DFDFEE" />
            <stop offset="1" stopColor="#DFDFEE" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="147.834"
            y1="-29.0172"
            x2="147.834"
            y2="141.672"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DFDFEE" />
            <stop offset="1" stopColor="#DFDFEE" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="295" height="198" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Text align="center" marginTop="5" color="gray.600">
        Nuk ka të dhëna
      </Text>
    </Flex>
  );
};
