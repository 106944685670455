import * as React from 'react';
import { Box } from '@chakra-ui/layout';
import { LawyerProfile } from '../../graphql';
import { Property } from '../../components/Property';
import { FaDownload } from 'react-icons/fa';
import { Text, Link } from '@chakra-ui/react';

export const LawyerDetails = ({ profile }: { profile: LawyerProfile }) => {
  return (
    <Box w="full" mt={10}>
      <Property label="Emri" value={profile.name} />
      <Property label="Mbiemri" value={profile.lastname} />
      <Property label="Shteti" value={profile.country.name} />
      <Property label="Qyteti" value={profile.city.name} />
      <Property label="Gjinia" value={profile.sex} />
      <Property
        label="Specialiteti"
        value={profile.specialities.map((i) => i.name).join(', ')}
      />
      <Property
        label="Pozicioni"
        value={profile.positions.map((i) => i.name).join(', ')}
      />
      <Property
        label="CV"
        value={
          <Link
            target={`_blank`}
            href={profile.cvUrl}
            display="flex"
            alignContent={'center'}
            alignItems={'center'}
          >
            <FaDownload />
            <Text mx={2}>Shkarko këtu</Text>
          </Link>
        }
      />
      <Property
        label="Diploma"
        value={
          <Link
            target={`_blank`}
            href={profile.diplomaUrl}
            display="flex"
            alignContent={'center'}
            alignItems={'center'}
          >
            <FaDownload />
            <Text mx={2}>Shkarko këtu</Text>
          </Link>
        }
      />
      <Property
        label="Licensa"
        value={
          <Link
            target={`_blank`}
            href={profile.licenseUrl}
            display="flex"
            alignContent={'center'}
            alignItems={'center'}
          >
            <FaDownload />
            <Text mx={2}>Shkarko këtu</Text>
          </Link>
        }
      />
    </Box>
  );
};
