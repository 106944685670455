import { Flex } from '@chakra-ui/layout';
import { Avatar } from '@chakra-ui/react';
import { Link } from '../../../../components/Link';

type ProfileProps = {
  name: string;
  lastname: string;
  id: number;
  profilePicture?: string;
};

export const AvatarWithFullName = (props: ProfileProps) => {
  return (
    <Flex alignItems="center">
      <Avatar src={props.profilePicture} name={props.name} size="sm" />
      <Link
        marginLeft="5"
        color="black"
        textDecoration="underline"
        textDecorationStyle="dotted"
        href={`/account/${props.id}`}
        fontSize="sm"
      >
        {props.name + ' ' + props.lastname}
      </Link>
    </Flex>
  );
};
