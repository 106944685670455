import { Button } from '@chakra-ui/button';
import {
  Badge,
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/layout';
import { EditProfileModal } from '../../modals/EditProfile';
import moment from 'moment';
import { HiPencilAlt } from 'react-icons/hi';
import { Loader } from '../../components/Loader';
import { Property } from '../../components/Property';
import { AccountRole, useMeQuery } from '../../graphql';
import { Avatar, useDisclosure } from '@chakra-ui/react';

const rolesMap: { [key in AccountRole]: string } = {
  [AccountRole.Admin]: 'Administrator',
  [AccountRole.Client]: 'Klient',
  [AccountRole.Lawyer]: 'Avokat',
};

export const ProfilePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, loading } = useMeQuery();

  if (loading || !data || !data.me || !data.me.profile) {
    return <Loader />;
  }

  return (
    <>
      <Box>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          paddingBottom={4}
        >
          <Heading fontWeight="medium" size="lg">
            Profili
          </Heading>
          <Button
            onClick={onOpen}
            variant="outline"
            minW="20"
            leftIcon={<HiPencilAlt />}
          >
            Modifiko
          </Button>
        </Flex>
        <Divider />
        <Center mt={8}>
          <Box w="full">
            <Flex justifyContent="center">
              <Avatar
                name={data.me.profile.name}
                src={data.me.profile.profilePicture?.lg}
                size="lg"
              />
            </Flex>
            <Center mt="4" flexDirection="column">
              <Box>
                <Text
                  fontSize="2xl"
                  fontWeight="medium"
                >{`${data.me.profile?.name} ${data.me.profile?.lastname}`}</Text>
              </Box>
              <Box>
                <Badge colorScheme="green">{rolesMap[data.me.role]}</Badge>
              </Box>
            </Center>

            <Box w="full" mt={10}>
              <Property label="Emri" value={data.me.profile.name} />
              <Property label="Mbiemri" value={data.me.profile.lastname} />
              <Property label="Email" value={data.me.email} />
              <Property
                label="Numri i telefonit"
                value={data.me.phoneNumber || '-'}
              />
              <Property
                label="Anëtar që prej"
                value={moment(data.me.createdAt).format('DD/MM/YYYY')}
              />
            </Box>
          </Box>
        </Center>
      </Box>
      <EditProfileModal isOpen={isOpen} onClose={onClose} data={data} />
    </>
  );
};
