import { Box, Button, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { InputFromQuestion } from '.';
import { Step } from '../../../../components/DocumentSteps/Step';
import { StepContent } from '../../../../components/DocumentSteps/StepContent';
import { Block, Question } from '../types';
import { checkCondition } from '../util';

type StepFromBlockProxyProps = {
  block: Block;
  prevButtonDisabled: boolean;
  nextButtonDisabled: boolean;
  nextStep: () => void;
  prevStep: () => void;
  currentStep: () => void;
  initialValues?: any;
  onStepSubmit: (values: any) => Promise<void>;
};

export const StepFromBlockProxy = (props: StepFromBlockProxyProps) => {
  const {
    block,
    prevButtonDisabled,
    nextButtonDisabled,
    prevStep,
    currentStep,
    initialValues,
    onStepSubmit,
  } = props;
  const validateFunction = (values: any) => {
    return validateFunctionHelper(values, block.questions);
  };

  const validateFunctionHelper = (values: any, questions: Question[]) => {
    let errors: any = {};

    questions.forEach((question) => {
      if (question.condition) {
        if (!checkCondition(question.condition, values)) {
          // field is not visible so dont validate it
          return;
        }
      }

      if (question.type === 'image') {
        if (!values[question.name] || !values[question.name].key) {
          errors[question.name] = 'Fushë e detyruar';
        }
      } else if (
        question.type === 'multiplechoice'
      ) {
        if (values[question.name] === 'other') {
          // validate children
          const otherOption = question.options?.find(
            (o) => o.value === 'other'
          );
          const otherErrors = validateFunctionHelper(
            values,
            otherOption?.children || []
          );

          errors = { ...errors, ...otherErrors };
        }
      } else if (question.type === 'address' && question.required) {
        errors[question.name] = {};

        if (!values[question.name]?.country) {
          errors[question.name].country = 'Fushë e detyrueshme';
        }

        if (!values[question.name]?.city) {
          errors[question.name].city = 'Fushë e detyrueshme';
        }

        if (!values[question.name]?.zip) {
          errors[question.name].zip = 'Fushë e detyrueshme';
        }

        if (!values[question.name]?.street) {
          errors[question.name].street = 'Fushë e detyrueshme';
        }
      } else if (question.type === 'short-address' && question.required) {
        errors[question.name] = {};

        if (!values[question.name]?.country) {
          errors[question.name].country = 'Fushë e detyrueshme';
        }

        if (!values[question.name]?.city) {
          errors[question.name].city = 'Fushë e detyrueshme';
        }
      } else {
        if (question.required && !values[question.name] && question.name) {
          errors[question.name] = 'Fushë e detyrueshme';
        }
      }
    });

    return errors;
  };

  return (
    <Step title={block.title} onClick={currentStep}>
      <StepContent>
        <Stack shouldWrapChildren spacing="4">
          <Text onClick={currentStep}>{block.title}</Text>

          <Form
            onSubmit={onStepSubmit}
            validate={validateFunction}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, values }) => {
              return (
                <Box as="form" onSubmit={handleSubmit}>
                  {block.questions.map((question) => (
                    <InputFromQuestion
                      key={question.label}
                      question={question}
                      initialValues={initialValues}
                      currentValues={values}
                    />
                  ))}

                  <HStack>
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={prevStep}
                      isDisabled={prevButtonDisabled || submitting}
                      leftIcon={<Icon as={FaArrowLeft} />}
                    >
                      Shko pas
                    </Button>
                    <Button
                      size="sm"
                      isDisabled={nextButtonDisabled || submitting}
                      isLoading={submitting}
                      type="submit"
                      rightIcon={<Icon as={FaArrowRight} />}
                    >
                      Ruaj ndryshimet
                    </Button>
                  </HStack>
                </Box>
              );
            }}
          ></Form>
        </Stack>
      </StepContent>
    </Step>
  );
};
