import { Box, Container, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';

export const PageContent = ({
  children,
}: React.PropsWithChildren<React.ReactNode>) => {
  return (
    <Box as="main" py="8" flex="1">
      <Container maxW="7xl">
        <Box bg={mode('white', 'gray.700')} p="6" rounded="lg" shadow="base">
          {children}
        </Box>
      </Container>
    </Box>
  );
};
