import React, { useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Progress,
  RadioGroup,
  Textarea,
  Text,
  Spinner,
  Button,
  Box,
  Flex,
  Spacer,
  Select,
  Grid,
  InputProps,
  Heading,
  Stack,
  StackProps,
  InputRightAddon,
  InputGroup,
  Tooltip,
  Icon,
  Collapse,
  Divider,
  HStack,
  Center,
} from '@chakra-ui/react';
import { Select as MultipleChoice } from 'chakra-react-select';
import { Field, useField, useForm } from 'react-final-form';
import {
  useGetPresignedPublicS3UrlLazyQuery,
  useGetPresignedS3UrlLazyQuery,
  useGetUrlFromKeyLazyQuery,
} from '../graphql';
import { MdDelete } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const AdaptedTextarea = ({ input, meta, ...rest }: any) => (
  <Textarea {...input} {...rest} isInvalid={meta.error && meta.touched} />
);

export const CheckboxControl = ({ name, value = '', children }: any) => {
  const {
    input: { checked, ...input },
    meta: { error, touched, invalid },
  } = useField(name, {
    type: 'checkbox', // important for RFF to manage the checked prop
  });
  return (
    <FormControl isInvalid={touched && invalid} my={4}>
      <Checkbox
        {...input}
        isChecked={checked}
        isInvalid={touched && invalid}
        my={4}
      >
        {children}
      </Checkbox>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export const CheckboxArrayControl = ({
  name,
  value,
  children,
}: {
  name: string;
  value: string;
  children: any;
}) => {
  const {
    input: { checked, ...input },
    meta: { error, touched },
  } = useField(name, {
    type: 'checkbox', // important for RFF to manage the checked prop
    value, // important for RFF to manage list of strings
  });
  return (
    <Checkbox {...input} isChecked={checked} isInvalid={error && touched}>
      {children}
    </Checkbox>
  );
};

interface FieldGroupProps extends StackProps {
  title?: string;
}

export const FieldGroup = (props: FieldGroupProps) => {
  const { title, children, ...flexProps } = props;
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing="6"
      py="4"
      {...flexProps}
    >
      <Box minW="3xs">
        {title && (
          <Heading as="h2" fontWeight="semibold" fontSize="lg" flexShrink={0}>
            {title}
          </Heading>
        )}
      </Box>
      {children}
    </Stack>
  );
};

interface IAdaptedRadioGroupProps extends InputProps {
  label: string;
  meta: {
    touched: boolean;
    invalid: boolean;
    error: string;
  };
  input: any;
  name: string;
}

export const AdaptedRadioGroup = ({
  input,
  meta,
  label,
  children,
}: IAdaptedRadioGroupProps) => (
  <FormControl isInvalid={meta.touched && meta.invalid} my={4}>
    <FormLabel htmlFor={input.name}>{label}</FormLabel>
    <RadioGroup {...input}>{children}</RadioGroup>
    <FormErrorMessage>{meta.error}</FormErrorMessage>
  </FormControl>
);

export const Control = ({ name, ...rest }: any) => {
  const {
    meta: { error, touched },
  } = useField(name, { subscription: { touched: true, error: true } });
  return <FormControl {...rest} isInvalid={error && touched} />;
};

export const Error = ({ name }: any) => {
  const {
    meta: { error },
  } = useField(name, { subscription: { error: true } });
  return <FormErrorMessage>{error}</FormErrorMessage>;
};

export const InfoTooltip = ({ text = '' }: any) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const shouldExpand = useMemo(() => (text || '').length > 80, [text]);
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  if (!text) {
    return null;
  }

  return (
    <>
      <HStack spacing="3px" direction={'row'}>
        <Center paddingLeft={'5px'}>
          <Tooltip
            label="Informacion shtesë për pyetjen."
            shouldWrapChildren
            placement="left"
          >
            <Icon
              as={FaInfoCircle}
              marginLeft="10px"
              marginTop="5px"
              color={'gray.500'}
            ></Icon>
          </Tooltip>
        </Center>
        <Box>
          <Divider orientation="horizontal"></Divider>
        </Box>

        <Collapse startingHeight={35} in={show}>
          <Box
            fontSize={'sm'}
            fontWeight="normal"
            noOfLines={shouldExpand && !show ? 1 : 0}
            listStyleType="bullet"
            color={'gray.500'}
          >
            <div
              style={{ padding: '10px 15px' }}
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </Box>
        </Collapse>
      </HStack>
      {shouldExpand && (
        <Box
          style={{
            boxShadow:
              shouldExpand && !show
                ? '0px -5px 7px -4px rgba(0,0,0,0.08)'
                : 'none',
          }}
        >
          <HStack paddingTop={'5px'}>
            <Divider width="40%" style={{ color: 'red' }}></Divider>
            <Button
              size="xs"
              onClick={handleToggle}
              variant="ghost"
              color={'gray.500'}
            >
              {show ? 'Fshih' : 'Shfaq'} detajet
            </Button>
            <Divider width="40%"></Divider>
          </HStack>
        </Box>
      )}
    </>
  );
};

export const InputControl = ({
  type = 'text',
  name,
  label,
  info,
  required = false,
  placeholder = '',
}: any) => {
  const { input, meta } = useField(name);
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>
        {label}
        <InfoTooltip text={info}></InfoTooltip>
      </FormLabel>
      <Input
        type={type}
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={placeholder || label}
      />
      <Error name={name} />
    </Control>
  );
};

export const CurrencyALLInputControl = ({ name, label }: any) => {
  const { input, meta } = useField(name);
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        <Input
          type="number"
          {...input}
          isInvalid={meta.error && meta.touched}
          id={name}
          placeholder={label}
        />
        <InputRightAddon children="EUR" />
      </InputGroup>
      <Error name={name} />
    </Control>
  );
};

export const MultiLanguageInputControl = ({ name, label }: any) => (
  <Control name={name} marginBottom={4}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <Field name={name} component={AdaptedMultiLanguageInput} id={name} />

    <Error name={name} />
  </Control>
);

export const AdaptedMultiLanguageInput = ({ input, meta, ...rest }: any) => {
  return <MultiLanguageInput {...input} {...rest} />;
};

export const MultiLanguageInput = ({ onChange, value, ...rest }: any) => {
  const [sq, setSq] = useState(value.sq);
  const [en, setEn] = useState(value.en);

  return (
    <Box>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <Control name={`sq`}>
          <FormLabel fontSize="sm" htmlFor={`sq`}>
            {'Shqip'}
          </FormLabel>
          <Input
            type="text"
            id={`sq`}
            placeholder={'Shqip'}
            onChange={(e) => {
              setSq(e.target.value);
              onChange({ sq: e.target.value, en });
            }}
            defaultValue={sq}
          />
        </Control>
        <Control name={`en`}>
          <FormLabel fontSize="sm" fontWeight="medium" htmlFor={`en`}>
            {'Anglisht'}
          </FormLabel>
          <Input
            type="text"
            id={`en`}
            placeholder={'Anglisht'}
            onChange={(e) => {
              setEn(e.target.value);
              onChange({ sq, en: e.target.value });
            }}
            defaultValue={en}
          />
        </Control>
      </Grid>
    </Box>
  );
};

export const MultiLanguageTextareaControl = ({ name, label }: any) => (
  <Control name={name} my={4}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <Field name={name} component={AdaptedMultiLanguageTextarea} id={name} />

    <Error name={name} />
  </Control>
);

export const AdaptedMultiLanguageTextarea = ({ input, meta, ...rest }: any) => {
  return <MultiLanguageTextarea {...input} {...rest} />;
};

export const MultiLanguageTextarea = ({ onChange, value }: any) => {
  const [sq, setSq] = useState(value.sq);
  const [en, setEn] = useState(value.en);

  return (
    <Box>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <Control name={`sq`} my={4}>
          <FormLabel htmlFor={`sq`}>{'Shqip'}</FormLabel>
          <Textarea
            id={`sq`}
            placeholder={'Shqip'}
            onChange={(e) => {
              setSq(e.target.value);
              onChange({ sq: e.target.value, en });
            }}
            defaultValue={sq}
          />
        </Control>
        <Control name={`en`} my={4}>
          <FormLabel htmlFor={`en`}>{'Anglisht'}</FormLabel>
          <Textarea
            id={`en`}
            placeholder={'Anglisht'}
            onChange={(e) => {
              setEn(e.target.value);
              onChange({ sq, en: e.target.value });
            }}
            defaultValue={en}
          />
        </Control>
      </Grid>
    </Box>
  );
};

export const TextareaControl = ({
  name,
  label,
  info,
  required = false,
  placeholder = '',
}: {
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
  info?: string;
}) => (
  <Control name={name} my={4}>
    <FormLabel htmlFor={name}>
      {label}
      <InfoTooltip text={info}></InfoTooltip>
    </FormLabel>
    <Field
      name={name}
      component={AdaptedTextarea}
      placeholder={placeholder}
      id={name}
    />
    <Error name={name} />
  </Control>
);

export const PercentComplete = (props: any) => {
  const form = useForm();
  const numFields = form.getRegisteredFields().length;
  const numErrors = Object.keys(form.getState().errors as string[]).length;
  return (
    <Progress
      value={numFields === 0 ? 0 : ((numFields - numErrors) / numFields) * 100}
      {...props}
    />
  );
};

export type Optional<T> = { [P in keyof T]?: T[P] };

const Preview = ({ type, name, url, size, onDelete }: any) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Box>
        {!type.startsWith('image') ? (
          <Text fontWeight="500">{name}</Text>
        ) : (
          <Box
            borderRadius="10px"
            boxSize="150px"
            backgroundImage={`url(${url})`}
            backgroundSize="contain"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
          ></Box>
        )}

        {size ? (
          <Text fontSize="sm" color="grey">
            {t('forms.size')}: {Math.round((size / 1024 / 1024) * 100) / 100} MB
          </Text>
        ) : null}
      </Box>
      <Spacer />
      <Box>
        <Button
          leftIcon={<MdDelete />}
          colorScheme="red"
          variant="solid"
          onClick={onDelete}
        >
          {t('forms.delete')}
        </Button>
      </Box>
    </Flex>
  );
};

type DropzoneProps = {
  onChange: (event: {
    key?: string;
    url?: string;
    contentType?: string;
  }) => void;
  value: {
    key: string;
    url: string;
    contentType: string;
  };
  accept: string;
};

type FileWithPreview = File & { preview: string };

function Dropzone({ onChange, value, accept }: DropzoneProps) {
  const { t } = useTranslation();
  const [clearCurrent, setClearCurrent] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [getPresignedUrl, { data }] = useGetPresignedS3UrlLazyQuery();
  const [getUrlFromKey] = useGetUrlFromKeyLazyQuery();

  const { getPresignedS3Url } = data || {};
  const { url, key, contentType } = getPresignedS3Url || {};

  useEffect(() => {
    async function run() {
      if (url) {
        setUploading(() => true);

        await fetch(url, {
          method: 'PUT',
          body: files[0],
        });

        if (onChange) {
          setUploading(() => false);
          onChange({ key, contentType });
        }
      }
    }

    run();

    // eslint-disable-next-line
  }, [url]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    noDrag: false,
    accept,
    onDropRejected: () => {
      alert(t('forms.invalidFile'));
    },
    onDropAccepted: async (acceptedFiles) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(files);

      getPresignedUrl({
        variables: {
          fileName: files[0].name,
        },
      });
    },
  });

  const removeFile = (file: FileWithPreview) => {
    // const newFiles = [...files];
    // newFiles.splice(newFiles.indexOf(file), 1);
    setFiles([]);
    onChange({});
  };

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <Box bg="white" w="100%" p={4} borderRadius="5px">
          {uploading ? (
            <>
              <Spinner /> {t('forms.uploading')}
            </>
          ) : (
            <>
              <Preview
                type={file.type}
                name={file.name}
                url={file.preview}
                size={file.size}
                onDelete={() => removeFile(file)}
              />
            </>
          )}
        </Box>
      </div>
    </div>
  ));

  useEffect(() => {
    if (value.key) {
      getUrlFromKey({ variables: { key: value.key } }).then((res) => {
        onChange({
          key: value.key,
          url: res.data?.getUrlFromKey || '',
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const inEditMode = !clearCurrent && value.url;

  return (
    <>
      {!thumbs.length && !inEditMode && (
        <Container
          {...getRootProps({ className: 'btn-dropzone' })}
          style={{ background: isDragActive ? '#DDEFFF' : '' }}
        >
          <input {...getInputProps()} />
          <span>{t('forms.slideOrClick')}</span>
        </Container>
      )}
      {inEditMode && (
        <Preview
          name={''}
          onDelete={() => {
            onChange({});
            setClearCurrent(true);
          }}
          url={value.url}
          size={''}
          type={'image'}
        />
      )}
      <aside>{thumbs}</aside>
    </>
  );
}

type DropzonePublicProps = {
  onChange: (
    event: {
      key?: string;
      url?: string;
      contentType?: string;
    } | null
  ) => void;
  accept: string;
  value: {
    key: string;
  };
};

function DropzonePublic({ onChange, value, accept }: DropzonePublicProps) {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [getPresignedUrl, { data }] = useGetPresignedPublicS3UrlLazyQuery();
  const [getUrlFromKey] = useGetUrlFromKeyLazyQuery();
  const [clearCurrent, setClearCurrent] = useState(false);

  const { getPresignedPublicS3Url } = data || {};
  const { url, key, contentType } = getPresignedPublicS3Url || {};

  useEffect(() => {
    async function run() {
      if (url) {
        setUploading(() => true);

        const res = await fetch(url, {
          method: 'PUT',
          body: files[0],
        });

        if (!res.ok) {
          return;
        }

        if (onChange) {
          setUploading(() => false);
          onChange({ key, contentType });
        }
      }
    }

    run();

    // eslint-disable-next-line
  }, [url]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    noDrag: false,
    accept,
    onDropRejected: () => {
      alert(t('forms.invalidFile'));
    },
    onDropAccepted: async (acceptedFiles) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(files);

      getPresignedUrl({
        variables: {
          fileName: files[0].name,
        },
      });
    },
  });

  const removeFile = (file: FileWithPreview) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    onChange(null);
  };

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <Box bg="white" w="100%" p={4} borderRadius="5px">
          {uploading ? (
            <>
              <Spinner /> {t('forms.uploading')}
            </>
          ) : (
            <Flex>
              <Box>
                {!file.type.startsWith('image') ? (
                  <Text fontWeight="500">{file.name}</Text>
                ) : (
                  <Box
                    borderRadius="10px"
                    boxSize="150px"
                    backgroundImage={`url(${file.preview})`}
                    backgroundSize="contain"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                  />
                )}

                <Text fontSize="sm" color="grey">
                  {t('forms.size')}:{' '}
                  {Math.round((file.size / 1024 / 1024) * 100) / 100} MB
                </Text>
              </Box>
              <Spacer />
              <Box>
                <Button
                  leftIcon={<MdDelete />}
                  colorScheme="red"
                  variant="solid"
                  onClick={removeFile(file)}
                >
                  {t('forms.delete')}
                </Button>
              </Box>
            </Flex>
          )}
        </Box>
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (value.key) {
      getUrlFromKey({ variables: { key: value.key } }).then((res) => {
        onChange({
          key: value.key,
          url: res.data?.getUrlFromKey || '',
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inEditMode = !clearCurrent && (value as any).url;

  return (
    <>
      {!thumbs.length && !inEditMode && (
        <Container
          {...getRootProps({ className: 'btn-dropzone' })}
          style={{ background: isDragActive ? '#DDEFFF' : '' }}
        >
          <input {...getInputProps()} />
          <span>{t('forms.slideOrClick')}</span>
        </Container>
      )}
      {inEditMode && (
        <Preview
          name={''}
          onDelete={() => {
            onChange({});
            setClearCurrent(true);
          }}
          url={(value as any).url}
          size={''}
          type={'image'}
        />
      )}
      <aside>{thumbs}</aside>
    </>
  );
}

type AdapedDropzoneProps = {
  input: DropzoneProps;
};

export const AdaptedDropzone = ({
  input,
  ...rest
}: AdapedDropzoneProps & DropzoneControlProps) => {
  return rest.unauthenticated ? (
    <DropzonePublic {...input} {...rest} />
  ) : (
    <Dropzone {...input} {...rest} />
  );
};

type DropzoneControlProps = {
  name: string;
  label: string;
  unauthenticated?: boolean;
  input?: any;
  meta: any;
  accept: string;
  initialValue?: { key: string };
};

export const DropzoneControl = ({
  name,
  label,
  unauthenticated = false,
  accept,
  initialValue,
}: DropzoneControlProps) => (
  <Control name={name} my={4}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <Field
      name={name}
      component={AdaptedDropzone}
      id={name}
      unauthenticated={unauthenticated}
      accept={accept}
      value={initialValue}
    />

    <Error name={name} />
  </Control>
);

export const AdaptedSelect = ({
  input,
  meta,
  label,
  children,
  onChange,
  ...rest
}: any) => {
  return (
    <FormControl isInvalid={meta.touched && meta.invalid} my={4}>
      <FormLabel htmlFor={input.name}>{label}</FormLabel>
      <Select
        {...input}
        onChange={(e) => {
          input.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
        placeholder="Zgjidh"
        {...rest}
      >
        {children}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export const AdaptedMultiplechoice = ({
  input,
  meta,
  label,
  options,
  onChange,
  ...rest
}: any) => {
  const { value = '', ...restinput } = input;

  const objectifiedValue = String(value)
    .split(', ')
    .map((label: string) => {
      return options.find((i: { label: string }) => i.label === label);
    });

  return (
    <FormControl isInvalid={meta.touched && meta.invalid} my={4}>
      <FormLabel htmlFor={input.name}>{label}</FormLabel>
      <MultipleChoice
        isMulti
        {...restinput}
        value={objectifiedValue}
        onChange={(e: Array<{ label: string }>) => {
          const data = e.map((i) => i.label).join(', ');
          input.onChange(data);
          if (onChange) {
            onChange(data);
          }
        }}
        options={options}
        placeholder="Zgjidh"
        closeMenuOnSelect={false}
        {...rest}
      />
    </FormControl>
  );
};

export const AdaptedCheckboxes = ({
  input,
  meta,
  label,
  options,
  onChange,
  // ...rest
}: any) => {
  // const { value = '', ...restinput } = input;

  // console.log(value, restinput)

  return (
    <FormControl isInvalid={meta.touched && meta.invalid} my={4}>
      <FormLabel htmlFor={input.name}>{label}</FormLabel>
      {options.map((o: { label: string; value: string }) => {
        return (
          <div
            key={`list-${input.name}-option-${o.value}`}
            className="custom-control custom-checkbox custom-control-inline"
          >
            <Field
              name={input.name}
              component="input"
              type="checkbox"
              value={o.value}
              id={`list-${input.name}-option-${o.value}`}
            />
            <label
              style={{ padding: '10px', paddingLeft: '10px' }}
              className="custom-control-label"
              htmlFor={`list-${input.name}-option-${o.value}`}
            >
              {o.label}
            </label>
          </div>
        );
      })}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
