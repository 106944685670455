import { Box, Button, Divider } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { InputControl } from '../components/Form';

export type ChangeDocumentTitleFormValues = {
  title: string;
};

type ChangeDocumentTitleProps = {
  onSubmit: (values: ChangeDocumentTitleFormValues) => void;
  initialValues: ChangeDocumentTitleFormValues;
};

export const ChangeDocumentTitleForm = (props: ChangeDocumentTitleProps) => {
  const { onSubmit, initialValues } = props;
  const validate = (values: ChangeDocumentTitleFormValues) => {
    const errors: Record<keyof ChangeDocumentTitleFormValues, string> =
      {} as Record<keyof ChangeDocumentTitleFormValues, string>;

    if (!values.title) {
      errors.title = 'Fushë e detyruar';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" onSubmit={handleSubmit}>
          {/** Title field */}
          <InputControl name="title" label="Titulli" type="text" />

          <Divider my="3" />
          <Button
            float="right"
            isLoading={submitting}
            disabled={submitting}
            colorScheme="red"
            type="submit"
          >
            Ruaj
          </Button>
        </Box>
      )}
    />
  );
};
