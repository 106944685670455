import * as React from 'react';
import { Box, Divider } from '@chakra-ui/layout';
import { Form } from 'react-final-form';
import { InputControl } from '../components/Form';
import { Button } from '@chakra-ui/button';

export type ResetPasswordFormValues = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

interface IResetPasswordForm {
  onSubmit: (values: ResetPasswordFormValues) => void;
}

export const ResetPasswordForm: React.FC<IResetPasswordForm> = ({
  onSubmit,
}) => {
  const validate = (values: ResetPasswordFormValues) => {
    const errors: Record<keyof ResetPasswordFormValues, string> = {} as Record<
      keyof ResetPasswordFormValues,
      string
    >;

    if (!values.oldPassword) {
      errors.oldPassword = 'Fushë e detyruar';
    }

    if (!values.password) {
      errors.password = 'Fushë e detyruar';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Fushë e detyruar';
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Fjalëkalimet nuk përputhen';
    }

    if (values.password?.length < 8) {
      errors.password = 'Fjalëkalimi duhet të ketë të paktën 8 karaktere';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{}}
      render={({ handleSubmit, submitting }) => (
        <Box as="form" onSubmit={handleSubmit}>
          {/** Old password */}
          <InputControl
            name="oldPassword"
            label="Fjalëkalimi i vjetër"
            type="password"
          />
          {/* Fjalekalimi */}
          <InputControl type="password" name="password" label="Fjalëkalimi" />
          {/* Riperserit fjalekalimin */}
          <InputControl
            type="password"
            name="confirmPassword"
            label="Ripërsërit fjalëkalimin"
          />

          <Divider my="3" />
          <Button
            float="right"
            isLoading={submitting}
            loadingText="Duke dërguar"
            colorScheme="red"
            type="submit"
          >
            Ruaj
          </Button>
        </Box>
      )}
    />
  );
};
