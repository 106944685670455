import * as React from 'react';
import { chakra } from '@chakra-ui/system';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Button } from '@chakra-ui/button';
import {
  AccountRole,
  AccountShortData,
  AccountStatus,
  useMeQuery,
  useResendActivationCodeMutation,
  useVerifyAccountMutation,
} from '../../graphql';
import { useLogout } from '../../hooks/useLogout';
import { useToast } from '@chakra-ui/toast';
import { Input } from '@chakra-ui/input';
import { Spinner } from '@chakra-ui/spinner';
import { Center, Text } from '@chakra-ui/layout';

export const ActivateAccountForm = ({
  localStorageUser,
}: {
  localStorageUser: AccountShortData | null;
}) => {
  const toast = useToast();
  const logout = useLogout();
  const [resendActivationCode] = useResendActivationCodeMutation();
  const [activateAccount] = useVerifyAccountMutation();
  const { data, loading, error } = useMeQuery();

  const [activationCode, setActivationCode] = React.useState('');
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [resendActivationCodeLoading, setResendActivationCodeLoading] =
    React.useState(false);

  const [showPendingAdminVerification, setShowPendingAdminVerification] =
    React.useState(false);

  React.useEffect(() => {
    if (error) {
      (async function () {
        await logout();
      })();
    }

    if (data && localStorageUser) {
      if (data.me.accountStatus !== localStorageUser.accountStatus) {
        (async function () {
          await logout();
        })();
      }
    }
  }, [data, localStorageUser, logout, error]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setSubmitLoading(true);
      await activateAccount({ variables: { activationCode } });
      toast({
        status: 'success',
        title: 'Llogaria u konfirmua me sukses',
      });
      if (data?.me.role === AccountRole.Client) {
        await logout();
      } else {
        setShowPendingAdminVerification(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleResendActivationCode = async () => {
    try {
      setResendActivationCodeLoading(true);
      await resendActivationCode();
    } catch (error) {
      console.error(error);
    } finally {
      setResendActivationCodeLoading(false);
    }
  };

  if (loading) {
    return (
      <Center>
        <Spinner color="red.500" />
      </Center>
    );
  }

  if (
    data?.me.accountStatus === AccountStatus.PendingAdminVerification ||
    showPendingAdminVerification
  ) {
    return (
      <Text fontWeight="medium" textAlign="center">
        Llogaria juaj nuk është verifikuar ende nga administratori.
      </Text>
    );
  }

  return (
    <chakra.form onSubmit={handleSubmit}>
      <FormControl isRequired>
        <FormLabel>Kodi i aktivizimit</FormLabel>
        <Input
          type="text"
          placeholder=""
          onChange={(event) => setActivationCode(event.currentTarget.value)}
        />
      </FormControl>
      <Button
        type="submit"
        colorScheme="red"
        size="lg"
        fontSize="md"
        width="full"
        isLoading={submitLoading}
        disabled={submitLoading || resendActivationCodeLoading}
        mt={4}
      >
        Verifiko
      </Button>

      <Button
        width="full"
        variant="link"
        colorScheme="red"
        isLoading={resendActivationCodeLoading}
        disabled={resendActivationCodeLoading || submitLoading}
        mt={4}
        onClick={handleResendActivationCode}
      >
        Ridërgo kodin e konfirmimit
      </Button>
    </chakra.form>
  );
};
