export const useResetPassword = () => {
  const getEmail = () => {
    return localStorage.getItem('reset-password');
  };

  const setEmail = (email: string) => {
    localStorage.setItem('reset-password', email);
  };

  const encryptEmailUser = (emailUser: string): string => {
    return emailUser[0] + new Array(emailUser.length - 1).fill('*').join('');
  };

  const encryptEmailProvider = (emailProvider: string): string => {
    const [provider, extension] = emailProvider.split('.');
    return `${provider[0]}${new Array(provider.length - 1)
      .fill('*')
      .join('')}.${extension}`;
  };

  const getEmailEncrypted = () => {
    const email = getEmail();
    if (email) {
      const [emailUser, emailProvider] = email.split('@');
      if (
        !emailUser ||
        !emailProvider ||
        emailUser.length < 2 ||
        emailProvider.length < 2
      ) {
        return null;
      }

      return `${encryptEmailUser(emailUser)}@${encryptEmailProvider(
        emailProvider
      )}`;
    }

    return email;
  };

  const removeResetPasswordEmail = () => {
    localStorage.removeItem('reset-password');
  };

  const email = getEmail();
  const emailEncrypted = getEmailEncrypted();

  return {
    email,
    emailEncrypted,
    setEmail,
    removeResetPasswordEmail,
  };
};
