import * as React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { Divider, Button } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { Spinner } from '@chakra-ui/spinner';
import { HiCheck } from 'react-icons/hi';
import { useParams } from 'react-router';
import {
  Account,
  AccountRole,
  AccountStatus,
  AdminProfile,
  ClientProfile,
  LawyerProfile,
  useGetAccountByIdQuery,
  useVerifyLawyerMutation,
  GetAccountByIdDocument,
  UsersByRoleDocument,
} from '../../graphql';
import { AdminDetails } from './adminDetails';
import { ClientDetails } from './clientDetails';
import { GeneralInfo } from './generalInfo';
import { LawyerDetails } from './lawyerDetails';

export const UserDetailsPage = () => {
  // @ts-ignore
  const { id } = useParams();
  const toast = useToast();
  const { data, loading } = useGetAccountByIdQuery({
    variables: { where: { id: Number(id) } },
  });
  const [verifyLawyer, result] = useVerifyLawyerMutation({
    variables: { verifyLawyerId: Number(id) },
    refetchQueries: [
      {
        query: GetAccountByIdDocument,
        variables: { where: { id: Number(id) } },
      },
      {
        query: UsersByRoleDocument,
        variables: { type: AccountRole.Lawyer },
      },
    ],
  });
  const [verifying, setVerifying] = React.useState(false);

  if (loading || !data?.account || !data.account.profile) {
    return <Spinner />;
  }

  const handleVerifyLawyer = async () => {
    try {
      setVerifying(true);
      await verifyLawyer();

      if (!result.error) {
        toast({
          title: 'Llogaria e avokatit u aprovua',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('err', error);
    } finally {
      setVerifying(false);
    }
  };

  const requireVerification =
    ((data.account as any).role as AccountRole) === AccountRole.Lawyer &&
    ((data.account as any).accountStatus as AccountStatus) ===
      AccountStatus.PendingAdminVerification;

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        paddingBottom={4}
      >
        <Heading fontWeight="medium" size="lg">
          Detajet e llogarisë
        </Heading>
        {requireVerification && (
          <Button
            onClick={handleVerifyLawyer}
            variant="outline"
            minW="20"
            isLoading={verifying}
            leftIcon={<HiCheck />}
          >
            Verifiko
          </Button>
        )}
      </Flex>
      <Divider />

      <Heading color="gray.600" size="md" paddingTop="5" my="5">
        Të gjena gjenerale
      </Heading>
      <Divider />
      <GeneralInfo account={data.account as Account} />

      <Heading color="gray.600" size="md" paddingTop="5" my="5">
        Të dhëna të tjera
      </Heading>
      <Divider />
      {data.account.profile.__typename === 'LawyerProfile' ? (
        <LawyerDetails profile={data.account.profile as LawyerProfile} />
      ) : data.account.profile.__typename === 'ClientProfile' ? (
        <ClientDetails profile={data.account.profile as ClientProfile} />
      ) : data.account.profile.__typename === 'AdminProfile' ? (
        <AdminDetails profile={data.account.profile as AdminProfile} />
      ) : (
        <Text>Profil i panjohur</Text>
      )}
    </Box>
  );
};
