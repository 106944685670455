import { Box, Divider, Stack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import moment from 'moment';
import { useParams } from 'react-router';
import { CardContent } from '../../../components/Card/CardContent';
import { LawyerIssueNavigation } from '../../../components/IssueNavigation';
import { Loader } from '../../../components/Loader';
import { Property } from '../../../components/Property';
import { useGetLawyerIssueByIdQuery } from '../../../graphql';
import { SectionTitle } from '../../../typography';
import { getStatusLabel } from '../../../utils/issue-status';

export const LawyerIssueDetailsPage = () => {
  //@ts-ignore
  const { id } = useParams();
  const { data, error, loading } = useGetLawyerIssueByIdQuery({
    variables: { getLawyerIssueByIdId: Number(id) },
  });

  if (loading || error) {
    return <Loader />;
  }

  return (
    <>
      {/* <CardHeader
        title={data?.getLawyerIssueById.title}
        action={
          <Box>
            { && (
              
            )}


          </Box>
        }
      ></CardHeader> */}
      <LawyerIssueNavigation
        id={data?.getLawyerIssueById?.id!}
        title={data?.getLawyerIssueById?.title!}
        canCancel={data?.getLawyerIssueById?.canCloseUnsuccessfully!}
        canClose={data?.getLawyerIssueById?.canCloseSuccessfully!}
        canChat={data?.getLawyerIssueById?.canChat!}
        canBid={data?.getLawyerIssueById.canBid!}
      />

      <SectionTitle>Detajet e çështjes</SectionTitle>
      <CardContent>
        <Property
          label="Titulli"
          value={data?.getLawyerIssueById.title || ''}
        />
        <Property
          label="Përshkrimi"
          value={data?.getLawyerIssueById.description || ''}
        />
        <Property
          label="Statusi"
          value={
            data?.getLawyerIssueById.status
              ? getStatusLabel(data?.getLawyerIssueById.status)
              : ''
          }
        />
        <Property
          label="Afati"
          value={moment(data?.getLawyerIssueById.deadline).format('DD/MM/YYYY')}
        />
      </CardContent>

      <SectionTitle>Oferta ime</SectionTitle>
      <Stack maxW="7xl" mx="auto" divider={<Divider />}>
        {!data?.getLawyerIssueById.myBid ? (
          <Text align="center" fontWeight="medium" my="5" color="gray.600">
            Ju nuk keni bërë asnjë ofertë për këtë çështje!
          </Text>
        ) : (
          <Box>
            <CardContent>
              <Property
                py="2"
                label="Data"
                value={moment(data?.getLawyerIssueById.myBid.createdAt).format(
                  'DD/MM/YYYY'
                )}
              />
              <Property
                bold
                py="2"
                label="Çmimi"
                value={`${data?.getLawyerIssueById.myBid.amount.toLocaleString()} EUR`}
              />
              <Property
                py="2"
                label="Mesazhi"
                value={data?.getLawyerIssueById.myBid.description}
              />
            </CardContent>
          </Box>
        )}
      </Stack>
    </>
  );
};
