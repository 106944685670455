import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/react';
import { UpdateDocumentTemplateForm } from '../forms/UpdateDocumentTemplateForm';
import {
  DocumentTemplate,
  GetAllDocumentTemplatesDocument,
  useUpdateDocumentTemplateMutation,
} from '../graphql';

type UpdateDocumentTemplateProps = {
  isOpen: boolean;
  template: DocumentTemplate;
  onClose: () => void;
};

interface IUpdateDocumentTemplateValues {
  title: string;
  price: number;
  active: boolean;
  categoryId: number;
  description: string;
}

export const UpdateDocumentTemplateModal = (
  props: UpdateDocumentTemplateProps
) => {
  const toast = useToast();
  const { isOpen, onClose, template } = props;
  const [updateDocument] = useUpdateDocumentTemplateMutation();
  const initialValues = template && {
    ...template,
    categoryId: template.category?.id,
  };

  const handleUpdateDocumentTemplate = async (
    payload: IUpdateDocumentTemplateValues
  ) => {
    try {
      await updateDocument({
        variables: {
          updateDocumentTemplateId: template.id,
          payload: {
            title: payload.title || '',
            price: Number(payload.price),
            active: payload.active || false,
            categoryId: Number(payload.categoryId),
            description: payload.description,
          },
        },
        refetchQueries: [
          {
            query: GetAllDocumentTemplatesDocument,
          },
        ],
      });
      onClose();
      toast({
        title: 'Sukses',
        description: 'Dokumenti u modifikua me sukses',
        status: 'success',
      });
    } catch (error) {
      console.error('err', error);
    }
  };

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifiko</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UpdateDocumentTemplateForm
            onSubmit={handleUpdateDocumentTemplate}
            initialValues={initialValues}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
