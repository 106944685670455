import i18n from 'i18next';
import en from '../langs/en';
import sq from '../langs/sq';

i18n.init({
  // we init with resources
  resources: {
    en: en,
    sq: sq,
  },
  fallbackLng: 'sq',
  debug: false,

  ns: ['translations'],
  defaultNS: 'translations',

  interpolation: {
    formatSeparator: ',',
  },
});

export default i18n;
