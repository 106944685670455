import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useAppApolloClient } from './client';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import { GlobalStore } from './contexts/GlobalContext';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import GlobalStyles from './styles';
import theme from './styles/theme';

import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './utils/i18n';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const Index = React.memo(() => {
  const { i18n } = useTranslation();
  const { client } = useAppApolloClient();

  useEffect(() => {
    const lang =
      localStorage.getItem('user-language') ||
      localStorage.getItem('selected-language') ||
      'sq';
    i18n.changeLanguage(lang);
  }, [i18n]);

  return (
    <ApolloProvider client={client}>
      <PayPalScriptProvider
        options={{
          'client-id':
            'AdhBQuVp2zfrAuInNBrvvPd5TiG66SxVlt4D0sgtQrZwUexQBv9G3rn0H4khcMfEDGRg1rpATRULpQ3V',
          currency: 'EUR',
        }}
      >
        <GlobalStore>
          <App />
        </GlobalStore>
      </PayPalScriptProvider>
    </ApolloProvider>
  );
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ChakraProvider theme={theme}>
      <CSSReset />
      <GlobalStyles />
      <Index />
    </ChakraProvider>
  </I18nextProvider>,
  document.getElementById('root')
);
