import { useEffect } from 'react';
import { useParams } from 'react-router';
import { LawyerIssueNavigation } from '../../../components/IssueNavigation';
import { Loader } from '../../../components/Loader';
import { UserCardWithTags } from '../../../components/UserInfo';
import {
  useGetChatInfoLazyQuery,
  useGetLawyerIssueByIdQuery,
} from '../../../graphql';
import { ChatPage } from '../../Chat';

export const LawyerIssueChatPage = () => {
  //@ts-ignore
  const { id } = useParams();
  const { data, loading } = useGetLawyerIssueByIdQuery({
    variables: { getLawyerIssueByIdId: Number(id) },
  });
  const [request, { data: chatData, loading: chatLoading }] =
    useGetChatInfoLazyQuery();

  useEffect(() => {
    request({ variables: { issueId: Number(id) } });
  }, []);

  if (loading || !data?.getLawyerIssueById) {
    return <Loader />;
  }

  return (
    <>
      <LawyerIssueNavigation
        id={data?.getLawyerIssueById.id!}
        title={data?.getLawyerIssueById.title!}
        canCancel={data?.getLawyerIssueById.canCloseUnsuccessfully!}
        canClose={data?.getLawyerIssueById.canCloseSuccessfully!}
        canChat={data?.getLawyerIssueById.canChat!}
      />

      {chatLoading || !chatData?.getChatInfo ? (
        <Loader />
      ) : (
        <>
          <UserCardWithTags data={chatData} />
          <ChatPage data={chatData} />
        </>
      )}
    </>
  );
};
