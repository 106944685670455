import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/react';
import {
  ChangeDocumentTitleForm,
  ChangeDocumentTitleFormValues,
} from '../forms/ChangeDocumentTitleForm';
import {
  useChangeDocumentTitleMutation,
  GetDocumentByIdDocument,
} from '../graphql';

type ChangeDocumentTitleProps = {
  isOpen: boolean;
  id: number;
  title: string;
  onClose: () => void;
};

export const ChangeDocumentTitleModal = (props: ChangeDocumentTitleProps) => {
  const toast = useToast();
  const { isOpen, onClose, id, title } = props;
  const [changeDocumentTitle] = useChangeDocumentTitleMutation();

  const handleChangeDocumentTitle = async (
    payload: ChangeDocumentTitleFormValues
  ) => {
    try {
      await changeDocumentTitle({
        variables: {
          changeDocumentTitleId: Number(id),
          title: payload.title,
        },
        refetchQueries: [
          {
            query: GetDocumentByIdDocument,
            variables: {
              getDocumentByIdId: Number(id),
            },
          },
        ],
      });
      onClose();
      toast({
        title: 'Sukses',
        description: 'Titulli i dokumentit u ndryshua me sukses',
        status: 'success',
      });
    } catch (error) {
      console.error('err', error);
    }
  };

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ndrysho titullin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ChangeDocumentTitleForm
            onSubmit={handleChangeDocumentTitle}
            initialValues={{ title }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
