import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useAuthToken } from './useAuthToken';

export const useLogout = () => {
  const history = useHistory();
  const { removeAuthToken } = useAuthToken();
  const apolloClient = useApolloClient();

  const logout = async () => {
    await apolloClient.clearStore();
    removeAuthToken();
    localStorage.removeItem('user-language');

    history.push('/login');
  };
  return logout;
};
