import { Box, Stack, StackDivider } from '@chakra-ui/layout';
import { useEffect } from 'react';
import { FaBell } from 'react-icons/fa';
import { CardHeader } from '../../components/Card/CardHeader';
import { Loader } from '../../components/Loader';
import { NoticeNoResults } from '../../components/Notice';
import { NotificationItem } from '../../components/Notification/item';
import { TablePagination } from '../../components/Pagination';
import {
  useReadUserNotificationsMutation,
  Notification,
  GetUnreadNotificationCountDocument,
} from '../../graphql';
import { useMyNotifications } from '../../hooks/useNotifications';

const NotificationsPage: React.FC = () => {
  const {
    items,
    loading,
    getNextPage,
    getPreviousPage,
    pageInfo,
    totalCount,
    showEmptyResults,
  } = useMyNotifications();
  const [readNotifications] = useReadUserNotificationsMutation();

  useEffect(() => {
    setTimeout(() => {
      readNotifications({
        refetchQueries: [
          {
            query: GetUnreadNotificationCountDocument,
          },
        ],
      });
    }, 1000);
  }, []);

  return (
    <Box>
      <CardHeader title="Njoftime"></CardHeader>

      {loading ? (
        <Loader />
      ) : showEmptyResults && !loading && items.length === 0 ? (
        <NoticeNoResults
          icon={FaBell}
          title="Nuk ka asnjë njoftim"
          description="Nuk ka asnjë njoftim të ri në llogarinë tuaj. Në momentin që ju do të përdorni një prej shërbimeve të Legit, njoftimet për veprimet tuaja do të shfaqen këtu."
        />
      ) : (
        <Stack divider={<StackDivider />} mt="5">
          {items.map((notification: Notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </Stack>
      )}
      <TablePagination
        total={totalCount}
        pageInfo={pageInfo as any}
        onNextPage={getNextPage}
        onPreviousPage={getPreviousPage}
      />
    </Box>
  );
};

export default NotificationsPage;
