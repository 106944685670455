import { Box, Divider, Text } from '@chakra-ui/react';
import {
  DropzoneControl,
  InputControl,
  TextareaControl,
} from '../../../../components/Form';
import { Question } from '../types';
import { checkCondition } from '../util';
import { AddressInputControl } from './address';
import { InputInfo } from './info';
import { MultipleChoice } from './multiplechoice';
import { SingleChoice } from './singlechoice';
import { ShortAddressInputControl } from './short-address';

type InputFromQuestionsProps = {
  question: Question;
  initialValues: any;
  currentValues: any;
};

export const InputFromQuestion = (props: InputFromQuestionsProps) => {
  const { question, initialValues, currentValues } = props;

  if (question.condition) {
    if (!checkCondition(question.condition, currentValues)) {
      return null;
    }
  }

  switch (question.type) {
    case 'text':
      return (
        <>
          <InputControl
            name={question.name}
            type="text"
            info={question.info}
            label={question.label}
            required={question.required}
            placeholder={question.placeholder || ''}
          />
          {/* {question.info && <InputInfo>{question.info}</InputInfo>} */}
        </>
      );
    case 'image':
      return (
        <>
          <DropzoneControl
            name={question.name}
            unauthenticated={false}
            accept={'.png,.jpeg'}
            label={question.label}
            meta={{}}
            initialValue={initialValues[question.name] || {}}
          />
          {question.info && <InputInfo>{question.info}</InputInfo>}
        </>
      );
    case 'longtext':
      return (
        <>
          <TextareaControl
            label={question.label}
            name={question.name}
            required={question.required}
            placeholder={question.placeholder || ''}
            info={question.info}
          />
          {/* {question.info && <InputInfo>{question.info}</InputInfo>} */}
        </>
      );
    case 'date':
      return (
        <>
          <InputControl
            type="date"
            name={question.name}
            label={question.label}
            required={question.required}
            placeholder={question.placeholder || ''}
            info={question.info}
          />
          {/* {question.info && <InputInfo>{question.info}</InputInfo>} */}
        </>
      );
    case 'address':
      return (
        <>
          <AddressInputControl
            name={question.name}
            initialAddress={initialValues[question.name] || {}}
          />
          {question.info && <InputInfo>{question.info}</InputInfo>}
        </>
      );
    case 'singlechoice':
      return (
        <SingleChoice
          name={question.name}
          label={question.label}
          options={question.options || []}
          required={question.required}
          initialValue={initialValues[question.name] || ''}
          currentValues={currentValues}
          allInitialValues={initialValues}
        />
      );
    case 'short-address':
      return (
        <>
          <ShortAddressInputControl name={question.name} />
          {question.info && <InputInfo>{question.info}</InputInfo>}
        </>
      );
    case 'multiplechoice':
      return (
        <>
          <MultipleChoice
            name={question.name}
            label={question.label}
            options={question.options || []}
            required={question.required}
            initialValue={initialValues[question.name] || ''}
            currentValues={currentValues}
            allInitialValues={initialValues}
          />
          {question.info && <InputInfo>{question.info}</InputInfo>}
        </>
      );
    case 'info':
      return (
        <Text fontWeight="bold" fontSize={'medium'} my="15px">
          {question.label}
          <Divider />
        </Text>
      );
    case 'number':
      return (
        <>
          <InputControl
            type="number"
            name={question.name}
            placeholder={question.placeholder || ''}
            label={question.label}
            required={question.required}
            info={question.info}
          />
          {/* {question.info && <InputInfo>{question.info}</InputInfo>} */}
        </>
      );
    default:
      return <Box>Unknown block {question.type}</Box>;
  }
};
