import { useEffect } from 'react';
import { useParams } from 'react-router';
import { ClientIssueNavigation } from '../../../components/IssueNavigation';
import { Loader } from '../../../components/Loader';
import { UserCardWithTags } from '../../../components/UserInfo';
import {
  useGetChatInfoLazyQuery,
  useGetIssueByIdQuery,
} from '../../../graphql';
import { ChatPage } from '../../Chat';

export const ClientIssueChatPage = () => {
  //@ts-ignore
  const { id } = useParams();
  const { data, loading } = useGetIssueByIdQuery({
    variables: { getIssueByIdId: Number(id) },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [request, { data: chatData, loading: chatLoading }] =
    useGetChatInfoLazyQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    request({ variables: { issueId: Number(id) } });
  }, []);

  if (loading || !data?.getIssueById) {
    return <Loader />;
  }

  return (
    <>
      <ClientIssueNavigation
        id={data?.getIssueById.id!}
        title={data?.getIssueById.title!}
        canCancel={data?.getIssueById.canCloseUnsuccessfully!}
        canClose={data?.getIssueById.canCloseSuccessfully!}
        canChat={data?.getIssueById.canChat!}
      />

      {chatLoading || !chatData?.getChatInfo ? (
        <Loader />
      ) : (
        <>
          <UserCardWithTags data={chatData} />
          <ChatPage data={chatData} />
        </>
      )}
    </>
  );
};
