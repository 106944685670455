import { Button, ButtonGroup } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Center } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Icon } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import React, { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { CardHeader } from '../../../components/Card';
import {
  DocumentCategoryForm,
  ICreateDocumentCategoryValues,
} from '../../../forms/DocumentCategoryForm';
import {
  DocumentCategory,
  GetAllDocumentCategoriesDocument,
  useCreateDocumentCategoryMutation,
  useDeleteDocumentCategoryMutation,
  useGetAllDocumentCategoriesQuery,
  useUpdateDocumentCategoryMutation,
} from '../../../graphql';
import { IColumn, SimpleTable } from '../../../utils/table';
import * as FontAwesomeIcons from 'react-icons/fa/index';

export const DocumentCategoriesPage = () => {
  const [currentItem, setCurrentItem] = useState<DocumentCategory | any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, loading } = useGetAllDocumentCategoriesQuery();
  const [create] = useCreateDocumentCategoryMutation();
  const [update] = useUpdateDocumentCategoryMutation();
  const [deleteItem] = useDeleteDocumentCategoryMutation();

  const handleCreatEvent = async (data: ICreateDocumentCategoryValues) => {
    currentItem
      ? await update({
          variables: {
            updateDocumentCategoryId: Number(currentItem.id),
            data: {
              name: {
                en: data.name.en,
                sq: data.name.sq,
              },
              icon: data.icon,
            },
          },
          refetchQueries: [
            {
              query: GetAllDocumentCategoriesDocument,
            },
          ],
        })
      : await create({
          variables: {
            data: {
              name: {
                en: data.name.en,
                sq: data.name.sq,
              },
              icon: data.icon,
            },
          },
          refetchQueries: [
            {
              query: GetAllDocumentCategoriesDocument,
            },
          ],
        });

    onClose();

    setCurrentItem(null);
  };

  const handleDeleteEvent = async (id: number) => {
    await deleteItem({
      variables: {
        deleteDocumentCategoryId: id,
      },
      refetchQueries: [
        {
          query: GetAllDocumentCategoriesDocument,
        },
      ],
    });

    onClose();
  };

  if (loading) {
    return (
      <Center>
        <Spinner></Spinner>
      </Center>
    );
  }

  const columns: IColumn[] = [
    {
      Header: 'Kategoria',
      accessor: 'name',
      tdParams: {
        width: '40%',
      },
    },
    {
      Header: 'Ikona',
      accessor: 'icon',
      Cell: function IconCell(cell: any, row: DocumentCategory) {
        return (
          <Icon
            as={FontAwesomeIcons[row.icon as keyof typeof FontAwesomeIcons]}
            fontSize="lg"
            color="GrayText"
          />
        );
      },
      tdParams: {
        width: '20%',
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function EditCell(cell: any, row: DocumentCategory) {
        return (
          <Button
            variant="link"
            colorScheme="blue"
            onClick={() => {
              setCurrentItem(row);
              onOpen();
            }}
            size="sm"
          >
            Redakto
          </Button>
        );
      },
      tdParams: {
        textAlign: 'right',
      },
    },
    {
      Header: '',
      accessor: '',
      Cell: function DeleteCell(cell: any, row: DocumentCategory) {
        return (
          <Button
            variant="link"
            colorScheme="red"
            size="sm"
            onClick={() => handleDeleteEvent(row.id)}
          >
            Fshi
          </Button>
        );
      },
      tdParams: {
        textAlign: 'right',
      },
    },
  ];

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <CardHeader
              title="Shto një kategori"
              action={<ModalCloseButton />}
            ></CardHeader>
          </ModalHeader>
          <ModalBody>
            <DocumentCategoryForm
              onSubmit={(data) => handleCreatEvent(data)}
              initialValues={{
                ...currentItem,
                name: currentItem?.rawName
                  ? currentItem.rawName
                  : { sq: '', en: '' },
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <CardHeader
        title="Lista e kategorive"
        action={
          <ButtonGroup size="sm" variant="outline">
            <Button
              iconSpacing="1"
              leftIcon={<HiPlus fontSize="1.25em" />}
              onClick={onOpen}
            >
              Shto
            </Button>
          </ButtonGroup>
        }
      ></CardHeader>

      <SimpleTable
        columns={columns}
        data={data?.getAllDocumentCategories || []}
      />
    </>
  );
};
