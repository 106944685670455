export function getMonthName(monthNumber: number): string {
  switch (monthNumber) {
    case 1:
      return 'Janar';
    case 2:
      return 'Shkurt';
    case 3:
      return 'Mars';
    case 4:
      return 'Prill';
    case 5:
      return 'Maj';
    case 6:
      return 'Qershor';
    case 7:
      return 'Korrik';
    case 8:
      return 'Gusht';
    case 9:
      return 'Shtator';
    case 10:
      return 'Tetor';
    case 11:
      return 'Nentor';
    case 12:
      return 'Dhjetor';
    default:
      return '';
  }
}
